<script>
  import TextField1 from '@/components/TextField1/TextField1.vue';
  import InfosText from '@/components/InfosText/InfosText.vue';

  export default {
    components: {
      TextField1,
      InfosText
    },
  }
</script>

<template>
  <div>
    <section>
      <TextField1 label="Taxe froncière" />
      <TextField1 label="Taxe d'habitation" />
    </section>
    <InfosText text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id purus nec in molestie tortor quisque odio. Tellus enim, feugiat et consectetur orci. Morbi ut sit et tempor." />
  </div>
</template>