import {defineStore} from "pinia";
import api from "../api";
import {noEmptyFields, initialList, initProcess } from "@/utils/helpers";
import { equipInfos } from "./New/datas";

const useEquipementStore = defineStore("equipment", {
    state: () =>({
        loading: false,
        status: "",
        error: "",
        equip:{
            ...equipInfos
        },
        getEquipmentsProcess: initProcess,
        getEquipmentProcess: initProcess,
        updateEquipmentProcess: initProcess,
        updatedEquipDatas:[],
        equips:initialList,
        deletionProcess: initProcess,
    }),
    getters:{},
    actions:{
        // fetch all equipments
        async getEquipements(offset){
            try {
                this.getEquipmentsProcess ={
                    loading: true,
                    status:"pending",
                    error:"",
                };
                const resp = await api.getAllEquipments(10, offset);
                if(resp){
                    console.log({resp: resp});
                    if(resp.status === 200){
                        let rows = [];
                        let cols = [
                            "id",
                            "nom_equipement"
                        ];
                        resp.data.map((item) => {
                            let row = [];
                            cols.map((col) => {
                              row.push(item[col]);
                            });
                            rows.push(row);
                          });
                          console.log({ results: rows });
                          this.equips = { ...this.equips, list:rows, total:resp.data.length}
                          this.getEquipmentsProcess = {loading:false, status:"success"}
                    }else{
                        this.getEquipmentsProcess = {
                            loading: false,
                            status: "failure",
                            error:
                              "Une erreur s'est produite, Veuillez réessayer plus tard !",
                          };
                    }
                }
            } catch (error) {
                console.log(error);
                this.getEquipmentsProcess = {
                    loading: false,
                    status: "failure",
                    error: error?.data?.detail,
                  };
            }
        },

        // add equipments
        async addEquipment(){
            if(!this.loading){
                 if(noEmptyFields(this.equip)){
                    this.loading = true;
                    this.status = "pending";
                    try {
                        console.log({newEquipement: this.equip});
                        const resp = await api.addEquipment(this.equip)
                        console.log({resp})
                        if(resp.status === 200){
                            this.loading = false;
                            this.status = "success";
                            location.replace("/travaux/equipements");
                        }else{
                            this.loading = false;
                            this.status = "failure";
                            this.error = resp?.response?.data?.detail;
                        }
                        
                    } catch (error) {
                        this.loading = false;
                        this.status = "failure";
                        this.error = error?.data?.detail;
                        console.log({ error });
                    }
                 }else{
                    this.loading = false;
                    this.status = "failure";
                    this.error =
                      "Vous semblez avoir omis certains des champs obligatoires !";
                 }
            }
        },

        //get equipement info by id
        async getOneEquipment(equipId){
            try {
                this.getEquipmentProcess = {loading:true, status:"pending"}
                const resp = await api.getOneEquipment(equipId)
                if(resp.status === 200){
                    this.equip = { ...resp.data}
                    console.log({equipment: this.equip})
                    this.getEquipmentProcess = {loading:false, status:"success"}
                }else{
                    this.getEquipmentProcess = {loading:false, status:"failure", error:"Une erreur s'est produite !"}
                }
            } catch (error) {
                console.log({error});
                this.getEquipmentProcess = {loading: false, status:"failure"}
            }
        },

        // update equipment
        async updateEquipment(id){
            this.updateEquipmentProcess = {loading:true, status:"pending"}
            let updatedDatas = {}
            this.updatedEquipDatas.map( (name) =>(
                updatedDatas = { ...updatedDatas, [name]: this.equip[name]}
            ))
            updatedDatas = { ...updatedDatas, "id_equipment":id}
            try {
                console.log({updatedDatas})
                const resp = await api.updateEquipment(updatedDatas)
                if(resp.status == 200){
                    console.log({result: this.resp})
                    this.updateEquipmentProcess = {loading:false, status:"success", error:"Opération réussie"}
                } else {
                    this.updateEquipmentProcess = {loading:false, status:"failure", error:"Une erreur s'est produite"}
                }
            } catch (error) {
                console.log({error})
                this.updateEquipmentProcess = {loading:false, status:"failure", error: error?.data.detail}
            }
        },

        // delete equipment by id
        async deleteEquip(id){
            this.deletionProcess ={loading:true, status:"pending"}
            try {
                const resp = await api.deleteEquipement({id_equipment: id})
                if(resp.status == 200){
                    console.log({resp})
                    this.deletionProcess = {loading: false, status: "success"}
                    location.replace("/travaux/equipments")
                }else{
                    this.deletionProcess = {loading: false, status:"failure", error: "Une erreur s'est produite !"}
                }
            } catch (error) {
                this.deletionProcess = {loading: false, status:"failure", error: "Une erreur s'est produite !"}
                console.log({error})
            }
        }
    }
})


export default useEquipementStore;