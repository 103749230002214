import {defineStore} from "pinia";
import api from "../api";
import {noEmptyFields, initialList, initProcess } from "@/utils/helpers";
import { meterInfos } from "./NewMeter/datas";


const useMeterStore = defineStore("meter", {
    state: () =>({
        loading: false,
        status: "",
        error: "",
        meter:{
            ...meterInfos
        },
        getMetersProcess: initProcess,
        getMeterProcess: initProcess,
        updateMeterProcess: initProcess,
        updatedMeterDatas:[],
        meters:initialList,
        deletionProcess: initProcess,
    }),
    getters:{},
    actions:{
        // fetch all meters
        async getMeters(offset){
            try {
                this.getMetersProcess ={
                    loading: true,
                    status:"pending",
                    error:"",
                };
                const resp = await api.getAllMeters(10, offset);
                if(resp){
                    console.log({resp: resp});
                    if(resp.status === 200){
                        let rows = [];
                        let cols = [
                            "id",
                            "numero_pdl",
                            "numero_pce",
                            "nom_compteur",
                            "localisation"
                        ];
                        resp.data.map((item) => {
                            let row = [];
                            cols.map((col) => {
                              row.push(item[col]);
                            });
                            rows.push(row);
                          });
                          console.log({ results: rows });
                          this.meters = { ...this.meters, list:rows, total:resp.data.length}
                          this.getMetersProcess = {loading:false, status:"success"}
                    }else{
                        this.getMetersProcess = {
                            loading: false,
                            status: "failure",
                            error:
                              "Une erreur s'est produite, Veuillez réessayer plus tard !",
                          };
                    }
                }
            } catch (error) {
                console.log(error);
                this.getMetersProcess = {
                    loading: false,
                    status: "failure",
                    error: error?.data?.detail,
                  };
            }
        },

        // add meter
        async addMeter(){
            if(!this.loading){
                 if(noEmptyFields(this.meter)){
                    console.log({newMeter: this.meter});
                    this.loading = true;
                    this.status = "pending";
                    try {
                        console.log({newMeter: this.meter});
                        const resp = await api.addMeter(this.meter)
                        console.log({resp})
                        if(resp.status === 200){
                            this.loading = false;
                            this.status = "success";
                            location.replace("/travaux/meters");
                        }else{
                            this.loading = false;
                            this.status = "failure";
                            this.error = resp?.response?.data?.detail;
                        }
                        
                    } catch (error) {
                        this.loading = false;
                        this.status = "failure";
                        this.error = error?.data?.detail;
                        console.log({ error });
                    }
                 }else{
                    this.loading = false;
                    this.status = "failure";
                    this.error =
                      "Vous semblez avoir omis certains des champs obligatoires !";
                 }
            }
        },

        //get equipement info by id
        async getOneMeter(meterId){
            try {
                this.getMeterProcess = {loading:true, status:"pending"}
                const resp = await api.getOneMeter(meterId)
                if(resp.status === 200){
                    this.meter = { ...resp.data}
                    console.log({meter: this.meter})
                    this.getMeterProcess = {loading:false, status:"success"}
                }else{
                    this.getMeterProcess = {loading:false, status:"failure", error:"Une erreur s'est produite !"}
                }
            } catch (error) {
                console.log({error});
                this.getMeterProcess = {loading: false, status:"failure"}
            }
        },

        // update meter
        async updateMeter(id){
            this.updateMeterProcess = {loading:true, status:"pending"}
            let updatedDatas = {}
            this.updatedMeterDatas.map( (name) =>(
                updatedDatas = { ...updatedDatas, [name]: this.meter[name]}
            ))
            updatedDatas = { ...updatedDatas, "id_meter":id}
            try {
                console.log({updatedDatas})
                const resp = await api.updateMeter(updatedDatas)
                if(resp.status == 200){
                    console.log({result: this.resp})
                    this.updateMeterProcess = {loading:false, status:"success", error:"Opération réussie"}
                } else {
                    this.updateMeterProcess = {loading:false, status:"failure", error:"Une erreur s'est produite"}
                }
            } catch (error) {
                console.log({error})
                this.updateMeterProcess = {loading:false, status:"failure", error: error?.data.detail}
            }
        },

        // delete meter by id
        async deleteMeter(id){
            this.deletionProcess ={loading:true, status:"pending"}
            try {
                const resp = await api.deleteMeter({id_meter: id})
                if(resp.status == 200){
                    console.log({resp})
                    this.deletionProcess = {loading: false, status: "success"}
                    location.replace("/travaux/compteurs")
                }else{
                    this.deletionProcess = {loading: false, status:"failure", error: "Une erreur s'est produite !"}
                }
            } catch (error) {
                this.deletionProcess = {loading: false, status:"failure", error: "Une erreur s'est produite !"}
                console.log({error})
            }
        }
    }
})


export default useMeterStore;