import { path as basePath } from "../WorksHome/datas"

// base url for the pages above this page in the global infrastructure

const baseUrl = basePath[basePath.length-1].url+"/compteurs"

// path to page
export const path = [
  ...basePath,
  {
    label: "compteurs",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Compteurs"

// fonction permettant de mettre les url au bon format en fonction de l'url de base
function url(url){
  return baseUrl+url
}
export const items = [
  {
    text: "Gestion des compteurs",
    buttons: [
      {
        label: "Créer un compteur",
        url: url("/creer"),
        empty: false
      },
    ]
  },

]

