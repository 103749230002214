import FirstPage from '@/views/Extranet/SharedFiles/FirstPage.vue'

// url de base des pages relatives aux fichiers partagés
const baseUrl = "/extranet/fichiers"

const filesRoutes = [
  {
    path: baseUrl,
    name: "extranetFiles",
    component: FirstPage,
  }
];

export default filesRoutes
