<script>
  import RegistersView from '../../RegistersView.vue';
  import RegHeader from '@/containers/Registers/Header/RegHeader.vue'
  import RegForm from '@/containers/Registers/Form/RegForm.vue'
  import MainInfos from './Steps/MainInfos.vue';
  import SuppInfos from './Steps/SuppInfos.vue'
  import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';

  import { path } from '../datas';

  export default {
    components: {
      RegistersView,
      RegHeader,
      RegForm,
      MainInfos,
      SuppInfos,
      ButtonOne
    },
    data(){
      return{
        opened: 'main',
        form: {
          number: '',
          owners: [""],
          beginDate: '',
          endDate: '',
          address: '',
          observation: ''
        },
        path: [
          ...path,
          {
            label: "ajouter",
            url: path[path.length-1].url+"/creer"
          }
        ]
      }
    },
    methods: {
      handleChange(e){
        const {name, value} = e.target;
        this.form = { ...this.form, [name]: value }
      },
      goTo(step){
        this.$emit('opened', step)
        return this.opened = step
      },
    },
  }
</script>

<template>
  <RegistersView :path="path">
    <div class="container">
      <h1 class="container-title"> Ajouter un projet de mandat </h1>
      <RegHeader title="Projet de mandat" />
      <div class="container-body">
        <RegForm @opened="(result) => opened = result" :on="opened">
          <MainInfos
            v-if="opened=='main'"
            :number="form.number"
            :owners="form.owners"
            :beginDate="form.beginDate"
            :endDate="form.endDate"
            :address="form.address"
            :observation="form.observation"
            :onChange="handleChange"
          />
          <SuppInfos v-if="opened=='supp'"/>
        </RegForm>
        <div class="add-project--footer">
          <ButtonOne
            v-if="opened == 'main'"
            text="Suivant"
            @click="() => goTo('supp')"
          />
          <ButtonOne
            v-if="opened == 'supp'"
            text="Terminer"
          />
        </div>
      </div>
    </div>
  </RegistersView>
</template>

<style src="./AddProject.css"></style>