<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';
import { mapWritableState } from 'pinia';
import usePropertyStore from '../../store';
import { pieces } from '../datas';

export default {
    data() {
        return {
            pieces
        }

    },
    components: {
        TextField1
    },
    computed: {
        // gives access to store property inside the component and allows setting it
        ...mapWritableState(usePropertyStore, ['property']),
    },
    methods: {
        onValueSelect() {

        },

    }
}
</script>

<template>
    
        <!-- <div class="flex flex-row">
            <TextField1 small label="Type de location" placeholder="Type de location" :value="property.type_de_location"
                @update:value="newValue => property.type_de_location = newValue" :optional="true"/>
            <TextField1 small label="Catégorie de location" placeholder="Catégorie de location"
                :value="property.categorie_location"
                @update:value="newValue => property.categorie_location = newValue" class="ms-4" :optional="true"/>

        </div> -->

        <div class="row w-full">
            <TextField1 fullWidth label="Zone Pinel" placeholder="Zone Pinel" :value="property.zone_pinel"
            @update:value="newValue => property.zone_pinel = newValue" :optional="true"/>
            <TextField1 fullWidth label="Numéro invariant" placeholder="Numéro invariant" :value="property.num_invariant"
            @update:value="newValue => property.num_invariant = newValue" :optional="true"/>
        </div>
       

        <div class="row w-full">
            <TextField1 fullWidth type="number" label="Surface habitable Pinel (em m2)" placeholder="Surface habitable Pinel"
                :value="property.sur_hab_pinel" @update:value="newValue => property.sur_hab_pinel = newValue" :optional="true"/>
            <TextField1 fullWidth type="number" label="Surface annexe Pinel (en m2)" placeholder="Surface annexe Pinel"
                :value="property.sur_annexe_pinel"
                @update:value="newValue => property.sur_annexe_pinel = newValue" :optional="true"/>
        </div>

        <div class="row w-full">
            <TextField1 fullWidth label="Centre des impôts" placeholder="Centre des impôts" :value="property.centre_impots"
                @update:value="newValue => property.centre_impots = newValue" />
            <TextField1 fullWidth label="Trésorerie" placeholder="Trésorerie" :value="property.tresorerie"
                @update:value="newValue => property.tresorerie = newValue" :optional="true"/>

        </div>


        <div class="row w-full">
            <TextField1 fullWidth label="Dispositif Fiscale" placeholder="Dispositif Fiscale"
            :value="property.dispositif_fiscale"
            @update:value="newValue => property.dispositif_fiscale = newValue" />
        <TextField1 fullWidth label="Régime Fiscale" placeholder="Régime Fiscale" :value="property.regime_fiscale"
            @update:value="newValue => property.regime_fiscale = newValue" />
        </div>
       
        <div class="my-2 flex flex-col justify-start items-start">
            <div class="flexed my-1">
                <input type="checkbox" name="teom" v-model="property.taxe_ordure" id="teom" class="mt-1" >
                <label for="teom">Soumis à la taxe des ordures ménagères(TEOM)</label>
            </div>
            <div class="flexed my-1">
                <input type="checkbox" name="crl" v-model="property.contri_revenue_locatif" id="crl" class="mt-1">
                <label for="crl">Soumis à la contribution sur les revenues locatifs</label>
            </div>
            <div class="flexed my-1">
                <input type="checkbox" name="tb" v-model="property.taxe_bureaux" id="tb" class="mt-1">
                <label for="tb">Soumis à la taxe sur les bureaux</label>
            </div>
            <div class="flexed my-1">
                <input type="checkbox" name="tlv" v-model="property.taxe_logement_vacant" id="tlv" class="mt-1" >
                <label for="tlv">Bien sur une zone soumise à la taxe sur les logements vacants</label>
            </div>
            <div class="flexed my-1">
                <input type="checkbox" name="zone_etendue" v-model="property.zone_tondue" id="zone_etendue" class="mt-1" >
                <label for="zone_etendue">Bien situé en zone étendue</label>
            </div>
        </div>
  
</template>