import FirstPage from '@/views/GED/Repositories/FirstPage.vue'

// url de base des pages relatives aux repertoires
const baseUrl = "/ged/repertoires"

const repositoriesRoutes = [
  {
    path: baseUrl,
    name: "gedRepositories",
    component: FirstPage,
  }
];

export default repositoriesRoutes
