<script>
export default {
  props: {
    message: String
  }
}
</script>

<template>
  <div class="ErrorMessage">
    <p>{{message || "Une erreur s'est produite !"}}</p>
  </div>
</template>

<style>
  .ErrorMessage{
    display: flex;
    min-width: 300px;
    min-height: 50px;
    background-color: var(--color-red_light);
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .ErrorMessage p{
    color: gray;
    font-weight: 500;
    letter-spacing: -0.05px;
    font-size: 15px;
    text-align: center;
    padding: 10px 15px;
  }
</style>
