<script>
import AccountLayer from '../AccountLayer.vue'
import { path, pageTitle } from './datas.js'



import mailIcon from '@/assets/icons/mail-box.svg'

import { mapActions, mapWritableState } from 'pinia'



import useAccountStore from '../store'

export default {
    components: {
       
        AccountLayer: AccountLayer
    },
    data() {
        return {
            pageTitle,
            path,
            assets: {
                mailIcon
            }
        }
    },

    computed: {
            ...mapWritableState(useAccountStore, ['getUserInfosProcess', 'getUserPhotoProcess', 'user', 'updateUserInfosProcess', 'updateUserInfosProcess', 'userChecker', 'isUpdatable', 'modulesInfos', 'photoUrl']),
    },
    methods: {
            ...mapActions(useAccountStore, ['updatePassword']),
            handleChange(e) {
            this.user[e.target.name] = e.target.value
         }
    },
    mounted() {
      this.getUserInfos()
    }
}
</script>
<template>
   
    <AccountLayer :path="path">
        <div class="container">
            <h1 class="container-title"> {{ pageTitle }} </h1>
            <div class="container-body">
                <h1>Hello World</h1>
            </div>
        </div>
    </AccountLayer>
</template>
<style src="./ChangePassword.css"></style>