import client from "@/views/client";
const baseUrl = "/gl/meter";


export const meterApi = {
    addMeter: (data) => client.post(`${baseUrl}/add`, data),
    getOneMeter: (id) => client.get(`${baseUrl}/meter_info/${id}`),
    updateMeter: (data) => client.patch(`${baseUrl}/update_meter`, data),
    // get tenants list with pagnination handeling
    getAllMeters: (limit, offset) => client.get(`${baseUrl}/list_meters/${limit}/${offset}`),
    deleteMeter: (data) => client.delete(`${baseUrl}/delete_meter`, {data})
  }
  
  export default meterApi;