import home1 from '@/assets/icons/dashboard.svg'
import folder1 from '@/assets/icons/folder1.svg'
import folder2 from '@/assets/icons/folder2.svg'
import file1 from '@/assets/icons/file1.svg'
import basket from '@/assets/icons/basket1.svg'
import import1 from '@/assets/icons/import1.svg'
import attachment1 from '@/assets/icons/attachment1.svg'
import owner1 from '@/assets/icons/owner1.svg'
import tenant1 from '@/assets/icons/tenant1.svg'
import goods1 from '@/assets/icons/goods1.svg'
import leases1 from '@/assets/icons/leases1.svg'
import rent1 from '@/assets/icons/rent1.svg'
import expenses1 from '@/assets/icons/expenses1.svg'
import collection1 from '@/assets/icons/collection1.svg'
// import math1 from '@/assets/icons/math1.svg'
// import archives1 from '@/assets/icons/archives1.svg'
// import synchro1 from '@/assets/icons/synchro1.svg'
import profile1 from '@/assets/icons/profile1.svg'
import discussions1 from '@/assets/icons/discussions1.svg'
import users1 from '@/assets/icons/users1.svg'
import cloudFolder1 from '@/assets/icons/cloud-folder1.svg'
import megaphone from '@/assets/icons/megaphone.png'

export const ged = {
  title: "GED",
  asideLinks: [
    {
      label: "Tableau de bord",
      url: "/ged",
      icon: home1
    },
    {
      label: "Dossiers",
      url: "/ged/dossiers",
      icon: folder1
    },
    {
      label: "FIchiers",
      url: "/ged/fichiers",
      icon: file1
    },
    {
      label: "Repositoire",
      url: "/ged/repertoires",
      icon: folder2
    },
    {
      label: "Corbeille",
      url: "/ged/corbeille",
      icon: basket
    }
  ],
  asideButtons: [
    {
      label: "Importer",
      empty: true,
      icon: import1
    },
    {
      label: "Lier un fichier",
      empty: false,
      icon: attachment1
    }
  ],
  pub: {
    title: "Interdum.",
    text: "Dapibus.",
    link: {
      label: "ultricies faucibus at.",
      url: "#"
    }
  }
}

export const marketPlace = {
  title: "Transactions immobilières",
  asideLinks: [
    {
      label: "Vos annonces",
      url: "/marketplace",
      icon: megaphone
    },
    {
      label: "propriétaires",
      url: "/marketplace/proprietaires",
      icon: folder1
    },
    {
      label: "Attachés commerciaux",
      url: "/marketplace/attaches-commerciaux",
      icon: folder1
    },
    {
      label: "Archives",
      url: "/marketplace/archives",
      icon: file1
    },
  ],
  pub: {
    title: "Interdum.",
    text: "Dapibus.",
    link: {
      label: "ultricies faucibus at.",
      url: "#"
    }
  }
}

// données de la side bar du module de gestion locative
export const rentalManagement = {
  title: "Gestion Locative",
  asideLinks: [
    {
      label: "Tableau de bord",
      url: "/gestion-locative",
      icon: home1
    },
    {
      label: "Propriétaires",
      url: "/gestion-locative/proprietaires",
      icon: owner1
    },
    {
      label: "Biens",
      url: "/gestion-locative/biens",
      icon: goods1
    },
    // {
    //   label: "CoPropriétaires",
    //   url: "/gestion-locative/coproprietaires",
    //   icon: owner1
    // },
    {
      label: "Locations",
      url: "/gestion-locative/locataires",
      icon: tenant1
    },
    // {
    //   label: "Co-locataires",
    //   url: "/gestion-locative/co-locataires",
    //   icon: tenant1
    // },

    {
      label: "Baux",
      url: "/gestion-locative/baux",
      icon: leases1
    },
    {
      label: "Loyers / Factures",
      url: "/gestion-locative/loyers",
      icon: rent1
    },
    {
      label: "Dépenses",
      url: "/gestion-locative/depenses",
      icon: expenses1
    },
    {
      label: "encaissements",
      url: "/gestion-locative/encaissements",
      icon: collection1
    },
    // {
    //   label: "Comptabilité",
    //   url: "/gestion-locative/comptabilite",
    //   icon: basket
    // },
    // {
    //   label: "Édition",
    //   url: "/gestion-locative/edition",
    //   icon: math1
    // },
    // {
    //   label: "Archives",
    //   url: "/gestion-locative/archives",
    //   icon: archives1
    // },
    // {
    //   label: "Synchro Bancaire",
    //   url: "/gestion-locative/synchro",
    //   icon: synchro1
    // }
  ]
}

// données de la side bar du module de regitres
export const registers = {
  title: "Registres de mandats",
  asideLinks: [
    {
      label: "Tableau de bord",
      url: "/registres",
      icon: home1
    },
    {
      label: "Registres de mandats",
      url: "/registres/liste",
    },
    {
      label: "Projets de mandats",
      url: "/registres/projets",
    },
    {
      label: "Archives",
      url: "/registres/archives",
    },
  ]
}

export const extranet = {
  title: "Extranet",
  asideLinks: [
    {
      label: "Tableau de bord",
      url: "/extranet",
      icon: home1
    },
    {
      label: "Votre profile",
      url: "/extranet/profile",
      icon: profile1
    },
    {
      label: "Conversations",
      url: "/extranet/conversations",
      icon: discussions1
    },
    {
      label: "Utilisateurs",
      url: "/extranet/utilisateurs",
      icon: users1
    },
    {
      label: "Fichiers Partagés",
      url: "/extranet/fichiers",
      icon: cloudFolder1
    }
  ],
  asideButtons: [
    {
      label: "Importer",
      empty: true,
      icon: import1
    },
    {
      label: "Lier un fichier",
      empty: false,
      icon: attachment1
    }
  ],
  pub: {
    title: "Interdum.",
    text: "Dapibus.",
    link: {
      label: "ultricies faucibus at.",
      url: "#"
    }
  }
}

export const works = {
  title: "Travaux",
  asideLinks: [
    {
      label: "Tableau de bord",
      url: "/travaux",
      icon: home1
    },
    {
      label: "Sinistres",
      url: "/travaux/sinistres",
      icon: profile1
    },
    {
      label: "Diagnostiques",
      url: "/travaux/diagnostiques",
      icon: discussions1
    },
    {
      label: "Détecteurs",
      url: "/travaux/detecteurs",
      icon: users1
    },
    {
      label: "Équipements",
      url: "/travaux/equipements",
      icon: cloudFolder1
    },
    {
      label: "Compteurs",
      url: "/travaux/compteurs",
      icon: cloudFolder1
    },
    {
      label: "Édition",
      url: "/travaux/edition",
      icon: cloudFolder1
    }
  ]
}

export const syndics = {
  title: "Syndic de copropriété",
  asideLinks: [
    {
      label: "Tableau de bord",
      url: "/syndics",
      icon: home1
    },
    {
      label: "Copropriétés",
      url: "/syndics/coproprietes",
      icon: profile1
    },
    {
      label: "Copropriétaires",
      url: "/syndics/coproprietaires",
      icon: discussions1
    },
    {
      label: "AG",
      url: "/syndics/ag",
      icon: users1
    },
    {
      label: "Technique",
      url: "/syndics/technique",
      icon: cloudFolder1
    },
    {
      label: "Edition",
      url: "/syndics/edition",
      icon: cloudFolder1
    },
    {
      label: "Archives",
      url: "/syndics/archives",
      icon: cloudFolder1
    }
  ]
}

// données de la side bar du compte
export const account = {
  title: "Account",
  asideLinks: [
    {
      label: "Votre compte",
      url: "/account",
      icon: home1
    },
    {
      label: "Changer le mot de passe",
      url: "/account/changer-mot-de-passe",
      icon: owner1,
    },
    {
      label: "Facture/Dévis",
      url: "/account/factures-devis",
      icon: owner1
    },
    {
      label: "Expert comptable",
      url: "/account/finances",
      icon: tenant1
    },
    {
      label: "Assistance",
      url: "/account/support",
      icon: tenant1
    }
  ],
  pub: {
    title: "Interdum.",
    text: "Dapibus.",
    link: {
      label: "ultricies faucibus at.",
      url: "#"
    }
  }
}

export const accounting = {
  title: "Comptabilité",
  asideLinks: [
    {
      label: "Tableau de bord",
      // showing dashboard recap infos 
      url: "/comptabilite",
      icon: home1
    },
    {
      label: "Comptes",
      // listing accounting accounts list
      url: "/comptabilite/comptes",
      icon: users1
    },
    {
      label: "Edition",
      url: "/comptabilite/edition",
      icon: users1

    },
    {
      label: "Export",
      // main account settings
      url: "/comptabilite/exports",
      icon: folder1
    },
    {
      label: "Transaction sans propriétaire",
      // main account settings
      url: "/comptabilite/transaction-sans-proprietaireZ",
      icon: folder1
    }
  ]
}