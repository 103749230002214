import FirstPage from '@/views/RentalManagement/Leases/FirstPage.vue'
import NewLeaseVue from '@/views/RentalManagement/Leases/New/NewLease.vue'
import LeaseViewVue from '@/views/RentalManagement/Leases/LeaseView/LeaseView.vue'
// url de base des pages relatives aux baux
const baseUrl = "/gestion-locative/baux"

const leasesRoutes = [
  {
    path: baseUrl,
    name: "leasesList",
    component: FirstPage,
  },
  {
    path: baseUrl+"/creer",
    name: "newLease",
    component: NewLeaseVue,
  },
  {
    path: baseUrl+"/:leaseId",
    name: "lease",
    component: LeaseViewVue,
  }
];

export default leasesRoutes
