import RegistersHome from '@/views/Registers/RegistersHome/RegistersHome.vue'
import registersArchives from './archives';
import mandateProjectsRoutes from './mandateProject';
import mandateRegisterRoutes from './mandateRegister';


const registerRoutes = [
  {
    path: "/registres",
    name: "registres",
    component: RegistersHome,
  },
  ...mandateRegisterRoutes,
  ...mandateProjectsRoutes,
  ...registersArchives
];

export default registerRoutes;