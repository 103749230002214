<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';
import { mapWritableState } from 'pinia';
import useCoTenantStore from '../../store';

export default {
  components: {
    TextField1
  },
  computed: {
    // gives access to store owner inside the component and allows setting it
    ...mapWritableState(useCoTenantStore, ['co_tenant']),
  }
}
</script>

<template>
  <TextField1 fullWidth  label="Civilités" placeholder="Mr., Mme, ..." :value="co_tenant.civility"  @update:value="newValue => co_tenant.civility = newValue" />
    <div class="row w-full">
    <TextField1 fullWidth  label="Prénom" placeholder="prénom" :value="co_tenant.first_name"  @update:value="newValue => co_tenant.first_name = newValue" />
    <TextField1 fullWidth  label="Nom" placeholder="nom" :value="co_tenant.lastname"  @update:value="newValue => co_tenant.lastname = newValue" />
  </div>

  <TextField1 label="Email" placeholder="email" :value="co_tenant.email"  @update:value="newValue => co_tenant.email = newValue" fullWidth />

  <div class="row w-full">
    <TextField1 label="Date de naissance" type="date" :value="co_tenant.date_of_birth"  @update:value="newValue => co_tenant.date_of_birth = newValue" fullWidth  />
  
    <TextField1 label="Lieu de naissance" placeholder="Lieu de naissance" :value="co_tenant.place_of_birth"  @update:value="newValue => co_tenant.place_of_birth = newValue" fullWidth  />
  </div>
  
  <TextField1 fullWidth  label="Profession" placeholder="Profession" :value="co_tenant.profession"  @update:value="newValue => co_tenant.profession = newValue" />
    
  <div class="row w-full">
    <TextField1 fullWidth  label="Code postal" placeholder="code postal" :value="co_tenant.zip_code"  @update:value="newValue => co_tenant.zip_code = newValue"/>
    <TextField1 fullWidth  label="Fax" placeholder="fax" :value="co_tenant.fax"  @update:value="newValue => co_tenant.fax = newValue" />
  </div>
  <div class="row w-full">
    <TextField1 label="Téléhone" placeholder="téléphone" :value="co_tenant.phone"  @update:value="newValue => co_tenant.phone = newValue" fullWidth />
    <TextField1 label="Portable" placeholder="portable" :value="co_tenant.cell_phone"  @update:value="newValue => co_tenant.cell_phone = newValue" fullWidth  />
  </div>
</template>