<script>
import TextField1 from '@/components/TextField1/TextField1.vue'
import AuthHeader from '@/containers/Auth/AuthHeader/AuthHeader.vue'

import mailIcon from '@/assets/icons/mail-box.svg'
import phoneIcon from '@/assets/icons/phone-box.svg'
import { mapActions, mapWritableState } from 'pinia'
import useAuthStore from '../store'

import { useRouter } from 'vue-router'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
export default {
  components: {
    TextField1,
    AuthHeader,
    ButtonOne,
    ErrorMessage
},
  data() {
    return {
      pageTitle: "Login",
      router: useRouter(),
      code: "",
      assets: {
        mailIcon,
        phoneIcon
      }
    }
  },
  methods: {
    ...mapActions(useAuthStore, ['checkResetPasswordCode', 'resetPassword']),
    handleChange(e) {
      this.user[e.target.name] = e.target.value
    }
  },
  computed: {
    email() {
      return this.$route.query.email;
    },
    step() {
      return this.$route.query.step
    },
    ...mapWritableState(useAuthStore, ['user', 'resetPasswordProcess'])
  },
  mounted(){
    if(this.step == 1 && this.code === ""){
      this.router.push("/mot-de-passe-oublie/nouveau/?email="+this.email+"&step=0")
    }
  }
}
</script>

<template>
  <div class="page auth-page">
    <div class="auth-box reset-pw">
      <AuthHeader title="Réinitialisation de mot de passe" />

      <form @submit.prevent="step == '0' ? checkResetPasswordCode(router, code, email) : resetPassword(email, code)">
        <section>
          <div class="rp-section--header">
            <img :src="assets.mailIcon" alt="">
            <div v-if="step == 0">
              <h4>Nous vous avons envoyé un code à 6 charactères</h4>
              <p>Veuillez le consulter votre boîte mail et entrer le code reçu.</p>
            </div>
            <div v-else>
              <h4>Entrez votre mot de passe</h4>
              <p>Veuillez enseigner votre nouveau mot de passe</p>
            </div>
          </div>
          <div v-if="step == 0">
            <TextField1 small type="code" :onChange="(e) => code = e.target.value" :value="code" name="code" placeholder="Code à 06 charactères" />
          </div>
          <div v-else>
            <TextField1 small type="password" :onChange="handleChange" :value="user.password" name="password" placeholder="Votre mot de passe" />
            <TextField1 small type="password" :onChange="handleChange" :value="user.password_confirm" name="password_confirm" placeholder="Confirmez votre mot de passe" />
          </div>
        </section>
        <section>
          <div class="rp-section--header">
            <img :src="assets.phoneIcon" alt="">
            <div>
              <h4>Contactez le service client</h4>
              <p>06 65 65 45</p>
            </div>
          </div>
        </section>
        <ErrorMessage v-if="resetPasswordProcess.status === 'failure'" :message="resetPasswordProcess.error" />
        <ButtonOne isAsync text="Confirmez" :loading="resetPasswordProcess.loading" :status="resetPasswordProcess.status" />
      </form>

      <router-link class="rp-no--account" to="/signup"><p>Inscrivez vous !</p></router-link>
    </div>
  </div>
</template>

<style src="../Auth.css"></style>
<style src="../SignUp.css"></style>
<style src="./ResetPW.css"></style>