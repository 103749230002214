import { path as basePath } from '../SyndicsHome/datas'

// path

export const path = [
  ...basePath,
  {
    label: "édition",
    url: basePath[basePath.length-1].url+"/edition"
  }
]

// page title value
export const pageTitle = "Édition"

// form steps
export const formSteps = [
  {
    name: "references",
    label: "Références"
  },
  {
    name: "address",
    label: "Adresse"
  },
  {
    name: "iban",
    label: "IBAN"
  },
  {
    name: "exercises",
    label: "Exercices"
  },
  {
    name: "lotsOfProperty",
    label: "Lots de la propriété"
  },
  {
    name: "finences",
    label: "Finances"
  },
  {
    name: "moreInfos",
    label: "Plus d'information"
  },
  {
    name: "edm",
    label: "ged"
  },
  {
    name: "contracts",
    label: "Contracts"
  },
  {
    name: "diagnostic",
    label: "Diagnostic"
  },
  {
    name: "equipements",
    label: "Equipements"
  },
  {
    name: "insurance",
    label: "Assurances"
  },
]