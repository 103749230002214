import FinancesHome from '@/views/Finances/FinancesHome/FinancesHome.vue'

const financesRoutes = [
  {
    path: "/finances",
    name: "finances",
    component: FinancesHome,
  },
];

export default financesRoutes;