// account type datas
export const accountTypes = [
  {
    name: 'Particulier',
    type: "particulier",
    text: 'Créez un compte particulier et gérez vos biens.',
    popUp: {
      texts: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus feugiat dolor in nibh sed rhoncus. Adipiscing odio in lacus, mauris risus. Mauris urna a enim lobortis tincidunt turpis. Nulla mi tristique aliquet habitant nunc, quam dictumst. Non in dictumst in lobortis. Quisque amet laoreet tellus, amet, aliquet. Pharetra sollicitudin sagittis id et tortor id sagittis, sit blandit.',
        'Elit, morbi arcu convallis cras. Amet diam elementum facilisis ut tortor. Adipiscing etiam sit euismod nulla at cursus cursus nisl. Viverra neque, nunc et, leo dolor varius leo ullamcorper morbi. Tortor sed venenatis sagittis, in odio justo est id cursus. Ornare diam et in semper quis. Faucibus amet tempus, egestas mattis vitae lacus. Rutrum donec mi felis nulla enim vitae lacus, quam enim. Augue nisl fames nibh libero lectus vel nam.',
        'Eget velit lorem vestibulum arcu lectus pellentesque. Convallis imperdiet auctor pellentesque malesuada sollicitudin. Id neque gravida integer mollis netus ullamcorper dolor. Mattis velit id sem bibendum quis urna nunc at. Ante mi molestie amet mauris ultricies imperdiet platea. Posuere molestie sed donec volutpat ultricies sem commodo mollis enim. Sed enim feugiat sagittis risus at sed sit. Morbi a adipiscing ultricies pellentesque ultrices netus enim. Fermentum quam in hac velit morbi malesuada velit dolor. '
      ]
    }
  },
  {
    name: 'Professionnel',
    type: "pro",
    text: 'Créez un compte professionnel. Centralisez et optimisez la gestion des biens de vos clients.',
    popUp: {
      texts: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus feugiat dolor in nibh sed rhoncus. Adipiscing odio in lacus, mauris risus. Mauris urna a enim lobortis tincidunt turpis. Nulla mi tristique aliquet habitant nunc, quam dictumst. Non in dictumst in lobortis. Quisque amet laoreet tellus, amet, aliquet. Pharetra sollicitudin sagittis id et tortor id sagittis, sit blandit.',
        'Elit, morbi arcu convallis cras. Amet diam elementum facilisis ut tortor. Adipiscing etiam sit euismod nulla at cursus cursus nisl. Viverra neque, nunc et, leo dolor varius leo ullamcorper morbi. Tortor sed venenatis sagittis, in odio justo est id cursus. Ornare diam et in semper quis. Faucibus amet tempus, egestas mattis vitae lacus. Rutrum donec mi felis nulla enim vitae lacus, quam enim. Augue nisl fames nibh libero lectus vel nam.',
        'Eget velit lorem vestibulum arcu lectus pellentesque. Convallis imperdiet auctor pellentesque malesuada sollicitudin. Id neque gravida integer mollis netus ullamcorper dolor. Mattis velit id sem bibendum quis urna nunc at. Ante mi molestie amet mauris ultricies imperdiet platea. Posuere molestie sed donec volutpat ultricies sem commodo mollis enim. Sed enim feugiat sagittis risus at sed sit. Morbi a adipiscing ultricies pellentesque ultrices netus enim. Fermentum quam in hac velit morbi malesuada velit dolor. '
      ]
    }
  },
  {
    name: 'Entreprise',
    type: "entreprise",
    text: 'Créez un compte entreprise. Administrez les biens de vos clients, vos vendeurs et toutes vos partie prenantes.',
    popUp: {
      texts: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus feugiat dolor in nibh sed rhoncus. Adipiscing odio in lacus, mauris risus. Mauris urna a enim lobortis tincidunt turpis. Nulla mi tristique aliquet habitant nunc, quam dictumst. Non in dictumst in lobortis. Quisque amet laoreet tellus, amet, aliquet. Pharetra sollicitudin sagittis id et tortor id sagittis, sit blandit.',
        'Elit, morbi arcu convallis cras. Amet diam elementum facilisis ut tortor. Adipiscing etiam sit euismod nulla at cursus cursus nisl. Viverra neque, nunc et, leo dolor varius leo ullamcorper morbi. Tortor sed venenatis sagittis, in odio justo est id cursus. Ornare diam et in semper quis. Faucibus amet tempus, egestas mattis vitae lacus. Rutrum donec mi felis nulla enim vitae lacus, quam enim. Augue nisl fames nibh libero lectus vel nam.',
        'Eget velit lorem vestibulum arcu lectus pellentesque. Convallis imperdiet auctor pellentesque malesuada sollicitudin. Id neque gravida integer mollis netus ullamcorper dolor. Mattis velit id sem bibendum quis urna nunc at. Ante mi molestie amet mauris ultricies imperdiet platea. Posuere molestie sed donec volutpat ultricies sem commodo mollis enim. Sed enim feugiat sagittis risus at sed sit. Morbi a adipiscing ultricies pellentesque ultrices netus enim. Fermentum quam in hac velit morbi malesuada velit dolor. '
      ]
    }
  },
]

export const initialUser = {
  nom: "",
  prenom: "",
  email: "",
  password: "",
  password_confirm: "",
  pays: "",
  ville: "",
  telephone: 0,
  adresse: "",
  complement_adresse: "",
  code_postal: "",
  user_type: ""
}

export const initialUserPwd = {
  current_password: "",
  password: "",
  confirm_password: "",
  email: ""
}

export const initialProInfos = {
  nom_entreprise: "",
  email_pro: "",
  id_professionnel: "",
  siret_siren: "",
  tva: ""
}

export const initialCompanyInfos = {
  nom_entreprise: "",
  type_entreprise: "",
  email_pro: "",
  siret_siren: "",
  tva: ""
}

export const initialUrlPhoto ={
  url : ""
}

export const userTypes = [
  {
    name: "particulier",
    title: "Particulier"
  },
  {
    name: "pro",
    title: "Professionnel"
  },
  {
    name: "entreprise",
    title: "Entreprise"
  },
]