<!-- Formulaire de création de projet de mandat -->
<script>
  export default {
    emits: ['opened'],
    props: {
      goods: Boolean,
      on: String,
    },
    data(){
      return{
        opened: "main"
      }
    },
    methods: {
      open(step){
        this.$emit('opened', step)
        return this.opened = step
      },
    }
  }
</script>

<template>
  <div class="reg-form">
    <div class="row-centered reg-form--steps">
      <span 
        class="step-link" 
        :class="{ current: on == 'main' }"
        @click="() => open('main')"
      >
        Infos principales
      </span>
      <span 
        class="step-link" 
        :class="{ current: on == 'supp' }"
        @click="() => open('supp')"
      >
        Informations supplémentaires
      </span>
      <span 
        class="step-link" 
        :class="{ current: on == 'goods' }" 
        @click="() => open('goods')"
        v-if="goods"
      >
        Biens liés
      </span>
    </div>
    <slot></slot>
  </div>
</template>

<style src="./RegForm.css"></style>