import FirstPage from '@/views/Registers/MandateRegister/FirstPage.vue'

const baseUrl = '/registres/liste'

const mandateRegisterRoutes = [
  {
    path: baseUrl,
    name: "mandateRegisters",
    component: FirstPage
  }
]

export default mandateRegisterRoutes