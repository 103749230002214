import LogIn from '@/views/Auth/LogIn.vue'
import TPLogin from '@/views/Auth/TPLogin.vue'

const baseUrl = "/login"

const logInRoutes = [
  {
    path: baseUrl,
    name: "Login",
    component: LogIn,
  },
  {
    path: baseUrl + "/tiers",
    name: "thirdPartLogin",
    component: TPLogin
  }
];

export default logInRoutes;