import CoOwnersList from '../../views/RentalManagement/CoOwnersList/CoOwnersList.vue'
import CoOwnerView from '../../views/RentalManagement/CoOwnersList/CoOwnerView/CoOwnerView.vue'
import NewCoOwner from '../../views/RentalManagement/CoOwnersList/NewCoOwner/NewCoOwner.vue'

// url de base des pages relatives aux propriétaires
const baseUrl = "/gestion-locative/coproprietaires"

const coOwnersRoutes = [
  {
    path: baseUrl,
    name: "coOwnersList",
    component: CoOwnersList,
  },
 
  {
    path: baseUrl+"/creer",
    name: "newCoOwner",
    component: NewCoOwner
  },

  {
    path: baseUrl+"/:coOwnerId",
    name: "coOwner",
    component: CoOwnerView
  }
];

export default coOwnersRoutes
