<script>
  import MarketPlace from '../MarketPlace.vue'
  import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
  import TextField1 from '@/components/TextField1/TextField1.vue'
  import TableOne from '@/containers/TableOne/TableOne.vue'

  import { path, pageTitle } from './datas'
  import filter from '@/assets/icons/filter.svg'
  import search from '@/assets/icons/research1.svg'

  export default {
    components: {
      MarketPlace,
      ButtonOne,
      TextField1,
      TableOne
    },
    data(){
      return {
        path,
        pageTitle,
        icons: {
          filter,
          search
        },
        searchKey: "",
      }
    },
    methods: {
      handleSubmit(){

      },
      handleChange(e){
        const term = e.target.value;
        this.searchKey = term;
      },
    }
  }
</script>

<template>
  <MarketPlace :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="box1 marketplace--header">
        <div class="header-top">
          <p class="header-top--title">
            <img :src="icons.filter" alt="">
            Contacts archivés
          </p>
          <ButtonOne text="+ Vider les archives" />
        </div>
        <div class="flexed header-content">
          <form class="flexed" @submit.prevent="handleSubmit">
            <TextField1 :onChange="handleChange" :value="searchKey" placeholder="Recherchez un contact archivé" />
            <ButtonOne type="submit" :leftIcon="icons.search" />
          </form>
        </div>
      </div>
      <div class="container-body">
        <TableOne title="Liste des Contacts archivés" noSearch />
      </div>
    </div>
  </MarketPlace>
</template>

<style src="./FirstPage.css"></style>