<!-- Box de présentation d'un bien immobilier -->
<script>
  export default {
    props: {
      image: Image,
      title: String,
      subTitle: String, 
      price: String, 
      charges: Boolean,
      type: String,
      furnished: Boolean,
      area: Number,
      levels: Number,
      minParkingPlaces: Number
    },
    data(){
      return {
        supInfos:[
          {
            label: "Type de bien",
            value: this.type
          },
          {
            label: "Meublé / non meublé",
            value: this.furnished
          },
          {
            label: "Surface",
            value: this.area
          },
          {
            label: "Nbre de niveaux",
            value: this.levels
          },
          {
            label: "Place parking",
            value: this.minParkingPlaces
          }
        ]
      }
    }
  }
</script>

<template>
  <div class="product-preview">
    <div class="product--image">
      <img :src="image" alt="" />
    </div>
    <div class="product-preview--content">
      <div class="product--infos">
        <h1 class="product-title">{{ title }}</h1>
        <p class="product-subtitle">{{ subTitle }}</p>
        <div class="product-price">{{ price }} € <span>{{ `(${charges ? 'Charges Comprises' : 'Hors Charges'})` }}</span></div>
      </div>
      <div class="product--sup-infos">
        <div v-for="(infos, index) in supInfos" :key="infos.label+index" class="product-supinfo">
          <div><div class="dot2"></div> {{ infos.label }}</div>
          <span>{{ infos.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./ProductPreview.css"></style>