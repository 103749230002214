<script>
import { textCutter } from '@/utils/helpers';

export default {
  props: {
    value: String,
    type: String,
    label: String,
    name: String,
    placeholder: String,
    id: String,
    textarea: Boolean,
    small: Boolean,
    rowed: Boolean,
    onChange: Function,
    optional: Boolean,
    helpertext: String,
    fullWidth: Boolean
  },
  emits: ["update:value"],
  data() {
    return {
      ht: textCutter(this.helpertext, 7),
      showFullHelpertext: false
    }
  }
};
</script>

<template>
  <div
    class="textfield--container"
    :class="{ rowed: rowed, 'text-area': textarea, 'textfield--container__small': small, 'textfield--container__full': fullWidth }"
  >
    <div v-if="label" class="row-centered textfield--label">
      <label :for="id"
        >{{ label }} <span>:</span></label
      >
      <p class="required-tag" v-if="!optional">*</p>
    </div>
    <textarea
      v-if="textarea"
      class="typing-zone"
      :value="value"
      @input="(e) => onchange && onChange(e)"
      :placeholder="placeholder"
      :name="name"
      :id="id"
      :required="!optional"
    />
    <input
      v-else-if="onChange"
      :required="!optional"
      class="typing-zone"
      :class="{ small: small, rowed: rowed - input }"
      :value="value"
      @input="(e) => onChange && onChange(e)"
      :placeholder="placeholder"
      :name="name"
      :type="type"
      :id="id"
    />
    <input
      v-else
      :required="!optional"
      class="typing-zone"
      :class="{ small: small, rowed: rowed - input }"
      :placeholder="placeholder"
      :name="name"
      :type="type"
      :id="id"
      :value="value"
      @input="$emit('update:value', $event.target.value)"
    />
    <!-- Add a helper text to comment the field -->
    <div v-if="helpertext" class="textfield--helpertext">
      <p>{{ showFullHelpertext ? helpertext : ht }}</p>
      <span
        @click="showFullHelpertext = !showFullHelpertext" 
        :title="showFullHelpertext ? 'Cliquez pour réduire le texte' : 'Cliquez pour voir plus'"
      >
        {{showFullHelpertext ? '-' : '+'}}
      </span>
    </div>
  </div>
</template>

<style src="./TextField1.css"></style>
