<script>
  import closeIcon from '@/assets/icons/close1.svg'

  export default {
    props: {
      images: Array
    },
    data(){
      return{
        assets: {
          closeIcon
        },
        currentImages: this.images
      }
    },
    methods: {
      removeImage(image){
        this.currentImages = this.currentImages.filter((img) => img !== image)
      }
    }
  }
</script>

<template>
  <div>
    <section class="flexed">
      <div
        v-for="image in currentImages"
        :key="image"
      >
        <img :src="image" alt="" />
        <img :src="assets.closeIcon" alt="" @click="removeImage(image)"/>
      </div>
    </section>

    <section>
      <p class="title">Importer une image</p>
      <div>
        drag and drop component
      </div>
    </section>
  </div>
</template>

<style>

</style>