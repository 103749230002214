<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';

import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
import {mapActions, mapWritableState } from 'pinia';
import usePropertyStore from '../../store';
import useOwnerStore from '@/views/RentalManagement/OwnersList/store';
import { pieces, loyers } from '../datas';

export default {
  data(){
    return{
     
      pieces,
      loyers
    }

  },
  components: {
    TextField1, ButtonOne
  },
  computed: {
    // gives access to store property inside the component and allows setting it
    ...mapWritableState(usePropertyStore, ['property', 'types']),
    ...mapWritableState(useOwnerStore, ['owners',])
  },
  methods:{
    goTo(){
     this.$router.push("/gestion-locative/proprietaires/creer")
    },
    ...mapActions(useOwnerStore, ['getOwners']),
    ...mapActions(usePropertyStore, ['getPropertyTypes']),
    handleFloatValue(name, newValue) {
    
      this.property[name] = parseFloat(newValue);
      console.log({float : parseFloat(newValue)});
      
    },

    handleStringValue(name, newValue) {
    
    this.property[name] = newValue;
    console.log({String : newValue});
    
  },

  },
  mounted() {
    this.getOwners(0);
    this.getPropertyTypes(0);
    console.log(this.owners);
    
   
  },
  watch: {
    offset(value) {
      this.getOwners(value);
      this.getPropertyTypes(value);
     
    }

   
  }
}
</script>

<template>
  <div class="row w-full">
   <div class="flex flex-col min-w-[50%]">
    <div class="flex flex-row mb-2"><label>Propriétiaire <span>: </span></label>
    <p class="required-tag text-red ms-1">*</p></div>
    <select v-model="property.id_owner" class="form-select rounded-md">
      <option disabled value="">Selectionnez</option>
      <option v-for="(item, i) in owners.list" :key="i" :value="item[0]">
      {{ item[2]}}</option>
    </select>
    <!-- <div v-for="(item, i) in owners.list" :key="i">{{ item }}</div> -->
   </div>
    
    <router-link class="path-link mt-8 ms-10" :to="{path:'/gestion-locative/proprietaires/creer'}"><ButtonOne text="Nouveau" /></router-link>
  </div>
  <div class="row  w-full">
    <TextField1 fullWidth label="Type de biens" placeholder="Type de biens" :value="property.type_de_bien"  @update:value="newValue => property.type_de_bien= newValue"/>
    <div class="flex ms-3 flex-col min-w-[35%]">
      <div class="flex flex-row mb-2"><label>Type de location<span>: </span></label>
      <p class="required-tag text-red ms-1">*</p></div>
      <select v-model="property.type_location" class="form-select rounded-md">
        <option disabled value="">Selectionnez</option>
        <option v-for="(item, i) in types.list" :key="i" :value="item[0]">
        {{ item[1]}}</option>
      </select>
    </div>
  </div>
  <div class="row w-full">
    <div class="flex ms-3 flex-col min-w-[35%]">
      <div class="flex flex-row"><label>Type de loyer<span>: </span></label>
      <p class="required-tag text-red ms-1">*</p></div>
      <select v-model="property.rent_type" class="form-select rounded-md">
        <option v-for="(item, i) in loyers" :key="i" >
        {{ item.value}}</option>
      </select>
    </div>
    <TextField1 fullWidth class="ms-4" type="number" label="Montant journalier" placeholder="Montant journalier" :value="property.daily_amount"  @update:value="newValue => handleFloatValue('daily_amount', newValue)" :optional="true"/>
   
  </div>
  <div class="row w-full ">
    <TextField1  fullWidth type="number" label="Montant hebdomadaire" placeholder="Montant hebdomadaire" :value="property.weekly_amount"  @update:value="newValue => handleFloatValue('weekly_amount', newValue)" :optional="true"/>
    <TextField1  fullWidth type="number" label="Montant mensuel" placeholder="Montant mensuel" :value="property.monthly_amount"  @update:value="newValue => handleFloatValue('monthly_amount', newValue)" :optional="true"/>
  </div>
  <div class="row w-full ">
    <TextField1 fullWidth label="Pays" placeholder="Pays" :value="property.pays"  @update:value="newValue => property.pays= newValue"/>
    <TextField1  fullWidth label="Ville" placeholder="Ville" :value="property.ville"  @update:value="newValue => property.ville= newValue"/>
  </div>
  <div class="row w-full ">
    <TextField1 fullWidth label="Adresse 1" placeholder="Adresse 1" :value="property.adresse"  @update:value="newValue => property.adresse= newValue"/>
    <TextField1  fullWidth label="Adresse 2" placeholder="Adresse 2" :value="property.complement_add"  @update:value="newValue => property.complement_add= newValue" :optional="true"/>
  </div>
  <div class="row w-full ">
    <TextField1 small label="Code postale" placeholder="Code postale" :value="property.code_postale"  @update:value="newValue => property.code_postale= newValue"/>
  </div>
  <div class="flex flex-row w-full ">
    <div class="flex flex-col min-w-[25%]">
    <div class="flex flex-row mb-2"><label>Nbre d'escaliers <span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model="property.nb_escalier" @change="handleStringValue('nb_escalier', property.nb_escalier)" class="form-select  rounded-md">
     
      <option v-for="(escalier, i) in pieces" :key="i">
      {{ escalier.value}}</option>
    </select>
   </div>
   <div class="flex flex-col min-w-[25%] ms-4">
    <div class="flex flex-row mb-2"><label>Nbre de portes <span>: </span></label>
    <p class="required-tag text-red ms-1">*</p></div>
    <select v-model="property.nb_porte" @change="handleStringValue('nb_porte', property.nb_porte)" class="form-select  rounded-md">
     
      <option v-for="(porte, i) in pieces" :key="i">
      {{ porte.value}}</option>
    </select>
   </div>
   <div class="flex flex-col min-w-[25%] ms-4">
    <div class="flex flex-row mb-2"><label>Nbre d'étages <span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model="property.nb_etage" @change="handleStringValue('nb_etage', property.nb_etage)" class="form-select  rounded-md">
     
      <option v-for="(etage, i) in pieces" :key="i">
      {{ etage.value}}</option>
    </select>
   </div>
  </div>
</template>