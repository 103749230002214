import { path as basePath } from "../WorksHome/datas"

import dpe from '@/assets/logos/diagnotics/dpe.svg'
import amiante from '@/assets/logos/diagnotics/amiante.svg'
import plomb from '@/assets/logos/diagnotics/plomb.svg'
import termites from '@/assets/logos/diagnotics/termites.svg'
import loicarrez from '@/assets/logos/diagnotics/loicarrez.svg'
import electricity from '@/assets/logos/diagnotics/electricity.svg'
import gaz from '@/assets/logos/diagnotics/gaz.svg'
import erp from '@/assets/logos/diagnotics/erp.svg'

// base url for the pages above this page in the global infrastructure
const baseUrl = "/travaux/diagnostiques"

// path to page
export const path = [
  ...basePath,
  {
    label: "diagnostiques",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Diagnostiques"

export const diagnostics = [
  {
    title: "dpe",
    amount: 0,
    expired: 0,
    comming: 0,
    logo: dpe
  },
  {
    title: "loi carrez",
    amount: 0,
    expired: 0,
    comming: 0,
    logo: loicarrez
  },
  {
    title: "amiante",
    amount: 0,
    expired: 0,
    comming: 0,
    logo: amiante
  },
  {
    title: "électricité",
    amount: 0,
    expired: 0,
    comming: 0,
    logo: electricity
  },
  {
    title: "plomb",
    amount: 0,
    expired: 0,
    comming: 0,
    logo: plomb
  },
  {
    title: "gaz",
    amount: 0,
    expired: 0,
    comming: 0,
    logo: gaz
  },
  {
    title: "termites",
    amount: 0,
    expired: 0,
    comming: 0,
    logo: termites
  },
  {
    title: "erp",
    amount: 0,
    expired: 0,
    comming: 0,
    logo: erp
  },
]