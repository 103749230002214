<script lang="js">
import RentalManagement from '../../RentalManagement.vue'

import { mapActions, mapWritableState } from 'pinia'

import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import { path, pageTitle, formSteps} from './datas'
import TextField1 from '@/components/TextField1/TextField1.vue';

import usePropertyStore from '../store'



export default {
    components: {
        RentalManagement,
       
        ButtonOne,
        EditionForm,
        TextField1
    },
    data() {
        return {
            path,
            pageTitle,
            steps:formSteps,
            currentStep:0,

        }
    },
    computed: {
        ...mapWritableState(usePropertyStore, ['types', 'goods', 'equips', 'loading', 'status', 'error', 'furniture', 'furnitures']),

    },
    methods: {
        ...mapActions(usePropertyStore, ['getPropertyTypes', 'getProperties', 'addEquipment', 'getListFurnitures', 'getEquipments']),
        findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    },
    reload() {
      location.reload()
    },
    handleSelect(event){
      
      this.getListFurnitures(event.target.value, 0);
     
    },
    handleEquip(event){
    
        this.furniture = {...{ "id_equipment" : event.target.value}};
        console.log(this.furniture);
    }
    },
    mounted() {
        this.getPropertyTypes(0);
        this.getProperties(0);
        this.getEquipments(0);
      
    },
    watch: {
        offset(value) {
            this.getPropertyTypes(value);
            this.getProperties(value);
            this.getEquipments(value);
        }
    }
} 
</script>
<template>
    <RentalManagement :path="path">
        <div class="container">
            <h1 class="container-title"> {{ pageTitle }}</h1>
            <div class="container-body">
                <EditionForm :save="addEquipment" :steps="steps" @step="(step)" :loading="loading" :status="status">
                    <template #form>
                        <section class="form-part" v-if="currentStep == findIndex('general')">
                        <div class="flex flex-row items-start w-full">
                            <div class="flex flex-col min-w-[40%]">
                                <div class="flex flex-row mb-2"><label>Bien <span>: </span></label>
                                    <p class="required-tag text-red ms-1">*</p>
                                </div>
                                <select v-model="furniture.id_bien_info" class="form-select rounded-md">
                                    <option disabled value="">Selectionnez</option>
                                    <option v-for="(item, i) in goods.list" :key="i" :value="item[0]">
                                        {{ item[1] }}</option>
                                </select>
                               
                            </div>
                            <div class="flex flex-col min-w-[40%] ms-4">
                                <div class="flex flex-row mb-2"><label>Type de bien <span>: </span></label>
                                    <p class="required-tag text-red ms-1">*</p>
                                </div>
                                <select  @change="handleSelect($event)" class="form-select rounded-md">
                                    <option  value="">Selectionnez</option>
                                    <option v-for="(item, i) in types.list" :key="i" :value="item[0]">
                                        {{ item[1] }}</option>
                                </select>
                               
                            </div>
                        </div>
                        <div class="flex flex-row items-start  w-full">
                            <div class="flex flex-col min-w-[50%] mt-2">
                                <div class="flex flex-row mb-2"><label>Equipement <span>: </span></label>
                                    <p class="required-tag text-red ms-1">*</p>
                                </div>
                                <select v-model="furniture.id_equipement" class="form-select rounded-md">
                                    <option disabled value="">Selectionnez</option>
                                    <option v-for="(item, i) in furnitures.list" :key="i" :value="item[0]">
                                        {{ item[2] }}</option>
                                </select>
                               
                            </div>
                            <div class="flex flex-row items-center  w-full">
                                <TextField1 small label="Coût de l'équipement" placeholder="Coût de l'équipement"
                                    :value="furniture.valeur"
                                    type="number"
                                    @update:value="newValue => furniture.valeur = newValue" class="ms-10"/>
                            </div>

                        </div>
                       
                        <div class="flexed">
                            <ButtonOne v-for="(button, i) in equips.list" :text="button[1]" :key="i"  fullRounded />
                        </div>
                       
                        </section>
                        
                    </template>

                </EditionForm>
            </div>
    </div>
</RentalManagement>
</template>