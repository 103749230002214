import { path as basePath } from "../datas";

// path

export const path = [
  ...basePath,
  {
    label: "nouveau-copropriétaire",
    url: basePath[basePath.length - 1].url + "/creer/copro",
  },
];

// page title value
export const pageTitle = "Ajouter un copropriétaire";

// form steps
export const formSteps = [
  {
    name: "general",
    label: "Coordonnées",
  },
  {
    name: "iban",
    label: "IBAN",
  },
  {
    name: "accounting",
    label: "Comptabilité",
  },
  // {
  //   name: "ownerGood",
  //   label: "Bien du propriétaire"
  // },
  // {
  //   name: "mandat",
  //   label: "Mandat"
  // },
  // {
  //   name: "tenants",
  //   label: "Locataires liés"
  // },
  // {
  //   name: "moreInfos",
  //   label: "Autres infos"
  // },
  // {
  //   name: "associates",
  //   label: "Assosiés / indivisaires"
  // },
  // {
  //   name: "edm",
  //   label: "ged"
  // },
  // {
  //   name: "logbook",
  //   label: "Carnet de bord"
  // },
  {
    name: "taxation",
    label: "Fiscalité",
  },
  // {
  //   name: "signatories",
  //   label: "Signataires"
  // },
];

export const generalInfos = {
  civility: "",
  first_name: "",
  lastname: "",
  date_of_birth: "",
  place_of_birth: "",
  address: "",
  complete_address: "",
  city: "",
  country: "",
  zip_code: "",
  phone: "",
  email: "",
  fax: "",
  cell_phone: "",
};

export const ibanInfos = {
  iban: "",
  bic: "",
  creditor_name: "",
  ics_number: "",
};

export const accountingInfos = {
  accounting_type: "",
  accounting_account_overpayment: "",
};

export const taxationInfos = {
  ifi: false,
  vat: false,
};
