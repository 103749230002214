<script>
// import { CalendarComponent } from "@syncfusion/ej2-vue-calendars"

import RentalManagement from '../RentalManagement.vue'
import Header from '@/containers/RentalManagement/Header/RMHeader.vue'
import TransactionBox from '@/components/TransactionBox/TransactionBox.vue'
import UsefullInfo from "@/components/UsefullInfo/UsefullInfo.vue"

import reviewIcon from '@/assets/icons/monthly-review.svg'
import locationIcon from '@/assets/icons/location2.svg'
import menuIcon from '@/assets/icons/menu-button.svg'
import downArrowIcon from '@/assets/icons/downArrow_black.svg'
import rentsGraph from '@/assets/vectors/rents-report.svg'

import { headerItems, path, pageTitle, transactions, usefullInfos } from './datas'
export default {
  components: {
    // "ejs-calendar": CalendarComponent,
    RentalManagement,
    Header,
    Transaction: TransactionBox,
    UsefullInfo
  },
  data(){
    return{
      headerItems,
      path,
      pageTitle,
      transactions,
      usefullInfos,
      reportPeriods: [
        "06 mois", "12 mois", "1 an", "2 ans"
      ],
      choosenReportPeriod: "12 mois",
      assets: {
        review: reviewIcon,
        location: locationIcon,
        menu: menuIcon,
        downArrow: downArrowIcon,
        rents: rentsGraph
      },
      // calSettings: {
      //   minDate: new Date("05/09/2017"),
      //   maxDate: new Date("05/15/2030"),
      // }
    }
  }
}
</script>

<template>
  <RentalManagement :path="path">
    <div class="container rm-home">
      <h1 class="container-title">{{ pageTitle }}</h1>
      <Header 
        :items="headerItems" 
      />
      <div class="container-body">
        <div class="sections">
          <section>
            <div class="box1 monthly-review">
              <div class="row-centered">
                <img :src="assets.review" alt="">
                <div>
                  <p class="review-title">Bilan mensuel</p>
                  <p class="review-text">Recettes</p>
                </div>
              </div>
              <div class="review-value">
                € 3250,00
              </div>
            </div>
            <div class="box1 agenda">
              <div class="row-centered agenda-header">
                <div class="agenda-header--text">
                  <p class="agenda-title">Agenda</p>
                  <p class="agenda-text">
                    Découvrez et faites votre liste de tâches
                  </p>
                </div>
                <div class="row-centered agenda-buttons">
                  <div class="location"><img :src="assets.location" alt=""></div>
                  <div class="details">détail</div>
                </div>
              </div>
              <!-- <ejs-calendar class="agenda-calendar" :min="calSettings.minDate" :max="calSettings.maxDate" :isMultiSelection="true"></ejs-calendar> -->
              </div>
            <div class="box1 last-transactions">
              <div class="flexed transactions-header">
                <div>
                  <p class="box-title">Dernières transactions</p>
                  <p class="box-text">
                    Encaissements et dépenses
                  </p>
                </div>
                <img :src="assets.menu" alt="">
              </div>

              <div class="transactions-list">
                <Transaction
                  v-for="({date, hour, transId, link}, index) in transactions"
                  :key="index+transId"

                  :date="date"
                  :hour="hour"
                  :transId="transId"
                  :link="link"
                />
              </div>
            </div>
          </section>

          <section>
            <div class="box1 rents-report">
              <div class="row-centered rents-header">
                <p class="box-title">Rapport de loyers</p>
                <div class="row-centered">
                  <div class="row-centered rents-legend">
                    <div class="row-centered entry">
                      <div class="legend-color"></div>
                      <span class="box-text">Entrée</span>
                    </div>
                    <div class="row-centered total">
                      <div class="legend-color"></div>
                      <span class="box-text">Total loyers</span>
                    </div>
                  </div>
                  <div class="rents-periods">
                    <button class="btn rents-button">
                      {{ choosenReportPeriod }}
                      <img :src="assets.downArrow" alt="">
                    </button>
                    <div class="periods-options">
                      <div
                        v-for="period in reportPeriods.filter((p) => p !== choosenReportPeriod)"
                        :key="period"
                        @click="choosenReportPeriod = period "
                        class="btn periods-option"
                      >
                        {{ period }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rents-report--graphic">
                <img :src="assets.rents" alt="">
              </div>
            </div>

            <div class="box1 infos">
              <div class="flexed box1-header infos-header">
                <div>
                  <p class="box-title">Informations utiles</p>
                  <p class="box-text">Quelques informatinos pour vous aider à mieux gérer</p>
                </div>

                <img :src="assets.menu" alt="">
              </div>

              <div class="infos-list">
                <UsefullInfo
                  v-for="{ text, value, link } in usefullInfos"
                  :key="text+link"

                  :text="text"
                  :value="value"
                  :link="link"
                />
              </div>
            </div>
            <!-- <div class="box1">
              Dernières annonces
            </div> -->
          </section>
        </div>
      </div>
    </div>
  </RentalManagement>
</template>

<style src="./RentalManagementHome.css"></style>