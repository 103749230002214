<script>
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
import TableOne from '@/containers/TableOne/TableOne.vue';

export default {
  components: {
    ButtonOne,
    TableOne
  }
}
</script>

<template>
  <div>
    <ButtonOne text="Ajouter"/>
  
    <TableOne
      title=""
      :cols="['Identifiant', 'a']"
      :rows="[['123', 'b']]"
      :actions="[{ type: 'delete', label: 'Supprimer' }]"
    />
  </div>
</template>