import { path as basePath } from "@/views/Accounting/AccountingDashboard/datas"

// basic url for rental management pages
const baseUrl = "/comptabilite/comptes"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "Comptes",
    url: baseUrl
  }
]


// page title value
export const pageTitle = "Comptes Comptables"

export const tableDatas = {
  title: 'Liste des Contracts de bail',
  cols: ['Compte', 'Compte bancaire', 'Solde'],
  actions: [
    {
      type: 'delete',
      label: "Supprimer"
    },
    {
      type: 'update',
      label: "Modifier"
    }
  ]
}

export const contentDatas = {
  title: "Comptes bancaires",
  button: {
    text: "Ajouter un compte",
    url: "/comptabilite/comptes/nouveau"
  },
}

export const worksActions = [
  {
    title: "Caisse",
    actions: [
      {
        title: "Détails des transactions",
        text: "Visualisez le détail des transactions sur votre caisse (ex: encaissement en liquide)",
        url: baseUrl+"/caisse/ajouter"
      },
      {
        title: "Remise en banque",
        text: "Effectuez une remise en banque de sommes en liquide",
        url: baseUrl
      },
    ]
  },
  {
    title: "Chèques",
    actions: [
      {
        title: "Remise en banque",
        text: "Effecutuer une remise en banque de chèques et imprimer un bordereau",
        url: baseUrl+"/caisse/ajouter"
      }
    ]
  }
]