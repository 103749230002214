import FirstPage from '@/views/Extranet/Discussions/FirstPage.vue'

// url de base des pages relatives aux discussions
const baseUrl = "/extranet/conversations"

const discussionsRoutes = [
  {
    path: baseUrl,
    name: "extranetDiscussions",
    component: FirstPage,
  }
];

export default discussionsRoutes
