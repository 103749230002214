import ExtranetHome from '@/views/Extranet/ExtranetHome/ExtranetHome.vue'
import discussionsRoutes from './discussions';
import profileRoutes from './profile';
import filesRoutes from './sharedFiles';
import usersRoutes from './users';

const baseUrl = "/extranet"
const extranetRoutes = [
  {
    path: baseUrl,
    name: "extranet",
    component: ExtranetHome,
  },
  ...discussionsRoutes,
  ...profileRoutes,
  ...usersRoutes,
  ...filesRoutes
];

export default extranetRoutes;