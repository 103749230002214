import { path as basePath } from "../datas";

// path

export const path = [
  ...basePath,
  {
    label: "Locataire",
    url: basePath[basePath.length - 1].url + "/",
  },
];
// page title value
export const pageTitle = "Locataire :";

// civilities
export const civils = [
  {label:"Mr", value:"Mr"},
  {label:"Mme", value:"Mme"}
];

export const pieces = [
  {label:"0", value:"0"},
  {label:"1", value:"1"},
  {label:"2", value:"2"},
  {label:"3", value:"3"},
  {label:"4", value:"4"},
  {label:"5", value:"5"},
  {label:"6", value:"6"},
  {label:"7", value:"7"},
  {label:"8", value:"8"},
  {label:"9", value:"9"},
  {label:"10", value:"10"}
]

export const loyers = [
  {label:"journalier", value:"journalier"},
  {label:"hebdomadaire", value:"hebdomadaire"},
  {label:"mensuel", value:"mensuel"},
 
]


// form steps
export const formSteps = [
  {
    name: "general",
    label: "Coordonnées",
  },
  {
    name: "description",
    label: "Description",
  },
  {
    name: "iban",
    label: "IBAN",
  },
  {
    name:"propriété",
    label:"Propriété",
  }
  // {
  //   name: "tenantGood",
  //   label: "Bien du propriétaire"
  // },
  // {
  //   name: "mandat",
  //   label: "Mandat"
  // },
  // {
  //   name: "tenants",
  //   label: "Locataires liés"
  // },
  // {
  //   name: "moreInfos",
  //   label: "Autres infos"
  // },
  // {
  //   name: "associates",
  //   label: "Assosiés / indivisaires"
  // },
  // {
  //   name: "edm",
  //   label: "ged"
  // },
  // {
  //   name: "logbook",
  //   label: "Carnet de bord"
  // },
  // {
  //   name: "taxation",
  //   label: "Fiscalité",
  // },
  // {
  //   name: "signatories",
  //   label: "Signataires"
  // },
];

export const generalInfos = {
  civility: "",
  first_name: "",
  lastname: "",
  date_of_birth: "",
  place_of_birth: "",
  address: "",
  complete_address: "",
  city: "",
  country: "",
  zip_code: "",
  phone: "",
  email: "",
  fax: "",
  cell_phone: "",
};

export const ibanInfos = {
  iban: "",
  bic: "",
  creditor_name: "",
  ics_number: "",
};
export const accountingInfos = {
  accounting_type: "",
  accounting_account_overpayment: "",
  cost_accounting: "",
  auxiliary_code: "",
  unpaid_reminder: true,
};


export const tableDatas = {
  title: 'Liste des biens disponibles',
  cols: [
    'N° de Bien', 'Type', 'Adresse', 'Ville', 'Pays', 'Type de loyer'
  ],
 
 
}

export const initialList = {
  list: [],
  total: 0,
  actions: [
    {
      type: 'view',
      label: 'Afficher'
    },
    {
      type: 'send',
      label: 'Email'
    },
    // {
    //   type: 'update',
    //   label: 'Modifier'
    // },
  ]
}