<script lang="js">
import CoproSyndics from '../CoproSyndics.vue';
import EditionForm from '@/containers/EditionForm/EditionForm.vue'

import { path, pageTitle, formSteps } from './datas';

export default {
  components: {
    CoproSyndics,
    EditionForm,
  },
  data(){
    return{
      path,
      pageTitle,
      steps: formSteps,
      currentStep: 0,
      name: "durin"
    }
  },
  methods: {
    save() {
      alert(this.currentStep);
    }
  }
}
</script>

<template>
  <CoproSyndics :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="profile container-body">
        <EditionForm :save="save" :steps="steps" @step="(step) => currentStep = step">
          <template #form>
            
          </template>
        </EditionForm>
      </div>
    </div>
  </CoproSyndics>
</template>

<style src="./FirstPage.css"></style>