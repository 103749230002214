<script>
  import GED from '../GED.vue'
  import HeaderTwo from '@/containers/GED/Header2/HeaderTwo.vue';
  import FolderBox from '@/components/FolderBox/FolderBox.vue';
  import { header, folders, path, pageTitle } from './datas';

  export default {
    data(){
      return {
        header: {
          title: header.title,
          icon: header.icon,
          text: header.text,
          button: header.button
        },
        // informations relatives à la permière section "dossier" du corps de la page
        folders: {
          title: folders.title,
          icon: folders.icon,
          items: folders.items
        },
        path,
        pageTitle
      }
    },
    components: {
      GED,
      HeaderTwo,
      FolderBox,
    },
  }
</script>

<template>
  <GED :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <HeaderTwo 
        :icon="header.icon"
        :title="header.title"
        :text="header.text"
        :button="header.button"
      />
      <div class="container-body">
        <!-- Première section : section des dossiers -->
        <section class="container-section">
          <span class="title container-section--title">
            {{ folders.title }}
          </span>
          <div class="list">
            <FolderBox 
              v-for="({ title, date, text, weight, files }, index) in folders.items"
              :key="'folder-item-'+index"
              :icon = "folders.icon"
              :title = "title"
              :date = "date"
              :text = "text"
              :weight = "weight"
              :files = "files"
            />
          </div>
        </section>
        <section>
          <span class="title container-section--title">
            Tous les dossiers
          </span>
          <table class="box1">
            tableau des dossiers
          </table>
        </section>
      </div>
    </div>
  </GED>
</template>

<style src="./FirstPage.css"></style>