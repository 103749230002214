import { path as basePath } from "../RentalManagementHome/datas"

const baseUrl = basePath[basePath.length-1].url+"/loyers"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "loyers",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Loyers"

// fonction permettant de mettre les url au bon format en fonction de l'url de base
function url(url){
  return baseUrl+url
}

export const items = [
  {
    text: "Lancez vos appels de loyers pour un ou plusieurs locataires et imprimez votre avis d'échéance",
    buttons: [
      {
        label: "Appel de loyers",
        url: url("/appel"),
        empty: false
      },
      {
        label: "Appels Spécifiques",
        url: url("/appel-specifique"),
        empty: false
      }
    ]
  },
  {
    text: "Créez une facture destinée à un propriétaire (ex: honoraires de location)",
    buttons: [
      {
        label: "Facturation propriétaires",
        url: url("/facturation"),
        empty: false
      },
    ]
  },
  {
    text: "Vérifiez que vos locataires sont assurés",
    buttons: [
      {
        label: "Demander des attestations d'assurance",
        url: url("/attestation-assurance"),
        empty: false
      },
    ]
  },
  {
    text: "Visualisez vos loyers et factures non réglées et effectuez vos relances",
    buttons: [
      {
        label: "Relances impayés",
        url: url("/liste?relance=true"),
        empty: false
      }
    ]
  },
  {
    text: "Utilisez cette option si vous générez de façon régulière des factures destinées à vos locataires",
    buttons: [
      {
        label: "Générateur de factures en masse",
        url: url("/facturations/creer?type=locataire&mass=true"),
        empty: false
      }
    ]
  },
  {
    text: "Définissez vos cycles de facturations ou votre méthode de répartition des facturations récurrentes",
    buttons: [
      {
        label: "Paramètres de cycles de facturations",
        url: url("/parametres/facturation"),
        empty: true
      }
    ]
  },
  {
    text: "Informez vos locataires des futures révisions de loyers",
    buttons: [
      {
        label: "Informez vos locataires",
        url: url("/revision-rent"),
        empty: false
      }
    ]
  },
  {
    text: "Gérez pour vos baux les ajouts / modifications / suppressions de facturations temporaires",
    buttons: [
      {
        label: "Facturations temporaires",
        url: url("/facturations?type=temporaire"),
        empty: false
      }
    ]
  },
  {
    text: "Visualisez les plans d'apurements actuellement en cours",
    buttons: [
      {
        label: "Plans d'apurements",
        url: url("/apurements"),
        empty: false
      }
    ]
  }
]

export const tableDatas = {
  title: 'Appels de loyer lancés',
  cols: [
    'N°', 'Période', 'Montant HT', 'Bien', 'Locataire', "Date d'exigibilité"
  ],
  rows: [
  
  ],
  actions: [
    {
      type: 'delete',
      label: "Supprimer"
    },
    {
      type: 'update',
      label: "Modifier"
    }
  ]
}