import pdfRed1 from '@/assets/icons/pdf_red1.svg'
import docxBlue1 from '@/assets/icons/docx_blue1.svg'
import jpgGreen1 from '@/assets/icons/jpg_green1.svg'


// les éléments relatifs aux fichiers
export default [
  {
    icon: pdfRed1,
    title: "Plan de l'appart",
    date: "08-10-2022",
    module: "Gestion locative",
    goodId: "001"
  },
  {
    icon: docxBlue1,
    title: "Plan de l'appart",
    date: "08-10-2022",
    module: "Gestion locative",
    goodId: "001"
  },
  {
    icon: jpgGreen1,
    title: "Plan de l'appart",
    date: "08-10-2022",
    module: "Gestion locative",
    goodId: "001"
  },
]