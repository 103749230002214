<script>
  export default {
    props: {
      icon: String,
      title: String,
      date: String,
      text: String,
      weight: Number,
      files: Number
    }
  }
</script>

<template>
  <div class="box1 list--item folder-box">
    <div class="folder-box--header">
      <div class="title">
        <img :src="icon" alt="folder-icon" />
        {{ title }}
      </div>
      <div class="dot"></div>
      <span class="date">{{ date }}</span>
    </div>
    <p class="folder-box--text">{{ text }}</p>
    <div class="folder-box--footer">
      <p class="weight">{{ weight }}Ko</p>
      <p class="files">{{ files }} fichiers</p>
    </div>
  </div>
</template>

<style src="./FolderBox.css"></style>