import { path as basePath } from "../RentalManagementHome/datas"

const baseUrl = basePath[basePath.length-1].url+"/locataires"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "locataires",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Demande de location"

function url(url){
  return baseUrl+url
}

export const items = [
  {
    text: "Gestion des demandes de location",
    buttons: [
      {
        label: "Faire une demande de location",
        url: url("/creer"),
        empty: false
      },
      {
        label: "Attribuer un bien à un locataire",
        url: url("#"),
        empty: true
      }
      // {
      //   label: "Solde de tout compte",
      //   url: url("/solde-tout-compte"),
      //   empty: true
      // }
    ]
  },
  // {
  //   text: "Dans le cas où vous avez créé des copropriétés, vous pouvez enregistrer des copopriétaires",
  //   buttons: [
  //     {
  //       label: "Créer un colocataire",
  //       url: url("/creer?coloc=true"),
  //       empty: false
  //     },
  //   ]
  // },
  {
    text: "Envoyez un mail simultané à plusieurs ou tous vos locataires",
    buttons: [
      {
        label: "Envoyez un mail groupé",
        url: url("/envoie-mail"),
        empty: false
      },
    ]
  },
  // {
  //   text: "Enregistrez le départ d'un locataire et soldez son compte",
  //   buttons: [
  //     {
  //       label: "Solde de compte locataire",
  //       url: url("/solde-compte"),
  //       empty: false
  //     }
  //   ]
  // },
  {
    text: "Obtenez le solde du compte de tous vos locataires",
    buttons: [
      {
        label: "Solde de compte locataire",
        url: url("/solde-compte"),
        empty: false
      }
    ]
  },
  {
    text: "Un propriétaire a vendu un bien et vous souhaitez attribuer le bien et le bail au nouveau propriétaire",
    buttons: [
      {
        label: "Remboursement",
        url: url("/solde-compte"),
        empty: true
      }
    ]
  }
]

export const tableDatas = {
  title: 'Liste des demandes de location',
  cols: [
    '#', 'Budget', 'Superficie(en m2)', 'Caution', 'Avance sur loyer'
  ],
  rows: [
 ],

}