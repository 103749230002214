import graph1 from '@/assets/vectors/graph1.svg'
import folder1 from '@/assets/vectors/folder1.svg'
import file1 from '@/assets/vectors/file1.svg'
import image1 from '@/assets/vectors/image1.svg'
import video1 from '@/assets/vectors/video1.svg'
import folderBlue1 from '@/assets/icons/folder_blue1.svg'
import pdfRed1 from '@/assets/icons/pdf_red1.svg'
import docxBlue1 from '@/assets/icons/docx_blue1.svg'
import jpgGreen1 from '@/assets/icons/jpg_green1.svg'

import { path as basePath } from "@/views/Dashboard/data"

// url de base des liens
const baseUrl = "/ged"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "ged",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Dashboard"

// fonction permettant de mettre les url au bon format
function url(url){
  // Ajoute en préfixe à l'url donnée en paramètre l'url de base du module
  return baseUrl+url
}

// les éléments relatifs au headers
export const header = {
  vector: graph1,
  resumes: [
    {
      text: 'Nombre total de fichiers liés',
      number: 48
    },
    {
      text: 'Nombre total de fichiers enregistrés',
      number: 86
    },
  ],
  shortLinks: [
    {
      label: "Classeur",
      url: url("/classeur"),
      color: "yellow",
      vect: folder1
    },
    {
      label: "Documents liés",
      url: url("/fichiers?type=linked-documents"),
      color: "purple",
      vect: file1
    },
    {
      label: "Images",
      url: url("/fichiers?type=images"),
      color: "green",
      vect: image1
    },
    {
      label: "Vidéos",
      url: url("/fichiers?type=videos"),
      color: "blue",
      vect: video1
    },

  ]
}

// les éléments relatifs aux dossiers
export const folders = {
  title: "Dossier",
  icon: folderBlue1,
  items: [
    {
      title: "Appel de loyer",
      date: "08-10-2022",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget rhoncus donec egestas viverra nisl sit. Accum....",
      weight: 250,
      files: 16
    },
    {
      title: "Appel de loyer",
      date: "08-10-2022",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget rhoncus donec egestas viverra nisl sit. Accum....",
      weight: 250,
      files: 16
    },
    {
      title: "Appel de loyer",
      date: "08-10-2022",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget rhoncus donec egestas viverra nisl sit. Accum....",
      weight: 250,
      files: 16
    },
  ]
}

// les éléments relatifs aux fichiers
export const files = {
  title: "Fichiers",
  items: [
    {
      icon: pdfRed1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      module: "Gestion locative",
      goodId: "001"
    },
    {
      icon: docxBlue1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      module: "Gestion locative",
      goodId: "001"
    },
    {
      icon: jpgGreen1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      module: "Gestion locative",
      goodId: "001"
    },
  ]
}

export const table = {
  cols: [
    'NOM', 'lIÉ À', 'TAILLE', 'CRÉÉ LE', 'DERNIÈRE MODIFICATION'
  ],
  rows: [
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
    [
      'Auctor tempor.', 'prelium sed', 'pretium sed.', '20-08-2022', '21-08-2022'
    ],
  ],
  actions: [
    {
      type: 'update',
      label: 'Modifier'
    },
    {
      type: 'delete',
      label: 'Supprimer'
    },
  ]
}