import AccountingDashboard from '@/views/Accounting/AccountingDashboard/AccountingDashboard.vue'
import accountingAccountsRoutes from './accounts';

const accoutingRoutes = [
  {
    path: "/comptabilite",
    name: "comptabilite",
    component: AccountingDashboard,
  },
  ...accountingAccountsRoutes,
];

export default accoutingRoutes;