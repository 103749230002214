import CommercialAttaches from '@/views/MarketPlace/CommercialAttaches/FirstPage.vue'

// url de base des pages relatives aux attachés commerciaux
const baseUrl = "/marketplace/attaches-commerciaux"

const commercialAttachesRoutes = [
  {
    path: baseUrl,
    name: "commercialAttaches",
    component: CommercialAttaches,
  }
];

export default commercialAttachesRoutes
