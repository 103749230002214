import FirstPage from '@/views/Works/Diagnostic/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/diagnostiques"

const diagnosticRoutes = [
  {
    path: baseUrl,
    name: "diagnostic",
    component: FirstPage,
  }
];

export default diagnosticRoutes
