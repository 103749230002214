<script>
  import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
  import downArrow from '@/assets/icons/down-arrow.png'

  export default {
    data(){
      return {
        downArrow,
        openedAction: ""
      }
    },
    props: {
      worksActions: Array
    },
    components: {
      ButtonOne,
    }
  }
</script>

<template>
  <div class="box1 worksnav-box">
    <section
      v-for="({ title, actions }, index) in worksActions"
      :key="title+`${index}`"
      :class="{ 'worksnav-section__opened': openedAction === title }"
    >
      <button 
        class="row-centered worksnav-box-title" 
        @click="openedAction = (openedAction === title ? '' : title)"
      >
        <h3 class="container-title">{{ title }}</h3>
        <img class="icon-mid" :src="downArrow" alt="">
      </button>
      <div 
        class="flexed worksnav-box-items"
      >
        <div
          v-for="({ title, text, url }, index) in actions"
          :key="title+`${index}`"
          class="worksnav-box--item"
        >
          <router-link :to="url">
            <ButtonOne :text="title" />
          </router-link>
          <p>{{ text }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<style src="./PageNavigationBox.css"></style>