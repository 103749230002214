import ServiceOrders from '@/views/Works/ServiceOrders/ServiceOrders'

// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/ordres-de-service"

const serviceOrdersRoutes = [
  {
    path: baseUrl,
    name: "serviceOrders",
    component: ServiceOrders,
  }
];

export default serviceOrdersRoutes
