import FirstPage from '@/views/Extranet/Profile/FirstPage.vue'

// url de base des pages relatives au profile de l'utilisateur
const baseUrl = "/extranet/profile"

const profileRoutes = [
  {
    path: baseUrl,
    name: "extranetProfile",
    component: FirstPage,
  }
];

export default profileRoutes
