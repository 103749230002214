<script>
  import modules from '@/utils/data/modules';

  import unlock from '@/assets/icons/unlock.png'

  export default{
    emits: ['active'],
    props: {
      on: Object
    },
    data() {
      return{
        modules,
        unlock
      }
    },
  }

</script>

<template>
  <div class="sidebar">
    <div class="sidebar--container">
      <div class="sidebar--links">
        <div
          v-for="module in modules"
          :key="module.label"
          class="row-centered sidebar-link"
          :class="{ 'sidebar-link__active': on.label == module.label, 'sidebar-link__locked': !module.unlocked, 'sidebar-link__locked-active': on.label == module.label && !module.unlocked }"
          @click="$emit('active', module)"
        > 
          <span>{{ module.label }}</span>
          <img v-if="!module.unlocked" :src="unlock" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./SideBar.css"></style>