import DashboardHome from '@/views/Dashboard/DashboardView.vue'

const dashboardRoutes = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardHome,
  },
];

export default dashboardRoutes;