<script>
import { mapActions, mapWritableState } from 'pinia'
import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
//import TextField1 from '@/components/TextField1/TextField1.vue'
import { path, pageTitle, formSteps} from './datas'
import useLeaseStore from '../../Leases/store'
import useRentStore from '../store'
import useTenantStore from '../../Tenants/store'

//import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
export default{
    components: {
        RentalManagement,
        EditionForm,
        // TextField1,
        // ErrorMessage
    },
    data(){
        return{
            path,
            pageTitle, 
            steps: formSteps,
            currentStep: 0, 
        }
    },
    computed: {
        ...mapWritableState(useLeaseStore, ['leases']),
        ...mapWritableState(useRentStore, ['rent', 'loading', 'status', 'error']),
        ...mapWritableState(useTenantStore, ['tenantOwners'])
    },
    methods: {
        ...mapActions(useLeaseStore, ['getLeaseList']),
        ...mapActions(useTenantStore, ['getTenants'])
    },
    async mounted(){
        this.getLeaseList(0);
        this.getTenants();

    },
    watch : {
        offset(value){
            this.getLeaseList(value);
            this.getTenants();

        }
    }
}
</script>
<template>
    <RentalManagement :path="path">
        <div class="container">
            <h1  class="container-title"> {{ pageTitle }} </h1>
            <div class="container-body">
                <EditionForm :save="f" :steps="steps" @step="(step => currentStep = step)" :loading="loading" :status="status">
                    <template #form>

                    </template>

                </EditionForm>
            </div>
        </div>
    </RentalManagement>
</template>