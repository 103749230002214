import api from "@/views/Auth/api/client"

export const findIndex = (name) => () => {
  // find step index in multisteps forms
  return this.steps.indexOf(this.steps.find(
    (step) => step.name === name))
}

export function noEmptyFields(data, optionals) {
  // Check check if a form fields are all not empty
  console.log(data);
  let entries = Object.entries(data)

  let result = true
  entries.map((entry) => {
    if (!optionals?.includes(entry[0]) && entry[1] === "") {
      result = false;
    }
    
  })

    result === false
    return result
}

export async function refreshToken(){
  // function used to refresh user token after specific actions get response
  // Not executed when action return error

  // get current user infos in localstorage
  const currentLocalUser = getLocalUser()

  if(currentLocalUser && currentLocalUser?.refresh_token){
    // if current user, execute the reques
    try {
      const refresh = await api.refreshToken(currentLocalUser.refresh_token)
      if(refresh){
        console.log({ refresh })
        // reset local user values
        setLocalUser({
          ...currentLocalUser,
          refresh_token: refresh?.data?.refresh_token,
          access_token: refresh?.data?.access_token
        })
      }
    } catch (refreshTokenError) {
      console.log({ refreshTokenError })
    }
  } else {
    return null
  }
}

export function getLocalUser(wanted){
  // used to obtain local user object, or one specific info of it 
  const localUser = JSON.parse(
    localStorage.getItem('xxl-profil-immo-user')
  )
  if(localUser) {
    if(wanted){
      return localUser[wanted]
    } else {
      return localUser
    }
  } else {
    return null
  }
}

export function setLocalUser(data){
  // this function is used to set the localStorage user, or some of his properties.
  data 
  ? localStorage.setItem('xxl-profil-immo-user', JSON.stringify(data))
  : localStorage.clear('xxl-profil-immo-user')
}

export function setButtonText(loading, status, originalText){
  // this function is used to define a text in a button according to the operation loading value and status
  if(!loading && (status === "" || !status)){
    // if the operation is not started yet
    return originalText
  } else if(status === "failure"){
    // if the operation has been launched, but has failed for whatever reason
    return "Echec. Réessayer !"
  } else if(loading === false) {
    // if the operation has been launched, has not failed, and has finished loading
    return "Opération réussie !"
  } else {
    // if the operation has been launched, has not failed, but is still loading
    return "Veuillez patienter..."
  }
}

export function textCutter(text, amount){

  // we split the text into words stored in a array
  const words = text?.split(" ");

  // we join the first "amount" words in a single text
  let _text = words?.splice(0, amount || 15).join(" ");

  // We add "..." at the end if the result is différent from the original text, and return the final result
  if(text !== _text){
    return _text+"..."
  } else {
    return _text
  }
}

export const initialList = {
  list: [],
  total: 0,
  actions: [
    {
      type: 'view',
      label: 'Afficher'
    },
    // {
    //   type: 'delete',
    //   label: 'Supprimer'
    // },
    // {
    //   type: 'update',
    //   label: 'Modifier'
    // },
  ]
}

export const initProcess = {
  loading: false,
  status: "",
  error: ""
}

export const initProcessReverse = {
  loading: true,
  status: "pending",
  error: ""
}