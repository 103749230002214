<!-- Tableau recyclable dans la plateforme, prend en charge : 
  - options individuelles, 
  - options en groupe, 
  - sélection,
  - pagination
  - recherche   -->
<script>
import TextField1 from '@/components/TextField1/TextField1.vue';

import updateIcon from '@/assets/icons/update1.svg'
import StatusCell from './StatusCell.vue';
import ChimpCell from './ChimpCell.vue';
import deleteIcon from '@/assets/icons/delete1.svg'
import eye from '@/assets/icons/eye.png'
import check from '@/assets/icons/check.png'
import downArrow from '@/assets/icons/down-arrow.png'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
import email from '@/assets/icons/email.svg';
import useTenantStore from '@/views/RentalManagement/Tenants/store'
import { mapActions} from 'pinia';
import { toast } from 'vue3-toastify'

const performSearch = (rows, term) => {
  const results = rows.filter((row) => (
    row.join(' ').toLowerCase().includes(term.toLowerCase().trim())
  ))
  return results;
}

export default {
  components: {
    TextField1,
    ButtonOne,
    StatusCell,
    ChimpCell
},
  emits: ['emitedOffset'],
  props: {
    cols: Array,
    rows: Array,
    actions: Array,
    title: String,
    noSearch: Boolean,
    total: Number,
    baseUrl: String,
    unMutable: Boolean,
    send:Boolean,
    id:Number
  },
  data() {
    return {
      activeRows: [],
      // allRows: this.rows,
      groupableActions: ['delete'],
      assets: {
        updateIcon,
        deleteIcon,
        checkIcon: check,
        downArrow,
        sendIcon:email,
      },
      selectedRows: [],
      rowMenuOpen: null,
      page: 0,
      totalPages: Math.ceil(this.total / 10) || 1,
     
    
    }
  },
  computed: {
    pageRows() {
      return this.allRows?.slice(
        this.page * 10,
        (this.page * 10) + 10 >= this.allRows?.length ? this.allRows?.length : (this.page * 10) + 10
      )
    }
  },
  mounted() {
    this.activeRows = this.rows
  },
  methods: {
    ...mapActions(useTenantStore, ['sendProperties']),
    updatePage(p) {
      this.page = p;
    },
    handleChange(e) {
      const term = e.target.value;
      this.activeRows = performSearch(this.rows, term)
    },
    setActionIcon(type) {
      let icon = ""
      switch (type) {
        case "update":
          icon = updateIcon;
          break;
        case "delete":
          icon = deleteIcon;
          break;
        case "view":
          icon = eye;
          break;
        case "send":
          icon = email;
          break;
        default:
          icon = ""
          break;
      }
      return icon;
    },
    // function to select all the rows of the table
    addSelectedRow(row) {
      if (this.selectedRows.includes(row)) {
        this.selectedRows.splice(this.selectedRows.indexOf(row), 1)
      } else {
        this.selectedRows.push(row)
      }
    },
    // function to select a single row and remove all the others
    selectSingleRow(row) {
      if (this.selectedRows == [row]) {
        this.selectedRows = []
      } else {
        this.selectedRows = [row]
      }
    },
    // function to add a row to the selected rows without removing the others
    selectAllRows() {
      if (this.selectedRows.length === this.activeRows.length) {
        this.selectedRows = []
      } else {
        this.selectedRows = this.activeRows.slice()
      }
    },
    handleAction(type, data) {
      if(type === "view"){
       location.replace(this.baseUrl+"/"+data[0])
        // location.replace(this.baseUrl+"/"+data)
      } else if(type === "send"){
        let rows = [];
        const value = data['0'];
        
        rows.push(value)
       
        this.sendProperties(this.id, rows, toast)
      } else {
        console.log("Nothing to do")
      }
    },
    handleMultiAction(data){
      let rows = [];
      data.map((item) =>{
        const value = item['0']
        rows.push(value)
      })
      console.log({datas : rows});
      
     this.sendProperties(this.id, rows, toast)
     
    }
    // delete selected rows
    // deleteSelected(){
    //   this.allRows = this.allRows.filter((row) => !this.selectedRows.includes(row))
    //   this.activeRows = this.selectedRows.filter((row) => !this.selectedRows.includes(row))
    // }
  },
  watch: {
    page(value) {
      this.$emit('emitedOffset', value)
    },
    // pageRows(newValue){
    //   this.activeRows = newValue;
    // }
  } 
}
</script>

<template>
  <!--  @blur="selectedRows = []" -->
  <div class="table-one" tabindex="1">
    <div class="row-centered table-head">
      <div v-if="title" class="title">{{ title }}</div>
      <div v-if="send && selectedRows.length >= 1">
        <h4
          @click="() => handleMultiAction(selectedRows)">
          send properties
        </h4>
         
     
      </div>
      <!-- <div v-if="selectedRows.length > 0">
        <h1 @click="deleteSelected()">supprimer les éléments sélectionnés</h1>
      </div> -->
      <TextField1
        type="text"
        small
        rowed
        placeholder="Recherchez ici"
        :onChange="handleChange"
        v-if="!noSearch"
      />
    </div>
    <!-- <div v-if="!unMutable && selectedRows.length >= 1 " class="flexed box1 table--group-action">
      <div
        v-for="({ type, label }, index) in actions.filter((action) => groupableActions.includes(action.type))"
        :key="type + '-' + index"
        class="table-action"
        :class="'table-action__' + type"
      >
        <img :src="setActionIcon(type)" alt="">
        {{ label }}
      </div>
    </div> -->
    <div class="table-bg">
      <table v-if="activeRows.length > 0">
        <thead>
          <tr>
            <th v-if="!unMutable" class="selection-cell" @click="selectAllRows">
              <div class="selector" :class="{ 'active-selector': activeRows.length === selectedRows.length }">
                <img :src="assets.checkIcon" alt="" />
              </div>
            </th>
            <th
              v-for="(col, index) in cols"
              :key="'col-'+index"
              :class="unMutable ? (cols.length === index+1 ? 'text-right' : index === 0 ? 'text-left' : 'text-center') : 'text-center'"
            >
              {{ col.label ? col.label : col }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, i) in activeRows"
            :key="'row-'+i"
            :class="{ 'active-row': selectedRows.includes(row) }"
          >
            <td v-if="!unMutable" class="selection-cell" @click="addSelectedRow(row)">
              <div class="selector"><img :src="assets.checkIcon" alt=""></div>
            </td>
            <td
              v-for="(cell, ix) in row"
              :key="'cell-'+i+'-'+ix"
              @click="selectSingleRow(row)"
              :class="unMutable ? (cols.length === ix+1 ? 'text-right' : ix === 0 ? 'text-left' : 'text-center') : 'text-center'"
            >
              <StatusCell v-if="cols[ix].type === 'status'" :cell="cols[ix].preText ? cols[ix].preText + ' ' + cell : cell" />
              <ChimpCell v-if="cols[ix].type === 'chimp'" :cell="cols[ix].preText ? cols[ix].preText + ' ' + cell : cell" />
              {{ !cols[ix].type ? cols[ix].preText ? cols[ix].preText + ' ' + cell : cell : null }}
            </td>
            <td class="action-cell" v-if="!unMutable && selectedRows.length === 0">
              <div class="menu-icon" 
                :class="{ 'menu-icon-opened' : rowMenuOpen === `cell-${i}` && selectedRows.length === 0}" 
                @click="rowMenuOpen === `cell-${i}` && selectedRows.length === 0 ? rowMenuOpen = null : rowMenuOpen = `cell-${i}`">
                <img :src="assets.downArrow" />
              </div>
              <div 
                class="right-click-menu"
                v-if="rowMenuOpen === `cell-${i}` && selectedRows.length === 0"
              >
                <div
                  v-for="({type, label}, index) in actions"
                  :key="type+'-'+index"
                  class="table-action"
                  :class="'table-action__'+type"
                  @click="() => handleAction(type, row)"
                >
                  <img :src="setActionIcon(type)" alt="">
                  {{ label }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="no-result-tab">
        <table>
          <thead>
            <tr>
              <th
                v-for="(col, index) in cols"
                :key="'alt-col-'+index"
              >
                {{ col }}
              </th>
            </tr>
          </thead>
        </table>
        <div>
          <h3>Aucun Résultat Trouvé</h3>
        </div>
      </div>
      <div v-if="page+1 === totalPages" class="table--end">
        <span>Fin des Résultats</span> 
      </div>
      <div class="flexed table--pagination">
        <ButtonOne
          @click="page = page - 1"
          v-if="page > 0"
          text="prev"
          unActive
        />
        <ButtonOne
          v-for="p in totalPages"
          :key="p"
          @click="updatePage(p-1)"
          :text="`${p}`"
          :unActive="p-1 !== page"
        />
        <ButtonOne
          @click="page = page + 1"
          v-if="page < totalPages - 1"
          text="next"
          unActive
        />
      </div>
    </div>
    
  </div>
</template>

<style src="./TableOne.css"></style>