<script>
  import ItemBox2 from '@/components/ItemBox2/ItemBox2.vue'

  export default {
    components: {
      ItemBox2
    },
    props: {
      items: Array,
    }
  }
</script>

<template>
  <div class="rm-header2">
    <ItemBox2
      v-for="({text, buttons}, index) in items"
      :key="`box-${index}`"
      :text="text"
      :buttons="buttons"
    />
  </div>
</template>

<style src="./RMHeader2.css"></style>