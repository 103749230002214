<script>
  import TextField1 from '@/components/TextField1/TextField1.vue';
  import calculator1 from '@/assets/icons/calculator1.svg'

  export default {
    components: {
      TextField1
    },
    data() {
      return {
        assets: {
          calculator1
        }
      }
    }
  }
</script>

<template>
  <div class="flexed supp-infos">
    <div>
      <p>Informations honnoraires</p>
      <div class="form-block">
        <TextField1
          label="Taux HT"
          name="taux-ht"
          placeholder="taux hors taxe"
        />
        <TextField1
          label="Base de calcul"
          name="calculation-bases"
          placeholder="Entrez ici"
        />
        <TextField1
          label="Taux TVA"
          name="taux-tva"
          placeholder="Taxe sur la valeur ajoutée"
        />
        <TextField1
          label="%TTC"
          name="taux-ttc"
          placeholder="taux tous taxes comprises"
        />
      </div>
    </div>
    <div class="supp-infos--fees">
      <p>Autres Honnoraires</p>
      <div class="form-block">
        <div class="row-centered supp-infos--ht-fees">
          <div class="supp-infos-ht-fees--loc fees-input">
            <TextField1
              label="Honoraires HT recherche locataire"
              name="fees-ht-loc"
              placeholder="Entrez ici"
              small
              class="fees-input"
            />
            <img :src="assets.calculator1" alt="">
          </div>
          <TextField1
            label="TTC"
            name="fees-ttc-loc"
            placeholder="Entrez ici"
            small
          />
        </div>
        <div class="row-centered supp-infos--ht-fees">
          <div class="supp-infos-ht-fees--loc fees-input">
            <TextField1
              label="Honoraires HT état des lieux"
              name="fees-ht-sp"
              placeholder="Entrez ici"
              small
              class="fees-input"
            />
            <img :src="assets.calculator1" alt="">
          </div>
          <TextField1
            label="TTC"
            name="fees-ttc-sp"
            placeholder="Entrez ici"
            small
          />
        </div>
        <TextField1
          label="Superficie"
          name="fees-area"
          placeholder="Entrez ici"
        />
        <TextField1
          label="Zone"
          name="fees-zone"
          placeholder="Entrez ici"
        />
      </div>
    </div>
  </div>
  <div class="more-infos">
    <p>Autres informations</p>
    <div class="more-infos--items form-block">
      <div class="more-infos-item">
        <span></span> Dépot de garantie conservée
      </div>
      <div class="more-infos-item">
        <span></span> Probision pour charge conservée
      </div>
      <div class="more-infos-item">
        <span></span> Recherche du locataire éffectuée par le mandataire
      </div>
    </div>
  </div>
</template>

<style src="./style.css"></style>