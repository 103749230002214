import { BASE_URL } from "@/urls";
import axios from "axios";

const Axios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  baseURL: `${BASE_URL}/auth`
})

const client = {
  login: (data) => Axios.post('/user/login', data),
  register: (data) => Axios.post('/user/add', data),
  validateAccount: (token) => Axios.post('/user/user_validation', {token}),
  resendCode: (email) => Axios.post('/user/resend_code', {email}),
  sendRPRequest: (email) => Axios.post('/update_password/send_code', {email}),
  checkResetPasswordCode: (code, email) => Axios.post('/update_password/check_code', {email, code}),
  resetPassword: (password, password_confirm, email, code) => Axios.patch('/update_password/new', {password, password_confirm, email, code}),
  refreshToken: (token) => axios.get(`${BASE_URL}/auth/refresh_token`, {
    headers: {
      // send refresh_token in request headers
      "Authorization": `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
}

export default client;