import client from "@/views/client";
const baseUrl = "/gls/equipments";


export const equipApi = {
    addEquipment: (data) => client.post(`${baseUrl}/add`, data),
    getOneEquipment: (id) => client.get(`${baseUrl}/info/${id}`),
    updateEquipment: (data) => client.patch(`${baseUrl}/update`, data),
    // get tenants list with pagnination handeling
    getAllEquipments: (limit, offset) => client.get(`${baseUrl}/list/${limit}/${offset}`),
    deleteEquipement: (data) => client.delete(`${baseUrl}/delete`, {data})
  }
  
  export default equipApi;