<script>
import AuthHeader from '@/containers/Auth/AuthHeader/AuthHeader.vue'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import useAuthStore from '../store';
import { mapActions, mapWritableState } from 'pinia';

import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';
export default {
  components: {
    AuthHeader,
    ButtonOne,
    ErrorMessage
},
  computed: {
    email() {
      return this.$route.query.email;
    },
    action(){
      return this.$route.query.action;
    },
    ...mapWritableState(useAuthStore, ['resendMailProcess'])
  },
  methods: {
    ...mapActions(useAuthStore, ['resendCode']),
    resendMail(){
      if(this.action === "signup"){
        return this.resendCode(this.email)
      }
    }
  },
  mounted(){
    this.$confetti.start({
      particles: [
        {type: 'rect', dropRate: 20}
      ],
      particlesPerFrame: 4
    })
    setTimeout(() => this.$confetti.stop(), 5000)
  }
}
</script>

<template>
  <div class="page auth-page">
    <div class="auth-box">
      <AuthHeader :teaseTrial="false" :title="action === 'signup' ? 'Compte créé avec success !' : 'Email envoyé avec success !'" :text="'Veuillez vérifier vos mails à l\'addresse '+email+' afin d\'accéder à votre compte !'" />
      <p>Vous n'avez pas reçu de mail ?</p>
      <ButtonOne isAsync text="Renvoyer Manuellement" @click.prevent="resendMail" :loading="resendMailProcess.loading" :status="resendMailProcess.status" />
      <ErrorMessage v-if="resendMailProcess.status === 'failure'" :message="resendMailProcess.error" />
    </div>
  </div>
</template>

<style src="../Auth.css"></style>
<style src="../SignUp.css"></style>