import article1 from "@/assets/photos/annonces/article1.svg"
import article2 from "@/assets/photos/annonces/article2.svg"
import article3 from "@/assets/photos/annonces/article3.svg"
import article4 from "@/assets/photos/annonces/article4.svg"
import article5 from "@/assets/photos/annonces/article5.svg"
import article6 from "@/assets/photos/annonces/article6.svg"
import article7 from "@/assets/photos/annonces/article7.svg"
import article8 from "@/assets/photos/annonces/article8.svg"
import article9 from "@/assets/photos/annonces/article9.svg"

// sample products
const products = [
  {
    id: 1,
    images: [article1, article2, article3],
    title: "Maison 170 m2 face à la forêt, à 40mn de Paris",
    description: "Maison mitoyenne meublée de style moderne, en face de la forêt dans un hameau verdoyant à 4 km de l'entrée de l'A10. Cuisine américaine surplombant le salon, baie vitrée sur terrasse, jardin clos 600 m2, 4 chambres et une mezzanine. Entièrement équipée (lave linge, lave vaisselle, fours, petit électroménager...), vaisselle, draps ... ",
    rooms: 6,
    area: 170,
    address: "longvilliers 78730",
    price: 2100,
    charged: true,
    type: "Maison",
    furnished: false,
    levels: 2,
    minParkingPlaces: 6,
    GES: "d",
    energieClass: "c"
  },
  {
    id: 2,
    images: [article2, article3, article4],
    title: "Appatement 170 m2 face à la forêt, à 40mn de Paris",
    description: "Appartement mitoyenne meublée de style moderne, en face de la forêt dans un hameau verdoyant à 4 km de l'entrée de l'A10. Cuisine américaine surplombant le salon, baie vitrée sur terrasse, jardin clos 600 m2, 4 chambres et une mezzanine. Entièrement équipée (lave linge, lave vaisselle, fours, petit électroménager...), vaisselle, draps ... ",
    rooms: 6,
    area: 170,
    address: "longvilliers 78730",
    price: 2100,
    charged: true,
    type: "Maison",
    furnished: false,
    levels: 2,
    minParkingPlaces: 6,
    GES: "d",
    energieClass: "c"
  },
  {
    id: 3,
    images: [article3, article4, article5],
    title: "Chambre 170 m2 face à la forêt, à 40mn de Paris",
    description: "Chambre mitoyenne meublée de style moderne, en face de la forêt dans un hameau verdoyant à 4 km de l'entrée de l'A10. Cuisine américaine surplombant le salon, baie vitrée sur terrasse, jardin clos 600 m2, 4 chambres et une mezzanine. Entièrement équipée (lave linge, lave vaisselle, fours, petit électroménager...), vaisselle, draps ... ",
    rooms: 6,
    area: 170,
    address: "longvilliers 78730",
    price: 2100,
    charged: true,
    type: "Maison",
    furnished: false,
    levels: 2,
    minParkingPlaces: 6,
    GES: "d",
    energieClass: "c"
  },
  {
    id: 4,
    images: [article4, article5, article6],
    title: "Maison 170 m2 face à la forêt, à 40mn de Paris",
    description: "Maison mitoyenne meublée de style moderne, en face de la forêt dans un hameau verdoyant à 4 km de l'entrée de l'A10. Cuisine américaine surplombant le salon, baie vitrée sur terrasse, jardin clos 600 m2, 4 chambres et une mezzanine. Entièrement équipée (lave linge, lave vaisselle, fours, petit électroménager...), vaisselle, draps ... ",
    rooms: 6,
    area: 170,
    address: "longvilliers 78730",
    price: 180,
    charged: true,
    type: "Maison",
    furnished: false,
    levels: 2,
    minParkingPlaces: 6,
    GES: "d",
    energieClass: "c"
  },
  {
    id: 5,
    images: [article5, article6, article7],
    title: "Garage à Paris",
    description: "Garage tranquil dans un environnement sécurisé.",
    rooms: 6,
    area: 100,
    address: "longvilliers 78730",
    price: 80,
    charged: true,
    type: "Garage",
    furnished: false,
    levels: 2,
    minParkingPlaces: 6,
    GES: "d",
    energieClass: "c"
  },
  {
    id: 6,
    images: [article6, article7, article8],
    title: "Maison 170 m2 face à la forêt, à 40mn de Paris",
    description: "Maison mitoyenne meublée de style moderne, en face de la forêt dans un hameau verdoyant à 4 km de l'entrée de l'A10. Cuisine américaine surplombant le salon, baie vitrée sur terrasse, jardin clos 600 m2, 4 chambres et une mezzanine. Entièrement équipée (lave linge, lave vaisselle, fours, petit électroménager...), vaisselle, draps ... ",
    rooms: 6,
    area: 170,
    address: "longvilliers 78730",
    price: 2100,
    charged: false,
    type: "Maison",
    furnished: true,
    levels: 2,
    minParkingPlaces: 6,
    GES: "d",
    energieClass: "c"
  },
  {
    id: 7,
    images: [article7, article8, article9],
    title: "Maison 170 m2 face à la forêt, à 40mn de Paris",
    description: "Maison mitoyenne meublée de style moderne, en face de la forêt dans un hameau verdoyant à 4 km de l'entrée de l'A10. Cuisine américaine surplombant le salon, baie vitrée sur terrasse, jardin clos 600 m2, 4 chambres et une mezzanine. Entièrement équipée (lave linge, lave vaisselle, fours, petit électroménager...), vaisselle, draps ... ",
    rooms: 6,
    area: 170,
    address: "longvilliers 78730",
    price: 2100,
    charged: true,
    type: "Maison",
    furnished: false,
    levels: 2,
    minParkingPlaces: 6,
    GES: "d",
    energieClass: "c"
  },
]

export default products;