import FirstPage from '@/views/Registers/MandateProject/FirstPage.vue'
import AddProject from '@/views/Registers/MandateProject/AddProject/AddProject.vue'

const baseUrl = '/registres/projets'

const mandateProjectsRoutes = [
  {
    path: baseUrl,
    name: "mandateProjects",
    component: FirstPage
  },
  {
    path: baseUrl+"/creer",
    name: "addProject",
    component: AddProject
  }
]

export default mandateProjectsRoutes