import FirstPage from '@/views/Account/InvoicesQuotations/FirstPage.vue'


// url de base des pages relatives aux dépenses
const baseUrl = "/account/factures-devis"

const invoicesQuotationsRoutes = [
  {
    path: baseUrl,
    name: "invoicesQuotations",
    component: FirstPage,
  }
];

export default invoicesQuotationsRoutes
