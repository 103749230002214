import client from "@/views/client"


const baseUrl = "/gl/lease_agreement"

export const leaseApi = {
    addLease: (data) => client.post(`${baseUrl}/add`, data),
    updateLease: (data) => client.patch(`${baseUrl}/update`, data),

    getOneLease: (id) => client.get(`${baseUrl}/info/${id}`),
    getAllLeases:(limit, offset) => client.get(`${baseUrl}/list/${limit}/${offset}`),

    deleteLease: (data) => client.delete(`${baseUrl}/delete`, {data}),

    
    
};

export default leaseApi;