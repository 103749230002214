<script>
import { accounting } from '@/utils/data/aside'
import ModuleLayer from '../ModuleLayer.vue';

export default {
    name: "AccountLayer",
    props: {
      path: Array,
      pageTitle: String
    },
    data() {
      return {
        title: accounting.title,
        asideLinks: accounting.asideLinks
      };
    },
    components: { ModuleLayer }
}
</script>

<template>
  <ModuleLayer
    :path="path"
    :datas="datas"
    :title="title"
    :asideLinks="asideLinks"
    moduleName="accounting"
    moduleClass="accounting"
    :pageTitle="pageTitle"
  >
    <slot> </slot>
  </ModuleLayer>
</template>