<script lang="js">
import SyndicsWrappper from '@/containers/Syndics/SyndicsWrapper/SyndicsWrappper.vue';
import CoproSyndics from '../CoproSyndics.vue';
import { path, pageTitle } from './datas';

export default {
  components: {
    CoproSyndics,
    SyndicsWrappper
},
  data(){
    return{
      path,
      pageTitle
    }
  }
}
</script>

<template>
  <CoproSyndics :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="profile container-body">
        <SyndicsWrappper />
      </div>
    </div>
  </CoproSyndics>
</template>

<style src="./FirstPage.css"></style>