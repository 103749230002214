import { path as basePath } from "../datas";

// path
export const path = [
    ...basePath,
    {
      label: "Compteur",
      url: basePath[basePath.length - 1].url + "/",
    },
  ];

export const formSteps = [
{
    name: "general",
    label: "Infos",
},]