import agenda1 from "@/assets/icons/agenda1.svg"
import rentCall1 from "@/assets/icons/rent-call1.svg"
import finances1 from '@/assets/icons/finances1.svg'

import { path as basePath } from "@/views/Dashboard/data"

// base url for the pages above this page in the global infrastructure
const baseUrl = "/syndics"

// path to page
export const path = [
  ...basePath,
  {
    label: "syndics",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Dashboard"

function url(url){
  return baseUrl+url
}

// header link
export const headerItems = [
  {
    icon: agenda1,
    title: "Agenda",
    text: "Consulter votre agenda",
    url: url("/agenda")
  },
  // {
  //   icon: messaging1,
  //   title: "Messagerie",
  //   text: "Consulter votre messagerie",
  //   url: "/extranet/conversations"
  // },
  {
    icon: rentCall1,
    title: "Appel de loyer",
    text: "Consultez vos appels de loyer",
    url: url("/appels")
  },
  {
    icon: finances1,
    title: "Finance",
    text: "Gérez vos finances",
    url: url("/finances")
  },
  // {
  //   icon: ged1,
  //   title: "GED",
  //   text: "Consulter votre documents",
  //   url: "/ged"
  // },
]

// transactions infos
export const transactions = [
  {
    date: "08 / 08 / 2022",
    hour: "10:46",
    transId: "045613",
    link: url(`/transactions/045613`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 07 / 2022",
    hour: "12:39",
    transId: "045614",
    link: url(`/transactions/045614`)
  },
  {
    date: "05 / 07 / 2022",
    hour: "16:46",
    transId: "045616",
    link: url(`/transactions/045616`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  }
]

// usefull infos datas
export const usefullInfos = [
  {
    text: "Baux arrivant à expiration",
    value: 4,
    link: url("/baux")
  },
  {
    text: "Validations des révisions de loyers",
    value: 12,
    link: url("/loyers")
  },
  {
    text: "Régularisations des charges",
    value: 8,
    link: url("/depenses")
  },
  {
    text: "Attestion d’assurances à obtenir",
    value: 2,
    link: url("/loyers")
  },
  {
    text: "Baux pouvant être dénnoncer",
    value: 6,
    link: url("/baux")
  },
  {
    text: "Révision de loyer en anomalie",
    value: 5,
    link: url("/loyers")
  },
  {
    text: "Attestation d’entretien",
    value: 1,
    link: url("/depenses")
  },
  {
    text: "Baux arrivant à expiration",
    value: 4,
    link: url("/baux")
  },
  {
    text: "Validations des révisions de loyers",
    value: 12,
    link: url("/loyers")
  },
  {
    text: "Régularisations des charges",
    value: 8,
    link: url("/depenses")
  },
  {
    text: "Attestion d’assurances à obtenir",
    value: 2,
    link: url("/loyers")
  },
  {
    text: "Baux pouvant être dénnoncer",
    value: 6,
    link: url("/baux")
  },
  {
    text: "Révision de loyer en anomalie",
    value: 5,
    link: url("/loyers")
  },
  {
    text: "Attestation d’entretien",
    value: 1,
    link: url("/depenses")
  }
]