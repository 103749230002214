import FirstPage from '@/views/Works/Edition/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/edition"

const editionRoutes = [
  {
    path: baseUrl,
    name: "edition",
    component: FirstPage,
  }
];

export default editionRoutes
