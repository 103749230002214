import { path as basePath } from "../WorksHome/datas"

// base url for the pages above this page in the global infrastructure
const baseUrl = "/travaux/sinistres"

// path to page
export const path = [
  ...basePath,
  {
    label: "sinistres",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Sinistres"