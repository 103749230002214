<script>
  import RentalManagement from '../RentalManagement.vue'
  import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'
  import { items, path, pageTitle, tableDatas } from './datas.js'
import TableOne from '@/containers/TableOne/TableOne.vue'
import { mapActions, mapWritableState } from 'pinia'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
import { initialList } from '@/utils/helpers'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import usePropertyStore from './store'

  export default {
    components: {
    RentalManagement,
    Header2,
    TableOne,
    ErrorMessage,
    AnimatedLoader,
    ButtonOne
},
    data(){
      return {
        items,
        path,
        pageTitle,
        datas: tableDatas,
        offset: 0,
        initialList
      }
    },
    computed: {
        ...mapWritableState(usePropertyStore, ['goods', 'getGoodsProcess'])
    },
    mounted() {
        this.getProperties(0);
    },
    methods: {
        ...mapActions(usePropertyStore, ['getProperties'])
    },
    watch: {
        offset(value) {
            this.getProperties(value);
        }
    }
  }
</script>

<template>
  <RentalManagement :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <Header2 :items="items" />

      <div class="container-body">
                <AnimatedLoader dark v-if="getGoodsProcess.loading" />
                <div v-else-if="getGoodsProcess.status === 'failure'" class="flex flex-col items-center gap-4">
                    <ErrorMessage :message="getGoodsProcess.error" />
                    <ButtonOne isAsync @click="getProperties(offset)" text="Actualiser"
                        :loading="getGoodsProcess.loading" :status="getGoodsProcess.status" />
                </div>
                <TableOne v-else :title="datas.title" :cols="datas.cols" :rows="goods.list"
                    :total="goods.total" :actions="initialList.actions" baseUrl="/gestion-locative/biens"
                    noSearch @emitedOffset="(value) => offset = value" />
      </div>
    </div>
  </RentalManagement>
</template>

<style src="./FirstPage.css"></style>