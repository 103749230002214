<script>
import ButtonOne from '../ButtonOne/ButtonOne.vue';

export default {
  components: {
    ButtonOne,
  },
  props: {
    date: String,
    hour: String,
    transId: String,
    link: String
  }
}
</script>

<template>
  <button class="row-centered transaction">
    <div>
      <div class="trans-date_and_hour">
        <span>{{ date }}</span>
        <span>-</span>
        <span>{{ hour }}</span>
      </div>
      <div class="trans-id">
        Transaction ID: #{{ transId }}
      </div>
    </div>
    <router-link :to="link">
      <ButtonOne text="détail" unActive />
    </router-link>
  </button>
</template>

<style src="./TransactionBox.css"></style>