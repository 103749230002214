import phone from '@/assets/icons/phone.svg'
import email from '@/assets/icons/email.svg'
import web from '@/assets/icons/web.svg'
import location from '@/assets/icons/location.svg'

import { path as basePath } from '../ExtranetHome/datas'

// path

export const path = [
  ...basePath,
  {
    label: "profile",
    url: "/extranet/profile"
  }
]

// page title value
export const pageTitle = "Profile"

// presentation
export const presentation = {
  name: {
    label: "Nom / Enseigne",
    value: "Steve immo-consulting"
  },
  description: {
    label: "Description",
    value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat congue elementum tincidunt massa, nulla. Egestas ligula ut scelerisque turpis sed in sodales nisl. Urna egestas."
  },
  contacts: [
    {
      label: "email",
      icon: email,
      value: "Steve_immo_consulting@gmail.com",
    },
    {
      label: "phone",
      icon: phone,
      value: "+33 656 998 987",
    },
    {
      label: "portable",
      icon: phone,
      value: "+33 656 998 987",
    },
    {
      label: "website",
      icon: web,
      value: "www.steveconsulting.com",
    },
    {
      label: "adresse",
      icon: location,
      value: "8344 Rue des bois",
    },
  ]
}