import FirstPage from '@/views/RentalManagement/Collections/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/gestion-locative/encaissements"

const collectionsRoutes = [
  {
    path: baseUrl,
    name: "collections",
    component: FirstPage,
  }
];

export default collectionsRoutes
