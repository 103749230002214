<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';

import { pieces } from '../datas';
import { mapWritableState } from 'pinia';
import usePropertyStore from '../../store';


export default {
  data(){
    return{

     pieces,

    }

  },
  components: {
    TextField1
  },
  computed: {
    // gives access to store property inside the component and allows setting it
    ...mapWritableState(usePropertyStore, ['property']),
  },
  methods: {
    handleStringValue(name, newValue) {
    
    this.property[name] = newValue;
    console.log({Integer : newValue});
    
  },
  },

}
</script>

<template>
  <div class="row  w-full">
    <TextField1 fullWidth label="Désignation" placeholder="Désignation" :value="property.designation"  @update:value="newValue => property.designation= newValue"/>
    <TextField1 fullWidth type="number"  label="Superficie(en m2)" placeholder="Superficie" :value="property.superficie"  @update:value="newValue => handleStringValue('superficie', newValue)"/>
  </div>
  <div class="row w-full">
    <TextField1 fullWidth label="Description" placeholder="Description"  :value="property.description"  @update:value="newValue => property.description= newValue" :optional="true"/>

  </div>
  <div class="row  w-full">
    <TextField1 fullWidth type="number" label="Superficie loi Boutin (en m2)" placeholder="Superficie loi Boutin" :value="property.superficie_boutin"  @update:value="newValue => handleStringValue('superficie_boutin', newValue)" />
    <TextField1 fullWidth type="number" label="Surface loi Carrez(en m2)" placeholder="Surface loi Carrez" :value="property.surface_carrez"  @update:value="newValue => handleStringValue('surface_carrez', newValue)" />
  </div>
  <div class="row  w-full">
    <TextField1 fullWidth type="number" label="Surface terrasse(en m2)" placeholder="Surface terrasse" :value="property.surface_terrasse"  @update:value="newValue => handleStringValue('surface_terrasse', newValue)" />
    <TextField1 fullWidth type="number" label="Surface annexes(en m2)" placeholder="Surface annexes" :value="property.surface_annexe"  @update:value="newValue => handleStringValue('surface_annexe', newValue)" />
  </div>
  <div class="flex flex-row w-full">
    <div class="flex flex-col min-w-[25%]">
    <div class="flex flex-row mb-2"><label>Nombre de salon<span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model="property.nb_sallon" @change="handleStringValue('nb_sallon', property.nb_sallon)" class="form-select  rounded-md">
     
      <option v-for="(salon, i) in pieces" :key="i">
      {{ salon.value}}</option>
    </select>
   </div>
   <div class="flex flex-col min-w-[25%] ms-4">
    <div class="flex flex-row mb-2"><label>Nombre de chambres<span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model="property.nb_chambre" @change="handleStringValue('nb_chambre', property.nb_chambre)" class="form-select  rounded-md">
     
      <option v-for="(chambre, i) in pieces" :key="i">
      {{ chambre.value}}</option>
    </select>
   </div>
   <div class="flex flex-col min-w-[25%] ms-4">
    <div class="flex flex-row mb-2"><label>Nbre de cuisine <span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model="property.nb_cuisine" @change="handleStringValue('nb_cuisine', property.nb_cuisine)" class="form-select  rounded-md">
     
      <option v-for="(cuisine, i) in pieces" :key="i">
      {{ cuisine.value}}</option>
    </select>
   </div>
  </div>
  <div class="flex flex-row w-full ">
    <div class="flex flex-col min-w-[25%]">
    <div class="flex flex-row mb-2"><label>Nombre de douche<span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model="property.nb_douche" @change="handleStringValue('nb_douche', property.nb_douche)" class="form-select rounded-md">
     
      <option v-for="(douche, i) in pieces" :key="i">
      {{ douche.value}}</option>
    </select>
   </div>
   <div class="flex flex-col min-w-[25%] ms-4">
    <div class="flex flex-row mb-2"><label>Nombre de salle à manger<span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model="property.nb_salle_a_manger" @change="handleStringValue('nb_salle_a_manger', property.nb_salle_a_manger)" class="form-select  rounded-md">
     
      <option v-for="(salle, i) in pieces" :key="i">
      {{ salle.value}}</option>
    </select>
   </div>
  
  </div>

  <div class="row w-full  ">
    <TextField1 fullWidth label="Autre pièce" placeholder="Autre pièce" :value="property.autre_piece"  @update:value="newValue => property.autre_piece = newValue" :optional="true"/>

    <TextField1 fullWidth label="Réfs Cadastrales"  placeholder="Réfs Cadastrales" :value="property.ref_cadastrale"  @update:value="newValue => property.ref_cadastrale = newValue" />
  </div>
    
 
</template>