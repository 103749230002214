import FirstPage from '@/views/Syndics/AG/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/syndics/ag"

const AGRoutes = [
  {
    path: baseUrl,
    name: "AG",
    component: FirstPage,
  }
];

export default AGRoutes
