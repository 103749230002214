import { path as basePath } from "@/views/Dashboard/data"

// base url for the pages above this page in the global infrastructure
const baseUrl = "/travaux"

// path to page
export const path = [
  ...basePath,
  {
    label: "travaux",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Travaux et chantiers"

export const contentDatas = {
  title: "Dossiers travaux",
  button: {
    text: "Créer un dossier travaux",
    url: "/travaux/ajouter"
  },
  table: {
    title: "Liste des dossiers de travaux",
    cols: [
      'Désignation', 'Date de début', 'Date de fin'
    ],
    actions: [
      { 
        label: 'Afficher', 
        type: 'view' 
      }, 
      { 
        label: 'Supprimer', 
        type: 'delete' 
      }
    ],
    rows: [
      ['Veit nunc.', 'Ornare.', 'Mauris Cum eget diam.']
    ]
  }
}

export const globalWorkActions = {
  works: 
    {
      title: "Dossier travaux",
      actions: [
        {
          title: "Création de dossier travaux",
          text: "Effectuez une demande de devis auprès d'un professionnel.",
          url: baseUrl+"/ajouter"
        },
        {
          title: "Liste des demandes et décisions",
          text: "Listez les devis reçus, relancez ceux toujours en attente et prenez vos décisions.",
          url: baseUrl+"/devis"
        },
      ]
    }
  ,
  quotes: {
    title: "Devis",
    actions: [
      {
        title: "Demande de devis",
        text: "Ajoutez un dossier travaux ou bien une fiche chantier.",
        url: baseUrl+"/devis/ajouter"
      },
      {
        title: "Liste des dossiers travaux",
        text: "Retrouver la liste de tous les travaux sur votre parc immobilier.",
        url: baseUrl
      },
    ]
  },
  serviceOrders: {
    title: "Ordre de services",
    actions: [
      {
        title: "Lister les ordres de service",
        text: "Liste des ordres de services liés à vos dossiers travux ou vos sinsitres.",
        url: baseUrl+"/ordres-de-service"
      },
      {
        title: "Créer un ordre de service",
        text: "Créer un ordre de service pou rdemander une intervention ou la réalisation de travaux.",
        url: baseUrl+"/ordres-de-service/ajouter"
      },
    ]
  },
  interventions: {
    title: "Interventions",
    actions: [
      {
        title: "Liste des interventions",
        text: "Retrouvez la liste des interventions prévues ou réalisées.",
        url: baseUrl+"/interventions"
      },
      {
        title: "Créer une fiche intervention",
        text: "Listez les devis reçus, relancez ceux toujours en attente et prenez vos décisionsCréez et imprimez une fiche intervention sur l'un de vos biens.",
        url: baseUrl+"/interventions/ajouter"
      },
    ]
  },
}

// works actions infos
// we include every items in globalWorkActions in our home works actions
export const worksActions = Object.keys(globalWorkActions)
  .reduce((acc, item) => (
    acc.concat(globalWorkActions[`${item}`])
  ), []);