import SignUp from "@/views/Auth/SignUp.vue"
import SignUpForm from '@/views/Auth/SignUpForm.vue'

// signup basic url
const baseUrl = "/signup"

const signUpRoutes = [
  {
    path: baseUrl,
    name: "Signup",
    component: SignUp,
  },
  {
    path: baseUrl + "/form",
    name: "SignupForm",
    component: SignUpForm
  }
];

export default signUpRoutes;