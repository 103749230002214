<script>
import ButtonOne from '../ButtonOne/ButtonOne.vue';

export default {
  components: {
    ButtonOne
  },
  props: {
    text: String,
    value: Number,
    link: String
  }
}
</script>

<template>
  <button class="btn row-centered usefull-info">
    <p class="usefull-info--text">{{ text }}</p>
    <div class="row-centered">
      <p class="usefull-info--value">{{ value }}</p>
      <router-link class="usefull-info--link" :to="link">
        <ButtonOne text="détails" />
      </router-link>
    </div>
  </button>
</template>

<style src="./UsefullInfo.css"></style>