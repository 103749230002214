<script>
import { mapActions, mapWritableState } from 'pinia'
import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import TextField1 from '@/components/TextField1/TextField1.vue'
import { path, pageTitle, formSteps} from './datas'
import useLeaseStore from '../store'
import usePropertyStore from '../../Goods/store'
import useTenantStore from '../../Tenants/store'

import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'

export default{
    components: {
        RentalManagement,
        EditionForm,
        TextField1,
        ErrorMessage
    },
    data(){
        return{
            path,
            pageTitle, 
            steps: formSteps,
            currentStep: 0,

        }
    },
    computed : {
        ...mapWritableState(useLeaseStore, ['lease', 'loading', 'status', 'error']),
        ...mapWritableState(usePropertyStore, ['goods']),
        ...mapWritableState(useTenantStore, ['tenantOwners'])
    },
    methods: {
        ...mapActions(useLeaseStore, ['createLease']),
        ...mapActions(usePropertyStore, ['getProperties']),
        ...mapActions(useTenantStore, ['getTenants'])
    },
    mounted(){
        this.getProperties(0);
        this.getTenants();
    },
    watch:{
        offset(value){
            this.getProperties(value);
            this.getTenants();
        }
    }
}
</script>
<template>
    <RentalManagement :path="path">
        <div class="container">
            <h1  class="container-title"> {{ pageTitle }} </h1>
            <div class="container-body">
                <EditionForm :save="createLease" :steps="steps" @step="(step => currentStep = step)" :loading="loading" :status="status">
                    <template #form>
                        <div class="flex w-full justify-start">
                            <div class="flex flex-col min-w-[48%] me-9">
                                <div class="flex flex-row mb-2"><label>Locataire <span>: </span></label>
                                <p class="required-tag text-red ms-1">*</p></div>
                                <select v-model="lease.tenant_id" class="form-select rounded-md">
                                <option disabled value="">Selectionnez</option>
                                <option v-for="(item, i) in tenantOwners.list" :key="i" :value="item[0]">
                                {{ item[1]}}</option>
                                </select>
                                
                            </div>
                        
                            <div class="flex flex-col min-w-[48%]">
                                <div class="flex flex-row mb-2"><label>Bien <span>: </span></label>
                                <p class="required-tag text-red ms-1">*</p></div>
                                <select v-model="lease.property_id" class="form-select rounded-md">
                                <option disabled value="">Selectionnez</option>
                                <option v-for="(item, i) in goods.list" :key="i" :value="item[0]">
                                {{ item[1]}}</option>
                                </select>
                                
                            </div>
                            
                        </div>
                        
                        <div class="flex w-full justify-start">
                            <TextField1 fullWidth label="Date de debut" type="date" :value="lease.input_date"  @update:value="newValue => lease.input_date = newValue"  />
                            <TextField1 fullWidth class="ms-10" label="Date de fin" type="date" :value="lease.output_date"  @update:value="newValue => lease.output_date = newValue"  />
                        </div>
                        <div class="flex w-full justify-start">
                            <TextField1 fullWidth label="Numéro du contrat" placeholder="Numéro du contrat" :value="lease.contract_number"  @update:value="newValue => lease.contract_number = newValue"/>
                            <TextField1  fullWidth class="ms-10" type="number" label="Montant" placeholder="Montant" :value="lease.rent_amount"  @update:value="newValue => lease.rent_amount = newValue"/>
                        </div>
                        <div class="flex w-full justify-start">
                            <TextField1 fullWidth class="me-96" label="Date de signature" type="date" :value="lease.date_of_signature"  @update:value="newValue => lease.date_of_signature = newValue"  />

                        </div>

                        <div v-if="status === 'failure'" class="flex gap-[20px]">
                            <div>
                                <ErrorMessage :message="error" />
                            </div>
                        </div>
                    </template>

                </EditionForm>
            </div>
        </div>
    </RentalManagement>
</template>