<script>
  import ButtonOne from '../../components/ButtonOne/ButtonOne.vue'
  import PathViewer from '@/containers/PathViewer/PathViewer.vue';

  export default {
    components: {
      ButtonOne,
      PathViewer
    },
    props: {
      specificMessage: String
    }
  }
</script>

<template>
  <div class="error404 page">
    <p v-if="specificMessage" class="error404--message">{{ specificMessage }}</p>
    <PathViewer />
    <img class="error404--vector" src="../../assets/vectors/404.svg" alt="Erreur 404">
    <div class="error404--content">
      <p>Il semble qu'il n'y ait rien ici</p>
      <router-link to="/"><ButtonOne text="Retour à la page d'accueil"/></router-link>
    </div>
  </div>
</template>
<style src="./404.css"></style>