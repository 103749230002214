<!-- Box que l'on retrouve dans toutes les pages d'authentification, utile pour englobre les formulaires ou les contenu de page en général -->
<script>
import AuthHeader from '@/containers/Auth/AuthHeader/AuthHeader.vue';

export default {
  components: {
    AuthHeader
  },
  props: {
    title: String,
    text: String
  },
  data() {
    return {
      pageTitle: "Sign Up"
    }
  }
}
</script>

<template>
  <div class="page auth-page signup-wrapper">
    <div class="auth-box">
      <AuthHeader :title="title" :text="text" />

      <slot>
        
      </slot>
    </div>
  </div>
</template>

<style src="../../../views/Auth/Auth.css"></style>
<style src="./SignUpWrapper.css"></style>