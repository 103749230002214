<script>
import GED from '../GED.vue'
import HeaderOne from '@/containers/GED/Header/HeaderOne.vue';
import FolderBox from '@/components/FolderBox/FolderBox.vue';
import FileBox from '@/components/FileBox/FileBox.vue'
import TableOne from '@/containers/TableOne/TableOne.vue';

import { header, folders, files, path, pageTitle, table } from './datas';
export default {
  data(){
    return {
      // informations relatives au header de la page
      header: {
        vector: header.vector,
        resumes: header.resumes,
        shortLinks: header.shortLinks
      },
      // informations relatives à la permière section "dossier" du corps de la page
      folders: {
        title: folders.title,
        icon: folders.icon,
        items: folders.items
      },
      // informations relatives à la permière section "fichiers" du corps de la page
      files: {
        title: files.title,
        items: files.items
      },
      table: table,
      // pagination variable
      path,
      pageTitle
    }
  },
  components: {
    GED,
    HeaderOne,
    FolderBox,
    FileBox,
    TableOne
  },
}
</script>

<template>
  <GED :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <HeaderOne 
        :vector="header.vector"
        :resumes="header.resumes"
        :shortLinks="header.shortLinks"
      />
      <div class="container-body">
        <!-- Première section : section des dossiers -->
        <section class="container-section">
          <span class="title container-section--title">
            {{ folders.title }}
          </span>
          <div class="list">
            <FolderBox 
              v-for="({ title, date, text, weight, files }, index) in folders.items"
              :key="'folder-item-'+index"
              :icon = "folders.icon"
              :title = "title"
              :date = "date"
              :text = "text"
              :weight = "weight"
              :files = "files"
            />
          </div>
        </section>
        <!-- Seconde section : section des fichiers -->
        <section class="container-section">
          <div>
            <span class="title container-section--title">
              {{ files.title }}
            </span>
            <div class="list">
              <FileBox 
                v-for="({ title, date, module, goodId, icon }, index) in files.items"
                :key="'folder-item-'+index"
                :icon = "icon"
                :title = "title"
                :date = "date"
                :module = "module"
                :goodId = "goodId"
              />
            </div>
          </div>
          <TableOne
            noSearch 
            :total="15"
            :cols="table.cols"
            :rows="table.rows"
            :actions="table.actions"
          />
        </section>
      </div>
    </div>
  </GED>
</template>

<style src="./GEDHome.css"></style>