import { path as basePath } from '../ExtranetHome/datas'

// path

export const path = [
  ...basePath,
  {
    label: "fichiers",
    url: "/extranet/fichiers"
  }
]

// page title value
export const pageTitle = "Fichiers Partagés"

export const allFilesCols = [
  "NOM", "ENVOYÉ PAR", "TAILLE", "CRÉÉ LE", "DESCRIPTION"
]

export const allFilesActions = [
  {
    type: "update",
    label: "modifier"
  },
  {
    type: "delete",
    label: "supprimer"
  },
]

export const allFilesRows = [
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
  ["Auctor tempor", "pretium sed.\n Locataire", "23ko", "20-08-2022", "Loorem ipsum dolor sit amet consecutuer"],
]