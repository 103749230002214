import WorksInterventions from '@/views/Works/WorksInterventions/WorksInterventions.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/interventions"

const interventionsRoutes = [
  {
    path: baseUrl,
    name: "interventions",
    component: WorksInterventions,
  }
];

export default interventionsRoutes
