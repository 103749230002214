<script>
  export default {
    props: {
      icon: String,
      title: String,
      date: String,
      module: String,
      goodId: String
    }
  }
</script>

<template>
  <div class="box1 list--item file-box">
    <div class="title">
      <img :src="icon" alt="file-icon" />
      {{ title }}
    </div>
    <div class="infos">
      <span v-if="date" class="date">{{ date }}</span>
      <div v-if="module" class="dot" />
      <span v-if="module" class="module">{{ module }}</span>
      <div v-if="goodId" class="dot" />
      <span v-if="goodId" class="good-id">Bien{{ goodId }}</span>
    </div>
  </div>
</template>

<style src="./FileBox.css"></style>