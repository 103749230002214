import { path as basePath } from "../datas";

// path
export const path = [
    ...basePath,
    {
      label: "Equipement",
      url: basePath[basePath.length - 1].url + "/",
    },
  ];

  // page title value
export const pageTitle = "Ajouter un equipement";

export const formSteps = [
{
    name: "general",
    label: "Infos",
},]