import { defineStore } from "pinia";
import api from "../api";
import { generalInfos, callLeaseInfos} from "./New/datas"
import { noEmptyFields, initialList, initProcess } from "@/utils/helpers";

const useLeaseStore = defineStore('lease-agreement', {
    state : () => ({
        loading : false,
        status : "",
        error : "",
        lease : {
            ...generalInfos,
        },
        makeCall:{
            ...callLeaseInfos,
        },
        getLeasesProcess : initProcess,
        getLeaseProcess : initProcess,
        updateLeaseProcess : initProcess,
        callLeaseProcess : initProcess,
        updatedLeaseDatas : [],
        leases : initialList,
        deletionProcess: initProcess
    }),
    actions : {
        // function to create a lease
        async createLease(){
            if(!this.loading){
                if(
                    noEmptyFields(this.lease, [])
                ){
                    this.loading = true,
                    this.status = "pending"

                    try{
                        console.log({newLease : this.lease})
                        const resp  = await api.addLease(this.lease)
                        console.log({resp : resp})
                        if(resp.status === 200){
                            this.loading = false;
                            this.status = "success"
                            location.replace("/gestion-locative/baux")
                        }else{
                            this.loading = false;
                            this.status = "failure";
                            this.error = resp?.response?.data?.detail
                        }

                    }catch(error){
                        this.loading = false
                        this.status = "failure"
                        this.error = error?.data?.detail
                        console.log({error})
                    }
                }else{
                    this.loading = false;
                    this.status = "failure"
                    this.error = "Vous semblez avoir omis certains des champs obligatoires !"
                }
            }
        },

        // function to fetch all the leases
        async getLeaseList(offset){
            try {
                this.getLeasesProcess = {loading:true, status: "pending", error:""}
                const resp = await api.getAllLeases(10, offset)
                console.log({resp : resp})
                if(resp.status === 200){
                    let rows = []
                    let cols = ['id','contract_number', 'property_info', 'tenant_id', 'rent_amount']
                    resp.data.listLease.map((item) =>{
                        let row = []
                        cols.map((col) =>{
                            if(col === 'property_info'){

                                row.push(item[col]['type_de_bien'])
                            }else if(col === 'tenant_id'){

                                row.push(item[col]['lastname'])
                            }else{

                                row.push(item[col])
                            }
                           
                        })
                        rows.push(row)
                    })
                    this.leases = { ...this.leases, list: rows, total: resp.data.nbItem}
                    console.log({ leases: this.leases })
                    this.getLeasesProcess = {loading: false, status: "success"}
                }else{
                    this.getLeasesProcess = {loading: false, status:"failure", error: "Une erreur s'est produite, veuillez réessayer plus tard !"}
                }
            } catch (error) {
                console.log({error})
                this.getLeasesProcess = {loading:false, status: "failure", error: error?.data?.detail}
            }
        },

        // function to fetch a lease
        async getLease(id){

            try {
                this.getLeaseProcess = {loading: true, status:"pending"}
                const resp = await api.getOneLease(id)
                console.log({respLease: resp})
                if(resp.status === 200){
            
                    this.lease = { ...resp.data}
                   
                    this.getLeaseProcess = {loading: false, status:'success'}
                }else{
                    this.getLeaseProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}

                }
            } catch (error) {
                console.log({error})
                this.getLeaseProcess = {loading: false, status: "failure", error: error}

            }
        },

        // function to update a lease
        async updateOneLease(id){
            this.updateLeaseProcess = {loading:true, status:"pending", error:""}
            let updatedDatas = {}
            this.updatedLeaseDatas.map((name) => (
                updatedDatas = { ...updatedDatas, [name]: this.lease[name]}
              ))
              updatedDatas = { ...this.lease, id }
              console.log({updatedDatas})

            try {
                const resp = await api.updateLease(updatedDatas)
                if(resp.status === 200){
                    console.log({ resp: resp})
                    this.updateLeaseProcess = {loading: false, status:'success', error:'Opération réussie'}
                }else{
                    console.log({resp})
                    this.updateLeaseProcess= {loading: false, status: 'failure', error:resp?.response?.data?.detail || "Veuillez vérifier vos informations !"}
                }
            } catch (error) {
                console.log(error)
                this.updateLeaseProcess = {loading: false, status: 'failure', error: error}
            }
        },

        // function to delete a lease
        async deleteOneLease(id){
            this.deletionProcess = {loading: true, status : "pending"}
            try {
                const resp = await api.deleteLease({id})
                if(resp.status === 200){
                    console.log({resp: resp})
                    this.deletionProcess = {loading:false, status:"success"}
                    location.replace("/gestion-locative/baux")
                }else{
                    this.deletionProcess = { loading: false, status: "failure", error: "Une erreur s'est produite !" }
                    console.log({ delete: resp})
                }
            } catch (error) {
                this.deletionProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
                console.log({ error });
            }
        },

        //function to call for a lease
        async callForLease(){
            this.callLeaseProcess = {loading:true, status:"pending", error:""}
            let body = {};
            body = {...this.makeCall}
            console.log({ dataCallLease: body})
            try {
                const resp = await api.callLease(body);
                console.log({ respCallLease: resp})
                if(resp.status === 200){
                    console.log({ resp: resp})
                    this.callLeaseProcess = {loading: false, status:'success', error:'Opération réussie'}
                }else{
                    console.log({resp})
                    this.callLeaseProcess= {loading: false, status: 'failure', error:resp?.response?.data?.detail || "Veuillez vérifier vos informations !"}
                }
            } catch (error) {
                console.log(error)
                this.callLeaseProcess = {loading: false, status: 'failure', error: error}
            }
        }
    }
});

export default  useLeaseStore;