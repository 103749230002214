<script>
  import PathViewer from '@/containers/PathViewer/PathViewer.vue';
  import SideBar1 from '@/containers/SideBar1/SideBar1.vue';

  import { marketPlace } from '@/utils/data/aside'

  export default {
    components: {
      PathViewer,
      SideBar1
    },
    props:{
      path: Array
    },
    data() {
      return {
        title: marketPlace.title,
        asideLinks: marketPlace.asideLinks,
        pub: marketPlace.pub
      }
    }
  }
</script>

<template>
  <div class="marketplace page">
    <PathViewer :path="path" />
    <div class="body">
      <SideBar1
        :title="title"
        :links="asideLinks"
        :pub="pub"
        module="marketplace"
      />
      <slot></slot>
    </div>
  </div>
</template>

<style>
  .marketplace--header{
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 32px 40px 40px 40px;
  }

  .marketplace--header .header-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .marketplace--header .header-top .header-top--title{
    display: flex;
    align-items: center;
    gap: 15.4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .marketplace--header .header-content{
    justify-content: space-between;
  }
</style>