import {
  rentalManagement,
  ged,
  registers,
  extranet,
  marketPlace,
  works,
  syndics,
  accounting
} from './aside'

export default [
  {
    label: "Gestion Locative",
    url: "/gestion-locative",
    menu: rentalManagement.asideLinks,
    unlocked: true
  },
  {
    label: "GED",
    url: "/ged",
    menu: ged.asideLinks,
    unlocked: true
  },
  {
    label: "Registres de mandat",
    url: "/registres",
    menu: registers.asideLinks,
    unlocked: true
  },
  {
    label: "Extranet",
    url: "/extranet",
    menu: extranet.asideLinks,
    unlocked: true
  },
  {
    label: "Transactions immobières",
    url: "/marketplace",
    menu: marketPlace.asideLinks,
    unlocked: true
  },
  {
    label: "Travaux",
    url: works.asideLinks[0].url,
    menu: works.asideLinks,
    unlocked: true
  },
  {
    label: syndics.title,
    url: syndics.asideLinks[0].url,
    menu: syndics.asideLinks,
    unlocked: true
  },
  {
    label: "Comptabilité",
    url: "/comptabilite",
    menu: accounting.asideLinks,
    unlocked: true
  },
  {
    label: "Finances",
    url: "/finances",
    menu: []
  }
]