<script>
  import AccountLayer from '../AccountLayer.vue'
  import TableOne from '@/containers/TableOne/TableOne.vue'
  import { path, pageTitle, cw } from './datas.js'
  import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
  import SelectField1 from '@/components/SelectField1/SelectField1.vue'
  import TextField1 from '@/components/TextField1/TextField1.vue'
  import { mapActions, mapWritableState } from 'pinia'
  import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
  import PhotoPreview from './PhotoPreview.vue'

  import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
  import useAccountStore from '../store'

  export default {
    components: {
    AccountLayer,
    TableOne,
    SelectField1,
    ButtonOne,
    TextField1,
    Loader: AnimatedLoader,
    ErrorMessage,
    PhotoPreview,
},
    data(){
      return {
        cw,
        path,
        pageTitle,
        profileInfosOn: true,
        workInfosOn: false,
        changePwdOn: false,
      }
    },
    computed: {
      ...mapWritableState(useAccountStore, ['getUserInfosProcess', 'getUserPhotoProcess', 'user', 'updateUserInfosProcess', 'updateUserPwdProcess', 'userChecker', 'isUpdatable', 'modulesInfos', 'photoUrl', 'changeUserPwd']),
    },
    methods: {
      ...mapActions(useAccountStore, ['getUserInfos', 'getUserPhoto', 'updateUserInfos', 'updateUserPhoto', 'updateUserPwd'])
    },
    mounted(){
      this.getUserInfos()
      this.getUserPhoto()
    }
  }
</script>

<template>
  <AccountLayer :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="container-body">
        <div class="account">
          <form class="flex flex-col gap-[20px]" @submit.prevent="updateUserInfos">
            <div class="box1 account-infos">
              <div class="flexed account-infos--header" @click="profileInfosOn = !profileInfosOn">
                <h1 class="container-title">{{ cw.accountInfos.title }}</h1>
                <div class="account-infos--toggleBtn">
                  <div v-if="profileInfosOn" class="reduce"></div>
                  <div v-else class="reduce"></div>
                </div>
              </div>
  
              <Loader v-if="getUserInfosProcess.loading" dark />
              <ErrorMessage v-else-if="getUserInfosProcess.status === 'failure'" :message="getUserInfosProcess.error" />
              <div v-else-if="profileInfosOn" class="w-full flex flex-col gap-[50px]">
                <section class="flex w-full justify-between">
                  <div class="">
                    <h2 class="title3">{{ cw.accountInfos.form.avatar.label }}</h2>
                   
                    <div class="flex flex-col">

                      <PhotoPreview :description="cw.accountInfos.form.avatar.description"/>
                     
                    </div>
                  </div>
                  <div class="" >
                    <h2 class="title3">{{ cw.accountInfos.form.profileType.label }}</h2>
                    <p>{{ cw.accountInfos.form.profileType.description }}</p>
                    <SelectField1 />
                  </div>
                </section>
                <section class="flex flex-col w-full">
                  <h2 class="title3">{{ cw.accountInfos.form.personal.title }}</h2>
                  <div>
                    <div class="flex gap-[20px]">
                      <TextField1  name="nom" :value="user.nom" @update:value="newValue => user.nom = newValue.trim()" fullWidth :label="cw.accountInfos.form.personal.name.label" :placeholder="cw.accountInfos.form.personal.name.placeholder"  />
                      <TextField1  name="prenom" :value="user.prenom" @update:value="newValue => user.prenom = newValue.trim()" fullWidth :label="cw.accountInfos.form.personal.firstName.label" :placeholder="cw.accountInfos.form.personal.firstName.placeholder" />
                    </div>
                    <div class="flex gap-[20px]">
                      <TextField1  name="ville" :value="user.ville" @update:value="newValue => user.ville = newValue.trim()" fullWidth :label="cw.accountInfos.form.personal.town.label" :placeholder="cw.accountInfos.form.personal.town.placeholder" />
                      <TextField1  name="pays" :value="user.pays" @update:value="newValue => user.pays = newValue.trim()" fullWidth :label="cw.accountInfos.form.personal.country.label" :placeholder="cw.accountInfos.form.personal.country.placeholder" />
                    </div>
                      <TextField1  name="adresse" :value="user.adresse" @update:value="newValue => user.adresse = newValue.trim()" fullWidth :label="cw.accountInfos.form.personal.address.label" :placeholder="cw.accountInfos.form.personal.address.placeholder" />
                    
                    <TextField1 optional name="complement_adresse" :value="user.complement_adresse" @update:value="newValue => user.complement_adresse = newValue.trim()" fullWidth :label="cw.accountInfos.form.personal.additionalAddress.label" :placeholder="cw.accountInfos.form.personal.additionalAddress.placeholder" />
  
                    <div class="flex gap-[20px]">
                      <TextField1  name="telephone" type="number" :value="user.telephone" @update:value="newValue => user.telephone = parseInt(newValue.trim())" fullWidth :label="cw.accountInfos.form.personal.telephone.label" :placeholder="cw.accountInfos.form.personal.telephone.placeholder" />
                      <TextField1 optional name="code_postal" :value="user.code_postal" @update:value="newValue => user.code_postal = newValue.trim()" fullWidth :label="cw.accountInfos.form.personal.zipCode.label" :placeholder="cw.accountInfos.form.personal.zipCode.placeholder" />
                    </div>
                  </div>
                </section>
              </div>
            </div>
  
            <div class="box1 account-infos">
              <div class="flexed account-infos--header" @click="workInfosOn = !workInfosOn">
                <h1 class="container-title">{{ cw.workInfos[user.user_type === 'pro' ? 'proTitle' : 'compTitle'] }}</h1>
                <div class="account-infos--toggleBtn">
                  <div v-if="workInfosOn" class="reduce"></div>
                  <div v-else class="reduce"></div>
                </div>
              </div>
              <Loader v-if="getUserInfosProcess.loading" dark />
              <ErrorMessage v-else-if="getUserInfosProcess.status === 'failure'" :message="getUserInfosProcess.error" />
              <form v-else-if="workInfosOn" class="w-full flex flex-col gap-[50px]" >
                <section class="flex flex-col w-full">
                  <div>
                    <div class="flex gap-[20px]">
                      <TextField1  name="email_pro" :value="user.email_pro" @update:value="newValue => user.email_pro = newValue.trim()" fullWidth :label="cw.workInfos.form.email_pro.label" :placeholder="cw.workInfos.form.email_pro.placeholder" type="email"  />
                      <TextField1  name="nom_entreprise" :value="user.nom_entreprise" @update:value="newValue => user.nom_entreprise = newValue.trim()" fullWidth :label="cw.workInfos.form.nom_entreprise.label" :placeholder="cw.workInfos.form.nom_entreprise.placeholder" />
                    </div>
                    <div class="flex gap-[20px]">
                      <TextField1  name="siret_siren" :value="user.siret_siren" @update:value="newValue => user.siret_siren = newValue.trim()" fullWidth :label="cw.workInfos.form.siret_siren.label" :placeholder="cw.workInfos.form.siret_siren.placeholder" />
                      <TextField1 v-if="user.user_type === 'pro'" name="id_professoinnel" :value="user.id_professionnel" @update:value="newValue => user.id_professionnel = newValue.trim()" fullWidth :label="cw.workInfos.form.id_professionnel.label" :placeholder="cw.workInfos.form.id_professionnel.placeholder" />
                      <TextField1 v-else :value="user.type_entreprise" @update:value="newValue => user.type_entreprise = newValue.trim()" fullWidth :label="cw.workInfos.form.type_entreprise.label" :placeholder="cw.workInfos.form.type_entreprise.placeholder" />
                    </div>
                  </div>
                </section>
              </form>
            </div>
  
            <div class="flex flex-col gap-2">
              <ErrorMessage v-if="updateUserInfosProcess.status === 'failure'" :message="updateUserInfosProcess.error" />
              <ButtonOne isAsync :loading="updateUserInfosProcess.loading" :status="updateUserInfosProcess.status" text="Valider vos modifications" :unClickable="!isUpdatable" />
            </div>
          </form>

          <div class="box1 account-infos change-password">
            <div class="flexed account-infos--header" @click="changePwdOn = !changePwdOn">
                <h1 class="container-title">{{ cw.changePwd['title'] }}</h1>
                <div class="account-infos--toggleBtn">
                  <div v-if="changePwdOn" class="reduce"></div>
                  <div v-else class="reduce"></div>
                </div>
              </div>
              <Loader v-if="getUserInfosProcess.loading" dark />
              <ErrorMessage v-else-if="getUserInfosProcess.status === 'failure'" :message="getUserInfosProcess.error" />
              <form v-else-if="changePwdOn" class="mx-60 flex flex-col place-content-center" @submit.prevent="updateUserPwd">
                <section class="flex flex-col mb-4">
                  <div>
                    <div class="flex flex-col gap-5">
                      <TextField1  name="current_password" :value="changeUserPwd.current_password" @update:value="newValue => changeUserPwd.current_password = newValue.trim()" fullWidth :label="cw.changePwd.form.oldPassword.label" :placeholder="cw.changePwd.form.oldPassword.placeholder"  type="password"/>
                        <TextField1  name="password" :value="changeUserPwd.password" @update:value="newValue => changeUserPwd.password = newValue.trim()" fullWidth :label="cw.changePwd.form.newPassword.label" :placeholder="cw.changePwd.form.newPassword.placeholder"  type="password"/>
                          <TextField1  name="confirm_password" :value="changeUserPwd.confirm_password" @update:value="newValue => changeUserPwd.confirm_password = newValue.trim()" fullWidth :label="cw.changePwd.form.confirmPassword.label" :placeholder="cw.changePwd.form.confirmPassword.placeholder"  type="password"/>
          
                    </div>
                   
                  </div>
                </section>
                <div class="flex flex-col gap-4">
                    <ErrorMessage v-if="updateUserPwdProcess.status === 'failure'" :message="updateUserPwdProcess.error" />
                    <ButtonOne isAsync :loading="updateUserPwdProcess.loading" :status="updateUserPwdProcess.status" text="Confirmez" />
                </div>
              </form>
          </div>

          <div class="box1 account-modules">
            <div class="account-modules--header w-full flex justify-between items-center">
              <h1 class="container-title">{{ cw.modulesInfos.title }}</h1>
              <p class="account-modules--buyBtn text-primary underline">
                <router-link :to="cw.modulesInfos.buyBtn.url">{{ cw.modulesInfos.buyBtn.label }}</router-link>     
              </p>
            </div>
            <TableOne 
              :cols="cw.modulesInfos.table.cols"
              :rows="modulesInfos.list"
              :actions="modulesInfos.actions"
              noSearch
              unMutable
            />
          </div>
        </div>
      </div>
    </div>
  </AccountLayer>
</template>

<style src="./FirstPage.css"></style>