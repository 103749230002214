<script lang="js">
import { mapActions, mapWritableState } from 'pinia';

import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import TextField1 from '@/components/TextField1/TextField1.vue';
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

import useLeaseStore from '../store'
import usePropertyStore from '../../Goods/store'
import useTenantStore from '../../Tenants/store'
import {formSteps} from './datas'
import { path as basePath} from "../datas"
const baseUrl = basePath[basePath.length - 1].url

export default{
    components: {
    RentalManagement,
    EditionForm,
    TextField1,
    ButtonOne,
    AnimatedLoader,
    ErrorMessage

    },
    data(){
        return {
            changed:false,
            deleteClicked: false,
            steps: formSteps,
            currentStep: 0
        }
    },
    computed:{
        ...mapWritableState(useLeaseStore, ['lease', 'updatedLeaseDatas', 'updateLeaseProcess', 'deletionProcess', 'loading', 'status', 'error', 'getLeaseProcess', 'callLeaseProcess', 'makeCall']),
        ...mapWritableState(usePropertyStore, ['goods']),
        ...mapWritableState(useTenantStore, ['tenantOwners']),
        urlParam(){
            return this.$route.params.leaseId
        },
        leaseId(){
            return parseInt(this.urlParam);
        },
        path(){
            return [
                ...basePath,
                {
                    label: (this.lease.contract_number),
                    url: baseUrl+ "/" + this.leaseId
                }
            ]
        }
    },
    methods: {
    findIndex(name) {
        return this.steps.indexOf(this.steps.find(
            (step) => step.name === name))
    },
    handleDeleteLease() {
      if (this.deleteClicked) {
        this.deleteClicked = false;
        this.deleteOneLease(this.leaseId);
      } else {
        this.deleteClicked = true;
      }
    },
    handleCallLease(){
      this.makeCall['id_lease'] = this.leaseId
      this.makeCall['echeance_de'] = this.lease.output_date
      this.makeCall['charge'] = this.lease.rent_amount
     this.callForLease();
    },
    handleUpdateSelect(name, event){
      this.changed = true;

      this.lease[name] = event.target.value;
      console.log(event.target.value)
      !this.updatedLeaseDatas.includes(name) && this.updatedLeaseDatas.push(name);
    },

    handleUpdateValue(name, newValue) {
      this.changed = true;
      this.lease[name] = newValue;
      !this.updatedLeaseDatas.includes(name) && this.updatedLeaseDatas.push(name);
    },

    reload() {
      location.reload()
    },
    ...mapActions(useLeaseStore, ['getLease', 'updateOneLease', 'deleteOneLease', 'callForLease']),
    ...mapActions(usePropertyStore, ['getProperties']),
    ...mapActions(useTenantStore, ['getTenants'])
    },
    async mounted(){
        this.path[this.path.length - 1].url += this.urlParam
        this.getLease(this.leaseId)
        this.getProperties(0)
        this.getTenants()
        this.makeCall['id_lease'] = this.leaseId
        this.makeCall['echeance_de'] = this.lease.output_date
        this.makeCall['charge'] = this.lease.rent_amount
       
    }
}
</script>
<template>
  <RentalManagement :path="path">
    <div v-if="getLeaseProcess.status === 'pending'">
      <AnimatedLoader dark />
    </div>
    <div v-if="getLeaseProcess.status === 'success'" class="container">
      <div class="flex items-center justify-between">
        <h1 class="container-title">Contrat N°: {{ lease.contract_number }}</h1>
       
        <div class="flex">
          <ButtonOne isAsync :loading="callLeaseProcess.loading" :status="callLeaseProcess.status" :dangerous="true" text="Lancer un appel de loyer" @click="handleCallLease()" class="me-3"/>
          <ButtonOne
          @click="handleDeleteLease()"
          :text="
            deleteClicked ? 'Cliquez à nouveau pour supprimer' : 'Supprimer'
          "
          :dangerous="deleteClicked"
          :loading="deletionProcess.loading"
          :status="deletionProcess.status"
          isAsync
        />
        </div>
      </div>
    
        <div class="container-body">
        <EditionForm :changed="changed" :steps="steps" @step="(step => currentStep = step)" :loading="loading" :status="status">
            <template #form>
            <form
                @submit.prevent="() => updateOneLease(leaseId)"
                class="flex flex-col w-full justify-start">
                <div class="flex w-full justify-start">
                <div class="flex flex-col min-w-[48%] me-9">
                    <div class="flex flex-row mb-2">
                    <label>Locataire <span>: </span></label>
                    <p class="required-tag text-red ms-1">*</p>
                    </div>
                    <select
                    v-model="lease.tenant_id"
                    @change="handleUpdateSelect('tenant_id', $event)"
                    class="form-select rounded-md"
                    >
                    <option disabled value="">Selectionnez</option>
                    <option
                        v-for="(item, i) in tenantOwners.list"
                        :key="i"
                        :value="item[0]"
                    >
                        {{ item[1] }}
                    </option>
                    </select>
                </div>
                <div class="flex flex-col min-w-[48%]">
                    <div class="flex flex-row mb-2">
                    <label>Bien <span>: </span></label>
                    <p class="required-tag text-red ms-1">*</p>
                    </div>
                    <select
                    v-model="lease.property_id"
                    @change="handleUpdateSelect('property_id', $event)"
                    class="form-select rounded-md"
                    >
                    <option disabled value="">Selectionnez</option>
                    <option
                        v-for="(item, i) in goods.list"
                        :key="i"
                        :value="item[0]"
                    >
                        {{ item[1] }}
                    </option>
                    </select>
                </div>
                </div>

                <div class="flex w-full justify-start">
                <TextField1
                    fullWidth
                    label="Date de debut"
                    type="date"
                    :value="lease.input_date"
                    @update:value="
                    (newValue) => handleUpdateValue('input_date', newValue)
                    "
                />
                <TextField1
                    fullWidth
                    label="Date de fin"
                    type="date"
                    class="ms-10"
                    :value="lease.output_date"
                    @update:value="
                    (newValue) => handleUpdateValue('output_date', newValue)
                    "
                />
                </div>
                <div class="flex w-full justify-start">
                <TextField1
                    fullWidth
                    label="Numéro du contrat"
                    placeholder="Numéro du contrat"
                    :value="lease.contract_number"
                    @update:value="
                    (newValue) => handleUpdateValue('contract_number', newValue)
                    "
                />
                <TextField1
                    fullWidth
                    label="Montant"
                    placeholder="Montant"
                    class="ms-10"
                    :value="lease.rent_amount"
                    @update:value="
                    (newValue) => handleUpdateValue('rent_amount', newValue)
                    "
                />
                </div>
                <div class= "flex w-full justify-start">
                    <TextField1
                fullWidth
                label="Date de signature"
                type="date"
                class="me-96"
                :value="lease.date_of_signature"
                @update:value="
                    (newValue) => handleUpdateValue('date_of_signature', newValue)
                "
                />
                </div>


                <div v-if="status === 'failure'" class="flex gap-[20px]">
                <div>
                    <ErrorMessage :message="error" />
                </div>
                </div>
                <div class="flex justify-center items-center mt-8">
                  <ButtonOne isAsync :loading="updateLeaseProcess.loading" :status="updateLeaseProcess.status" text="Modifier" />

                </div>
            </form>
            </template>
        </EditionForm>
        </div>
    </div>
    <div v-if="getLeaseProcess.status === 'failure'" class="container">
      <div class="">
        <h1 class="container-title">Erreur de chargement</h1>
        <ButtonOne @click="reload()" text="Cliquez pour recharger" />
      </div>
    </div>
  </RentalManagement>
</template>
