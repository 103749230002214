import ChangePassword from '@/views/Account/ChangePassword/ChangePassword.vue' 


const baseUrl = "/account/changer-de-mot-de-passe"

const changePasswordRoutes = [
    {
    path: baseUrl,
    name: "Changer le mot de passe",
    component: ChangePassword,

    }
];

export default changePasswordRoutes