<script>
export default {
  name: "StatusCell",
  props: {
    cell: String
  }
}
</script>

<template>
  <div class="flex items-center gap-[8px] justify-center">
    <div class="h-[20px] w-[20px] rounded-full flex justify-center items-center" :class="cell === 'actif' ? 'bg-[#c2f9dd]' : 'bg-[#e7aeae]'">
      <div class="h-[12px] w-[12px] rounded-full" :class="cell === 'actif' ? 'bg-[#3db36f]' : 'bg-[#F53F46]'"></div>
    </div>
    <div :class="cell === 'actif' ? 'text-[#2db36f]' : 'text-[#f53f46]'">{{ cell }}</div>
  </div>
</template>