import FirstPage from '@/views/MarketPlace/Archives/FirstPage.vue'

// url de base des pages relatives à la corbeille
const baseUrl = "/marketplace/archives"

const marketePlaceArchivesRoutes = [
  {
    path: baseUrl,
    name: "marketPlaceArchives",
    component: FirstPage,
  }
];

export default marketePlaceArchivesRoutes
