<script>
  import { Carousel, Slide, Navigation } from 'vue3-carousel'
  import 'vue3-carousel/dist/carousel.css'

  import ErrorPage404 from '@/views/404/ErrorPage404.vue'
  import MarketPlace from '../MarketPlace.vue'
  import DescriptionStep from './EditSteps/DescriptionStep.vue'
  import MandatStep from './EditSteps/MandatStep.vue'
  import OwnerStep from './EditSteps/OwnerStep.vue'
  import DetailsStep from './EditSteps/DetailsStep.vue'
  import FinancialInfosStep from './EditSteps/FinancialInfosStep.vue'
  import RoomsStep from './EditSteps/RoomsStep.vue'
  import CoPropertyStep from './EditSteps/CoPropertyStep.vue'
  import PhotosStep from './EditSteps/PhotosStep.vue'
  import DiagnosticStep from './EditSteps/DiagnosticStep.vue'

  import products from '@/utils/data/products'
  import { path as basePath } from "../MarketPlaceHome/datas"
  import { sections, carousel } from './datas'

  import downArrow from '@/assets/icons/down-arrow.png'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

  // base url for the pages above this page in the global infrastructure
  const baseUrl = basePath[basePath.length-1].url+"/products"

  export default {
    components: {
    ErrorPage404,
    MarketPlace,
    Carousel,
    Slide,
    Navigation,
    DescriptionStep,
    MandatStep,
    OwnerStep,
    DetailsStep,
    FinancialInfosStep,
    RoomsStep,
    CoPropertyStep,
    PhotosStep,
    DiagnosticStep,
    ButtonOne
},
    data(){
      return{
        downArrow,
        editingOn: false,
        sections,
        openedSection: "description",
        carousel,
        importing: false
      }
    },
    computed: {
      // choosen product id
      urlParam(){
        return this.$route.params.productId
      },  
      productId(){
        return parseInt(this.urlParam);
      },
      // choosen product
      product(){
        if (this.urlParam !== "add") {
          return products.find(({id}) => id === this.productId);
        } else {
          return undefined;
        }
      },
      productSupInfos(){
        return [
          {
            label: "Type de bien",
            value: this.product.type,
          },
          {
            label: "Meublé / Non meublé",
            value: `${!this.product.furnished ? "Non m" : "M"}eublé`,
          },
          {
            label: "Nombre de niveaux",
            value: this.product.levels,
          },
          {
            label: "Places parking",
            value: this.product.minParkingPlaces,
          },
          {
            label: "GES",
            value: this.product.GES,
          },
          {
            label: "Classe énergie",
            value: this.product.energieClass,
          },
        ]
      },
      // page path
      path(){
        return [
          ...basePath,
          {
            label: this.productId ? this.product.title.split(" ").slice(0, 2).join("-").toUpperCase()+"..." : "Nouveau",
            url: this.productId ? baseUrl+"/"+this.productId : baseUrl+"/add"
          }
        ]
      }
    }
  }
</script>

<template>
  <MarketPlace v-if="product || urlParam === 'add'" :path="path">
    <div class="container">
      <h1 class="container-title"> {{ product ? 'Détails de produit' : 'Ajouter Un nouveau produit'}} </h1>
      <div class="container-body product">
        <div v-if="product" class="box1 product-infos">
          <div class="product--images">
            <img class="product--main-image" :src="product.images[0]" alt="" />
            <div class="product--sec-images">
              <img  :src="product.images[1]" alt="">
              <img  :src="product.images[2]" alt="">
            </div>
          </div>
          <div class="product--details">
            <h1 class="product-title"> {{ product.title }} </h1>

            <div class="product--subtitle">
              <span class="product-rooms" v-if="product.rooms"> {{ product.rooms }} Pièces </span>
              <div class="dot2" v-if="product.area"></div>
              <span class="product-area" v-if="product.area"> {{ product.area }} </span>
              <div class="dot2" v-if="product.address"></div>
              <span class="product-address" v-if="product.address"> {{ product.address }} </span>
            </div>

            <p class="product--price-infos">
              <span class="product-price">{{ product.price }}</span>
              <span class="product-charged"> {{ `${product.charged ? 'Charges Comprises' : 'Hors Charges'}` }} </span>
            </p>

            <div class="product-description">
              {{ product.description }}
            </div>

            <div class="product--sup-infos">
              <div
                v-for="{ label, value } in productSupInfos"
                :key="label+value"
                class="product-supinfo"
              >
                <div>
                  <div class="dot2"></div>
                  <p> {{ label }} </p>
                </div>
                <p> {{ value }} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="product-edition">
          <div 
            class="box1 editing--button-wrapper"
            :class="{'editing-on--button-wrapper': editingOn}">
            <div class="product--edit-button" @click="editingOn = !editingOn">
              <span>{{ `${editingOn ? "Fermer" : "Editer Le Produit"}` }}</span>
              <img :src="downArrow" alt="" />
            </div>
          </div>
          <div v-if="editingOn" class="box1 product--edit-form" :class="{'product--edit-form__off': !editingOn}">
            <div class="product--select-part">
              <Carousel class="product-select--carousel" :settings="carousel.settings" :breakpoints="carousel.breakpoints">
                <Slide 
                  @click="openedSection = key" 
                  v-for="{label, key} in sections" :key="key" 
                  class="carousel__item"
                  :class="{'carousel__item__on': openedSection === key}"
                  >
                  <div>{{ label }}</div>
                </Slide>
                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
            </div>

            <div class="product--edit-form--part">
              <DescriptionStep class="form-part" v-if="openedSection === 'description'" />
              <MandatStep class="form-part" v-if="openedSection === 'mandat'" />
              <OwnerStep class="form-part" v-if="openedSection === 'owner'" />
              <DetailsStep class="form-part" v-if="openedSection === 'details'" />
              <FinancialInfosStep class="form-part" v-if="openedSection === 'financial-infos'" />
              <RoomsStep class="form-part" v-if="openedSection === 'rooms'" />
              <CoPropertyStep class="form-part" v-if="openedSection === 'coproperty'" />
              <PhotosStep class="form-part" v-if="openedSection === 'photos'" :images="product.images" />
              <DiagnosticStep class="form-part" v-if="openedSection === 'diagnostic'" />
            </div>
          </div>
        </div>
        <div v-if="!product">
          <div class="row-centered">
            ou <ButtonOne text="Importer des biens" @click="importing=true" />
          </div>
          <div v-if="importing" class="popup">
            <div class="popup-back-closer" @click="importing = false"></div>
            <div class="box1 popup-container">
              <h3>Aucun bien à importer</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MarketPlace>
  <ErrorPage404 specific-message="Produit Indisponible ou inexistant" v-else />
</template>

<style src="./ProductView.css"></style>
<style src="./ProductEdition.css"></style>