import { path as basePath } from "../datas";

// path

export const path = [
  ...basePath,
  {
    label: "appel-loyer",
    url: basePath[basePath.length - 1].url + "/appel",
  },
];


// page title value
export const pageTitle = "Appel de loyer";




export const generalInfos = {
   date_rent : "",
   tenant_id: 0,
   lease_id: 0,
  };


  // form steps
export const formSteps = [
    {
      name: "general",
      label: "Informations",
    },
   
    
  
  ];