<script lang="js">
  import ExtranetView from '../ExtranetView.vue';
  import EditModal from '@/containers/Extranet/EditModal/EditModal.vue';
  import { presentation, path, pageTitle } from './datas';

  import defaultProfile from '@/assets/vectors/default-profile.svg'
  import pencil1 from '@/assets/icons/pencil1.svg'
  import card from '@/assets/icons/card.svg'

  export default {
    components: {
      ExtranetView,
      EditModal
    },
    data(){
      return{
        defaultProfile,
        pencil1,
        card,
        contacts: presentation.contacts,
        path,
        pageTitle
        // openedEditor: {
        //   type: "contacts",
        //   title: "Coordonées",
        //   description: "Modifiez vos coordonées."
        // }
      }
    }
  }
</script>

<template>
  <ExtranetView :path="path">
    <EditModal
      v-if="openedEditor"
      :type="openedEditor.type"
      :title="openedEditor.title"
      :desc="openedEditor.description"
    />
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="box1 profile container-body">
        <div class="row-centered profile--header">
          <img :src="card" alt="">
          <h2>Votre présentation <span>(Elle sera visible par les utilisateurs dans l'interface de l'extranet)</span></h2>
        </div>
        <div class="profile-images">
          <h2>Photo de profile</h2>
          <div class="profile-images--container">
            <div class="profile-images--cover">
  
            </div>
            <div class="profile-images--profile">
              <img class="pp" :src="defaultProfile" alt="">
              <div>
                Steve-immo_consulting
                <img :src="pencil1" alt="">
              </div>
            </div>
          </div>
        </div>
        <section class="profile-description">
          <div class="row-centered profile-section--header">
            <h2>Description</h2>
            <span>modifier</span>
          </div>
          <p class="profile--description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget condimentum quam tincidunt rhoncus ac ornare rhoncus. Dolor, pharetra enim quis diam. Sit morbi vel vitae nisi, posuere pellentesque. Orci feugiat dapibus massa scelerisque. Urna sit fames neque non posuere mauris mollis rutrum. Nullam platea velit quis in vel tortor elit sed. Odio sagittis, dui, aliquam sed sit dui libero id arcu. Volutpat auctor nunc orci aliquet. Mattis euismod malesuada volutpat et sed. Nunc nulla at eleifend et dolor.
          </p>
        </section>
        <section class="profile-contacts">
          <div class="row-centered profile-section--header">
            <h2>Coordonnées</h2>
            <span>modifier</span>
          </div>
          <div class="profile-contacts--content">
            <div 
              class="row-centered profile-contacts--item"
              v-for="{icon, value, label} in contacts"
              :key="'contact-'+label"
            >
              <img :src="icon" alt="">
              {{ value }}
            </div>
          </div>
        </section>
      </div>
    </div>
  </ExtranetView>
</template>

<style src="./FirstPage.css"></style>