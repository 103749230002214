import { path as basePath, globalWorkActions } from "../WorksHome/datas"

// base url for the pages above this page in the global infrastructure
const baseUrl = "/travaux"

// path to page
export const path = [
  ...basePath,
  {
    label: "devis",
    url: baseUrl+"/devis"
  }
]

// page title value
export const pageTitle = "Travaux et chantiers"

export const contentDatas = {
  title: "Devis",
  button: {
    text: "Créer une demande de devis",
    url: baseUrl+"/devis/ajouter"
  },
  table: {
    title: "Liste des demandes de devis",
    cols: [
      'Désignation', 'Date de début', 'Date de fin'
    ],
    actions: [
      { 
        label: 'Afficher', 
        type: 'view' 
      }, 
      { 
        label: 'Supprimer', 
        type: 'delete' 
      }
    ],
    rows: [
      ['Veit nunc.', 'Ornare.', 'Mauris Cum eget diam.']
    ]
  }
}

// works actions infos
export const worksActions = [
  globalWorkActions.works,
  globalWorkActions.serviceOrders,
  globalWorkActions.interventions
]