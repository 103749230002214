<script lang="js">
import { mapActions, mapWritableState } from 'pinia';

import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import TextField1 from '@/components/TextField1/TextField1.vue';
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

import { formSteps } from './datas';
import { path as basePath } from "../datas"
import { pieces, loyers } from './datas'
import usePropertyStore from '../store'
import useOwnerStore from '../../OwnersList/store';
const baseUrl = basePath[basePath.length - 1].url

export default {
  components: {
    RentalManagement,
    EditionForm,
    TextField1,
    ButtonOne,
    AnimatedLoader,
    ErrorMessage

  },
  data() {
    return {
      steps: formSteps,
      currentStep: 0,
      pieces : pieces,
      deleteClicked: false,
      changed:false,
      loyers

    }
  },
  computed: {
    // gives access to store property inside the component and allows setting it
    ...mapWritableState(usePropertyStore, ['property', 'equips', 'updatedGoodDatas', 'updateGoodProcess', 'deletionProcess', 'loading', 'status', 'error', 'getGoodProcess', 'types']),
    ...mapWritableState(useOwnerStore, ['owners','owner']),
    urlParam() {
      return this.$route.params.propertyId
    },
    propertyId() {
      return parseInt(this.urlParam);
    },
    path() {
      return [
        ...basePath,
        {
          label: (this.property.designation)+ "...",
          url: baseUrl + "/" + this.propertyId
        }
      ]
    }
  },
  methods: {
    findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    },
    reload() {
      location.reload()
    },
    ...mapActions(useOwnerStore, ['getOwners', 'getOwner']),
    ...mapActions(usePropertyStore, ['getProperty', 'updateProperty', 'deleteProperty', 'getPropertyTypes', 'getEquipmentByIdProperty']),
    handleUpdateValue(name, newValue) {
      this.changed = true;
    
      this.property[name] = newValue;
      // if(name === "daily_amount" || name === "weekly_amount" || name === "monthly_amount"){
      //   this.property[name] = parseFloat(newValue);
      // }else{
      //   this.property[name] = newValue;
      // }
      !this.updatedGoodDatas.includes(name) && this.updatedGoodDatas.push(name);
    },
    handleDeleteProperty() {
      if (this.deleteClicked) {
        this.deleteClicked = false;
        this.deleteProperty(this.propertyId);
      } else {
        this.deleteClicked = true;
      }
    },
    handleUpdateSelect(name, event){
      this.changed = true;
      
      this.property[name] = event.target.value;
      console.log(event.target.value)
      !this.updatedGoodDatas.includes(name) && this.updatedGoodDatas.push(name);
    }
  },
  async mounted() {
    this.path[this.path.length - 1].url += this.urlParam
    this.getProperty(this.propertyId)
    this.getOwners(0);
    this.getOwner(this.property.id_owner);
    this.getPropertyTypes(0);
    this.getEquipmentByIdProperty(this.propertyId, 0);
  },
  watch: {
    offset(value) {
      this.getOwners(value);
      this.getPropertyTypes(value);
      this.getEquipmentByIdProperty(this.propertyId, value);
    }

   
  }
 
}
</script>

<template>
  <RentalManagement :path="path">
    <div v-if="getGoodProcess.status === 'pending'">
      <AnimatedLoader dark />
    </div>
    <div v-if="getGoodProcess.status === 'success'" class="container">
      <div class="flex items-center justify-between">
        <h1 class="container-title">Désignation du bien: {{ property.designation }}</h1>
      <ButtonOne @click="handleDeleteProperty()" :text="deleteClicked ? 'Cliquez à nouveau pour supprimer' : 'Supprimer'"
        :dangerous="deleteClicked" :loading="deletionProcess.loading" :status="deletionProcess.status" isAsync />
      </div>
     
      <div class="profile container-body">
        <EditionForm :changed="changed"  :steps="steps" @step="(step) => currentStep = step"  :loading="loading" :status="status">
         
          <template #form>
            <form @submit.prevent="() => updateProperty(propertyId)" class="flex flex-col w-full justify-start">
          
              <section class="form-part" v-if="currentStep == findIndex('general')">
                <div class="row w-full">
                  <div class="flex flex-col min-w-[50%]">
                    <div class="flex flex-row mb-2"><label>Propriétiaire <span>: </span></label>
                      <p class="required-tag text-red ms-1">*</p>
                    </div>
                    <select v-model="property.id_owner" @change="handleUpdateSelect('id_owner', $event)" class="form-select rounded-md">
                      <option disabled value="">Selectionnez</option>
                      <option v-for="(item, i) in owners.list" :key="i" :value="item[0]">
                        {{ item[1] }}  {{ item[2] }}</option>
                    </select>
                  </div>

                  <router-link class="path-link mt-8 ms-10" :to="{ path: '/gestion-locative/proprietaires/creer' }">
                    <ButtonOne text="Nouveau" />
                  </router-link>
                </div>
                <div class="row  w-full">
                  <TextField1 fullWidth label="Type de biens" placeholder="Type de biens" :value="property.type_de_bien"
                    @update:value="newValue => handleUpdateValue('type_de_bien', newValue)" />
                    <div class="flex ms-3 flex-col min-w-[35%]">
                  <div class="flex flex-row mb-2"><label>Type de location<span>: </span></label>
                  <p class="required-tag text-red ms-1">*</p></div>
                  <select v-model="property.type_location" @change="handleUpdateSelect('type_location', $event)" class="form-select rounded-md">
                    <option disabled value="">Selectionnez</option>
                    <option v-for="(item, i) in types.list" :key="i" :value="item[0]">
                    {{ item[1]}}</option>
                  </select>
                </div>
                </div>
                <div class="row w-full ">
                  <div class="flex flex-col min-w-[35%]">
                    <div class="flex flex-row "><label>Type de loyer<span>: </span></label>
                    <p class="required-tag text-red ms-1">*</p></div>
                    <select v-model="property.rent_type" @change="handleUpdateSelect('rent_type', $event)" class="form-select rounded-md">
                      <option v-for="(item, i) in loyers" :key="i" >
                      {{ item.value}}</option>
                    </select>
                  </div>
                  <TextField1 fullWidth type="number" label="Montant journalier" placeholder="Montant journalier" :value="property.daily_amount"  @update:value="newValue => handleUpdateValue('daily_amount', newValue)"  :optional= "true"/>
                
                </div>
                <div class="row w-full ">
                  <TextField1  fullWidth type="number" label="Montant hebdomadaire" placeholder="Montant hebdomadaire" :value="property.weekly_amount"  @update:value="newValue => handleUpdateValue('weekly_amount', newValue)" :optional="true"/>
                  <TextField1  fullWidth type="number" label="Montant mensuel" placeholder="Montant mensuel" :value="property.monthly_amount"  @update:value="newValue => handleUpdateValue('monthly_amount', newValue)" :optional="true"/>
                </div>
                <div class="row w-full ">
                  <TextField1 fullWidth label="Pays" placeholder="Pays" :value="property.pays"
                    @update:value="newValue => handleUpdateValue('pays', newValue)" />
                  <TextField1 fullWidth label="Ville" placeholder="Ville" :value="property.ville"
                    @update:value="newValue => handleUpdateValue('ville', newValue)" />
                </div>
                <div class="row w-full ">
                  <TextField1 fullWidth label="Adresse 1" placeholder="Adresse 1" :value="property.adresse"
                    @update:value="newValue => handleUpdateValue('adresse', newValue)" />
                  <TextField1 fullWidth label="Adresse 2" placeholder="Adresse 2"
                    :value="property.complement_add" @update:value="newValue => handleUpdateValue('complement_add', newValue)"
                    :optional="true" />
                </div>
                <div class="row w-full ">
                  <TextField1 small label="Code postale" placeholder="Code postale" :value="property.code_postale"
                    @update:value="newValue => handleUpdateValue('code_postale', newValue)" />
                </div>
                <div class="row w-full">
                  <div class="flex flex-col min-w-[25%]">
                    <div class="flex flex-row mb-2"><label>Nbre d'escaliers <span>: </span></label>
                      <p class="required-tag text-red ms-1"></p>
                    </div>
                    <select v-model="property.nb_escalier" @change="handleUpdateSelect('nb_escalier', $event)" class="form-select  rounded-md" >

                      <option v-for="(escalier, i) in pieces" :key="i" >
                        {{ escalier.value }}</option>
                    </select>
                  </div>
                  <div class="flex flex-col min-w-[25%] ms-4">
                    <div class="flex flex-row mb-2"><label>Nbre de portes <span>: </span></label>
                      <p class="required-tag text-red ms-1">*</p>
                    </div>
                    <select v-model="property.nb_porte" @change="handleUpdateSelect('nb_porte', $event)" class="form-select  rounded-md">

                      <option v-for="(porte, i) in pieces" :key="i">
                        {{ porte.value }}</option>
                    </select>
                  </div>
                  <div class="flex flex-col min-w-[25%] ms-4">
                    <div class="flex flex-row mb-2"><label>Nbre d'étages <span>: </span></label>
                      <p class="required-tag text-red ms-1"></p>
                    </div>
                    <select v-model="property.nb_etage" @change="handleUpdateSelect('nb_etage', $event)" class="form-select  rounded-md">

                      <option v-for="(etage, i) in pieces" :key="i">
                        {{ etage.value }}</option>
                    </select>
                  </div>
                </div>

              </section>

              <section class="form-part" v-if="currentStep == findIndex('description')">
                <div class="row  w-full">
                  <TextField1 fullWidth label="Désignation" placeholder="Désignation" :value="property.designation"
                    @update:value="newValue => handleUpdateValue('designation', newValue)" />
                  <TextField1 fullWidth type="number" label="Superficie(en m2)" placeholder="Superficie"
                    :value="property.superficie" @update:value="newValue => handleUpdateValue('superficie', newValue)" />
                </div>
                <div class="row  w-full">
                  <TextField1 fullWidth label="Description" placeholder="Description" textarea :value="property.description"
                    @update:value="newValue => handleUpdateValue('description', newValue)" :optional="true" />

                </div>
                <div class="row  w-full">
                  <TextField1 fullWidth type="number" label="Superficie loi Boutin (en m2)" placeholder="Superficie loi Boutin"
                    :value="property.superficie_boutin" @update:value="newValue => handleUpdateValue('superficie_boutin', newValue)"
                   />
                  <TextField1 fullWidth type="number" label="Surface loi Carrez(en m2)" placeholder="Surface loi Carrez"
                    :value="property.surface_carrez" @update:value="newValue => handleUpdateValue('surface_carrez', newValue)"
                   />
                </div>
                <div class="row w-full">
                  <TextField1 fullWidth type="number" label="Surface terrasse(en m2)" placeholder="Surface terrasse"
                    :value="property.surface_terrasse" @update:value="newValue => handleUpdateValue('surface_terasse', newValue)"
                   />
                  <TextField1 fullWidth type="number" label="Surface annexes(en m2)" placeholder="Surface annexes"
                    :value="property.surface_annexe" @update:value="newValue => handleUpdateValue('surface_annexe', newValue)"
                   />
                </div>
                <div class="row w-full">
                  <div class="flex flex-col min-w-[25%]">
                    <div class="flex flex-row mb-2"><label>Nombre de salon<span>: </span></label>
                      <p class="required-tag text-red ms-1"></p>
                    </div>
                    <select v-model="property.nb_sallon" @change="handleUpdateSelect('nb_sallon', $event)" class="form-select  rounded-md">

                      <option v-for="(salon, i) in pieces" :key="i">
                        {{ salon.value }}</option>
                    </select>
                  </div>
                  <div class="flex flex-col min-w-[25%] ms-4">
                    <div class="flex flex-row mb-2"><label>Nombre de chambres<span>: </span></label>
                      <p class="required-tag text-red ms-1"></p>
                    </div>
                    <select v-model="property.nb_chambre" @change="handleUpdateSelect('nb_chambre', $event)" class="form-select  rounded-md">

                      <option v-for="(chambre, i) in pieces" :key="i" >
                        {{ chambre.value }}</option>
                    </select>
                  </div>
                  <div class="flex flex-col min-w-[25%] ms-4">
                    <div class="flex flex-row mb-2"><label>Nbre de cuisine <span>: </span></label>
                      <p class="required-tag text-red ms-1"></p>
                    </div>
                    <select v-model="property.nb_cuisine" @change="handleUpdateSelect('nb_cuisine', $event)" class="form-select  rounded-md">

                      <option v-for="(cuisine, i) in pieces" :key="i">
                        {{ cuisine.value }}</option>
                    </select>
                  </div>
                </div>
                <div class="row w-full">
                  <div class="flex flex-col min-w-[25%]">
                    <div class="flex flex-row mb-2"><label>Nombre de douche<span>: </span></label>
                      <p class="required-tag text-red ms-1"></p>
                    </div>
                    <select v-model="property.nb_douche" @change="handleUpdateSelect('nb_douche', $event)" class="form-select rounded-md">

                      <option v-for="(douche, i) in pieces" :key="i">
                        {{ douche.value }}</option>
                    </select>
                  </div>
                  <div class="flex flex-col min-w-[25%] ms-4">
                    <div class="flex flex-row mb-2"><label>Nombre de salle à manger<span>: </span></label>
                      <p class="required-tag text-red ms-1"></p>
                    </div>
                    <select v-model="property.nb_salle_a_manger" @change="handleUpdateSelect('nb_salle_a_manger', $event)" class="form-select  rounded-md">

                      <option v-for="(salle, i) in pieces" :key="i">
                        {{ salle.value }}</option>
                    </select>
                  </div>

                </div>

                <div class="row w-full">
                  <TextField1 fullWidth label="Autre pièce" placeholder="Autre pièce" :value="property.autre_piece"
                    @update:value="newValue => handleUpdateValue('autre_piece', newValue)" :optional="true" />

                  <TextField1 fullWidth label="Réfs Cadastrales" class="ms-4" placeholder="Réfs Cadastrales"
                    :value="property.ref_cadastrale" @update:value="newValue => handleUpdateValue('ref_cadastrale', newValue)" />
                </div>
              </section>

              <section class="form-part" v-if="currentStep == findIndex('diagnostic')">
                <div class="row w-full">

                  <TextField1 fullWidth label="Année de construction" placeholder="Année de construction"
                    :value="property.annee_construction"
                    @update:value="newValue => handleUpdateValue('annee_construction', newValue)" />

                  <TextField1 fullWidth  label="Date DPE" type="date" :value="property.date_dpe"
                    @update:value="newValue => handleUpdateValue('date_dpe', newValue)" :optional="true" />

                </div>
                <div class="row w-full">
                  <TextField1 fullWidth type="number" label="Energie(KWhep/m2/an)" placeholder="Energie(KWhep/m2/an)"
                    :value="property.energie" @update:value="newValue => handleUpdateValue('energie', newValue)" :optional="true" />
                  <TextField1 fullWidth type="number" label="Climat(KgepCo2/m2/an)" placeholder="Climat(KgepCo2/m2/an)"
                    :value="property.climat" @update:value="newValue => handleUpdateValue('climat', newValue)" :optional="true" />

                </div>

                <div class="row w-full">
                  <TextField1 fullWidth label="Diagnostic DPE valable jusqu'au" type="date"
                    :value="property.diagnostic_dpe" @update:value="newValue => handleUpdateValue('diagnostic_dpe', newValue)"
                    :optional="true" />
                  <TextField1 fullWidth label="Diagnostic Plomb valable jusqu'au" type="date"
                    :value="property.diagnostic_plomb" @update:value="newValue => handleUpdateValue('diagnostic_plomb', newValue)"
                    :optional="true" />

                </div>

                <div class="row w-full">
                  <TextField1 fullWidth label="Diagnostic Electricité valable jusqu'au" type="date"
                    :value="property.diagnostic_electricite"
                    @update:value="newValue => handleUpdateValue('diagnostic_electricite', newValue)" :optional="true" />

                  <TextField1 fullWidth label="Diagnostic Termites valable jusqu'au" type="date"
                    :value="property.diagnostic_termites"
                    @update:value="newValue => handleUpdateValue('diagnostic_termites', newValue)" :optional="true" />

                </div>

                <div class="row w-full">
                  <TextField1 fullWidth label="Diagnostic Gaz valable jusqu'au" type="date"
                    :value="property.diagnostic_gaz" @update:value="newValue => handleUpdateValue('diagnostic_gaz', newValue)"
                    :optional="true" />
                  <TextField1 fullWidth label="Diagnostic ERP valable jusqu'au" type="date"
                    :value="property.diagnostic_erp" @update:value="newValue => handleUpdateValue('diagnostic_erp', newValue)"
                    :optional="true" />
                </div>
              </section>

              <section class="form-part" v-if="currentStep == findIndex('fiscales')">
            
                  <div class="row w-full">
                    <TextField1 fullWidth label="Zone Pinel" placeholder="Zone Pinel" :value="property.zone_pinel"
                      @update:value="newValue => handleUpdateValue('zone_pinel', newValue)" :optional="true" />
                    <TextField1 fullWidth label="Numéro invariant" placeholder="Numéro invariant"
                      :value="property.num_invariant" @update:value="newValue => handleUpdateValue('num_invariant', newValue)"
                      :optional="true" />
                  </div>


                  <div class="row w-full">
                    <TextField1 fullWidth type="number" label="Surface habitable Pinel (em m2)" placeholder="Surface habitable Pinel"
                      :value="property.sur_hab_pinel" @update:value="newValue => handleUpdateValue('sur_hab_pinel', newValue)"
                      :optional="true" />
                    <TextField1 fullWidth type="number" label="Surface annexe Pinel (en m2)" placeholder="Surface annexe Pinel"
                      :value="property.sur_annexe_pinel" @update:value="newValue => handleUpdateValue('sur_annexe_pinel', newValue)"
                      :optional="true" />
                  </div>

                  <div class="row w-full">
                    <TextField1 fullWidth label="Centre des impôts" placeholder="Centre des impôts"
                      :value="property.centre_impots" @update:value="newValue => handleUpdateValue('centre_impots', newValue)" />
                    <TextField1 fullWidth label="Trésorerie" placeholder="Trésorerie"
                      :value="property.tresorerie" @update:value="newValue => handleUpdateValue('tresorerie', newValue)"
                      :optional="true" />

                  </div>

                <div class="row w-full">
                    <TextField1 fullWidth label="Dispositif Fiscale" placeholder="Dispositif Fiscale"
                    :value="property.dispositif_fiscale"
                    @update:value="newValue => handleUpdateValue('dispositiy_fiscale', newValue)" />
                  <TextField1 fullWidth label="Régime Fiscale" placeholder="Régime Fiscale" :value="property.regime_fiscale"
                    @update:value="newValue => handleUpdateValue('regime_fiscale', newValue)" />

                  </div>
                <div class="flex flex-col items-start  w-full">
                 
                  <div class="my-2">
                    <div class="flexed my-1">
                      <input type="checkbox" name="teom" v-model="property.taxe_ordure" id="teom" class="mt-1">
                      <label for="teom">Soumis à la taxe des ordures ménagères(TEOM)</label>
                    </div>
                    <div class="flexed my-1">
                      <input type="checkbox" name="crl" v-model="property.contri_revenue_locatif" id="crl" class="mt-1">
                      <label for="crl">Soumis à la contribution sur les revenues locatifs</label>
                    </div>
                    <div class="flexed my-1">
                      <input type="checkbox" name="tb" v-model="property.taxe_bureaux" id="tb" class="mt-1">
                      <label for="tb">Soumis à la taxe sur les bureaux</label>
                    </div>
                    <div class="flexed my-1">
                      <input type="checkbox" name="tlv" v-model="property.taxe_logement_vacant" id="tlv" class="mt-1">
                      <label for="tlv">Bien sur une zone soumise à la taxe sur les logements vacants</label>
                    </div>
                    <div class="flexed my-1">
                      <input type="checkbox" name="zone_etendue" v-model="property.zone_tondue" id="zone_etendue"
                        class="mt-1">
                      <label for="zone_etendue">Bien situé en zone étendue</label>
                    </div>
                  </div>
                </div>
              </section>

              <section class="form-part" v-if="currentStep == findIndex('copropriété')">
                <div class="row w-full">
                  <TextField1 fullWidth label="Réf lot copropriété" placeholder="Réf lot copropriéte"
                    :value="property.ref_copro" @update:value="newValue => handleUpdateValue('ref_copro', newValue)" />
                  <TextField1 fullWidth label="Concierge" placeholder="Concierge" :value="property.concierge"
                    @update:value="newValue => handleUpdateValue('concierge', newValue)" />
                </div>
                <div class="row w-full">
                  <TextField1 fullWidth label="Copropriété" placeholder="Copropriéte" :value="property.copropriete"
                    @update:value="newValue => handleUpdateValue('copropriete', newValue)" :optional="true" />

                  <TextField1 fullWidth label="Lot" placeholder="Lot" :value="property.lot"
                    @update:value="newValue => handleUpdateValue('lot', newValue)" />

                </div>

              </section>
              <section class="form-part" v-if="currentStep == findIndex('equipements')">
                <div class="flexed">
                 
                    <ButtonOne v-for="(button, i) in equips.list" :text="button[2]" :key="i"  fullRounded />
                </div>

              </section>
           
            <div class="flex gap-[20px]">
              <div v-if="deletionProcess.status === 'failure'">
                <ErrorMessage :message="deletionProcess.error" />
              </div>
  
              <div v-if="status === 'failure'">
                <ErrorMessage :message="error" />
              </div>
             
            </div>
            <div class="flex justify-center items-center mt-8">
                <ButtonOne isAsync :loading="updateGoodProcess.loading" :status="updateGoodProcess.status" text="Modifier" />
              </div>
          </form>
          </template>
        </EditionForm>
      </div>
    </div>
    <div v-if="getGoodProcess.status === 'failure'" class="container">
      <div class="owner-header">
        <h1 class='container-title'>Erreur de chargement</h1>
        <ButtonOne @click="reload()" text="Cliquez pour recharger" />
      </div>
    </div>
  </RentalManagement>
</template>