<script>
  import AccountLayer from '../AccountLayer.vue'
  import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'
  import { items, path, pageTitle } from './datas.js'

  export default {
    components: {
      AccountLayer: AccountLayer,
      Header2
    },
    data(){
      return {
        items,
        path,
        pageTitle
      }
    }
  }
</script>

<template>
  <AccountLayer :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <Header2 :items="items" />
      <div class="container-body">
      </div>
    </div>
  </AccountLayer>
</template>

<style src="./FirstPage.css"></style>