<script>
//import RentalManagement from '../RentalManagement.vue'
import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'
import { itemsCoOwnerList, path, pageTitle, tableDatas } from './datas.js'
import TableOne from '@/containers/TableOne/TableOne.vue'
import useCoOwnerStore from './store'
import { initialList } from '@/utils/helpers'
import { mapActions, mapWritableState } from 'pinia'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
import CoproSyndics from '../../Syndics/CoproSyndics.vue';
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
export default {
  components: {
   // RentalManagement,
   Header2,
    TableOne,
    AnimatedLoader,
    ErrorMessage,
    ButtonOne,
    CoproSyndics
},
  data(){
    return {
      itemsCoOwnerList,
      path,
      pageTitle,
      datas: tableDatas,
      offset: 0,
      initialList
    }
  },
  computed: {
    ...mapWritableState(useCoOwnerStore, ['coOwners', 'getCoOwnersProcess'])
  },
  mounted() {
 
    this.getCoOwnersList(0);
  },
  methods: {
    ...mapActions(useCoOwnerStore, ['formatRows', 'getCoOwnersList'])
  },
  watch: {
    offset(value) {
      this.getCoOwnersList(value);
    }
  }
}
</script>

<template>
  <CoproSyndics :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
     <Header2 :items="itemsCoOwnerList"/>
    
      <div class="container-body">
        <AnimatedLoader dark v-if="getCoOwnersProcess.loading" />
        <div v-else-if="getCoOwnersProcess.status === 'failure'" class="flex flex-col items-center gap-4">
          <ErrorMessage :message="getCoOwnersProcess.error" />
          <ButtonOne isAsync @click="getCoOwnersList(offset)" text="Actualiser" :loading="getCoOwnersProcess.loading" :status="getCoOwnersProcess.status" />
        </div>
        <TableOne
          v-else
          :title="datas.coproprietaire.title"
          :cols="datas.coproprietaire.cols"
          :rows="coOwners.list"
          :total="coOwners.total"
          :actions="initialList.actions"
          baseUrl="/gestion-locative/coproprietaires"
          noSearch
          @emitedOffset="(value) => offset = value"
        />
      </div>
    </div>
  </CoproSyndics>
</template>

<style src="./CoOwnersList.css"></style>