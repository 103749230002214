<script lang="js">
import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import { mapActions, mapWritableState } from 'pinia';
import useCoTenantStore from '../store'
import GeneralInfos from './Steps/GeneralInfos.vue'
import AccountingInfos from './Steps/AccountingInfos.vue'
import IBAN from './Steps/IBAN.vue';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import {
  path,
  pageTitle, 
  formSteps
} from './datas';


export default {
  components: {
    RentalManagement,
    EditionForm,
    GeneralInfos,
    IBAN,
    AccountingInfos,
    ErrorMessage
  },
  data() {
    return {
      path,
      pageTitle,
      steps: formSteps,
      currentStep: 0,
      name: "durin"
    }
  },

  computed: {
    ...mapWritableState(useCoTenantStore, ['co_tenant', 'loading', 'status', 'error'])
  },
  methods: {
    ...mapActions(useCoTenantStore, ['createCoTenant']),
    findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    }
  }
}
</script>

<template>
  <RentalManagement :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="profile container-body">
        <EditionForm :save="createCoTenant" :steps="steps" @step="(step) => currentStep = step" :loading="loading" :status="status">
          <template #form>
            <section class="form-part" v-if="currentStep == findIndex('general')" >
              <GeneralInfos />    
            </section>
            <section class="form-part" v-if="currentStep == findIndex('iban')" >
              <IBAN />    
            </section>

            <section class="form-part" v-if="currentStep == findIndex('accounting')" >
              <AccountingInfos />
            </section>

            <div v-if="status === 'failure'" class="flex gap-[20px]">
              <div>
                <ErrorMessage :message="error" />
              </div>
            </div>
            
            
          </template>
        </EditionForm>
      </div>
    </div>
  </RentalManagement>
</template>