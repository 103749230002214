import { defineStore } from "pinia";
import api from "../api";


import {
  generalInfos,
  ibanInfos,
  descriptionInfos
} from "./New/datas";
import { noEmptyFields, initialList, initProcess } from "@/utils/helpers";

const useTenantStore = defineStore('tenant', {
  state: () => ({
    loading: false,
    status: "",
    error: "",
    tenant: {
      ...generalInfos,
      ...descriptionInfos,
      ...ibanInfos
    },
  
    getTenantsProcess: initProcess,
    getTenantProcess: initProcess,
    getTenantIbanProcess: initProcess,
    updateTenantProcess: initProcess,
    updateTenantIbanProcess: initProcess,
    deletionProcess: initProcess,
    updatedTenantDatas: [],
    updatedTenantIbanDatas:[],
    tenants: initialList,
    tenantOwners: initialList,
    properties: initialList,
    tenantTypeList:initialList,
    ibans: initialList,
    propertyList:[]

  }),
  getters: {
    // tenant: (state) => ({
    //   ...state.generalInfos,
    //   ...state.ibanInfos,
    //   ...state.accountingInfos
    // })
  },
  actions: {


    async createRentRequest() {
      // handle tenant creation
      if (!this.loading) {
        console.log("launch")
        if (
          // checking if no field is empty
          noEmptyFields(this.tenant, ["fax", "phone", "child", "pets"])
        ) {
          this.loading = true;
          this.status = "pending"
         
          try {
            console.log({newTenant: this.tenant})
            const resp = await api.addRentRequest(this.tenant)
            console.log({resp})
            if (resp.status === 200) {
              this.loading = false;
              this.status = "success"
              location.replace("/gestion-locative/locataires");
            } else {
              this.loading = false;
              this.status = "failure"
              this.error = resp?.response?.data?.detail
            }
          } catch (error) {
              this.loading = false;
              this.status = "failure"
              this.error = error?.data?.detail
              console.log({error})
          }
        } else {
          this.loading = false;
          this.status = "failure"
          this.error = "Vous semblez avoir omis certains des champs obligatoires !"
        }
      } else {
        //
      }
    },

    async createTenant() {
      // handle tenant creation
      if (!this.loading) {
        console.log("launch")
        if (
          // checking if no field is empty
          noEmptyFields(this.tenant, ["fax", "phone"])
        ) {
          this.loading = true;
          this.status = "pending"
          console.log(this.tenant)
          // execute request
          try {
            console.log({newTenant: this.tenant})
            const resp = await api.addTenant(this.tenant)
            console.log({resp})
            if (resp.status === 200) {
              this.loading = false;
              this.status = "success"
              location.replace("/gestion-locative/locataires");
            } else {
              this.loading = false;
              this.status = "failure"
              this.error = resp?.response?.data?.detail
            }
          } catch (error) {
              this.loading = false;
              this.status = "failure"
              this.error = error?.data?.detail
              console.log({error})
          }
        } else {
          this.loading = false;
          this.status = "failure"
          this.error = "Vous semblez avoir omis certains des champs obligatoires !"
        }
      } else {
        //
      }
    },

    async getTenant(tenantId) {
      // taking user info from local storage
      try {
        this.getTenantProcess = {loading: true, status: "pending"}
        const resp = await api.getTenant(tenantId)
        if (resp) {
          console.log({ resp })
          this.tenant = resp[0]
         
          // let rows = [];
          // let cols = ["iban", "bic", "creditor_name", "ics_number"];
          // resp.data.iban_info.map((item) => {
          //   let row = [];
          //   cols.map((col) => {
          //     row.push(item[col]);
          //   });
          //   rows.push(row);
          // });
          // this.ibans = {
          //   ...this.ibans,
          //   list: rows,
          //   total: resp.data.iban_info.length,
          // };
         
          this.getTenantProcess = {loading: false, status: 'success'}
        } else {
          // alert("Une erreur s'est produite !")
          this.getTenantProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        }
      } catch (getTenantError) {
        console.log({ getTenantError });
        this.getTenantProcess = {loading: false, status: "failure", error: getTenantError?.data?.detail}
      }
    },

    async getOneRentRequest(id) {
      // taking user info from local storage
      try {
        this.getTenantProcess = {loading: true, status: "pending"}
        const resp = await api.getRentRequest(id)
        
        if (resp) {
       
          this.tenant = {...resp.data}
          console.log({tenant : this.tenant})
          await this.getSelectProperty(this.tenant.tenant_id,0)
          this.getTenantProcess = {loading: false, status: 'success'}
        } else {

          this.getTenantProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        }
      } catch (getTenantError) {
        console.log({ getTenantError });
        this.getTenantProcess = {loading: false, status: "failure", error: getTenantError}
      }
    },

    async updateRequest(id) {
      this.updateTenantProcess = {loading: true, status: "pending", error:""}
      // merging user data into tenant infos in a single datas objects
      console.log('updating tenant: '+id+' ...')
      let updatedDatas = {};
      // console.log({updatedOwnerDatas: this.updatedOwnerDatas})
      this.updatedTenantDatas.map((name) => (
        updatedDatas = { ...updatedDatas, [name]: this.tenant[name]}
      ))
      updatedDatas = { ...this.tenant, id }

      console.log({updatedDatas})
      // sending request
      try {
        const resp = await api.updateRentRequest(updatedDatas)
        if (resp.status === 200) {
          console.log({ updatedTenant: resp })
          this.updateTenantProcess = {loading: false, status: "success", error:"Opération réussie"}
          //location.reload()
        } else {
          console.log({resp})
          // alert("Une erreur s'est produite !\nVeuillez Réessayer plus tard !")
          this.updateTenantProcess = {loading: false, status: "failure", error:resp?.response?.data?.detail || "Veuillez vérifier vos informations !"}
     
        }
      } catch (error) {
        console.log({ error })
        this.updateTenantProcess = {loading: false, status: "failure", error:error.data.detail}
      }
    },

    async updateTenant(id) {
      this.updateTenantProcess = {loading: true, status: "pending", error:""}
      // merging user data into tenant infos in a single datas objects
      console.log('updating tenant: '+id+' ...')
      let updatedDatas = {};
      // console.log({updatedOwnerDatas: this.updatedOwnerDatas})
      this.updatedTenantDatas.map((name) => (
        updatedDatas = { ...updatedDatas, [name]: this.tenant[name]}
      ))
      updatedDatas = { ...updatedDatas, id }

      // console.log({updatedDatas})
      // sending request
      try {
        const resp = await api.updateTenant(updatedDatas)
        if (resp.status === 200) {
          console.log({ updatedTenant: resp })
          this.updateTenantProcess = {loading: false, status: "success", error:"Opération réussie"}
          //location.reload()
        } else {
          console.log({resp})
          // alert("Une erreur s'est produite !\nVeuillez Réessayer plus tard !")
          this.updateTenantProcess = {loading: false, status: "failure", error:resp?.response?.data?.detail || "Veuillez vérifier vos informations !"}
     
        }
      } catch (error) {
        console.log({ error })
        this.updateTenantProcess = {loading: false, status: "failure", error:error.data.detail}
      }
    },
  
    async deleteTenant(id){
      // delete owner
      this.deletionProcess = {loading: true, status: "pending"}
      try {
        const deletedTenant = await api.deleteTenant({id, tenant_or_co_tenant: "tenant"})
        if (deletedTenant?.status === 200) {
          console.log({ deletedTenant })
          this.deletionProcess = {loading: false, status: "success"}
          location.replace("/gestion-locative/locataires");
        } else {
          this.deletionProcess = { loading: false, status: "failure", error: "Une erreur s'est produite !" }
          console.log({ deletedOwner2: deletedTenant})
        }
      } catch (deletionError) {
        this.deletionProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        console.log({ deletionError });
      }
    },

    async deleteRentRequest(id){
      // delete Rent Request
      this.deletionProcess = {loading: true, status: "pending"}
      try {
        const resp = await api.deleteRent({id})
        if (resp?.status === 200) {
          console.log({ resp })
          this.deletionProcess = {loading: false, status: "success"}
          location.replace("/gestion-locative/locataires");
        } else {
          this.deletionProcess = { loading: false, status: "failure", error: "Une erreur s'est produite !" }
          console.log({ delete: resp})
        }
      } catch (deletionError) {
        this.deletionProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        console.log({ deletionError });
      }
    },

    async previewRequest(){
      // delete Rent Request
      
    },

    async getTenants() {
      // sending request
      try {
        this.getTenantsProcess = {loading: true, status: "pending", error: ""}
        const resp = await api.getTenants()
        console.log({ resp: resp});
        if (resp.status === 200) {
          let rows = []
          let cols = ['id', 'lastname', 'first_name', 'email', 'cell_phone']
          resp.data.map((item) => {
            let row = []
            cols.map((col) => {
              row.push(item[col])
            })
            rows.push(row)
          })
          this.tenantOwners = { ...this.tenantOwners, list: rows, total: resp.data.total }
          console.log({ tenantOwners: this.tenantOwners })
          this.getTenantsProcess = {loading: false, status: "success"}
        } else {
          this.getTenantsProcess = {loading: false, status: "failure", error: "Une erreur s'est produite, Veuillez réessayer plus tard !"}
        }
      } catch (error) {
        console.log({ error })
        this.getTenantsProcess = {loading: false, status: "failure", error: error?.data?.detail}
      }
    },

     async getRentRequestList(offset) {
      // sending request
      try {
        this.getTenantsProcess = {loading: true, status: "pending", error: ""}
        const resp = await api.getListRentRequest(10, offset)
        console.log({ resp: resp.status});
        if (resp) {
          let rows = []
          let cols = ['id', 'budget', 'area', 'caution', 'avance_loyer']
          resp.data.map((item) => {
            let row = []
            cols.map((col) => {
              row.push(item[col])
            })
            rows.push(row)
          })
          this.tenants = { ...this.tenants, list: rows, total: resp.data.total }
          console.log({ tenants: this.tenants })
          this.getTenantsProcess = {loading: false, status: "success"}
        } else {
          this.getTenantsProcess = {loading: false, status: "failure", error: "Une erreur s'est produite, Veuillez réessayer plus tard !"}
        }
      } catch (error) {
        console.log({ error })
        this.getTenantsProcess = {loading: false, status: "failure", error: error?.data?.detail}
      }
    },

    async getTypeTenantList() {
      // sending request
      try {
      
        const resp = await api.getListTypeTenants()
      
        if (resp) {
          let rows = []
          let cols = ['id', 'type_locataire']
          resp.data.map((item) => {
            let row = []
            cols.map((col) => {
              row.push(item[col])
            })
            rows.push(row)
          })
          this.tenantTypeList = { ...this.tenantTypeList, list: rows, total: resp.data.total }
          console.log({ tenantTypes: this.tenantTypeList })
        
        }
      } catch (error) {
        console.log({ error })
       
      }
    },

    async getSelectProperty(tenantId, offset){
      // sending request
      try {
        this.getTenantsProcess = {loading: true, status: "pending", error: ""}
        console.log("Fetch properties...")
        const resp = await api.previewRent(tenantId,10, offset)
        console.log({ resp: resp});
        if (resp) {
          let rows = []
          let cols = [
            "id",
            "type_de_bien",
            "adresse",
            "ville",
            "pays",
            "rent_type",
          ];
          resp.data.map((item) => {
            let row = []
            cols.map((col) => {
              row.push(item[col])
            })
            rows.push(row)
          })
          this.properties = { ...this.properties, list: rows, total: rows.length }
          console.log({ properties: this.properties })
          this.getTenantsProcess = {loading: false, status: "success"}
        } else {
          this.getTenantsProcess = {loading: false, status: "failure", error: "Une erreur s'est produite, Veuillez réessayer plus tard !"}
        }
      } catch (error) {
        console.log({ error })
        this.getTenantsProcess = {loading: false, status: "failure", error: error?.data?.detail}
      }
    },


    async sendProperties(tenantId, data, toast){
      // sending request
      try {
       // toast.loading("Veuillez patienter")
        console.log("Send properties...")
        let sendDatas = {};
     

      sendDatas = { list_property: data, id_tenant: tenantId }
      

      console.log({sendDatas: sendDatas})
        const resp = await api.sendPropertiesByEmail(sendDatas)
        
        if (resp) {
        
          console.log({ respAns: resp })
          toast.success("Email envoyé");
        } else {
         
          toast.error("Email non envoyé, une erreur est survenue !");
          this.getTenantsProcess = {loading: false, status: "failure", error: "Une erreur s'est produite, Veuillez réessayer plus tard !"}
        }
      } catch (error) {
       
        toast.error("Email non envoyé, une erreur est survenue !");
       
      }
    },


    // get tenant by id
    // async getTenant(tenantId) {
    //   // // taking user info from local storage
    //   // const user = JSON.parse(localStorage.getItem("xxl-profil-immo-user"))
    //   // // extracting required datas for fetching
    //   // const user_email = user.email_user;
    //   // const user_type = user.user_type;
    //   // try {
    //   //   const resp = await client.get(`/tenant/tenant_info/${tenantId}/${user_email}/${user_type}`)
        
    //   //   if (resp.statusText === "OK") {
    //   //     this.tenant = { ...this.tenant, ...resp.data }
    //   //     console.log({ tenant: this.tenant })
    //   //   } else {
    //   //     alert("Une erreur s'est produite !")
    //   //   }
    //   // } catch (error) {
    //   //     console.log({ error });
    //   // }
    // },
    async updateTenant2() {
      // sending request
      // try {
      //   const resp = await client.post("/tenant/update_tenant", this.tenant)
      //   if (resp.statusText === "OK") {
      //     console.log(this.tenant)
      //     alert("Propriétaire mis à jour")
      //   } else {
      //     alert("Une erreur s'est produite")
      //   }
      // } catch (error) {
      //   console.log({ error })
      // }
    },
    handleChange(property, newValue){
      this.tenant = { ...this.tenant, [property]: newValue }
    }
  }
})

export default useTenantStore;