<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';
import {mapActions, mapWritableState } from 'pinia';
import useTenantStore from '../../store';
import { pieces, loyers } from '../datas'
import usePropertyStore from '@/views/RentalManagement/Goods/store';
export default {
  data(){
    return{
      pieces,
      loyers
    }
  },
 
  components: {
    TextField1
  },
  computed: {
    // gives access to store owner inside the component and allows setting it
    ...mapWritableState(useTenantStore, ['tenant', 'tenantTypeList']),
    ...mapWritableState(usePropertyStore, ['types']),
  },
  methods:{
    ...mapActions(useTenantStore, ['getTypeTenantList']),
    ...mapActions(usePropertyStore, ['getPropertyTypes']),

    handleFloatValue(name, newValue) {
    
      this.tenant[name] = parseFloat(newValue);
      
    },

    handleIntValue(name, newValue) {
    
    this.tenant[name] = parseInt(newValue);
    
  },
  },
  async mounted() {
    this.getPropertyTypes(0);
    this.getTypeTenantList()
  
  },
  watch: {
    offset(value) {
      this.getPropertyTypes(value);
      
    },
    
   
  }
}
</script>

<template>
  <div class="row w-full">
    <div class="flex ms-3 flex-col min-w-[40%]">
      <div class="flex flex-row mb-2"><label>Statut<span>: </span></label>
      <p class="required-tag text-red ms-1">*</p></div>
      <select v-model="tenant.type_tenant" class="form-select rounded-md">
        <option disabled value="">Selectionnez</option>
        <option v-for="(item, i) in tenantTypeList.list" :key="i" :value="item[0]">
        {{ item[1]}}</option>
      </select>
    </div>
  </div>
  <div class="row w-full">
    <div class="flex flex-col min-w-[40%]">
      <div class="flex flex-row mb-2"><label>Type de location<span>: </span></label>
      <p class="required-tag text-red ms-1">*</p></div>
      <select v-model="tenant.type_of_rental" class="form-select rounded-md">
        <option disabled value="">Selectionnez</option>
        <option v-for="(item, i) in types.list" :key="i" :value="item[0]">
        {{ item[1]}}</option>
      </select>
    </div>
  
    <div class="flex ms-3 flex-col min-w-[40%]">
      <div class="flex flex-row mb-2"><label>Type de loyer<span>: </span></label>
      <p class="required-tag text-red ms-1">*</p></div>
      <select v-model="tenant.type_of_rent" class="form-select rounded-md">
        <option v-for="(item, i) in loyers" :key="i" >
        {{ item.value}}</option>
      </select>
    </div>
  </div>

  <div class="row w-full">
    <div class="flex flex-col min-w-[25%]">
    <div class="flex flex-row mb-2"><label>Nombre de salon<span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model ="tenant.livingroom_nb"  @change="handleIntValue('livingroom_nb', tenant.livingroom_nb)" class="form-select  rounded-md">
     
      <option v-for="(salon, i) in pieces" :key="i">
      {{ salon.value}}</option>
    </select>
   </div>
   <div class="flex flex-col min-w-[25%] ms-4">
    <div class="flex flex-row mb-2"><label>Nombre de chambre<span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model ="tenant.bedroom_nb" @change="handleIntValue('bedroom_nb', tenant.bedroom_nb)" class="form-select  rounded-md">
     
      <option v-for="(chambre, i) in pieces" :key="i">
      {{ chambre.value}}</option>
    </select>
   </div>
   <div class="flex flex-col min-w-[25%] ms-4">
    <div class="flex flex-row mb-2"><label>Nbre de douche <span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model ="tenant.shower_nb" @change="handleIntValue('shower_nb', tenant.shower_nb)" class="form-select  rounded-md">
     
      <option v-for="(douche, i) in pieces" :key="i">
      {{ douche.value}}</option>
    </select>
   </div>
  </div>

  <div class="row w-full">
    <div class="flex flex-col min-w-[40%]">
    <div class="flex flex-row mb-2"><label>Nombre de cuisine<span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model ="tenant.kitchen_nb" @change="handleIntValue('kitchen_nb', tenant.kitchen_nb)" class="form-select  rounded-md">
     
      <option v-for="(cuisine, i) in pieces" :key="i">
      {{ cuisine.value}}</option>
    </select>
   </div>
   <div class="flex flex-col min-w-[40%] ms-4">
    <div class="flex flex-row mb-2"><label>Nombre de salle à manger<span>: </span></label>
    <p class="required-tag text-red ms-1"></p></div>
    <select v-model ="tenant.dining_room_nb"  @change="handleIntValue('dining_room_nb', tenant.dining_room_nb)" class="form-select  rounded-md">
     
      <option v-for="(dining, i) in pieces" :key="i">
      {{ dining.value}}</option>
    </select>
   </div>

  </div>

  <div class="row w-full">
    <TextField1 fullWidth label="Superficie" placeholder="Superficie" :value="tenant.area"
      @update:value="newValue => handleIntValue('area', newValue)" />

    <TextField1 fullWidth label="Budget" placeholder="Budget" :value="tenant.budget"
      @update:value="newValue => handleIntValue('budget', newValue)" />
  </div>

  <div class="row w-full">
    <TextField1 fullWidth label="Caution" placeholder="Caution" :value="tenant.caution"
      @update:value="newValue => handleIntValue('caution', newValue)" />

    <TextField1 fullWidth label="Avance sur le loyer" placeholder="Avance sur le loyer" :value="tenant.avance_loyer"
      @update:value="newValue => handleIntValue('avance_loyer', newValue)" />
  </div>

 

 
</template>

