<script lang="js">
import { mapActions, mapWritableState } from 'pinia';

import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import TextField1 from '@/components/TextField1/TextField1.vue'
import TableOne from '@/containers/TableOne/TableOne.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'

import { formSteps, tableDatas } from './datas';
import { path as basePath } from "../datas"
import { initialList } from './datas'

import useTenantStore from '../store';
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';
import {loyers, pieces, civils} from './datas';
import usePropertyStore from '@/views/RentalManagement/Goods/store';

const baseUrl = basePath[basePath.length - 1].url

export default {
  components: {
    RentalManagement,
    EditionForm,
    TextField1,
    TableOne,
    ButtonOne,
    AnimatedLoader,
    ErrorMessage
  },
  data() {
    return {

      steps: formSteps,
      datas:tableDatas,
      currentStep: 0,
      changed: false,
      loyers,
      pieces,
      civils,
      deleteClicked: false,
      tenantFound: false,
      initialList
    }
  },
  computed: {

    ...mapWritableState(useTenantStore, ['tenant', 'properties', 'ibans', 'updatedTenantDatas', 'deletionProcess', 'loading', 'status', 'error', 'getTenantProcess', 'updateTenantProcess', 'tenantTypeList', 'getTenantsProcess']),
    ...mapWritableState(usePropertyStore, ['types']),
    urlParam() {
      return this.$route.params.rentId
    },
    rentId() {
      return parseInt(this.urlParam);
    },
    path() {
      return [
        ...basePath,
        {
          label: (this.tenant.first_name + " " + this.tenant.lastname).split(" ").slice(0, 2).join("-").toUpperCase() + "...",
          url: baseUrl + "/" + this.rentId
        }
      ]
    }
  },
  methods: {
    ...mapActions(useTenantStore, ['getTypeTenantList']),
    ...mapActions(usePropertyStore, ['getPropertyTypes']),
    findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    },
    reload() {
      location.reload()
    },
    ...mapActions(useTenantStore, ['getTenant', 'updateTenant', 'deleteTenant', 'getOneRentRequest', 'updateRequest', 'deleteRentRequest', 'getSelectProperty']),
    
    handleUpdateValue(name, newValue) {
      this.changed = true;
      this.tenant[name] = newValue;
      !this.updatedTenantDatas.includes(name) && this.updatedTenantDatas.push(name);
    },

    handleUpdateSelect(name, event){
      this.changed = true;
      if(name === 'type_of_rent' || name === 'civility'){
        this.tenant[name] = event.target.value;
      }else {
        this.tenant[name] = parseInt(event.target.value)
      }
      console.log(event.target.value)
      !this.updatedTenantDatas.includes(name) && this.updatedTenantDatas.push(name);
    },
   
    handleIntValue(name, newValue) {
      this.changed = true;
      this.tenant[name] = parseInt(newValue);
      !this.updatedTenantDatas.includes(name) && this.updatedTenantDatas.push(name);
    },
    handleDeleteTenant() {
      if (this.deleteClicked) {
        this.deleteClicked = false;
        this.deleteRentRequest(this.rentId);
      } else {
        this.deleteClicked = true;
      }
    }
  },
  async mounted() {
    this.path[this.path.length - 1].url += this.urlParam
    this.getPropertyTypes(0)
    this.getTypeTenantList()
    this.getOneRentRequest(this.rentId)
   // this.getSelectProperty(this.tenant.tenant_id, 0)
   
  },
  watch: {
    // offset(value) {
    //   this.getSelectProperty(this.tenant.tenant_id, value);
    // }
  }
}
</script>

<template>
  <RentalManagement :path="path">
    <div v-if="getTenantProcess.status === 'pending'">
      <AnimatedLoader dark />
    </div>
    <div v-if="getTenantProcess.status === 'success'" class="container">
      <div class="flex items-center justify-between">
        <h1 class='container-title'>Locataire: {{ tenant.first_name }} {{ tenant.lastname }}</h1>
        <ButtonOne @click="handleDeleteTenant()" :text="deleteClicked ? 'Cliquez à nouveau pour supprimer' : 'Supprimer'"
          :dangerous="deleteClicked" :loading="deletionProcess.loading" :status="deletionProcess.status" isAsync />
      </div>

        <div class="profile container-body">
          <EditionForm :changed="changed" :steps="steps" @step="(step) => currentStep = step" :loading="loading"
            :status="status">
            <template #form>
              <form @submit.prevent="() => updateRequest(rentId)" class="flex flex-col w-full">
                <section class="form-part" v-if="currentStep == findIndex('general')">
                  <div class="flex flex-row items-start w-full">
                    <div class="flex flex-col min-w-[25%]">
                      <div class="flex flex-row mb-2"><label>Civilité <span>: </span></label>
                        <p class="required-tag text-red ms-1">*</p>
                      </div>
                      <select v-model="tenant.civility" @change="handleUpdateSelect('civility', $event)"
                        class="form-select rounded-md">
                        <option disabled value="">Selectionnez</option>
                        <option v-for="(item, i) in civils" :key="i">
                          {{ item.value }}</option>
                      </select>

                    </div>
                  </div>

                  <div class="row w-full">
                    <TextField1 fullWidth label="Prénom" placeholder="prénom" :value="tenant.first_name"
                      @update:value="newValue => handleUpdateValue('first_name', newValue)" />
                    <TextField1 fullWidth label="Nom" placeholder="nom" :value="tenant.lastname"
                      @update:value="newValue => handleUpdateValue('lastname', newValue)" />
                  </div>

                  <TextField1 label="Email" placeholder="email" :value="tenant.email"
                    @update:value="newValue => handleUpdateValue('email', newValue)" fullWidth />

                  <div class="row w-full">
                    <TextField1 label="Date de naissance" type="date" :value="tenant.date_of_birth"
                      @update:value="newValue => handleUpdateValue('date_of_birth', newValue)" fullWidth />

                    <TextField1 label="Lieu de naissance" placeholder="Lieu de naissance" :value="tenant.place_of_birth"
                      @update:value="newValue => handleUpdateValue('place_of_birth', newValue)" fullWidth />
                  </div>

                  <div class="row w-full ">
                    <TextField1 fullWidth label="Pays" placeholder="Pays" :value="tenant.country"
                      @update:value="newValue => handleUpdateValue('country', newValue)" />
                    <TextField1 fullWidth label="Ville" placeholder="Ville" :value="tenant.city"
                      @update:value="newValue => handleUpdateValue('city', newValue)" />
                  </div>
                  <div class="row w-full">
                    <TextField1 fullWidth label="Profession" placeholder="Profession" :value="tenant.profession"
                      @update:value="newValue => handleUpdateValue('profession', newValue)" />

                    <TextField1 fullWidth label="Code postal" placeholder="Votre code postal" :value="tenant.zip_code"
                      @update:value="newValue => handleUpdateValue('zip_code', newValue)" />
                  </div>
                  <div class="row w-full ">
                    <div class="flex  flex-col min-w-[40%]">
                      <div class="flex flex-row mb-2"><label>Nombre d'enfants<span>: </span></label>
                        <p class="required-tag text-red ms-1">*</p>
                      </div>
                      <select v-model="tenant.child" @change="handleUpdateSelect('child', $event)"
                        class="form-select rounded-md">
                        <option disabled value="">Selectionnez</option>
                        <option v-for="(item, i) in pieces" :key="i">
                          {{ item.value }}</option>
                      </select>
                    </div>

                    <div class="flex  flex-col min-w-[40%]">
                      <div class="flex flex-row mb-2"><label>Nombre d'animaux de compagnie<span>: </span></label>
                        <p class="required-tag text-red ms-1">*</p>
                      </div>
                      <select v-model="tenant.pets" @change="handleUpdateSelect('pets', $event)"
                        class="form-select rounded-md">
                        <option v-for="(item, i) in pieces" :key="i">
                          {{ item.value }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row w-full">
                    <TextField1 fullWidth label="Fax" placeholder="fax" :value="tenant.fax"
                      @update:value="newValue => handleUpdateValue('fax', newValue)" />
                    <TextField1 label="Téléhone" placeholder="téléphone" :value="tenant.phone"
                      @update:value="newValue => handleUpdateValue('phone', newValue)" fullWidth />
                  </div>

                </section>

                <section class="form-part" v-if="currentStep == findIndex('description')">
                  <div class="row w-full">
                    <div class="flex ms-3 flex-col min-w-[40%]">
                      <div class="flex flex-row mb-2"><label>Statut<span>: </span></label>
                        <p class="required-tag text-red ms-1">*</p>
                      </div>
                      <select v-model="tenant.type_tenant" @change="handleUpdateSelect('type_tenant', $event)" class="form-select rounded-md">
                        <option disabled value="">Selectionnez</option>
                        <option v-for="(item, i) in tenantTypeList.list" :key="i" :value="item[0]">
                          {{ item[1] }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="row w-full">
                    <div class="flex flex-col min-w-[40%]">
                      <div class="flex flex-row mb-2"><label>Type de location<span>: </span></label>
                        <p class="required-tag text-red ms-1">*</p>
                      </div>
                      <select v-model="tenant.type_of_rental" @change="handleUpdateSelect('type_of_rental', $event)" class="form-select rounded-md">
                        <option disabled value="">Selectionnez</option>
                        <option v-for="(item, i) in types.list" :key="i" :value="item[0]">
                          {{ item[1] }}</option>
                      </select>
                    </div>

                    <div class="flex ms-3 flex-col min-w-[40%]">
                      <div class="flex flex-row mb-2"><label>Type de loyer<span>: </span></label>
                        <p class="required-tag text-red ms-1">*</p>
                      </div>
                      <select v-model="tenant.type_of_rent" @change="handleUpdateSelect('type_of_rent', $event)" class="form-select rounded-md">
                        <option v-for="(item, i) in loyers" :key="i">
                          {{ item.value }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row w-full">
                    <div class="flex flex-col min-w-[25%]">
                      <div class="flex flex-row mb-2"><label>Nombre de salon<span>: </span></label>
                        <p class="required-tag text-red ms-1"></p>
                      </div>
                      <select v-model="tenant.livingroom_nb" @change="handleUpdateSelect('livingroom_nb', $event)"
                        class="form-select  rounded-md">

                        <option v-for="(salon, i) in pieces" :key="i">
                          {{ salon.value }}</option>
                      </select>
                    </div>
                    <div class="flex flex-col min-w-[25%] ms-4">
                      <div class="flex flex-row mb-2"><label>Nombre de chambre<span>: </span></label>
                        <p class="required-tag text-red ms-1"></p>
                      </div>
                      <select v-model="tenant.bedroom_nb" @change="handleUpdateSelect('bedroom_nb', $event)"
                        class="form-select  rounded-md">

                        <option v-for="(chambre, i) in pieces" :key="i">
                          {{ chambre.value }}</option>
                      </select>
                    </div>
                    <div class="flex flex-col min-w-[25%] ms-4">
                      <div class="flex flex-row mb-2"><label>Nbre de douche <span>: </span></label>
                        <p class="required-tag text-red ms-1"></p>
                      </div>
                      <select v-model="tenant.shower_nb" @change="handleUpdateSelect('shower_nb', $event)"
                        class="form-select  rounded-md">

                        <option v-for="(douche, i) in pieces" :key="i">
                          {{ douche.value }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="row w-full">
                    <div class="flex flex-col min-w-[40%]">
                      <div class="flex flex-row mb-2"><label>Nombre de cuisine<span>: </span></label>
                        <p class="required-tag text-red ms-1"></p>
                      </div>
                      <select v-model="tenant.kitchen_nb" @change="handleUpdateSelect('kitchen_nb', $event)"
                        class="form-select  rounded-md">

                        <option v-for="(cuisine, i) in pieces" :key="i">
                          {{ cuisine.value }}</option>
                      </select>
                    </div>
                    <div class="flex flex-col min-w-[40%] ms-4">
                      <div class="flex flex-row mb-2"><label>Nombre de salle à manger<span>: </span></label>
                        <p class="required-tag text-red ms-1"></p>
                      </div>
                      <select v-model="tenant.dining_room_nb"
                        @change="handleUpdateSelect('dining_room_nb', $event)" class="form-select  rounded-md">

                        <option v-for="(dining, i) in pieces" :key="i">
                          {{ dining.value }}</option>
                      </select>
                    </div>

                  </div>

                  <div class="row w-full">
                    <TextField1 fullWidth label="Superficie" placeholder="Superficie" :value="tenant.area"
                      @update:value="newValue => handleIntValue('area', newValue)" />

                    <TextField1 fullWidth label="Budget" placeholder="Budget" :value="tenant.budget"
                      @update:value="newValue => handleIntValue('budget', newValue)" />
                  </div>

                  <div class="row w-full">
                    <TextField1 fullWidth label="Caution" placeholder="Caution" :value="tenant.caution"
                      @update:value="newValue => handleIntValue('caution', newValue)" />

                    <TextField1 fullWidth label="Avance sur le loyer" placeholder="Avance sur le loyer"
                      :value="tenant.avance_loyer" @update:value="newValue => handleIntValue('avance_loyer', newValue)" />
                  </div>
                </section>

                <section class="form-part" v-if="currentStep == findIndex('iban')">
                  <div class="row w-full">
                    <TextField1 label="IBAN" placeholder="Votre IBAN" :value="tenant.iban"
                      @update:value="newValue => handleUpdateValue('iban', newValue)" small />

                    <TextField1 label="BIC" placeholder="Votre bic" :value="tenant.bic"
                      @update:value="newValue => handleUpdateValue('bic', newValue)" small />
                  </div>

                  <div class="row w-full">
                    <TextField1 label="Nom créancier" placeholder="Votre Banque" :value="tenant.creditor_name"
                      @update:value="newValue => handleUpdateValue('creditor_name', newValue)" small />

                    <TextField1 label="N° ICS" placeholder="ICS" :value="tenant.ics_number"
                      @update:value="newValue => handleUpdateValue('ics_number', newValue)" small />
                  </div>
                </section>

                <section class="form-part" v-if="currentStep == findIndex('propriété')">
                  <div class="container-body">
                  
                    <AnimatedLoader dark v-if="getTenantsProcess.loading" />
                    <div v-else-if="getTenantsProcess.status === 'failure'" class="flex flex-col items-center gap-4">
                      <ErrorMessage :message="getTenantsProcess.error" />
                      <ButtonOne isAsync @click="getSelectProperty(this.tenant.tenant_id, offset)" text="Actualiser" :loading="getTenantsProcess.loading" :status="getTenantsProcess.status" />
                    </div>
                    <TableOne
                      v-else
                      :title="datas.title"
                      :cols="datas.cols"
                      :rows="properties.list"
                      :total="properties.total"
                      :actions="initialList.actions"
                      baseUrl="/gestion-locative/biens"
                      noSearch
                      @emitedOffset="(value) => offset = value"
                      :send="true"
                      :id="this.tenant.tenant_id"
                    />
                  </div>
                </section>
                <div class="flex gap-[20px]">
                <div v-if="deletionProcess.status === 'failure'">
                  <ErrorMessage :message="deletionProcess.error" />
                </div>

                <div v-if="status === 'failure'">
                  <ErrorMessage :message="error" />
                </div>
              </div>
                <div class="flex justify-center items-center mt-8">
                  <ButtonOne isAsync :loading="updateTenantProcess.loading" :status="updateTenantProcess.status" text="Modifier" />
                </div>
              </form>
              
            
            </template>
          </EditionForm>
        </div>
    </div>
   
  <div v-if="getTenantProcess.status === 'failure'" class="container">
    <div class="tenant-header">
      <h1 class='container-title'>Erreur de chargement</h1>
      <ButtonOne @click="reload()" text="Cliquez pour recharger" />
    </div>
  </div>
</RentalManagement></template>