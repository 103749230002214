<script>
import { mapActions, mapWritableState } from 'pinia';
import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import TextField1 from '@/components/TextField1/TextField1.vue';

import { path, pageTitle, formSteps } from './datas'
import useCoOwnerStore from '../store'

import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';
export default {
  components: {
    RentalManagement,
    EditionForm,
    TextField1,
    ErrorMessage
},
  data() {
    return {
      path,
      pageTitle,
      steps: formSteps,
      currentStep: 0,
      name: "durin",
    }
  },
  computed: {
    ...mapWritableState(useCoOwnerStore, ['coOwner', 'loading', 'status', 'error'])
  },
  methods: {
    ...mapActions(useCoOwnerStore, ['createCoOwner']),
    findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    }
  }
}
</script>

<template>
  <RentalManagement :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="profile container-body">
        <EditionForm :save="createCoOwner" :steps="steps" @step="(step) => currentStep = step" :loading="loading" :status="status" >
          <template #form>
            <section class="form-part" v-if="currentStep == findIndex('general')" >
              <TextField1 fullWidth label="Civilités" placeholder="Mr., Mme, ..." :value="coOwner.civility"  @update:value="newValue => coOwner.civility = newValue" />
                
              <div class="row w-full">
                <TextField1 fullWidth  label="Prénom" placeholder="prénom" :value="coOwner.first_name"  @update:value="newValue => coOwner.first_name = newValue" />

                <TextField1 fullWidth  label="Nom" placeholder="nom" :value="coOwner.lastname"  @update:value="newValue => coOwner.lastname = newValue" />
              </div>

              <TextField1 fullWidth label="Email" placeholder="email" :value="coOwner.email"  @update:value="newValue => coOwner.email = newValue" />

              <div class="row w-full">
                <TextField1 fullWidth label="Date de naissance" type="date" :value="coOwner.date_of_birth"  @update:value="newValue => coOwner.date_of_birth = newValue"  />
                
                <TextField1 fullWidth label="Lieu de naissance" placeholder="Lieu de naissance" :value="coOwner.place_of_birth"  @update:value="newValue => coOwner.place_of_birth = newValue"  />
              </div>

              <div class="row w-full">
                <TextField1 fullWidth label="Adresse" placeholder="adresse" :value="coOwner.address"  @update:value="newValue => coOwner.address = newValue"  />
              
                <TextField1 fullWidth label="Complément d'adresse" placeholder="Complément d'adresse" :value="coOwner.complete_address"  @update:value="newValue => coOwner.complete_address = newValue"  />
              </div>

              <div class="row w-full">
                <TextField1 fullWidth label="Ville" placeholder="ville" :value="coOwner.city"  @update:value="newValue => coOwner.city = newValue"  />
            
                <TextField1 fullWidth label="Pays" placeholder="pays" :value="coOwner.country"  @update:value="newValue => coOwner.country = newValue"  />
              </div>

              <div class="row w-full">
                <TextField1 fullWidth label="Code postal" placeholder="code postal" :value="coOwner.zip_code"  @update:value="newValue => coOwner.zip_code = newValue"  />
          
                <TextField1 fullWidth label="Fax" placeholder="fax" :value="coOwner.fax"  @update:value="newValue => coOwner.fax = newValue"  />
              </div>

              <div class="row w-full">
                <TextField1 fullWidth label="Téléhone" placeholder="téléphone" :value="coOwner.phone"  @update:value="newValue => coOwner.phone = newValue"  />

                <TextField1 fullWidth label="Portable" placeholder="portable" :value="coOwner.cell_phone"  @update:value="newValue => coOwner.cell_phone = newValue"  />
              </div>
            
            </section>

            <section class="form-part" v-if="currentStep == findIndex('iban')" >
                <div class="row w-full">
                  <TextField1 fullWidth label="IBAN" placeholder="IBAN" :value="coOwner.iban"  @update:value="newValue => coOwner.iban = newValue"  />
              
                  <TextField1 fullWidth label="BIC" placeholder="bic" :value="coOwner.bic"  @update:value="newValue => coOwner.bic = newValue"  />
                </div>

                <div class="row w-full">
                  <TextField1 fullWidth label="Nom créancier" placeholder="Banque" :value="coOwner.creditor_name"  @update:value="newValue => coOwner.creditor_name = newValue"  />
            
                  <TextField1 fullWidth label="N° ICS" placeholder="ICS" :value="coOwner.ics_number"  @update:value="newValue => coOwner.ics_number = newValue"  />
                </div>
            </section>

            <section class="form-part" v-if="currentStep == findIndex('accounting')" >
              <TextField1 fullWidth label="Type de comptabilité" placeholder="type de compatbilité" :value="coOwner.accounting_type"  @update:value="newValue => coOwner.accounting_type = newValue" />
        
              <TextField1 fullWidth label="Compte comptable trop perçu" placeholder="Iban ou Numéro simple" :value="coOwner.accounting_account_overpayment"  @update:value="newValue => coOwner.accounting_account_overpayment = newValue" />
            </section>
            
            <section class="form-part" v-if="currentStep == findIndex('taxation')" >
              <div class="flexed">
                <input type="checkbox" name="taxation" v-model="coOwner.ifi" id="ifi" >
                <label for="ifi">Propriétaire assujetti à l'IFI (Impôt sur la Fortune Immobilière)</label>
              </div>

              <div class="flexed">
                <input type="checkbox" name="taxation" v-model="coOwner.vat" id="vat" >
                <label for="vat">Propriétaire assujetti à la TVA</label>
              </div>
            </section>
            <div v-if="status === 'failure'" class="flex gap-[20px]">
              <div>
                <ErrorMessage :message="error" />
              </div>
            </div>
          </template>
        </EditionForm> 
      </div>
    </div>
  </RentalManagement>
</template>
