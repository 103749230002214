import WorksQuotes from '@/views/Works/WorksQuotes/WorksQuotes.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/devis"

const quotesRoutes = [
  {
    path: baseUrl,
    name: "quotes",
    component: WorksQuotes,
  }
];

export default quotesRoutes
