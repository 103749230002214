import WorksHome from '@/views/Works/WorksHome/WorksHome.vue'
import claimsRoutes from './claims';
import detectorsRoutes from './detectors';
import diagnosticRoutes from './diagnostic';
import editionRoutes from './edition';
import equipmentsRoutes from './equipments';
import interventionsRoutes from './interventions';
import quotesRoutes from './quotes';
import serviceOrdersRoutes from './serviceOrders';
import metersRoutes from './meters';

const worksRoutes = [
  {
    path: "/travaux",
    name: "travaux",
    component: WorksHome,
  },
  ...claimsRoutes,
  ...diagnosticRoutes,
  ...detectorsRoutes,
  ...equipmentsRoutes,
  ...editionRoutes,
  ...quotesRoutes,
  ...serviceOrdersRoutes,
  ...interventionsRoutes,
  ...metersRoutes
];

export default worksRoutes;