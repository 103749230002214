<script>
import RentalManagement from '../RentalManagement.vue'
import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'
import { items, path, pageTitle, tableDatas } from './datas.js'
import TableOne from '@/containers/TableOne/TableOne.vue'

import { initialList } from '@/utils/helpers'
import useCoTenantStore from './store'
import { mapActions, mapWritableState } from 'pinia'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'

import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

export default {
    components: {
        RentalManagement,
        Header2,
        TableOne,
        ErrorMessage,
        AnimatedLoader,
        ButtonOne
    },
    data() {
        return {
            items,
            path,
            pageTitle,
            datas: tableDatas,
            offset: 0,
            initialList
        }
    },
    computed: {
        ...mapWritableState(useCoTenantStore, ['co_tenants', 'getCoTenantsProcess'])
    },
    mounted() {
        this.getCoTenants(0);

    },
    methods: {
        ...mapActions(useCoTenantStore, ['getCoTenants'])
    },
    watch: {
        offset(value) {
            this.getCoTenants(value);

        }
    }
}
</script>

<template>
    <RentalManagement :path="path">
        <div class="container">
            <h1 class="container-title">{{ pageTitle }}</h1>
            <Header2 :items="items" />
            <div class="container-body">
                <AnimatedLoader dark v-if="getCoTenantsProcess.loading" />
                <div v-else-if="getCoTenantsProcess.status === 'failure'" class="flex flex-col items-center gap-4">
                    <ErrorMessage :message="getCoTenantsProcess.error" />
                    <ButtonOne isAsync @click="getCoTenants(offset)" text="Actualiser"
                        :loading="getCoTenantsProcess.loading" :status="getCoTenantsProcess.status" />
                </div>
                <TableOne v-if="datas" :title="datas.title" :cols="datas.cols" :rows="co_tenants.list"
                    :total="co_tenants.total" :actions="initialList.actions" baseUrl="/gestion-locative/co-locataires"
                    noSearch @emitedOffset="(value) => offset = value" />
            </div>
        </div>
    </RentalManagement>
</template>

<style src="./FirstPage.css"></style>