<!-- Composant utilisé pour indiquer le chemin d'accès à une page donnée -->
<script>
import arrow from '@/assets/icons/leftArrow1.svg'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';

export default {
  props: {
    path: Array
  },
  data() {
    return {
      arrow,
      prevPage: this.$router.options.history.state.back,
    };
  },
  methods: {
    goBack() {
      this.prevPage && this.$router.go(-1);
    },
  },
  components: { ButtonOne }
}
</script>

<template>
  <div class="path-viewer">
    <ButtonOne
      :title="prevPage === null ? 'Aucune page avant celle-ci' : (`Rejoindre la page ${prevPage === '/' ? '/Accueil' : prevPage}`)"
      :class="{ unactive: prevPage === null }" text="back" :left-icon="arrow" @click="goBack" />
    <div v-if="path" class="path">
      <router-link class="path-link" v-for="p in path" :key="p.label + p.url" :to="p.url"><span>/</span><span
          class="link">{{ p.label }}</span></router-link>
    </div>
  </div>
</template>

<style src="./PathViewer.css"></style>