import FirstPage from '@/views/MarketPlace/Owners/FirstPage.vue'

// url de base des pages relatives à la corbeille
const baseUrl = "/marketplace/proprietaires"

const marketePlaceOwnersRoutes = [
  {
    path: baseUrl,
    name: "marketPlaceOwners",
    component: FirstPage,
  }
];

export default marketePlaceOwnersRoutes
