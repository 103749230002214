<script>
  import settings1 from "@/assets/icons/settings1.png"
  import faq from "@/assets/icons/faq.png"
  import unlock from '@/assets/icons/unlock-2.png'

  export default{
    props: {
      module: Object
    },
    data(){
      return{
        settings1,
        faq,
        unlock
      }
    }
  }
</script>

<template>
  <div class="row-centered home-header">
    <div class="home-header--links">
      <router-link v-if="module.unlocked" class="row-centered home-header--link settings" :to="`/settings/${module.label.split(' ').join('-').toLocaleLowerCase()}`">
        <img :src="settings1" alt="" />
        <p>Paramètres : <span>{{ module.label }}</span></p>
      </router-link>
      <router-link class="row-centered home-header--link faq" to="/faq">
        <img :src="faq" alt="" />
        <p>FAQ</p>
      </router-link>
      <router-link v-if="!module.unlocked" to="/upgrade" class="row-centered home-header--link home-header--link__locked">
        <img :src="unlock" alt="">
        <p>Débloquer : <span>{{ module.label }}</span></p>
      </router-link>
    </div>
  </div>
</template>

<style src="./HeaderOne.css"></style>