import { path as basePath } from "../WorksHome/datas"

// base url for the pages above this page in the global infrastructure

const baseUrl = basePath[basePath.length-1].url+"/equipements"

// path to page
export const path = [
  ...basePath,
  {
    label: "équipements",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Équipements"

// fonction permettant de mettre les url au bon format en fonction de l'url de base
function url(url){
  return baseUrl+url
}
export const items = [
  {
    text: "Gestion des équipements",
    buttons: [
      {
        label: "Créer un équipement",
        url: url("/creer"),
        empty: false
      },
    ]
  },

]


export const worksActions = [
    {
      title: "Équipement Gestion locative",
      actions: [
        {
          title: "Liste des Équipements gestion locative",
          text: "Liste des équipements liés à vos biens en gestion locative.",
          url: baseUrl+"/liste?type=gestion-locative"
        },
        {
          title: "Liste des équipements gestion locative",
          text: "Listez les devis reçus, relancez ceux toujours en attente et prenez vos décisions.",
          url: baseUrl+"/devis"
        },
      ]
    },
  {
    title: "Équipement copropriété",
    actions: [
      {
        title: "Liste des équipements des copropriétés",
        text: "Liste des équipements présents dans les copropriétés dont vous assurez la gestion",
        url: baseUrl+"/liste?type=copropriete"
      },
      {
        title: "Créer un équipement",
        text: "Créez un ordre de service pour demander une intervention ou la réalisation de travaux.",
        url: baseUrl+"/ajouter"
      },
    ]
  }
]