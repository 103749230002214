<script>
import face from '@/assets/icons/facebook_black1.svg'
import twitter from '@/assets/icons/twitter_black1.svg'
import linkedin from '@/assets/icons/linkedIn_black1.svg'
import youtube from '@/assets/icons/youtube_black1.png'
import tiktok from '@/assets/icons/tik-tok_black1.png'

export default {
  data(){
    return {
      face,
      twitter,
      linkedin,
      youtube,
      tiktok
    }
  }
}
</script>

<template>
  <div class="footer">
    <p>Copyright | <a href="https://xxl-webtechnology.uk" target="_blank">XXL WEB TECHNOLOGY</a></p>
    <div class="flexed">
      <a href="#"><img :src="face" alt="facebook"></a>
      <a href="#"><img :src="twitter" alt="twitter"></a>
      <a href="#"><img :src="linkedin" alt="linkedin"></a>
      <a href="#"><img :src="youtube" alt="youtube"></a>
      <a href="#"><img :src="tiktok" alt="tiktok"></a>
    </div>
  </div>
</template>

<style>
.footer{
  width: calc(100% - 80px);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 25px;
  background-color: var(--color-primary-trans);
  color: black;
  border-radius: 20px;
  margin-bottom: 15px;
}
.footer p{
  text-align: center;
  font-weight: bold;
}

.footer p a{
  color: var(--color-primary)
}

.footer div{
  gap: 15px;
}

.footer div a img{
  height: 20px;
}
</style>