import client from "@/views/client";

const baseUrl = "/gl/tenant"

export const coTenantApi = {
  addCoTenant: (data) => client.post(`${baseUrl}/add_co_tenant`, data),
  getCoTenant: (id) => client.get(`${baseUrl}/info_co_tenant/${id}`),
  updateCoTenant: (data) => client.patch(`${baseUrl}/update_co_tenant`, data),
  // get owners list with pagnination handeling
  getCoTenants: (limit, offset) => client.get(`${baseUrl}/list_co_tenant/${limit}/${offset}`),
  deleteCoTenant: (data) => client.delete(`${baseUrl}/delete_co_tenant`, {data})
}

export default coTenantApi;
