<script>
  export default{
    props: {
      type: String, 
      title: String,
      description: String,
    }
  }
</script>

<template>
  <teleport to='body'>
    <div class="modal-bg">
      <div class="modal-container">
        
      </div>
    </div>
  </teleport>
</template>

<style src="./EditModal.css"></style>