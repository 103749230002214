<script>
  import RentalManagement from '../RentalManagement.vue'
  import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'
  import { items, path, pageTitle, tableDatas } from './datas.js'
import TableOne from '@/containers/TableOne/TableOne.vue'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
 
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import { initialList } from '@/utils/helpers'
import useLeaseStore from './store'
import { mapActions, mapWritableState } from 'pinia'

  export default {
    components: {
    RentalManagement,
    Header2,
    TableOne,
    ErrorMessage,
    AnimatedLoader,
    ButtonOne
},
    data(){
      return {
        items,
        path,
        pageTitle,
        datas: tableDatas,
        offset: 0,
        initialList
      }
    },
    computed:{
      ...mapWritableState(useLeaseStore, ['leases', 'getLeasesProcess'])
    },
    mounted(){
        this.getLeaseList(0);
    },
    methods:{
      ...mapActions(useLeaseStore, ['getLeaseList'])
    },
    watch:{
      offset(value){
        this.getLeaseList(value);
      }
    }
  }
</script>

<template>
  <RentalManagement :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <Header2 :items="items" />
      <div class="container-body">
        <AnimatedLoader dark v-if="getLeasesProcess.loading" />
        <div v-else-if="getLeasesProcess.status === 'failure'" class="flex flex-col items-center gap-4">
          <ErrorMessage :message="getLeasesProcess.error" />
          <ButtonOne isAsync @click="getLeaseList(offset)" text="Actualiser" :loading="getLeasesProcess.loading" :status="getLeasesProcess.status" />
        </div>
        <TableOne
          v-else
          :title="datas.title"
          :cols="datas.cols"
          :rows="leases.list"
          :total="leases.total"
          :actions="initialList.actions"
          baseUrl="/gestion-locative/baux"
          @emitedOffset="(value) => offset = value"
        />
      </div>
    </div>
  </RentalManagement>
</template>

<style src="./FirstPage.css"></style>