<script>
import AccountingLayer from '../AccountingLayer.vue';
import DashboardPage from '../../../containers/Common/DashboardPage.vue';
import { path, pageTitle, transactions, usefullInfos } from './datas'
export default {
  name: "AccountingDashboard",
  components: { AccountingLayer, DashboardPage },
  data(){
    return{
      path,
      pageTitle,
      transactions,
      usefullInfos
    }
  }
}
</script>

<template>
  <AccountingLayer :path="path" :pageTitle="pageTitle">
    <DashboardPage :pageTitle="pageTitle" :monthlyReview="3250.00" :lastTransactions="transactions" :rentReport="{}" :usefullInfos="usefullInfos" />
  </AccountingLayer>
</template>