<script>
import WorksView from '../WorksView.vue'
import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'


import { mapActions, mapWritableState } from 'pinia'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
import { initialList } from '@/utils/helpers'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import {items, path, pageTitle} from './datas'
import useMeterStore from './store'
import TableOne from '@/containers/TableOne/TableOne.vue'

  export default {
    data(){
      return {
        path,
        pageTitle,
        initialList,
        items
      }
    },
    components: {
      WorksView,
      ErrorMessage,
      AnimatedLoader,
      ButtonOne,
      TableOne,
      Header2
    },
    computed: {
      ...mapWritableState(useMeterStore, ['meters', 'getMetersProcess'])
    },
   methods:{
    ...mapActions(useMeterStore, ['getMeters'])
   },
   mounted(){
    this.getMeters(0);
   },
   watch:{
    offset(value){
      this.getMeters(value);
    }
   }
  }
</script>

<template>
  <WorksView :path="path">
    <div class="container works-home">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <Header2 :items="items" />
      <!-- body page -->
      <div class="container-body">
        <AnimatedLoader dark v-if="getMetersProcess.loading" />
                <div v-else-if="getMetersProcess.status === 'failure'" class="flex flex-col items-center gap-4">
                    <ErrorMessage :message="getMetersProcess.error" />
                    <ButtonOne isAsync @click="getMeters(offset)" text="Actualiser"
                        :loading="getMetersProcess.loading" :status="getMetersProcess.status" />
                </div>

          <TableOne v-else :title="Compteurs" :cols="['ID', 'Numéro PDL', 'Numéro PCE', 'Nom Compteur', 'Localisation']" :rows="meters.list"
                    :total="meters.total" :actions="initialList.actions" baseUrl="/travaux/compteurs"
                    noSearch @emitedOffset="(value) => offset = value" />
      </div>
    </div>
  </WorksView>
</template>

<style src="./FirstPage.css"></style>