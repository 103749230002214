import FirstPage from '@/views/GED/Files/FirstPage.vue'

// url de base des pages relatives aux fichiers
const baseUrl = "/ged/fichiers"

const filesRoutes = [
  {
    path: baseUrl,
    name: "gedFiles",
    component: FirstPage,
  }
];

export default filesRoutes
