import { path as basePath } from "../datas";

// path

export const path = [
  ...basePath,
  {
    label: "Nouveau",
    url: basePath[basePath.length - 1].url + "/creer",
  },
];

// page title value
export const pageTitle = "Ajouter une demande de location";

// civilities
export const civils = [
  {label:"Mr", value:"Mr"},
  {label:"Mme", value:"Mme"}
];

export const pieces = [
  {label:"0", value:"0"},
  {label:"1", value:"1"},
  {label:"2", value:"2"},
  {label:"3", value:"3"},
  {label:"4", value:"4"},
  {label:"5", value:"5"},
  {label:"6", value:"6"},
  {label:"7", value:"7"},
  {label:"8", value:"8"},
  {label:"9", value:"9"},
  {label:"10", value:"10"}
]

export const loyers = [
  {label:"journalier", value:"journalier"},
  {label:"hebdomadaire", value:"hebdomadaire"},
  {label:"mensuel", value:"mensuel"},
 
]

// form steps
export const formSteps = [
  {
    name: "general",
    label: "Coordonnées",
  },
  {
    name: "description",
    label: "Description",
  },
  {
    name: "iban",
    label: "IBAN",
  },
  

];

export const generalInfos = {
  civility: "",
  first_name: "",
  lastname: "",
  profession: "",
  date_of_birth: "",
  place_of_birth: "",
  country:"",
  city:"",
  phone: "",
  email: "",
  fax: "",
  child:0,
  pets:0,
  tenant_id:0,
  type_tenant:0,
  zip_code:""
};

export const descriptionInfos = {
  type_of_rent:"",
  type_of_rental:"",
  budget:0,
  bedroom_nb:0,
  livingroom_nb: 0,
  shower_nb: 0,
  kitchen_nb: 0,
  dining_room_nb: 0,
  area: 0,
  caution: 0,
  avance_loyer: 0
};

export const accountingInfos = {
  accounting_type: "",
  accounting_account_overpayment: "",
  cost_accounting: "",
  auxiliary_code: "",
  unpaid_reminder: true,
};

export const internationalInfos = {
  export_identifier: "",
  language: "",
  resident_number: "",
  nationality: "",
};

export const companyInfos = {
  siret: "",
  intravo_vat: "",
};
export const ibanInfos = {
  iban: "",
  bic: "",
  creditor_name: "",
  ics_number: "",
};

export const tenantType = {

    type_locataire: "",
    id: 0

}

