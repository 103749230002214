import { path as basePath } from "../datas";

// path

export const path = [
  ...basePath,
  {
    label: "nouveau",
    url: basePath[basePath.length - 1].url + "/creer",
  },
];


// page title value
export const pageTitle = "Ajouter un contrat";


export const generalInfos = {
  
    input_date: "",
    output_date: "",
    contract_number: "",
    tenant_id: "",
    property_id: "",
    date_of_signature: "",
    rent_amount: "",
   
  };

  export const callLeaseInfos = {
    id_lease: 0,
    echeance_de: "",
    charge: "",
  };


  // form steps
export const formSteps = [
    {
      name: "general",
      label: "Informations",
    },
   
    
  
  ];