import { defineStore } from "pinia";
import api from "../api";


import {
  generalInfos,
  ibanInfos,
  accountingInfos
} from "./New/datas";
import { noEmptyFields, initialList, initProcess } from "@/utils/helpers";

const useCoTenantStore = defineStore('co_tenant', {
  state: () => ({
    loading: false,
    status: "",
    error: "",
    co_tenant: {
      ...generalInfos,
      ...accountingInfos,
      ...ibanInfos
    },
  
    getCoTenantsProcess: initProcess,
    getCoTenantProcess: initProcess,
    getCoTenantIbanProcess: initProcess,
    updateCoTenantProcess: initProcess,
    updateCoTenantIbanProcess: initProcess,
    deletionProcess: initProcess,
    updatedCoTenantDatas: [],
    updatedCoTenantIbanDatas:[],
    co_tenants: initialList,
    ibans: initialList,

  }),
  getters: {
    co_tenant: (state) => ({
      ...state.generalInfos,
      ...state.ibanInfos,
      ...state.accountingInfos
    })
  },
  actions: {
   
    async createCoTenant() {
      // handle tenant creation
      if (!this.loading) {
        console.log("launch")
        if (
          // checking if no field is empty
          noEmptyFields(this.co_tenant, ["fax", "phone"])
        ) {
          this.loading = true;
          this.status = "pending"
          console.log(this.co_tenant)
          // execute request
          try {
            console.log({newTenant: this.tenant})
            const resp = await api.addCoTenant(this.co_tenant)
            console.log({resp})
            if (resp.status === 200) {
              this.loading = false;
              this.status = "success"
              location.replace("/gestion-locative/co-locataires");
            } else {
              this.loading = false;
              this.status = "failure"
              this.error = resp?.response?.data?.detail
            }
          } catch (error) {
              this.loading = false;
              this.status = "failure"
              this.error = error?.data?.detail
              console.log({error})
          }
        } else {
          this.loading = false;
          this.status = "failure"
          this.error = "Vous semblez avoir omis certains des champs obligatoires !"
        }
      } else {
        //
      }
    },

    async getCoTenant(cotenantId) {
      // taking user info from local storage
      try {
        this.getCoTenantProcess = {loading: true, status: "pending"}
        const resp = await api.getCoTenant(cotenantId)
        if (resp) {
          console.log({ resp })
          this.cotenant = resp[0]
          
          // let rows = [];
          // let cols = ["iban", "bic", "creditor_name", "ics_number"];
          // resp.data.iban_info.map((item) => {
          //   let row = [];
          //   cols.map((col) => {
          //     row.push(item[col]);
          //   });
          //   rows.push(row);
          // });
          // this.ibans = {
          //   ...this.ibans,
          //   list: rows,
          //   total: resp.data.iban_info.length,
          // };
         
          this.getCoTenantProcess = {loading: false, status: 'success'}
        } else {
          // alert("Une erreur s'est produite !")
          this.getCoTenantProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        }
      } catch (getCoTenantError) {
        console.log({ getCoTenantError });
        this.getCoTenantProcess = {loading: false, status: "failure", error: getCoTenantError?.data?.detail}
      }
    },

    async updateCoTenant(id) {
      this.updateCoTenantProcess = {loading: true, status: "pending", error:""}
      // merging user data into cotenant infos in a single datas objects
      console.log('updating cotenant: '+id+' ...')
      let updatedDatas = {};
      // console.log({updatedOwnerDatas: this.updatedOwnerDatas})
      this.updatedCoTenantDatas.map((name) => (
        updatedDatas = { ...updatedDatas, [name]: this.co_tenant[name]}
      ))
      updatedDatas = { ...updatedDatas, id }

      // console.log({updatedDatas})
      // sending request
      try {
        const resp = await api.updateCoTenant(updatedDatas)
        if (resp.status === 200) {
          console.log({ updatedTenant: resp })
          this.updateCoTenantProcess = {loading: false, status: "success", error:"Opération réussie"}
          //location.reload()
        } else {
          console.log({resp})
          // alert("Une erreur s'est produite !\nVeuillez Réessayer plus tard !")
          this.updateCoTenantProcess = {loading: false, status: "failure", error:resp?.response?.data?.detail || "Veuillez vérifier vos informations !"}
     
        }
      } catch (error) {
        console.log({ error })
        this.updateCoTenantProcess = {loading: false, status: "failure", error:error.data.detail}
      }
    },
  
    async deleteCoTenant(id){
      // delete cotenant
      this.deletionProcess = {loading: true, status: "pending"}
      try {
        const deletedCoTenant = await api.deleteCoTenant({id, tenant_or_co_tenant: "Co_tenant"})
        if (deletedCoTenant?.status === 200) {
          console.log({ deletedCoTenant })
          this.deletionProcess = {loading: false, status: "success"}
          location.replace("/gestion-locative/co-locataires");
        } else {
          this.deletionProcess = { loading: false, status: "failure", error: "Une erreur s'est produite !" }
         
        }
      } catch (deletionError) {
        this.deletionProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        console.log({ deletionError });
      }
    },

    async getCoTenants(offset) {
      // sending request
      try {
        this.getCoTenantsProcess = {loading: true, status: "pending", error: ""}
        const resp = await api.getCoTenants(10, offset)
        console.log({ resp: resp.status});
        if (resp) {
          let rows = []
          let cols = ['id', 'lastname', 'first_name', 'email', 'cell_phone']
          resp.data.list.map((item) => {
            let row = []
            cols.map((col) => {
              row.push(item[col])
            })
            rows.push(row)
          })
          this.co_tenants = { ...this.co_tenants, list: rows, total: resp.data.total }
          console.log({ co_tenants: this.co_tenants })
          this.getCoTenantsProcess = {loading: false, status: "success"}
        } else {
          this.getCoTenantsProcess = {loading: false, status: "failure", error: "Une erreur s'est produite, Veuillez réessayer plus tard !"}
        }
      } catch (error) {
        console.log({ error })
        this.getCoTenantsProcess = {loading: false, status: "failure", error: error?.data?.detail}
      }
    },


    // get tenant by id
    // async getTenant(tenantId) {
    //   // // taking user info from local storage
    //   // const user = JSON.parse(localStorage.getItem("xxl-profil-immo-user"))
    //   // // extracting required datas for fetching
    //   // const user_email = user.email_user;
    //   // const user_type = user.user_type;
    //   // try {
    //   //   const resp = await client.get(`/tenant/tenant_info/${tenantId}/${user_email}/${user_type}`)
        
    //   //   if (resp.statusText === "OK") {
    //   //     this.tenant = { ...this.tenant, ...resp.data }
    //   //     console.log({ tenant: this.tenant })
    //   //   } else {
    //   //     alert("Une erreur s'est produite !")
    //   //   }
    //   // } catch (error) {
    //   //     console.log({ error });
    //   // }
    // },
    async updateTenant2() {
      // sending request
      // try {
      //   const resp = await client.post("/tenant/update_tenant", this.tenant)
      //   if (resp.statusText === "OK") {
      //     console.log(this.tenant)
      //     alert("Propriétaire mis à jour")
      //   } else {
      //     alert("Une erreur s'est produite")
      //   }
      // } catch (error) {
      //   console.log({ error })
      // }
    },
    handleChange(property, newValue){
      this.cotenant = { ...this.cotenant, [property]: newValue }
    }
  }
})

export default useCoTenantStore;