import client from "@/views/client";
const baseUrl = "/gl/bien";


export const goodApi = {
    addGood: (data) => client.post(`${baseUrl}/add`, data),
    getGood: (id) => client.get(`${baseUrl}/bien_info/${id}`),
    updateGood: (data) => client.patch(`${baseUrl}/update_bien`, data),
    // get tenants list with pagnination handeling
    getGoods: (limit, offset) => client.get(`${baseUrl}/list_bien/${limit}/${offset}`),
    deleteGood: (data) => client.delete(`${baseUrl}/delete_bien`, {data}),
    getTypesProperties:(limit, offset) => client.get(`admin/list_typ_immo/${limit}/${offset}`),
    getListEquipments:(id_type, limit, offset) => client.get(`admin/furniture/get_by_type_property/${id_type}/${limit}/${offset}`),
    addFurniture: (data) => client.post(`/gls/equipments/add`, data),
    getAllEquipments: (limit, offset) => client.get(`/gls/equipments/list/${limit}/${offset}`),
    getEquipmentById: (id_property, limit, offset) => client.get(`/gls/equipments/list_by_property/${id_property}/${limit}/${offset}`)
  }
  
  export default goodApi;