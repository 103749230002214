import FirstPage from '@/views/Syndics/CoOwners/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/syndics/coproprietaires"

const coOwnersRoutes = [
  {
    path: baseUrl,
    name: "coOwners",
    component: FirstPage,
  }
];

export default coOwnersRoutes
