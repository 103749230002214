<script>
import PathViewer from '../../containers/PathViewer/PathViewer.vue';
import SideBar1 from '../../containers/SideBar1/SideBar1.vue';

import { rentalManagement } from '@/utils/data/aside'

export default {
  components: {
    PathViewer,
    SideBar1,
  },
  props: {
    path: Array,
    datas: Object
  },
  data() {
    return {
      title: rentalManagement.title,
      asideLinks: rentalManagement.asideLinks
    }
  }
}
</script>

<template>
  <div class="rentalManagement page">
    <PathViewer :path="path" />
    <div class="body">
      <SideBar1 
        :title="title"
        :links="asideLinks"
        module="gestion-locative"
      />
      <slot> </slot>
    </div>
  </div>
</template>