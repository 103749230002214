<!-- Ce container est un box utiliser dans la page de choix de type de compte lors du processus de création de compte -->

<script>
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';

export default {
  components: {
    ButtonOne
  },
  props: {
    name: String,
    text: String,
    popUpTexts: Array,
    type: String,
  },
  emits: ['emitedPopUp'],
  methods: {
    setPopUp() {
      const pp = {
        value: true,
        title: this.name.toLowerCase(),
        texts: this.popUpTexts
      }

      this.$emit('emitedPopUp', pp)
    }
  }
}
</script>

<template>
  <div class="acc-type">
    <div class="acc-type--header">
      {{ name }}
    </div>

    <div class="acc-type--body">
      <p class="type-text">{{ text }}</p>
      <button @click="setPopUp">Détails ></button>
    </div>

    <div class="acc-type--footer">
      <router-link :to="'/signup/form?type='+type"><ButtonOne text="Inscrivez-vous" fullRounded /></router-link>
    </div>
  </div>
</template>

<style src="./AccTypeBox.css"></style>