<script>
import { mapActions, mapWritableState } from 'pinia';

import useAuthStore from './store'
import TextField1 from '@/components/TextField1/TextField1.vue'
import AuthHeader from '@/containers/Auth/AuthHeader/AuthHeader.vue'
import ButtonContent from '@/components/ButtonContent/ButtonContent.vue';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';

import { toast } from 'vue3-toastify'

export default {
  components: {
    TextField1,
    AuthHeader,
    ButtonContent,
    ErrorMessage
},
  data() {
    return {
      pageTitle: "Login",
    }
  },
  computed: {
    accountType() {
      return this.$route.query.type;
    },
    ...mapWritableState(useAuthStore , ['user', 'loginStatus', 'errorMessage']),
  },
  methods: {
    ...mapActions(useAuthStore, ['login']),
    handleChange(e) {
      this.user[e.target.name] = e.target.value
    },
    handleLogin() {
      this.login(toast)
    }
  }
}
</script>

<template>
  <div class="page auth-page">
    <div class="auth-box login-box">
      <AuthHeader title="Connexion" />
        <form @submit.prevent="handleLogin">
          <TextField1 label="Email" type="email" :onChange="handleChange" :value="user.email" name="email" placeholder="email" />
          <TextField1 label="Mot de passe" type="password" :onChange="handleChange" :value="user.password" name="password" placeholder="password" />
          <router-link to="/mot-de-passe-oublie" class="reset-password-link">mot de passe oublié ?</router-link>
          <ErrorMessage v-if="loginStatus.status === 'failure'" :message="errorMessage" />
          <button class="submit-btn" type="submit">
            <ButtonContent :loading="loginStatus.loading" :status="loginStatus.status" originalText="connexion" />
          </button>
        </form>

      <router-link to="/signup"> Pas encore de compte ? </router-link>
    </div>
  </div>
</template>

<style src="./Login.css"></style>
<style src="./Auth.css"></style>