<script>
export default {
  name: "ChimpCell",
  props: {
    cell: String
  }
}
</script>

<template>
  <div class="flex py-[5px] bg-primary_trans rounded-full items-center justify-center text-primary">
    {{ cell }}
  </div>
</template>