<!-- Formulaire recyclable, avec pour les options multi-étapes, validation, status de modification de données, et personnalisation d'étape -->
<script>
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
import rightArrow from '@/assets/icons/rightArrow1.svg'
import leftArrow from '@/assets/icons/leftArrow1.svg'

export default {
  name: "EditionForm",
  components: {
    ButtonOne
  },
  emits: ['step'],
  props: {
    steps: Array,
    save: Function,
    edit: Boolean,
    changed: Boolean,
    loading: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentStep: 0,
      assets: {
        rightArrow,
        leftArrow
      }
    }
  },
  methods: {
    next() {
      if (this.currentStep >= this.steps.length - 1) {
        this.save()
      } else {
        this.currentStep += 1;
      }
    },
    handleUpdate() {
      if (this.changed) {
        this.save()
      }
    }
  },
  watch:{
    currentStep(value){
      this.$emit('step', value)
    }
  }
}
</script>

<template>
  <div class="edit-form">
    <div class="flexed edit-form--wrapper">
      <nav v-if="steps.length > 1" class="box1 flexed">
        <div 
          v-for="({ name, label }, index) in steps"
          :key="`step- ${ index } : ${ name }`"
          class="edit-form--step-link"
          :class="{'edit-form--step-link__current': currentStep === index}"
        >
          <div></div>
          <button @click.prevent="currentStep = index"> {{ label }} </button>
        </div>
      </nav>

      <div class="box1 flexed edit-form--step-wrapper">
        <slot name="form">
        </slot>
      </div>
    </div>

    <div class="flexed edit-form--footer">
      <ButtonOne
        :leftIcon="assets.leftArrow"
        v-if="steps.length > 1 && currentStep !== 0" 
        text="Précédant"
        @click="currentStep -= 1"
      />

      <ButtonOne
        :rightIcon="assets.rightArrow"
        v-if="steps.length > 1 && currentStep !== steps.length-1" 
        text='Suivant'
        @click="next"
      />
      
      <ButtonOne 
        v-if="currentStep === steps.length - 1 && !edit" 
        text='Enregistrer'
        @click="save"
        isAsync
        :loading="loading"
        :status="status"
      />
      
      <ButtonOne 
        v-if="edit" 
        text="Valider les modifications"
        @click="save"
        :unClickable="!changed"
        isAsync
        :loading="loading"
        :status="status"
      />
    </div>
  </div>
</template>

<style src="./EditionForm.css"></style>