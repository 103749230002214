import { path as basePath } from "../RentalManagementHome/datas"

const  baseUrl = basePath[basePath.length-1].url+"/proprietaires"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "propriétaires",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Propriétaires"


function url(url){
  return baseUrl+url
}

export const items = [
  {
    text: "Gestion des propriétaires",
    buttons: [
      {
        label: "Créer un propriétaire",
        url: url("/creer"),
        empty: true
      },
      {
        label: "Solde de tout compte",
        url: url("/soldes-comptes"),
        empty: false
      }
    ]
  },
 
  {
    text: "Envoyez un mail simultané à plusieurs ou tous vos propriétaires",
    buttons: [
      {
        label: "Envoyer un mail groupé",
        url: url("/envoie-mail"),
        empty: true
      },
    ]
  }
]

export const tableDatas = {
  proprietaire:{
    title: 'Liste des Propriétaires',
    cols: [
      'N° Propriétaire', 'Nom', 'Prénom', 'Email', 'Téléphone'
    ],
    rows: [
     
    ],
  },


  actions: [
    {
      type: 'delete',
      label: "Supprimer"
    },
    {
      type: 'update',
      label: "Modifier"
    }
  ]
}