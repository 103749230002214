import OwnersList from '@/views/RentalManagement/OwnersList/OwnersList.vue'
import NewOwner from "@/views/RentalManagement/OwnersList/New/NewOwner.vue"
import OwnerView from '@/views/RentalManagement/OwnersList/OwnerView/OwnerView.vue'


// url de base des pages relatives aux propriétaires
const baseUrl = "/gestion-locative/proprietaires"

const ownersRoutes = [
  {
    path: baseUrl,
    name: "ownersList",
    component: OwnersList,
  },
  {
    path: baseUrl+"/creer",
    name: "newOwner",
    component: NewOwner
  },
 
  {
    path: baseUrl+"/:ownerId",
    name: "owner",
    component: OwnerView
  },
 
 
];

export default ownersRoutes
