<!-- Sidebar principal interne aux modules -->
<script>
  import bar from '@/assets/icons/bar_dashed1.svg'
  import vector from '@/assets/vectors/sideBar1.svg'
  import stockIcon from '@/assets/icons/folder3.svg'
  import level from '@/assets/vectors/level.svg'
  import up from '@/assets/icons/upArrow1.svg'
  import down from '@/assets/icons/downArrow1.svg'
  import { VISITOR_URL } from '@/utils/constants'

  export default {
    props: {
      title: String,
      links: Array,
      buttons: Array,
      pub: Object,
      module: String
    },
    data(){
      return {
        bar,
        vector,
        stockIcon,
        level,
        up,
        down,
        VISITOR_URL
      }
    }
  }
</script>

<template>
  <aside class="aside1 aside">
    <h1 class="aside--title">{{ title }}</h1>
    <div class="aside--container">
      <nav class="aside--links">
        <router-link
          v-for="(link, index) in links"
          :key="`aside-link-${index}`"
          class="aside-link"
          :to="link.url"
        >
          <img v-if="link.icon" :src="link.icon" :alt="link.label">
          {{ link.label }}
        </router-link>
      </nav>
      <div class="aside--buttons" v-if="buttons">
        <button 
          v-for="(button, index) in buttons"
          :key="`aside-button-${index}`"
          class="aside-button"
        >
          <img v-if="button.icon" :src="button.icon" alt="">
          {{ button.label }}
        </button>
      </div>
      <img class="aside-separator" v-if="buttons" :src="bar" alt="separator" />
      <div class="aside--redirections">
        <router-link class="aside-redir--link" :to="`/settings/${module || ''}`">Paramètres</router-link>
        <a class="aside-redir--link" target="_blank" :href="`${VISITOR_URL}#faq`">FAQ</a>
      </div>
      <img class="aside-separator" :src="bar" alt="separator" />
      <div class="aside--stocking">
        <div class="stocking-container">
          <img :src="vector" alt="stockage">
          <div class="stocking-content">
            <router-link to="/ged/stocking" class="stockage">
              <img :src="stockIcon" alt="">
              stockage
            </router-link>
            <router-link to="/upgrade" class="upgrade">
              upgrade
            </router-link>
          </div>
        </div>
        <img :src="level" alt="">
      </div>
    </div>
    <div class="aside--pub" v-if="pub">
      <div class="pub-content">
        <p>{{ pub.title }}</p>
        <span>{{ pub.text }}</span>
        <a :href="pub.link.url">{{ pub.link.label }}</a>
      </div>
      <nav class="pub-nav">
        <img :src="up" alt="up" />
        <img :src="down" alt="down" />
      </nav>
    </div>
  </aside>
</template>

<style src="./SideBar1.css"></style>