import FirstPage from '@/views/GED/Basket/FirstPage.vue'

// url de base des pages relatives à la corbeille
const baseUrl = "/ged/corbeille"

const basketRoutes = [
  {
    path: baseUrl,
    name: "gedBasket",
    component: FirstPage,
  }
];

export default basketRoutes
