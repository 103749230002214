import profile from '@/assets/photos/profile.svg'
import { path as basePath } from '../ExtranetHome/datas'

export const path = [
  ...basePath,
  {
    label: "messages",
    url: "/extranet/conversations"
  }
]

// données de discussions
export const conversations = [
  {
    id: 1,
    user: {
      picture: profile,
      name: "Stephan Plaza 1",
      module: "Gestion Locative",
      role: "Locataire",
    },
    messages: [
      {
        id: 1,
        author: 2,
        content: "Hello",
      },
      {
        id: 2,
        author: 2,
        content: "These Groupings Allow Philosophers To Focus On A Set Of Similar Topics And Interact With Other Thinkers Who Are Interested In The Same Questions.",
      },
      {
        id: 3,
        author: 1,
        content: "Hi",
      },
      {
        id: 4,
        author: 2,
        content: "What Do You Think About This ?",
      },
      {
        id: 5,
        author: 1,
        content: "Basically This Looks Good",
      },
    ]
  },
  {
    id: 2,
    user: {
      picture: profile,
      name: "Stephan Plaza 2",
      module: "copropriete",
    },
    messages: [
      {
        id: 1,
        author: 2,
        content: "Holé",
      },
      {
        id: 2,
        author: 2,
        content: "These Groupings Allow Philosophers To Focus On A Set Of Similar Topics And Interact With Other Thinkers Who Are Interested In The Same Questions.",
      },
      {
        id: 3,
        author: 1,
        content: "Hi",
      },
      {
        id: 4,
        author: 2,
        content: "What Do You Think About This?",
      },
      {
        id: 5,
        author: 1,
        content: "Basically This Looks Good",
      },
    ]
  },
  {
    id: 3,
    user: {
      picture: profile,
      name: "Stephan Plaza 3",
      module: "Gestion Locative",
      role: "Proprietaire",
    },
    messages: [
      {
        id: 1,
        author: 2,
        content: "aloa",
      },
      {
        id: 2,
        author: 2,
        content: "These Groupings Allow Philosophers To Focus On A Set Of Similar Topics And Interact With Other Thinkers Who Are Interested In The Same Questions.",
      },
      {
        id: 3,
        author: 1,
        content: "Hi",
      },
      {
        id: 4,
        author: 2,
        content: "What Do You Think About This?",
      },
      {
        id: 5,
        author: 1,
        content: "Basically This Looks Good",
      },
    ]
  },
  {
    id: 4,
    user: {
      picture: profile,
      name: "Stephan Plaza 4",
      module: "copropriete",
    },
    messages: [
      {
        id: 1,
        author: 2,
        content: "bonjourno !",
      },
      {
        id: 2,
        author: 2,
        content: "These Groupings Allow Philosophers To Focus On A Set Of Similar Topics And Interact With Other Thinkers Who Are Interested In The Same Questions.",
      },
      {
        id: 3,
        author: 1,
        content: "Hi",
      },
      {
        id: 4,
        author: 2,
        content: "What Do You Think About This?",
      },
      {
        id: 5,
        author: 1,
        content: "Basically This Looks Good",
      },
    ]
  },
  {
    id: 5,
    user: {
      picture: profile,
      name: "Stephan Plaza 5",
      module: "copropriete",
    },
    messages: [
      {
        id: 1,
        author: 2,
        content: "bonjour !",
      },
      {
        id: 2,
        author: 2,
        content: "These Groupings Allow Philosophers To Focus On A Set Of Similar Topics And Interact With Other Thinkers Who Are Interested In The Same Questions.",
      },
      {
        id: 3,
        author: 1,
        content: "Hi",
      },
      {
        id: 4,
        author: 2,
        content: "What Do You Think About This?",
      },
      {
        id: 5,
        author: 1,
        content: "Basically This Looks Good",
      },
    ]
  },
  {
    id: 6,
    user: {
      picture: profile,
      name: "Stephan Plaza 6",
      module: "contact",
    },
    messages: [
      {
        id: 1,
        author: 2,
        content: "yo !",
      },
      {
        id: 2,
        author: 2,
        content: "These Groupings Allow Philosophers To Focus On A Set Of Similar Topics And Interact With Other Thinkers Who Are Interested In The Same Questions.",
      },
      {
        id: 3,
        author: 1,
        content: "Hi",
      },
      {
        id: 4,
        author: 2,
        content: "What Do You Think About This?",
      },
      {
        id: 5,
        author: 1,
        content: "Basically This Looks Good",
      },
    ]
  }
]