<script>
import ItemBox1 from '@/components/ItemBox1/ItemBox1.vue';

import separator from '@/assets/vectors/separator.svg'
import plus from '@/assets/vectors/plus.svg'
  
export default {
  components: {
    ItemBox1
  },
  props: {
    items: Array
  },
  data() {
    return {
      assets: {
        separator,
        plus
      }
    }
  }
}
</script>

<template>
  <div class="row-centered rm-header">
    <div class="row-centered">
      <router-link 
        :to="url" 
        v-for="({ icon, title, text, url }, index) in items"
        :key="`item-${index}`"
      >
        <ItemBox1
          :icon="icon"
          :title="title"
          :text="text"
        />
      </router-link>
    </div>

    <div class="row-centered">
      <img :src="assets.separator" alt="">
      <div class="btn box1 add-shortcut">
        <img :src="assets.plus" alt="">
        <span>Ajouter un raccourcis</span>
      </div>
    </div>
  </div>
</template>

<style src="./RMHeader.css"></style>