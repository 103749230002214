<script>
  import GED from '../GED.vue'
  import HeaderTwo from '@/containers/GED/Header2/HeaderTwo.vue';
  import FileBox from '@/components/FileBox/FileBox.vue';
  import { header, files, path, pageTitle } from './datas';

  export default {
    data(){
      return {
        header: {
          title: header.title,
          icon: header.icon,
          text: header.text,
        },
        // informations relatives à la permière section "dossier" du corps de la page
        files: {
          icon: files.icon,
          items: files.items
        },
        path,
        pageTitle
      }
    },
    components: {
      GED,
      HeaderTwo,
      FileBox,
    },
  }
</script>

<template>
  <GED :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <HeaderTwo 
        :icon="header.icon"
        :title="header.title"
        :text="header.text"
      />
      <div class="container-body">
        <!-- Première section : section des dossiers -->
        <section class="container-section">
          <span class="title container-section--title">
            {{ files.title }}
          </span>
          <div class="list">
            <FileBox 
              v-for="({ title, date, icon, module, goodId }, index) in files.items"
              :key="'file-item-'+index"
              :icon = "icon"
              :title = "title"
              :date = "date"
              :module = "module"
              :goodId = "goodId"
            />
          </div>
        </section>
        <section>
          <span class="title container-section--title">
            Tous les dossiers
          </span>
          <table class="box1">
            tableau des dossiers
          </table>
        </section>
      </div>
    </div>
  </GED>
</template>

<style src="./FirstPage.css"></style>