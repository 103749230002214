
import meterApi from '../../api/meters';

import useMeterStore from '../store';

<script lang="js">
import WorksView from '../../WorksView.vue'

import { mapActions, mapWritableState } from 'pinia'

import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import { path, pageTitle, formSteps} from './datas'
import TextField1 from '@/components/TextField1/TextField1.vue';

import useMeterStore from '../store'
import usePropertyStore from '../../../RentalManagement/Goods/store'

export default {
    components: {
        WorksView,
        ButtonOne,
        EditionForm,
        TextField1
    },
    data() {
        return {
            path,
            pageTitle,
            steps:formSteps,
            currentStep:0,

        }
    },
    computed: {
        ...mapWritableState(useMeterStore, ['meter', 'loading', 'status', 'error']),
        ...mapWritableState(usePropertyStore, ['goods']),

    },
    methods: {
        ...mapActions(useMeterStore, ['addMeter']),
        ...mapActions(usePropertyStore, ['getProperties']),
        findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    },
    reload() {
      location.reload()
    },
    },
    mounted() {
        this.getProperties(0);
        console.log(this.goods);
    },
    watch: {
        offset(value) {
            this.getProperties(value);
        }
    }
} 
</script>
<template>
        <WorksView :path="path">
        <div class="container">
            <h1 class="container-title"> {{ pageTitle }}</h1>
            <div class="container-body">
                <EditionForm :save="addMeter" :steps="steps" @step="(step)" :loading="loading" :status="status">
                    <template #form>
                        <section class="form-part" v-if="currentStep == findIndex('general')">
                        <div class="flex flex-row items-center w-full">
                            <div class="flex flex-col">
                                <div class="flex flex-row mb-2"><label>Bien <span>: </span></label>
                                    <p class="required-tag text-red ms-1">*</p>
                                </div>
                                <select v-model="meter.id_bien_info" class="form-select rounded-md">
                                    <option disabled value="">Selectionnez</option>
                                    <option v-for="(item, i) in goods.list" :key="i" :value="item[0]">
                                        {{ item[1] }}</option>
                                </select>
                               
                            </div>
                            <router-link class="path-link mt-8 ms-10" :to="{ path: '/gestion-locative/biens/creer' }">
                                    <ButtonOne text="Nouveau" />
                            </router-link>
                        </div>
                        <div class="flex flex-row items-center  w-full">
                            <TextField1 small label="Intitulé Compteur" placeholder="Intitulé Compteur"
                                :value="meter.nom_compteur"
                                @update:value="newValue => meter.nom_compteur = newValue"/>
                                <TextField1 class="ms-4" small label="Localisation Compteur" placeholder="Localisation Compteur"
                                :value="meter.localisation"
                                @update:value="newValue => meter.localisation = newValue"/>

                        </div>
                        <div class="flex flex-row items-center  w-full">
                            <TextField1 small label="Numéro PDL" placeholder="Numéro PDL"
                                :value="meter.numero_pdl"
                                @update:value="newValue => meter.numero_pdl = newValue"/>
                                <TextField1 class="ms-4" small label="Numéro PCE" placeholder="Numéro PCE"
                                :value="meter.numero_pce"
                                @update:value="newValue => meter.numero_pce = newValue"/>

                        </div>
                        </section>
                        
                    </template>

                </EditionForm>
            </div>
    </div>
</WorksView>
</template>