import { path as basePath } from "../datas";

// path

export const path = [
  ...basePath,
  {
    label: "Bail",
    url: basePath[basePath.length - 1].url + "/",
  },
];

// page title value
export const pageTitle = "Bail :";

export const formSteps = [
  {
    name: "general",
    label: "Informations",
  },
 
];

