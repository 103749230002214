import { path as basePath } from "../RentalManagementHome/datas"

const baseUrl = basePath[basePath.length-1].url+"/co-locataires"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "co-locataires",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Co-locataires"

function url(url){
  return baseUrl+url
}

export const items = [
  {
    text: "Gestion des co-locataires",
    buttons: [
      {
        label: "Créer un co-locataire",
        url: url("/creer"),
        empty: false
      },
      {
        label: "Solde de tout compte",
        url: url("/solde-tout-compte"),
        empty: true
      }
    ]
  },
  // {
  //   text: "Dans le cas où vous avez créé des copropriétés, vous pouvez enregistrer des copopriétaires",
  //   buttons: [
  //     {
  //       label: "Créer un colocataire",
  //       url: url("/creer?coloc=true"),
  //       empty: false
  //     },
  //   ]
  // },
  {
    text: "Envoyez un mail simultané à plusieurs ou tous vos locataires",
    buttons: [
      {
        label: "Envoyez un mail groupé",
        url: url("/envoie-mail"),
        empty: false
      },
    ]
  },
  // {
  //   text: "Enregistrez le départ d'un locataire et soldez son compte",
  //   buttons: [
  //     {
  //       label: "Solde de compte locataire",
  //       url: url("/solde-compte"),
  //       empty: false
  //     }
  //   ]
  // },
  {
    text: "Obtenez le solde du compte de tous vos co-locataires",
    buttons: [
      {
        label: "Solde de compte co-locataire",
        url: url("/solde-compte"),
        empty: false
      }
    ]
  },
//   {
//     text: "Un propriétaire a vendu un bien et vous souhaitez attribuer le bien et le bail au nouveau propriétaire",
//     buttons: [
//       {
//         label: "Remboursement",
//         url: url("/solde-compte"),
//         empty: true
//       }
//     ]
//   }
]

export const tableDatas = {
  title: 'Liste des Co-locataires',
  cols: [
    'N° Co-locataire', 'Nom', 'Prénom', 'Email', 'Téléphone'
  ],
  rows: [
 ],

}