<script>
  /* 
    Container used to wrap home page and subpages with common global structure
  */
  import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';

  export default {
    props: {
      title: String,
      buttonText: String,
      buttonUrl: String
    },
    components: {
      ButtonOne
    }
  }
</script>

<template>
  <div class="box1 content-box">
    <div class="flexed content-box--top">
      <h2 class="container-title">{{ title }}</h2>
      <router-link :to="buttonUrl" >
        <ButtonOne :text="buttonText" />
      </router-link>
    </div>

    <slot>
    </slot>
  </div>
</template>

<style src="./PageContentBox.css"></style>