<!-- Première forme de header rencontrée dans l'extranet -->
<script>
  export default {
    props: {
      greatings: Object, 
      resumes: Array
    }
  }
</script>

<template>
  <div class="list extranet-header">
    <div class="list--item2 greatings">
      <h2>{{ greatings.title }}</h2>
      <p>{{ greatings.text }}</p>
    </div>
    <div 
      class="list--item2 resume-item"
      :class="'resume-item__'+color"
      v-for="({ number, text, vector, color }, index) in resumes"
      :key="'resume'+index"
    >
      <img :src="vector" alt="vector icon" />
      <div>
        <h2>{{ number }}</h2>
        <p>{{ text }}</p>
      </div>
    </div>
  </div>
</template>

<style src="./HeaderOne.css"></style>