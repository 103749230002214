import { path as basePath } from '../SyndicsHome/datas'

// path

export const path = [
  ...basePath,
  {
    label: "archives",
    url: basePath[basePath.length-1].url+"/archives"
  }
]

// page title value
export const pageTitle = "Archives"
