<script>
  import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
  import InfosText from '@/components/InfosText/InfosText.vue';
  import TextField1 from '@/components/TextField1/TextField1.vue';

  import { detailsSectionButtons } from '../datas'
  export default {
    components: {
      ButtonOne,
      InfosText,
      TextField1
    },
    data(){
      return{
        buttons: detailsSectionButtons,
        kitchenIsFurnished: false
      }
    }
  }
</script>

<template>
  <div class="details-section">
    <section class="details-section--general">
      <div class="section-box">
        <div class="flexed">
          <ButtonOne v-for="button in buttons.status.options" :text="button.label" :key="button.key" :class="{ 'button__unactive': buttons.status.value !== button.key }" @click="buttons.status.value = button.key" fullRounded />
        </div>
        <InfosText text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id purus nec in molestie tortor quisque odio. Tellus enim, feugiat et consectetur orci. Morbi ut sit et tempor." />
        <div class="other-infos">
          <h2>Autre information</h2>
          <div>
            <TextField1 label="Standing" small />
            <TextField1 label="Mode de chauffage" small />
            <TextField1 label="Année de construction" small />
            <TextField1 label="Superficie" small />
            <TextField1 label="Surface séjour" small />
            <TextField1 label="Surface loi Carrez" small />
            <TextField1 label="Superifcie loi Boutin" small />
          </div>
        </div>
      </div>
    </section>

    <section class="details-section--specific">
      <div class="section-box">
        <h2>Cuisine</h2>
        <div class="flexed">
          <ButtonOne 
            :class="{ 'button__unactive': !kitchenIsFurnished }" 
            text="meublée" fullRounded
            @click="kitchenIsFurnished = true"
          />
          <ButtonOne 
            :class="{ 'button__unactive': kitchenIsFurnished }" 
            text="pas meublée" fullRounded
            @click="kitchenIsFurnished = false"
          />
        </div>
        <div class="flexed" v-if="kitchenIsFurnished">
          <ButtonOne v-for="button in buttons.kitchen" :text="button.label" :key="button.key" :class="{ 'button__unactive': button.value === false }" @click="button.value = !button.value" fullRounded />
        </div>
      </div>

      <div class="section-box">
        <h2>Extérieur / jardin</h2>
        <div>
          <div>
            <TextField1 label="Superficie terrain" type="number" small />
            <TextField1 label="Superficie jardin" type="number" small />
          </div>
          <div class="row-centered">
            <ButtonOne 
              v-for="button in buttons.garden" :text="button.label" 
              :key="button.label" 
              :class="{ 'button__unactive': button.value === false }" 
              @click="button.value = !button.value" 
              fullRounded
            />
          </div>
        </div>
      </div>

      <div class="section-box">
        <h2>Équipements</h2>
        <div class="flexed">
          <ButtonOne 
            v-for="button in buttons.equipments" 
            :text="button.label" 
            :key="button.label" 
            :class="{ 'button__unactive': button.value === false }" 
            @click="button.value = !button.value" 
            fullRounded
          />
        </div>
      </div>

      <div class="section-box">
        <h2>Commodités</h2>
        <div class="flexed">
          <ButtonOne 
            v-for="button in buttons.closeTo" 
            :text="button.label" 
            :key="button.label" 
            :class="{ 'button__unactive': button.value === false }" 
            @click="button.value = !button.value" 
            fullRounded
          />
        </div>
      </div>
    </section>
  </div>
</template>

<style>
  .button__unactive{
    background: rgba(196, 196, 196, 0.4);
    color: #333;
  }

  .details-section .details-section--general .section-box{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .details-section .details-section--general .other-infos > div{
    display: flex;
    flex-wrap: wrap;
    gap:14px;
  }

  .details-section .details-section--specific{
    display: flex;
    flex-direction: column;
    align-items: unset;
  }

  .details-section .details-section--specific .section-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    min-height: 230px;
  }

  .details-section .details-section--specific .section-box > div{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-items: flex-start;
  }
</style>