import ErrorPage404 from '@/views/404/ErrorPage404.vue'
import SystemError from '@/views/SystemError/SystemError.vue'

const errorsRoutes = [
  {
    path: "/system-error",
    name: "SystemError",
    component: SystemError,
  },
  {
    path: "/:pathMatch(.*)",
    name: "error-404",
    component: ErrorPage404,
  },
];

export default errorsRoutes;