import client from "@/views/client"

const baseUrl = "/gl/lease_agreement"

export const rentApi = {
    
    // appel de loyer
    callLease: (data) => client.post(`${baseUrl}/lease_call/add`, data),

    // get list of rent call
    getAllCall:(limit, offset) => client.get(`${baseUrl}/list_lease_call/${limit}/${offset}`),

    // get list of rent call by id
    getAllCallById: (id_lease, limit, offset) => client.get(`${baseUrl}/list_lease_call/${id_lease}/${limit}/${offset}`),
    
};

export default rentApi;