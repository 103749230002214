import { path as basePath } from "@/views/Dashboard/data"

// basic url for rental management pages
const baseUrl = "/account"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "Compte",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Votre compte"

// function allowing to add automatically basic url to any url
// function url(url){
//   return baseUrl+url
// }

// texts
export const cw = {
  accountInfos: {
    title: "Informations de compte",
    form: {
      avatar: {
        label: "Photo de profil",
        btn: "Importer une photo",
        description: "Min 200x200px, PNG ou JPEG"
      },
      profileType: {
        label: "Votre profile",
        description: "Sélectionner le type de profil avec lequel vous allez exercer sur la plateforme"
      },
      personal: {
        title: "Vos coordonnées",
        name: {
          label: "Votre nom",
          placeholder: "Entrez votre nom"
        },
        firstName: {
          label: "Votre prénom",
          placeholder: "Entrez votre prénom"
        },
        town: {
          label: "Ville",
          placeholder: "Entrez votre ville"
        },
        country: {
          label: "Pays",
          placeholder: "Entrez votre pays"
        },
        zipCode: {
          label: "Code postal",
          placeholder: "Entrez votre code postal"
        },
        address: {
          label: "Adresse",
          placeholder: "Entrez votre adresse"
        },
        additionalAddress: {
          label: "Complément d'adresse",
          placeholder: "Entrez votre complément d'adresse"
        },
        telephone: {
          label: "Téléphone",
          placeholder: "Entrez votre téléphone"
        },
        mobile: {
          label: "Portable",
          placeholder: "Entrez votre numéro de portable"
        }
      },
      pro: {
        proTitle: "Informations Professionneles",
        CompanyTitle: "Informations d'Entreprise",
        proId: {
          label: "Votre Id Professionnel",
          placeholder: "Entrez votre id professionnel"
        },
        companyName: {
          label: "Nom de votre Entreprise",
          placeholder: "Entrez le nom de votre entreprise"
        },
        tva: {
          label: "Numéro de TVA",
          placeholder: "Entrez Votre numéro de téléphone"
        },
        siretSiren: {
          label: "Siret / Siren",
          placeholder: "Entrez votre numéro de siret/siren"
        },
        companyType: {
          label: "Type d'entreprise",
          placeholder: "Renseigner le type de votre entreprise"
        }
      }
    }
  },
  workInfos:{
    
    proTitle: "Informations Professionnelles",
    compTitle: "Informations D'entreprise",
    form: {
      email_pro: {
        label: "Votre email professionnel",
        placeholder: "Veuillez entrer votre email pro"
      },
      nom_entreprise: {
        label: "Nom de Votre Entreprise",
        placeholder: "Veuillez Entrer le nom de votre entreprise"
      },
      siret_siren: {
        label: "Numéro Siren ou Siret",
        placeholder: "Entrez un Siret / Siren valide"
      },
      id_professionnel: {
        label: "Id Pro",
        placeholder: "Veuillez entrer votre Id professionnel"
      },
      type_entreprise: {
        label: "Votre structure d'entreprise",
        placeholder: "Votre structure d'entreprise"
      },
      tva: {
        label: "Votre email professionnel",
        placeholder: "Veuillez entrer votre email pro"
      },
    }
  },
  changePwd:{
    title: "Changer votre mot de passe",
    form:{
      oldPassword: {
        label: "Ancien mot de passe",
        placeholder: "Entrez votre mot de passe actuel"
      },
      newPassword:{
        label:"Nouveau mot de passe",
        placeholder:"Entrez votre nouveau mot de passe"
      },
      confirmPassword:{
        label:"Confirmer mot de passe",
        placeholder:"Entrez votre nouveau mot de passe"
      }
    }
  },
  modulesInfos: {
    title: "Modules",
    buyBtn: {
      label: "Passer à l'achat",
      url: "/upgrade"
    },
    table: {
      cols: ["Module", {type: "status", label: "Status"}, {type: "chimp", preText: "Update :", label: "Mise à jour"}, "Prix mensuel"]
    }
  }
}