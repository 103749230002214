<script>
  export default {
    props: {
      title: String,
      subTitle: String,
      text: String,
      date: String,
      endDate: String, 
      numero: Number,
      total: Number
    }
  }
</script>

<template>
  <div class="box1 board-item">
    <div v-if="numero" class="board-item--numero">
      n <span>{{ numero }}</span>
    </div>
    <div class="board-item--content">
      <p class="board-item-head">
        <span class="item-head--title" v-if="title">{{ title }}</span>
        <span class="item-head--date" v-if="date">{{ date }}</span>
      </p>
      <p class="item--content--sub-title" v-if="subTitle">{{ subTitle }}</p>
      <p class="item--content--text" v-if="text">{{ text }}</p>
      <p class="item--content--end-date" v-if="endDate">{{ endDate }}</p>
    </div>
    <p v-if="total" class="board-item--total">{{ total }}</p>
  </div>
</template>

<style src="./BoardItem.css"></style>