import { path as basePath } from "../RentalManagementHome/datas"

const baseUrl = basePath[basePath.length-1].url+"/encaissements"


// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "encaissements",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Encaissements"

function url(url){
  return baseUrl+url
}

export const items = [
  {
    text: "Saisissez un encaissement supportée par le locataire / propriétaire",
    buttons: [
      {
        label: "Saisie d'encaissement",
        url: url("/ajouter"),
        empty: false
      },
    ]
  },
  {
    text: "Saisissez des encaissements non liées à votre activité de gestion locative (ex: frais bancaires...)",
    buttons: [
      {
        label: "encaissement propriétaires",
        url: url("/ajouter?type=extra"),
        empty: true
      },
    ]
  },
  {
    text: "Saisissez le relevé de charges reçu de la copropriété où est présente votre location",
    buttons: [
      {
        label: "Relevé de charges copropriété",
        url: url("/ajouter/releve-charge"),
        empty: false
      },
    ]
  },
  {
    text: "Gagnez du temps en effectuant une copie d'un modèle de encaissement pré-enregistré",
    buttons: [
      {
        label: "Copie de encaissements",
        url: url("/brouillons"),
        empty: false
      }
    ]
  },
]