<script>
import ButtonOne from '../ButtonOne/ButtonOne.vue';

  export default {
    props: {
        text: String,
        buttons: String,
    },
    components: { ButtonOne }
}
</script>

<template>
  <div class="box1 item-box2">
    <p>{{ text }}</p>
    <div
      class="item-box2--buttons"
    >
      <router-link
        v-for="({ label, url, empty }, index) in buttons"
        :key="`button.label-${index}`"
        :to="url"
        class="item-box2--button"
        :class="{ empty: empty }"
      >
        <ButtonOne :text="label" />
      </router-link>
    </div>
  </div>
</template>

<style src="./ItemBox2.css"></style>