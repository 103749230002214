import ComponentsView from '@/views/ComponentsView/ComponentsView.vue'

const componentsRoutes = [
  {
    path: "/components",
    name: "Components",
    component: ComponentsView,
  },
];

export default componentsRoutes;