import { path as basePath } from "../datas";

// path

export const path = [
  ...basePath,
  {
    label: "Bien",
    url: basePath[basePath.length - 1].url + "/",
  },
];

// page title value
export const pageTitle = "Bien :";

export const items = [
  { label: "loyer", value: "loyer" },
  { label: "propriété", value: "propriete" }
]

export const pieces = [
  {label:"0", value:"0"},
  {label:"1", value:"1"},
  {label:"2", value:"2"},
  {label:"3", value:"3"},
  {label:"4", value:"4"},
  {label:"5", value:"5"},
  {label:"6", value:"6"},
  {label:"7", value:"7"},
  {label:"8", value:"8"},
  {label:"9", value:"9"},
  {label:"10", value:"10"}
]
export const loyers = [
  {label:"journalier", value:"journalier"},
  {label:"hebdomadaire", value:"hebdomadaire"},
  {label:"mensuel", value:"mensuel"},
 
]
// form steps
export const formSteps = [
{
  name: "general",
  label: "Coordonnées",
},
{
  name: "description",
  label: "Description",
},

{
  name: "diagnostic",
  label: "Diagnostics",
},
{
  name: "photos",
  label: "Photos",
},
{
  name: "fiscales",
  label: "Infos Fiscales",
},
{
  name: "copropriété",
  label: "Copropriété",
},
{
  name: "equipements",
  label: "Equipements",
},

];
