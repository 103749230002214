import { path as basePath } from "../RentalManagementHome/datas"

const baseUrl = basePath[basePath.length-1].url+"/depenses"


// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "dépenses",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Dépenses"

function url(url){
  return baseUrl+url
}

export const items = [
  {
    text: "Saisissez une dépense supportée par le locataire / propriétaire",
    buttons: [
      {
        label: "Saisie de dépenses",
        url: url("/ajouter"),
        empty: false
      },
    ]
  },
  {
    text: "Saisissez des dépenses non liées à votre activité de gestion locative (ex: frais bancaires...)",
    buttons: [
      {
        label: "Dépense propriétaires",
        url: url("/ajouter?type=extra"),
        empty: true
      },
    ]
  },
  {
    text: "Saisissez le relevé de charges reçu de la copropriété où est présente votre location",
    buttons: [
      {
        label: "Relevé de charges copropriété",
        url: url("/ajouter/releve-charge"),
        empty: false
      },
    ]
  },
  {
    text: "Gagnez du temps en effectuant une copie d'un modèle de dépense pré-enregistré",
    buttons: [
      {
        label: "Copie de dépenses",
        url: url("/brouillons"),
        empty: false
      }
    ]
  },
]