import ResetPW from '@/views/Auth/ResetPW/ResetPW.vue'
import NewPassword from '@/views/Auth/ResetPW/NewPassword.vue'

const baseUrl = "/mot-de-passe-oublie"

const resetPasswordRoutes = [
  {
    path: baseUrl,
    name: "resetPassword",
    component: ResetPW,
  },
  {
    path: baseUrl + "/nouveau",
    name: "newPassword",
    component: NewPassword
  }
];

export default resetPasswordRoutes;