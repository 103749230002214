<script>
export default {
  data() {
    return {
      pageTitle: "Third Part Login"
    }
  }
}
</script>

<template>
  <div>
    {{ pageTitle }}
  </div>
</template>