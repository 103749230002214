<script>
  import RentalManagement from '../RentalManagement.vue'
  import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'
  import { items, path, pageTitle, tableDatas } from './datas.js'
  import TableOne from '@/containers/TableOne/TableOne.vue'

  import { initialList } from '@/utils/helpers'
  import useTenantStore from './store'
import { mapActions, mapWritableState } from 'pinia'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
 
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

  export default {
    components: {
    RentalManagement,
    Header2,
    TableOne,
    ErrorMessage,
    AnimatedLoader,
    ButtonOne
},
    data(){
      return {
        items,
      path,
      pageTitle,
      datas: tableDatas,
      offset: 0,
      initialList
      }
    },
    computed: {
    ...mapWritableState(useTenantStore, ['tenants', 'getTenantsProcess'])
  },
  mounted() {
    this.getRentRequestList(0);
   
  },
  methods: {
    ...mapActions(useTenantStore, ['getRentRequestList'])
  },
  watch: {
    offset(value) {
      this.getRentRequestList(value);
     
    }
  }
  }
</script>

<template>
  <RentalManagement :path="path">
    <div class="container">
      <h1 class="container-title">{{ pageTitle }}</h1>
      <Header2 :items="items" />
      <div class="container-body">
        <AnimatedLoader dark v-if="getTenantsProcess.loading" />
        <div v-else-if="getTenantsProcess.status === 'failure'" class="flex flex-col items-center gap-4">
          <ErrorMessage :message="getTenantsProcess.error" />
          <ButtonOne isAsync @click="getRentRequestList(offset)" text="Actualiser" :loading="getTenantsProcess.loading" :status="getTenantsProcess.status" />
        </div>
        <TableOne
          v-else
          :title="datas.title"
          :cols="datas.cols"
          :rows="tenants.list"
          :total="tenants.total"
          :actions="initialList.actions"
          baseUrl="/gestion-locative/locataires"
         
          @emitedOffset="(value) => offset = value"
        />
      </div>
    </div>
  </RentalManagement>
</template>

<style src="./FirstPage.css"></style>