import FirstPage from '@/views/RentalManagement/Expenses/FirstPage.vue'

// url de base des pages relatives aux dépenses
const baseUrl = "/gestion-locative/depenses"

const expensessRoutes = [
  {
    path: baseUrl,
    name: "expenses",
    component: FirstPage,
  }
];

export default expensessRoutes
