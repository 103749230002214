import FirstPage from '@/views/Account/CertifiedAccountant/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/account/finances"

const financesRoutes = [
  {
    path: baseUrl,
    name: "accountFinance",
    component: FirstPage,
  }
];

export default financesRoutes
