<script>
  import TextField1 from '@/components/TextField1/TextField1.vue';

  export default {
    components: {
      TextField1
    },
    props: {
      number: String,
      owners: Array,
      beginDate: Date,
      endDate: Date,
      address: String,
      observation: Text,
      onChange: Function,
    },
    data(){
      return{
        newOwner : ""
      }
    }
  }
</script>

<template>
  <div class="main-infos">
    <TextField1 
      :value="number"
      :onChange="(e) => onChange(e)"
      type="text"
      label="N de mandat"
      name="number" 
      placeholder="Projet"
      id="mandateNumber"
      class="main-infos--number"
    />
    <div class="flexed main-infos--sec">
      <div class="main-infos--owner">
        <TextField1 
          :value="newOwner"
          :onChange="(e) => onChange(e)"
          type="text"
          label="propriétaire(s)"
          name="owner" 
          placeholder="Liste des propriétaires"
          id="owner"
        />
        <TextField1 
          :value="address"
          :onChange="(e) => onChange(e)"
          type="text"
          label="adresses"
          name="address" 
          id="address"
          placeholder="addresse"
          textarea
        />
      </div>
      <div class="main-infos--mandat">
        <TextField1 
          :value="beginDate"
          :onChange="(e) => onChange(e)"
          type="date"
          label="Date de début"
          name="beginDate" 
          id="begin-date"
        />
        <TextField1 
          :value="endDate"
          :onChange="(e) => onChange(e)"
          type="date"
          label="Date de fin"
          name="endDate" 
          id="end-date"
        />
        <TextField1 
          :value="observation"
          :onChange="(e) => onChange(e)"
          type="text"
          label="Observations"
          name="observation" 
          id="observation"
          textarea
        />
      </div>
    </div>
  </div>
</template>

<style src="./style.css">
  
</style>