<script>
import PathViewer from '@/containers/PathViewer/PathViewer.vue';
import SideBar1 from '@/containers/SideBar1/SideBar1.vue';

export default {
    name: "AccountLayer",
    props: {
        path: Array,
        title: String,
        pageTitle: String,
        asideLinks: Array,
        moduleName: String,
        moduleClass: String
    },
    components: { PathViewer, SideBar1 }
}
</script>

<template>
  <div class="page" :class="moduleClass">
    <PathViewer :path="path" />
    <div class="body">
      <SideBar1
        :title="title"
        :links="asideLinks"
        :module="moduleName"
      />
      
      <div class="container">
        <h1 class="container-title"> {{ pageTitle }} </h1>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>