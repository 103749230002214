import ProductView from '@/views/MarketPlace/Product/ProductView.vue'

// url de base des pages relatives à un produit
const baseUrl = "/marketplace/products/:productId"

const marketplaceProductRoute = [
  {
    path: baseUrl,
    name: "marketplaceProduct",
    component: ProductView,
  }
];

export default marketplaceProductRoute
