import fileBlue1 from '@/assets/icons/file_blue1.svg'
import pdfRed1 from '@/assets/icons/pdf_red1.svg'
import docxBlue1 from '@/assets/icons/docx_blue1.svg'
import jpgGreen1 from '@/assets/icons/jpg_green1.svg'

import { path as basePath } from '../GEDHome/datas'

// url de base des liens
const baseUrl = basePath[basePath.length-1].url+"/fichiers"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "fichiers",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Fichiers"


// les éléments relatifs au headers
export const header = {
  icon: fileBlue1,
  title: "Fichiers",
  text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
}

// les éléments relatifs aux fichiers
export const files = {
  title: "Récement lié",
  items: [
    {
      icon: pdfRed1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      module: "Gestion locative",
      goodId: "001"
    },
    {
      icon: docxBlue1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      module: "Gestion locative",
      goodId: "001"
    },
    {
      icon: jpgGreen1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      module: "Gestion locative",
      goodId: "001"
    },
  ]
}