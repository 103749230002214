<script>
import RentalManagement from '../RentalManagement.vue'
import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'
import { items, path, pageTitle, tableDatas } from './datas.js'
import TableOne from '@/containers/TableOne/TableOne.vue'
import useOwnerStore from './store'
import { initialList } from '@/utils/helpers'
import { mapActions, mapWritableState } from 'pinia'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
 
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
export default {
  components: {
    RentalManagement,
    Header2,
    TableOne,
    AnimatedLoader,
    ErrorMessage,
    ButtonOne
},
  data(){
    return {
      items,
      path,
      pageTitle,
      datas: tableDatas,
      offset: 0,
      initialList
    }
  },
  computed: {
    ...mapWritableState(useOwnerStore, ['owners', 'getOwnersProcess'])
  },
  mounted() {
    this.getOwners(0);
    
   
  },
  methods: {
    ...mapActions(useOwnerStore, ['getOwners', 'formatRows'])
  },
  watch: {
    offset(value) {
      this.getOwners(value);
     
    }
   
  }
}
</script>

<template>
  <RentalManagement :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <Header2 :items="items"/>
      <div class="container-body">
        <AnimatedLoader dark v-if="getOwnersProcess.loading" />
        <div v-else-if="getOwnersProcess.status === 'failure'" class="flex flex-col items-center gap-4">
          <ErrorMessage :message="getOwnersProcess.error" />
          <ButtonOne isAsync @click="getOwners(offset)" text="Actualiser" :loading="getOwnersProcess.loading" :status="getOwnersProcess.status" />
        </div>
        <TableOne
          v-else
          :title="datas.proprietaire.title"
          :cols="datas.proprietaire.cols"
          :rows="owners.list"
          :total="owners.total"
          :actions="initialList.actions"
          baseUrl="/gestion-locative/proprietaires"
          noSearch
          @emitedOffset="(value) => offset = value"
        />
      </div>
    </div>
  </RentalManagement>
</template>

<style src="./OwnersList.css"></style>