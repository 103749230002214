<script>
import routes from "@/router/routes";

import HeaderVue from "@/containers/Header/HeaderVue.vue";
import FooterVue from "@/containers/Footer/FooterVue.vue";

export default {
  components: {
    HeaderVue,
    FooterVue,
  },
  data() {
    return {
      routes,
      on: false,
    };
  },
};
</script>

<template>
  <div class="App">
    <HeaderVue />
    <router-view class="router-view" />
    <FooterVue />
  </div>
</template>

<style src="./App.css"></style>
<style src="./index.css"></style>
