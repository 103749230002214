<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';
import { mapWritableState } from 'pinia';
import useCoTenantStore from '../../store';

export default {
  components: {
    TextField1
  },
  computed: {
    // gives access to store owner inside the component and allows setting it
    ...mapWritableState(useCoTenantStore, ['co_tenant']),
  }
}
</script>

<template>
  <div class="row w-full">
    <TextField1 label="Type de comptabilité" placeholder="Type de compatbilité" :value="co_tenant.accounting_type"  @update:value="newValue => co_tenant.accounting_type = newValue" small />
  
    <TextField1 label="Compte comptable trop perçu" placeholder="Iban ou Numéro simple" :value="co_tenant.accounting_account_overpayment"  @update:value="newValue => co_tenant.accounting_account_overpayment = newValue" small />
  </div>

  <div class="row w-full">
    <TextField1 label="Compte comptable analytique" placeholder="Compte comptable analytique" :value="co_tenant.cost_accounting"  @update:value="newValue => co_tenant.cost_accounting = newValue" small />

    <TextField1 label="Code Auxiliaire" placeholder="Code" :value="co_tenant.auxiliary_code"  @update:value="newValue => co_tenant.auxiliary_code = newValue" small />
  </div>

  <!-- <div class="header">
    <h3>SEPA</h3>
  </div>

  <div class="row">
    <TextField1 label="IBAN" placeholder="IBAN" :value="co_tenant.iban"  @update:value="newValue => co_tenant.iban = newValue" small />

    <TextField1 label="BIC" placeholder="bic" :value="co_tenant.bic"  @update:value="newValue => co_tenant.bic = newValue" small />          
  </div> -->

  <div class="header">
    <h3>RELANCE IMPAYÉE</h3>
  </div>

  <div class="flexed">
    <input type="checkbox" style="border-color: #2DB36F; outline: #2DB36F;" name="reminder" v-model="co_tenant.unpaid_reminder" id="reminder" >
    <label for="reminder">Faire des relances impayeés pour ce locataire</label>
  </div>
</template>

