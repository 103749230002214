import { path as basePath } from "@/views/Dashboard/data"

// base url for the pages above this page in the global infrastructure
const baseUrl = "/registres"

// path to page
export const path = [
  ...basePath,
  {
    label: "registres et projets de mandats",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Dashboard"

export const columns = [
  {
    boards: [
      {
        title: "Derniers mandats enregistrés",
        color: "blue",
        items: [
          {
            title: "Nom du mandat",
            subTitle: "Aderesse du mandat",
            text: "observation",
            date: "12 / 12 / 2022",
            endDate: "12 / 12 / 2023",
            numero: 8,
          },
          {
            title: "Nom du mandat",
            subTitle: "Aderesse du mandat",
            text: "observation",
            date: "12 / 12 / 2022",
            endDate: "12 / 12 / 2023",
            numero: 8,
          },
          {
            title: "Nom du mandat",
            subTitle: "Aderesse du mandat",
            text: "observation",
            date: "12 / 12 / 2022",
            endDate: "12 / 12 / 2023",
            numero: 8,
          },
          {
            title: "Nom du mandat",
            subTitle: "Aderesse du mandat",
            text: "observation",
            date: "12 / 12 / 2022",
            endDate: "12 / 12 / 2023",
            numero: 8,
          },
          {
            title: "Nom du mandat",
            subTitle: "Aderesse du mandat",
            text: "observation",
            date: "12 / 12 / 2022",
            endDate: "12 / 12 / 2023",
            numero: 8,
          },
          {
            title: "Nom du mandat",
            subTitle: "Aderesse du mandat",
            text: "observation",
            date: "12 / 12 / 2022",
            endDate: "12 / 12 / 2023",
            numero: 8,
          },
        ]
      },
    ]
  },
  {
    boards: [
      {
        title: "Registres ayant expirés",
        color: "green",
        items: [
          {
            title: "Nombre de mandats ayant expirés",
            subTitle: "Consulter",
            total: 10
          }
        ]
      },
      {
        title: "Projet de mandat",
        color: "red",
        items: [
          {
            title: "Créer un projet de mandat",
            subTitle: "Créer un projet de mandat de gestion. Vous pourrez ainsi notamment imprimer un mandat de gestion à faire valider par votre mandant."
          },
          {
            title: "Transformer un projet en mandat",
            subTitle: "Validez un projet en le transformant en mandat de gestion"
          }
        ]
      }
    ]
  },
  {
    boards: [
      {
        title: "Registre de mandat",
        color: "purple",
        items: [
          {
            title: "Ajouter un nouveau mandat",
            subTitle: "Créer un projet de mandat de gestion. Vous pourrez ainsi notamment imprimer un mandat de gestion à faire valider par votre mandat."
          },
          {
            title: "Faire une réservation",
            subTitle: "Réservez un numéro de mandat. Vous pourroez le compléter ultérieurement."
          },
        ]
      },
      {
        title: "Solde de mandat existant",
        color: "green",
        items: [
          {
            title: "Enregistrer vos modèles existants",
            subTitle: "Reprenez la liste de vos mandats existants. Cette saisie ne générera pas de numéro de mandat car les mandats ont obtenu leur numérotation dans le passé."
          }
        ]
      }
    ]
  }
]