<script>
import SignUpWrapper from '@/containers/Auth/SignUpWrapper/SignUpWrapper.vue';
import TextField1 from '@/components/TextField1/TextField1.vue';
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'

import useAuthStore from './store.js'
import { mapActions, mapWritableState } from 'pinia';
import downArrow from '@/assets/icons/down-arrow.png'


export default {
  components: {
    SignUpWrapper,
    TextField1,
    ButtonOne,
    ErrorMessage
},
  data() {
    return {
      downArrow,
    }
  },
  computed: {
    accountType() {
      return this.$route.query.type;
    },
    ...mapWritableState(useAuthStore, ['user', 'openedSection', 'loading', "errorMessage", "status"])
  },
  mounted(){
    this.user = { ...this.user, user_type: this.accountType },
    this.openedSection = "main-infos"
    console.log({user: this.user})
  },
  methods: {
    ...mapActions(useAuthStore, ['goToNextStep', 'backToPrevStep', 'register']),
  }
}
</script>

<template>
  <SignUpWrapper title="Création de compte: étape 02" text="Veuiller remplir correctement les champs suvant">
    <form class="signupform" >
      <section
        :class="{ 'signupform-section__opened': openedSection === 'main-infos' }"
      >
        <div 
          class="row-centered signupform-title"
        >
          <h3 class="container-title">Identifiants de connexion</h3>
          <img class="icon-mid" :src="downArrow" alt="">
        </div>
        <div 
          class="flexed signupform-items"
        >
          <TextField1 label="Prénom" name="firstName" placeholder="Votre prénom" :value="user.prenom" @update:value="newValue => user.prenom = newValue.trim()" />

          <TextField1 label="Nom" placeholder="Votre nom" :value="user.nom" @update:value="newValue => user.nom = newValue.trim()" />

          <TextField1 label="Adresse email" type="email" placeholder="Votre adresse email" :value="user.email"  @update:value="newValue => user.email = newValue.trim()" />

          <TextField1 label="Mot de passe" type="password" placeholder="Définir un mot de passe" :value="user.password"  @update:value="newValue => user.password = newValue.trim()" helpertext="Votre mot de passe doit contenir au moins 8 caractères, un chiffre, une lettre majuscule, une miniscule, et un caractère spécial (@, &, #, etc ...)" />

          <TextField1 label="Confirmation de mot de passe" type="password" placeholder="Confirmez votre mot de passe" :value="user.password_confirm"  @update:value="newValue => user.password_confirm = newValue.trim()" />
        </div>
      </section>

      <section
        :class="{ 'signupform-section__opened': openedSection === 'pros-infos' }"
        v-if="accountType === 'pro'"
      >
        <div 
          class="row-centered signupform-title"
        >
          <h3 class="container-title">Informations professionnelles</h3>
          <img class="icon-mid" :src="downArrow" alt="">
        </div>
        <div 
          class="flexed signupform-items"
        >
          <TextField1 label="Nom de l'établissement" placeholder="Nom de votre établissement" :value="user.nom_entreprise"  @update:value="newValue => user.nom_entreprise = newValue.trim()"  />
          <TextField1 label="Email professionnel" type="email" placeholder="Votre email professionnelle" :value="user.email_pro"  @update:value="newValue => user.email_pro = newValue.trim()" />
          <TextField1 label="Id de carte professionnelle" placeholder="Id de votre carte pro immobilier" :value="user.id_professionnel"  @update:value="newValue => user.id_professionnel = newValue.trim()" />
          <TextField1 label="Numéro de SIRET / SIREN" placeholder="SIRET / SIREN" :value="user.siret_siren"  @update:value="newValue => user.siret_siren = newValue.trim()" />
          <TextField1 label="Numéro de TVA" placeholder="TVA" :value="user.tva"  @update:value="newValue => user.tva = newValue.trim()" />
        </div>
      </section>

      <section
        :class="{ 'signupform-section__opened': openedSection === 'comp-infos' }"
        v-if="accountType === 'entreprise'"
      >
        <div 
          class="row-centered signupform-title"
        >
          <h3 class="container-title">Informations entreprise</h3>
          <img class="icon-mid" :src="downArrow" alt="">
        </div>
        <div 
          class="flexed signupform-items"
        >
          <TextField1 label="Nom de l'entreprise" placeholder="Nom de votre entreprise" :value="user.nom_entreprise"  @update:value="newValue => user.nom_entreprise = newValue.trim()" />

          <TextField1 label="Email entreprise" type="email" placeholder="l'email de votre entreprise" :value="user.email_pro"  @update:value="newValue => user.email_pro = newValue.trim()" />

          <TextField1 label="Type d'Entreprise" placeholder="Type de l'entreprise" :value="user.type_entreprise"  @update:value="newValue => user.type_entreprise = newValue.trim()" />

          <TextField1 label="Numéro de SIRET / SIREN" placeholder="SIRET / SIREN" :value="user.siret_siren"  @update:value="newValue => user.siret_siren = newValue.trim()" />

          <TextField1 label="Numéro de TVA" placeholder="TVA" :value="user.tva"  @update:value="newValue => user.tva = newValue.trim()" />
        </div>
      </section>

      <section
        :class="{ 'signupform-section__opened': openedSection === 'supp-infos' }"
      >
        <div 
          class="row-centered signupform-title" 
        >
          <h3 class="container-title">Informations supplémentaires</h3>
          <img class="icon-mid" :src="downArrow" alt="">
        </div>
        <div 
          class="flexed signupform-items"
          >

          <TextField1 label="Pays" placeholder="Pays" :value="user.pays"  @update:value="newValue => user.pays = newValue.trim()" />

          <TextField1 label="Ville" placeholder="Ville" :value="user.ville"  @update:value="newValue => user.ville = newValue.trim()" />

          <TextField1 label="Numéro de téléphone" placeholder="Numéro de téléphone" :value="user.telephone"  @update:value="newValue => user.telephone = newValue.trim()" />

          <TextField1 label="Adresse" placeholder="votre adresse" :value="user.adresse"  @update:value="newValue => user.adresse = newValue.trim()" />

          <TextField1 optional label="Complément d'adresse" placeholder="cptl d'adresse" :value="user.complement_adresse"  @update:value="newValue => user.complement_adresse = newValue.trim()" />

          <TextField1 label="Code postal" placeholder="code postal" :value="user.code_postal"  @update:value="newValue => user.code_postal = newValue.trim()" />
        </div>
      </section>

      <ErrorMessage v-if="status === 'failure'" :message="errorMessage" />

      <div class="flexed">
        <ButtonOne v-if="openedSection !== 'main-infos'" @click.prevent="backToPrevStep" text="Précedant" />
        <ButtonOne v-if="openedSection === 'supp-infos'" @click.prevent="register"  text='Terminer' :loading="loading" :status="status" isAsync />
        <ButtonOne v-if="openedSection !== 'supp-infos'" @click.prevent="goToNextStep" text="Suivant" />
      </div>
    </form>
  </SignUpWrapper>
</template>

<style src="./SignUp.css"></style>
