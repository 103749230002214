import { path as basePath } from "../RentalManagementHome/datas"

const baseUrl = basePath[basePath.length-1].url+"/baux"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "baux",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Baux"

// fonction permettant de mettre les url au bon format en fonction de l'url de base
function url(url){
  return baseUrl+url
}

export const items = [
  {
    text: "Gestion des baux",
    buttons: [
      {
        label: "Créer un contrat de bail",
        url: url("/creer"),
        empty: false
      },
      {
        label: "liste des vacants",
        url: url("/list?type=vacants"),
        empty: true
      }
    ]
  },
  {
    text: "Vérifiez que vos locataires sont bien assurés",
    buttons: [
      {
        label: "Demander des attestations d'assurances",
        url: url("/attestation-assurance"),
        empty: false
      },
      {
        label: "en savoir plus",
        url: url("/list?type=archived"),
        empty: true
      },
    ]
  },
  {
    text: "Les immeubles rassemblent plusieurs biens",
    buttons: [
      {
        label: "Gestion des immeubles",
        url: url("/immeubles"),
        empty: false
      },
      {
        label: "Ajouter un immeuble",
        url: url("/immeubles/creer"),
        empty: false
      },
    ]
  }
]

export const tableDatas = {
  title: 'Liste des Contracts de bail',
  cols: [
   '#', 'N° de Contrat', 'Bien', 'Locataire', 'Montant de loyer'
  ],
  rows: [
   
  ],
 
}