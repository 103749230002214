<script>
import AccountingLayer from '../AccountingLayer.vue';
import { path, pageTitle, tableDatas, contentDatas, worksActions } from './data'
import WorksWrapper from '@/containers/Works/WorksWrapper/WorksWrapper.vue';
export default {
  name: "AccountingAccounts",
  components: { AccountingLayer, WorksWrapper },
  data() {
    return {
      path,
      pageTitle,
      tableDatas,
      contentDatas,
      worksActions
    }
  }
}
</script>

<template>
  <AccountingLayer :path="path" :pageTitle="pageTitle">
    <!-- Comptes bancaires -->
    <div>
      <WorksWrapper
        :title="contentDatas.title"
        :button="contentDatas.button"
        :table="tableDatas"
        :worksActions="worksActions"
      />
    </div>
  </AccountingLayer>
</template>
