<script>
  import PathViewer from '@/containers/PathViewer/PathViewer.vue';
  import SideBar1 from '@/containers/SideBar1/SideBar1.vue';

  import { works } from '@/utils/data/aside'

  export default {
    components: {
      PathViewer,
      SideBar1
    },
    props:{
      path: Array
    },
    data() {
      return {
        title: works.title,
        asideLinks: works.asideLinks,
        pub: works.pub
      }
    }
  }
</script>

<template>
  <div class="works page">
    <PathViewer :path="path" />
    <div class="body">
      <SideBar1
        :title="title"
        :links="asideLinks"
        :pub="pub"
        module="works"
      />
      <slot></slot>
    </div>
  </div>
</template>

<style>
  .works--header{
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 32px 40px 40px 40px;
  }

  .works--header .header-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .works--header .header-top .header-top--title{
    display: flex;
    align-items: center;
    gap: 15.4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .works--header .header-content{
    justify-content: space-between;
  }

  .works .container-body > .box1{
    padding: 30px;
  }
  
  .works h2.container-title,
  .works h3.container-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #298097;
  }

  .works h3.container-title {
    font-size: 20px;
  }

  .works .table-one .table-bg{
    border: 1.3px solid rgba(196, 196, 196, 0.5);
  }
</style>