import AuthMailSent from '@/views/Auth/AuthMailSent/AuthMailSent.vue'

const baseUrl = "/email-envoye"

const emailSentRoutes = [
  {
    path: baseUrl,
    name: "emailSent",
    component: AuthMailSent,
  },
];

export default emailSentRoutes;