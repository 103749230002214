import FirstPage from '@/views/Works/Detectors/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/detecteurs"

const detectorsRoutes = [
  {
    path: baseUrl,
    name: "detector",
    component: FirstPage,
  }
];

export default detectorsRoutes
