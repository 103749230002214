import { path as basePath } from "../ProfilInfos/datas"

const baseUrl = basePath[basePath.length-1].url+"/changer-mot-de-passe"


// le chemin d'accès
export const path = [
    ...basePath,
    {
      label: "Changer-mot-de-passe ",
      url: baseUrl
    }
  ]

// page title value
export const pageTitle = "Changer le mot de passe"

