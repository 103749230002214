import { path as basePath } from "../RegistersHome/datas"

// base url for pages directly above this page in global project infrastructure
const baseUrl = basePath[basePath.length-1].url+"/projets"

// path to the page
export const path = [
  ...basePath,
  {
    label: "projets de mandats",
    url: baseUrl
  }
] 

// page title value
export const pageTitle = "Projets de mandat"

// datas relative to header links
export const header = {
  title: "Projet de mandat",
  links: [
    {
      label: "+ Ajouter un nouveau projet de mandat", 
      url: baseUrl+"/creer",
    }
  ]
}