import FirstPage from '@/views/RentalManagement/Rents/FirstPage.vue'
import NewRent from '@/views/RentalManagement/Rents/New/NewRent.vue'
import NewRentSpecific from '@/views/RentalManagement/Rents/RentSpecific/NewRentSpecific.vue'
import FacturationRent from '@/views/RentalManagement/Rents/Facturation/FacturationRent.vue'
import RevisionRent from '@/views/RentalManagement/Rents/Revision/RevisionRent.vue'
// url de base des pages relatives aux loyers
const baseUrl = "/gestion-locative/loyers"

const rentsRoutes = [
  {
    path: baseUrl,
    name: "rents",
    component: FirstPage,
  },
  {
    path: baseUrl+"/appel",
    name: "newRent",
    component: NewRent,
  },

  {
    path: baseUrl+"/appel-specifique",
    name: "newRentSpecific",
    component: NewRentSpecific,
  },

  {
    path: baseUrl+"/facturation",
    name: "factureOwners",
    component: FacturationRent,
  },

  {
    path: baseUrl+"/revision-rent",
    name: "revisionRent",
    component: RevisionRent,
  }
];

export default rentsRoutes;
