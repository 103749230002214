<script lang="js">
  import ExtranetView from '../ExtranetView.vue';
  import FileBox from '@/components/FileBox/FileBox.vue';
  import { allFilesCols, allFilesRows, allFilesActions, path, pageTitle } from './datas'

  import sampleFiles from '@/utils/data/sampleFiles'
import TableOne from '@/containers/TableOne/TableOne.vue';

  export default {
    components: {
    ExtranetView,
    FileBox,
    TableOne
},
    data(){
      return{
        sampleFiles,
        allFilesActions,
        allFilesCols,
        allFilesRows,
        path,
        pageTitle
      }
    }
  }
</script>

<template>
  <ExtranetView :path="path">
    <div class="shared-files container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <section class="shared-files--header">
        <div class="title">Recent</div>
        <div class="list shared-files--list">
          <FileBox 
            v-for="(file, index) in sampleFiles"
            :key="file.title+index"
            :icon="file.icon"
            :title="file.title"
            :date="file.date"
            :module="file.module"
            :good-id="file.goodId"
          />
        </div>
      </section>
      <section class="container-body">
        <div class="shared-files--board">
          <TableOne
            :actions="allFilesActions"
            :rows="allFilesRows"
            :cols="allFilesCols"
            title="Tous les fichiers"
          />
        </div>
      </section>
    </div>
  </ExtranetView>
</template>

<style src="./FirstPage.css"></style>