import { defineStore } from "pinia";
import api from "./api";
import { initProcess, initProcessReverse, initialList } from "@/utils/helpers";
import { initialUser, initialCompanyInfos, initialProInfos, initialUrlPhoto, initialUserPwd } from "../Auth/datas";
import isEqual from "lodash.isequal";

const useAccountStore = defineStore('owner', {
  state: () => ({
    user: {
      ...initialUser,
      ...initialCompanyInfos,
      ...initialProInfos,
      ...initialUrlPhoto
    },
    changeUserPwd:{
     ...initialUserPwd
    },
    photoUrl: {...initialUrlPhoto},
    userChecker: {
      ...initialUser,
      ...initialCompanyInfos,
      ...initialProInfos,
      ...initialUrlPhoto
    },
    getUserInfosProcess: initProcessReverse,
    getUserPhotoProcess: initProcessReverse,
    updateUserInfosProcess: initProcess,
    updateUserPhotoProcess: initProcess,
    updateUserPwdProcess : initProcess,
    modulesInfos: {
      ...initialList, 
      list: [
        ["Gestion locative", "actif", "12/12/2022", "119,99"],
        ["Transaction immobilière", "actif", "12/12/2022", "119,99"],
        ["Syndic de copropriété", "actif", "12/12/2022", "119,99"],
        ["Location saisonnière", "inactif",  "12/12/2022", "119,99"],
        ["Gestion des travaux et incidents", "actif", "12/12/2022", "119,99"],
        ["GED", "actif", "12/12/2022", "119,99"],
        ["Comptabilité", "inactif", "12/12/2022", "119,99"],
        ["Finance", "actif", "12/12/2022", "119,99"],
        ["États des lieux", "actif", "12/12/2022", "119,99"],
        ["Extranet", "inactif", "12/12/2022", "119,99"],
        ["Registre de mandats", "inactif", "12/12/2022", "119,99"],
      ]
    }
  }),
  getters: {
    isUpdatable: ({user, userChecker}) => !isEqual(user, userChecker)
   
  },
  actions: {
    async getUserInfos() {
      try {
        const userInfos = await api.getUserInfos();
        if(userInfos){
          console.log({userInfos})
          this.getUserInfosProcess = {
            loading: false,
            status: 'success',
            error: ""
          }
          console.log({userInfos})
          this.user = {...userInfos.data}
          this.userChecker = {...userInfos.data}
        } else {
          console.log('No user infos')
          this.getUserInfosProcess = {
            loading: false,
            status: "failure",
            error: "No user infos"
          }
        }
      } catch (error) {
        console.log({error})
        this.getUserInfosProcess = {
          loading: false,
          status: "failure",
          error: error.response ? error.response.data.detail : "Une erreur s'est produite, veuillez reessayer plus tard !"
        }
      }
    },
    async getUserPhoto() {
      try {
        const userPhoto = await api.getUserPhoto();
        if(userPhoto){
          console.log({userPhoto})
          this.getUserInfosProcess = {
            loading: false,
            status: 'success',
            error: ""
          }
          console.log({userPhoto})
          this.userPhoto = {...userPhoto.data}
          this.userChecker = {...userPhoto.data}
        } else {
          console.log('No user photo')
          this.getUserPhotoProcess = {
            loading: false,
            status: "failure",
            error: "Pas de photo disponible"
          }
        }
      } catch (error) {
        console.log({error})
        this.getUserPhotoProcess = {
          loading: false,
          status: "failure",
          error: error.response ? error.response.data.detail : "Une erreur s'est produite, veuillez reessayer plus tard !"
        }
      }
    },
    async updateUserInfos() {
      if(this.isUpdatable){
        this.updateUserInfosProcess = {
          loading: true,
          status: "pending",
          error: ""
        }
        try{
          const updatedUser = await api.updateUserInfos(this.user)
          if(updatedUser.status === 200){
            console.log({updatedUser});
            this.updateUserInfosProcess = {
              loading: false,
              status: "success",
              error: ""
            }
            setTimeout(() => {
              this.updateUserInfosProcess = {
                ...this.updateUserInfosProcess,
                status: ""
              }
              this.userChecker = { ...this.user }
            }, 5000)
          } else if (updatedUser.response.status === 400) {
            console.log({updatedUser})
            this.updateUserInfosProcess = {
              loading: false,
              status: "failure",
              error: "Veuillez vérifier vos informations."
            }
          }
        } catch (updateError) {
          console.log({updateError})
          this.updateUserInfosProcess = {
            loading: false,
            status: "failure",
            error: updateError?.data?.details
          }
        }
      }
    },
    async updateUserPhoto() {
      if(this.isUpdatable){
        this.updateUserPhotoProcess = {
          loading: true,
          status: "pending",
          error: ""
        }
        try{
          const updatedUserPhoto = await api.updateUserPhoto(this.photoUrl)
          if(updatedUserPhoto.status === 200){
            console.log({updatedUserPhoto});
            this.updateUserPhotoProcess = {
              loading: false,
              status: "success",
              error: ""
            }
            setTimeout(() => {
              this.updateUserPhotoProcess = {
                ...this.updateUserPhotoProcess,
                status: ""
              }
              this.userChecker = { ...this.user }
            }, 5000)
          } else if (updatedUserPhoto.response.status === 400) {
            console.log({updatedUserPhoto})
            this.updateUserPhotoProcess = {
              loading: false,
              status: "failure",
              error: "Echec de modification de la photo."
            }
          }
        } catch (updateError) {
          console.log({updateError})
          this.updateUserPhotoProcess = {
            loading: false,
            status: "failure",
            error: updateError?.data?.details
          }
        }
      }
    },
    async updateUserPwd() {
      this.updateUserPwdProcess = {
        loading: true,
        status: "pending",
        error: ""
      }
      this.changeUserPwd.email = this.user.email;
      console.log(this.changeUserPwd);
      if(this.changeUserPwd.current_password !== "" && this.changeUserPwd.password !== "" && this.changeUserPwd.confirm_password !== ""){
        if(this.changeUserPwd.password === this.changeUserPwd.confirm_password){

          try{
            const updatedUserPwd = await api.updateUserInfos(this.changeUserPwd)
            if(updatedUserPwd.status === 200){
              console.log({updatedUserPwd});
              this.updateUserPwdProcess = {
                loading: false,
                status: "success",
                error: "Mot de passe modifié avec succès"
              }
            
            } else if (updatedUserPwd.response.status === 400) {
              console.log({updatedUserPwd})
              this.updateUserPwdProcess = {
                loading: false,
                status: "failure",
                error: "Une erreur est survenue veuillez réessayer ultérieurement."
              }
            }
          } catch (updateError) {
            console.log({updateError})
            this.updateUserPwdProcess = {
              loading: false,
              status: "failure",
              error: updateError?.data?.details
            }
          }

        }else{
          this.updateUserPwdProcess = {
            loading: false,
            status: "failure",
            error: "Le mot de passe et la confirmation de mot de passe doivent être identiques"
          }
        }
       
      }else{
        this.updateUserPwdProcess = {
          loading: false,
          status: "failure",
          error: "Vous semblez avoir omis certains champs obligatoires !"
        }

      }
    },
  }
})

export default useAccountStore;