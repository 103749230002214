// product form section
export const sections = [
  {
    label: "Description",
    key: "description"
  },
  {
    label: "Mandat",
    key: "mandat"
  },
  {
    label: "Propriétaire",
    key: "owner"
  },
  {
    label: "Details",
    key: "details"
  },
  {
    label: "Infos Financières",
    key: "financial-infos"
  },
  {
    label: "Pièces",
    key: "rooms"
  },
  {
    label: "Copropriété",
    key: "coproperty"
  },
  {
    label: "Photos",
    key: "photos"
  },
  {
    label: "Diagnostique",
    key: "diagnostic"
  },
]

// carousel settings
export const carousel = {
  settings: {
    itemsToShow: 3,
    snapAlign: 'start'
  },
  // breakpoints are mobile first
  // any settings not specified will fallback to the carousel settings
  breakpoints: {
    // 700 and up
    700: {
      itemsToShow: 5,
      snapAlign: 'start'
    },
    // 1000 and up
    1000: {
      itemsToShow: 7,
      snapAlign: 'start'
    },

  },
}

// data for buttons in details section
export const detailsSectionButtons = {
    status: {
      value: null,
      options: [
        {
          label: "Bien récent",
          key: "recent"
        },
        {
          label: "Bien neuf",
          key: "new",
        },
        {
          label: "Bien ancien",
          key: "old"
        },
        {
          label: "Refait à neuf",
          key: "renewed"
        },
        {
          label: "Travaux à prévoir",
          key: "work-needed"
        },
      ]
    },
  kitchen: {
    oven: {
      value: false,
      label: "Four"
    },
    dishwasher:{
      value: false,
      label: "Lave vaisselle"
    },
    microwave: {
      value: false,
      label: "Micro-ondes"
    },
    freezer: {
      value: false,
      label: "Congélateur"
    }
  },
  garden: {
    court: {
      value: false,
      label: "Cour"
    },
    pool: {
      value: false,
      label: "Piscine"
    },
    loggia: {
      value: false,
      label: "loggia"
    },
    veranda: {
      value: false,
      label: "Veranda"
    },
    fence: {
      value: false,
      label: "Terrain clôturé"
    }
  },
  equipments: {
    ac: {
      value: false,
      label: "Climatiseur"
    },
    lifter: {
      value: false,
      label: "Ascenseur"
    },
    closet: {
      value: false,
      label: "Placards"
    },
    washingMachine: {
      value: false,
      label: "Lave linge"
    },
    clothesDryer: {
      value: false,
      label: "Sèche linge"
    },
    cableTv: {
      value: false,
      label: "TV par cable"
    },
    ADSL: {
      value: false,
      label: "ADSL / Fibre"
    },
    monoxideDetector: {
      value: false,
      label: "Détecteur de monoxyde"
    },
    separateToilet: {
      value: false,
      label: "WC séparés"
    },
    smokeDetector: {
      value: false,
      label: "Détecteur de fumée"
    },
    cellar: {
      value: false,
      label: "Cave"
    }
  },
  closeTo: {
    cityCenter: {
      value: false,
      label: "Proche du centre ville"
    },
    station: {
      value: false,
      label: "Gare / métro à proximité"
    },
    busStop: {
      value: false,
      label: "Arrêt de bus à proximité"
    },
    airport: {
      value: false,
      label: "Proche d'un aéroport"
    },
    highwayExit: {
      value: false,
      label: "Proche d'une sortie d'autoroute"
    },
    shoppingCenter: {
      value: false,
      label: "Proche d'un centre commercial"
    },
    primarySchool: {
      value: false,
      label: "Proche d'une école primaire"
    },
    college: {
      value: false,
      label: "Proche d'un collège"
    },
    highSchool: {
      value: false,
      label: "Proche d'un lycée"
    },
    university: {
      value: false,
      label: "Université à proximité"
    }
  }
}

export const roomsSectionButtons = {
  levels: {
    label: "Nombre de niveaux", 
    value: 0
  },
  rooms: {
    label: "Nombre de pièces", 
    value: 0
  },
  bedRooms: {
    label: "Nombre de chambres", 
    value: 0
  },
  toilets: {
    label: "Nombre de wc", 
    value: 0
  },
  waterRooms: {
    label: "Salles d'eau", 
    value: 0
  },
  bathRooms: {
    label: "Salles de bain", 
    value: 0
  },
  parkings: {
    label: "Nombre de parkings", 
    value: 0
  },
  boxes: {
    label: "Nombre de boxes", 
    value: 0
  },
  terrace: {
    label: "Nombre de terrasses", 
    value: 0
  },
  caves: {
    label: "Nombre de caves", 
    value: 0
  }
}