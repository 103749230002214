import folderYellow1 from '@/assets/icons/folder_yellow1.svg'
import folderBlue1 from '@/assets/icons/folder_blue1.svg'

import { path as basePath } from '../GEDHome/datas'

// url de base des liens
const baseUrl = basePath[basePath.length-1].url+"/repertoires"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "repertoires",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Repertoires"

// fonction permettant de mettre les url au bon format
function url(url){
  // Ajoute en préfixe à l'url donnée en paramètre l'url de base du module
  return baseUrl+url
}

// les éléments relatifs au headers
export const header = {
  icon: folderYellow1,
  title: "Dossier / classeur",
  text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  button: {
    label: "Créer un nouveau dossier",
    url: url('/créer')
  }
}

// les éléments relatifs aux dossiers
export const folders = {
  title: "Dossier récemment créés",
  icon: folderBlue1,
  items: [
    {
      title: "Appel de loyer",
      date: "08-10-2022",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget rhoncus donec egestas viverra nisl sit. Accum....",
      weight: 250,
      files: 16
    },
    {
      title: "Appel de loyer",
      date: "08-10-2022",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget rhoncus donec egestas viverra nisl sit. Accum....",
      weight: 250,
      files: 16
    },
    {
      title: "Appel de loyer",
      date: "08-10-2022",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget rhoncus donec egestas viverra nisl sit. Accum....",
      weight: 250,
      files: 16
    },
  ]
}