<script>
  import TextField1 from '@/components/TextField1/TextField1.vue';
  import SelectField1 from '@/components/SelectField1/SelectField1.vue';
  import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
  import icon from '@/assets/icons/folder1.svg';

  export default{
    components: {
      TextField1,
      SelectField1,
      ButtonOne,
    },
    data(){
      return {
        icon
      }
    }
  }
</script>

<template>
  <div class="components">
    <SelectField1 />
    
    <ButtonOne text="simple button" />
    
    <ButtonOne text="full rounded button" fullRounded />
    
    <ButtonOne text="full rounded button" :leftIcon="icon" />
    
    <ButtonOne text="full rounded button" fullRounded :rightIcon="icon" />
    
    <ButtonOne text="full rounded button" :leftIcon="icon" :rightIcon="icon" />
    
    <TextField1
      type="date"
      vModal=""
      small
      label="date input"
    />

    <TextField1
      vModal="text"
      type="text"
      label="input"
      name="input"
      placeholder="enter some text"
      id="input"
    />
    <TextField1
      vModal="number"
      type="number"
      label="number input"
      name="number input"
      placeholder="enter a number"
      id="number input"
    />
    <TextField1
      vModal="text"
      type="text"
      label="rowed input"
      name="input"
      placeholder="enter some text"
      id="input"
      rowed
      small
    />
    <TextField1
      vModal="text"
      type="text"
      label="small input"
      name="small input"
      placeholder="enter some text"
      id="small input"
      small
    />

    <TextField1
      vModal="text"
      type="text"
      label="textarea"
      name="textarea"
      placeholder="enter some text"
      id="textarea"
      textarea
    />
    <TextField1
      vModal="text"
      type="text"
      label="rowed textarea"
      name="rowed textarea"
      placeholder="enter some text"
      id="rowed textarea"
      rowed
      small
      textarea
    />

  </div>
</template>

<style>
  .components{
    padding: 80px 40px 40px 40px;
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
  }
</style>