<script>
  import arrow from '@/assets/icons/downArrow_black.svg'

 

  export default {
    props:{
      label:String,
      items:{
        type:Array,
      },
      optional: Boolean,
    },
   
    data(){
      return {
        arrow,
        on: false,
        selecteItem: {},
        
      }
    },
    methods: {
      handleClick(){
        console.log(this.on);
        return this.on = !this.on;
        
      },
      SelectItem(item){
        if (this.selecteItems !== item) {
          
          this.selecteItem = item;
        }
      }
    }
  }
</script>

<template>
  <div class="selectfield">
    <div class="flex flex-row"><label>{{ label }} <span>: </span></label>
    <p class="required-tag text-red ms-1" v-if="!optional">*</p></div>
    
    <div class="selectfield--selector" @click="handleClick">
      <div class="content">{{ selecteItem.label || "sélectionnez" }}</div>
      <img :src="arrow" alt="" :class="{turned: on}">
    </div>
    <transition name="fade">  
      <div class="selectfield--items" v-show="on" @click="handleClick">
        <span
          v-for="item in items"
          :key="item.value"
          v-on:click="() => SelectItem(item)" >
          {{ item.value }}
        </span>
      </div>
    </transition>
  </div>
</template>

<style src="./SelectField1.css"></style>