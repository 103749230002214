// import FirstPage from "@/views/Works/Equipments/FirstPage.vue";
// import NewEquipment from "../../views/Works/Equipments/";
// import EquipmentView from "../../views/Works/Equipements/EquipmentView/EquipmentView.vue";


// url de base des pages relatives aux equipements
//const baseUrl = "/gestion-locative/equipements"

const equipmentRoutes = [
    // {
    //     path: baseUrl,
    //     name:"equipements",
    //     component: FirstPage,
    // },
    // {
    //     path: baseUrl+"/creer",
    //     name:"newEquipement",
    //     component: NewEquipment,
    // },
    // {
    //     path: baseUrl+"/:equipmentId",
    //     name:"equipement",
    //     component: EquipmentView,
    // }
];


export default equipmentRoutes;