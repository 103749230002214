<script>
  import RegistersView from '../RegistersView.vue'
  import TableOne from '@/containers/TableOne/TableOne.vue'

  import { path, pageTitle } from './datas'

  export default {
    components: {
      RegistersView,
      TableOne
    },
    data(){
      return {
        path,
        pageTitle,
      }
    },
  }
</script>

<template>
  <RegistersView :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="container-body">
        <TableOne title="Liste des mandats archivés" />
      </div>
    </div>
  </RegistersView>
</template>

<style src="./FirstPage.css"></style>