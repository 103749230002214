import { path as basePath } from '@/views/Dashboard/data'

import profile1 from '@/assets/vectors/profile1.svg'
import folder1 from '@/assets/vectors/folder1.svg'
import chats1 from '@/assets/vectors/chats1.svg'
import profile from '@/assets/photos/profile.svg'
import pdfRed1 from '@/assets/icons/pdf_red1.svg'
import docxBlue1 from '@/assets/icons/docx_blue1.svg'
import jpgGreen1 from '@/assets/icons/jpg_green1.svg'

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "extranet",
    url: "/extranet"
  }
]

// les éléments relatifs au headers
export const header = {
  greatings: {
    title: "Salut ! Mr. Julien",
    text: "Heureux de vous revoir voici un petit recap des actions menées pour vous et vos utilisateur de l’extranet"
  },
  resumes: [
    {
      number: 12,
      text: "Utilisateurs enregistrés.",
      vector: profile1,
      color: "blue"
    },
    {
      number: 12,
      text: "Nouveaux documents partagés.",
      vector: folder1,
      color: "orange"
    },
    {
      number: 12,
      text: "Nouveaux messages reçus",
      vector: chats1,
      color: "purple"
    },
  ]
}

export const presentation = {
  picture: profile,
  name: {
    label: "Nom / Enseigne",
    value: "Steve immo-consulting"
  },
  description: {
    label: "Description",
    value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat congue elementum tincidunt massa, nulla. Egestas ligula ut scelerisque turpis sed in sodales nisl. Urna egestas."
  },
  phone: "600 500 400",
  portable: "123 456 789",
  email: "steve@gmail.com",
  website: "www.steve-consolting.com",
  address: "8844 - Rue des bois"
}

// les éléments relatifs aux fichiers
export const files = {
  title: "Fichiers",
  items: [
    {
      icon: pdfRed1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      name: "Stephan Plaza",
    },
    {
      icon: docxBlue1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      name: "Stephan Plaza",
    },
    {
      icon: jpgGreen1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      name: "Stephan Plaza",
    },
    {
      icon: pdfRed1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      name: "Stephan Plaza",
    },
    {
      icon: docxBlue1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      name: "Stephan Plaza",
    },
    {
      icon: jpgGreen1,
      title: "Plan de l'appart",
      date: "08-10-2022",
      name: "Stephan Plaza",
    },
  ]
}