<script>
  import WorksView from '../WorksView.vue'
  import PageContentBox from '@/containers/Works/PageContentBox/PageContentBox.vue'
  import TableOne from '@/containers/TableOne/TableOne.vue'

  import graph from '@/assets/vectors/claims-graph.svg'

  import { path, pageTitle } from './datas'

  export default {
    data(){
      return {
        path,
        pageTitle,
        assets: {
          graph
        }
      }
    },
    components: {
      WorksView,
      PageContentBox,
      TableOne
    },
  }
</script>

<template>
  <WorksView :path="path">
    <div class="container works-claims">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <!-- corp de la page -->
      <div class="container-body">
        <PageContentBox title="Sinistres et incidents" buttonText="Créer une fiche sinistre" buttonUrl="/works/sinistres/ajouter">
          <div class="row-centered works-claims--content">
            <TableOne
              title="Liste des sinistres et incidents"
              :cols="['Désignations', 'Date de début', 'Date de fin']"
              :actions="[{ type: 'view', label: 'afficher' }, { type: 'delete', label: 'supprimer' }]"
              :rows="[['Velit nunc.', 'Omore.', 'Mauris cum eget diam']]"
            />

            <div class="claims-stats">
              <h3 class="container-title">
                Sinistre de l'année
              </h3>

              <img :src="assets.graph" alt="">

              <div class="graph-legend">
                <div class="row-centered closed">
                  <p></p> <span>Cloturé</span>
                </div>
                <div class="row-centered opened">
                  <p></p> <span>Ouvert</span>
                </div>
              </div>
            </div>
          </div>
        </PageContentBox>
      </div>
    </div>
  </WorksView>
</template>

<style src="./FirstPage.css"></style>