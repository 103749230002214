<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';
import { mapWritableState } from 'pinia';
import useCoTenantStore from '../../store';

export default {
  components: {
    TextField1
  },
  computed: {
    // gives access to store co_tenant inside the component and allows setting it
    ...mapWritableState(useCoTenantStore, ['co_tenant']),
  }
}
</script>

<template>
  <div class="row w-full">
    <TextField1 fullWidth label="IBAN" placeholder="IBAN" :value="co_tenant.iban"
      @update:value="newValue => co_tenant.iban = newValue" />

    <TextField1 fullWidth label="BIC" placeholder="bic" :value="co_tenant.bic"
      @update:value="newValue => co_tenant.bic = newValue" />
  </div>

  <div class="row w-full">
    <TextField1 fullWidth label="Nom créancier" placeholder="Banque" :value="co_tenant.creditor_name"
      @update:value="newValue => co_tenant.creditor_name = newValue" />

    <TextField1 fullWidth label="N° ICS" placeholder="ICS" :value="co_tenant.ics_number"
    @update:value="newValue => co_tenant.ics_number = newValue" />
</div></template>