import profile from '@/assets/photos/profile.svg'
import addUserBlue from '@/assets/icons/add-user__blue.svg'
import addUserOrange from '@/assets/icons/add-user__orange.svg'
import addUserBlueSky from '@/assets/icons/add-user__sky-blue.svg'
import { path as basePath } from '../ExtranetHome/datas'

// path

export const path = [
  ...basePath,
  {
    label: "parties prenantes",
    url: "/extranet/utilisateurs"
  }
]

// page title value
export const pageTitle = "Utilisateurs"

// données relatives aux utilisateurs classés par options
export const users = [
  {
    option: "Gestion locative",
    color: "blue",
    total: 21,
    icon: addUserBlue,
    usersList: [
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
        role: "Locataire"
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
        role: "Propriétaire"
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
        role: "Locataire"
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
        role: "Propriétaire"
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
        role: "Locataire"
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
        role: "Propriétaire"
      },
    ]
  },
  {
    option: "Copropriété",
    color: "orange",
    total: 21,
    icon: addUserOrange,
    usersList: [
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
    ]
  },
  {
    option: "Contact",
    color: "sky-blue",
    total: 21,
    icon: addUserBlueSky,
    usersList: [
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
      {
        picture: profile,
        name: "Stephan Plaza",
        date: "08-10-2022",
      },
    ]
  }
] 