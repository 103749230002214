import ownerApi from "./owners";
import coOwnerApi from "./coowners";
import tenantApi from "./tenants";
import coTenantApi from "./cotenants";
import goodApi from "./goods";
import leaseApi from "./leases";
import rentApi from "./rents";

const api = {
  ...ownerApi,
  ...coOwnerApi,
  ...tenantApi,
  ...coTenantApi,
  ...goodApi,
  ...leaseApi,
  ...rentApi
}

export default api;