<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';

import { mapWritableState } from 'pinia';
import usePropertyStore from '../../store';


export default {
  data(){
    return{
    date: null
    }

  },
  components: {
    TextField1
  },
  computed: {
    // gives access to store property inside the component and allows setting it
    ...mapWritableState(usePropertyStore, ['property']),
  },
  methods:{
   

  }
}
</script>

<template>
  <div class="row w-full">

    <TextField1 fullWidth label="Année de construction" placeholder="Année de construction" :value="property.annee_construction"  @update:value="newValue => property.annee_construction = newValue"  />
    
    <TextField1 fullWidth  label="Date DPE" type="date" :value="property.date_dpe"  @update:value="newValue => property.date_dpe = newValue" :optional="true" />

  </div>
  <div class="row  w-full">
    <TextField1 fullWidth label="Energie(KWhep/m2/an)" placeholder="Energie(KWhep/m2/an)" :value="property.energie"  @update:value="newValue => property.energie= newValue" :optional="true"/>
    <TextField1 fullWidth label="Climat(KgepCo2/m2/an)" placeholder="Climat(KgepCo2/m2/an)" :value="property.climat"  @update:value="newValue => property.climat= newValue" :optional="true"/>

  </div>

  <div class="row w-full">
      
        <TextField1 fullWidth label="Diagnostic DPE valable jusqu'au" type="date" :value="property.diagnostic_dpe"  @update:value="newValue => property.diagnostic_dpe= newValue" :optional="true"/>
          <TextField1 fullWidth label="Diagnostic Plomb valable jusqu'au" type="date" :value="property.diagnostic_plomb"  @update:value="newValue => property.diagnostic_plomb= newValue" :optional="true"/>

  </div>

  <div class="row w-full">
    <TextField1 fullWidth label="Diagnostic Electricité valable jusqu'au" type="date" :value="property.diagnostic_electricite"  @update:value="newValue => property.diagnostic_electricite= newValue" :optional="true"/>

        <TextField1 fullWidth label="Diagnostic Termites valable jusqu'au" type="date" :value="property.diagnostic_termites"  @update:value="newValue => property.diagnostic_termites= newValue" :optional="true"/>

  </div>
 
  <div class="row w-full">
       
        <TextField1 fullWidth label="Diagnostic Gaz valable jusqu'au" type="date" :value="property.diagnostic_gaz"  @update:value="newValue => property.diagnostic_gaz= newValue" :optional="true"/>
          <TextField1 fullWidth label="Diagnostic ERP valable jusqu'au" type="date" :value="property.diagnostic_erp"  @update:value="newValue => property.diagnostic_erp= newValue" :optional="true"/>

  </div>

 
</template>