import client from "@/views/client";

const baseUrl = "/gl/tenant"

export const tenantApi = {
  addTenant: (data) => client.post(`${baseUrl}/add`, data),
  getTenant: (id) => client.get(`${baseUrl}/info/${id}`),
  updateTenant: (data) => client.patch(`${baseUrl}/update_tenant`, data),
  // get tenants list with pagnination handeling
  getTenants: (data) => client.get(`${baseUrl}/list_all_tenant`, data),
  deleteTenant: (data) => client.delete(`${baseUrl}/delete_tenant`, {data}),
  deleteRent: (data) => client.delete(`${baseUrl}/delete_rental_request`, {data}),
  // routes for rental request
  addRentRequest:(data)=> client.post(`${baseUrl}/add_rental_request`, data),
  getRentRequest:(id) => client.get(`${baseUrl}/rental_request_info/${id}`),
  confirmRentRequest:(data)=> client.post(`${baseUrl}/confirm_rental_request`, data),
  updateRentRequest:(data)=> client.patch(`${baseUrl}/update_rental_request`, data),
  getListRentRequest:(limit, offset) => client.get(`${baseUrl}/get_all_rental_request_info/${limit}/${offset}`),
  getListTypeTenants:() => client.get(`admin/list_type_tenant`),
  
  previewRent:(tenantId, limit, offset) => client.get(`${baseUrl}/proposer_bien/${tenantId}/${limit}/${offset}`),
  sendPropertiesByEmail:(data) => client.post(`${baseUrl}/send_email_properties`, data)
}

export default tenantApi;
