<script>
import ButtonOne from '../../components/ButtonOne/ButtonOne.vue'
  export default {
    components: {
      ButtonOne
    }
  }
</script>

<template>
  <div class="system-error">
    <h1>System Error</h1>
    <p>Nous rencontrons un problème<br/>réessayez plus tard</p>
    <router-link to="/"><ButtonOne text="Retour à la page d'accueil" /></router-link>
    <img src="../../assets/vectors/systemError.svg" alt="">
  </div>
</template>

<style src="./SystemError.css"></style>