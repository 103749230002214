<script>
  import ExtranetView from '../ExtranetView.vue'
  import HeaderOne from '@/containers/Extranet/HeaderOne/HeaderOne.vue';
  import FileBox from '@/components/FileBox/FileBox.vue';
  
  import { header, presentation, files, path } from './datas'
  
  import pencil1 from '@/assets/icons/pencil1.svg'
  import tripleDots from '@/assets/icons/triple-dots.svg'

  export default {
    data(){
      return{
        header: {
          greatings: header.greatings,
          resumes: header.resumes
        },
        path,
        pres: {
          picture: presentation.picture,
          name: presentation.name,
          description: presentation.description,
          phone: presentation.phone,
          portable: presentation.portable,
          email: presentation.email,
          website: presentation.website,
          address: presentation.address
        },
        assets: {
          pencil1,
          tripleDots
        },
        // informations relatives à la permière section "fichiers" du corps de la page
        files: {
          title: files.title,
          items: files.items
        }
      }
    },
    components: {
    ExtranetView,
    HeaderOne,
    FileBox
},
  }
</script>

<template>
  <ExtranetView :path="path">
    <div class="container">
      <HeaderOne
        :greatings="header.greatings"
        :resumes="header.resumes"
      />
      <div class="container-body">
        <div class="extranet-infos">
          <section class="box1 presentation">
            <div class="box-header">
              <div class="box-header--object">
                Votre présentation <span>( elle sera visible par vos utilisateurs connectés )</span>
              </div>
              <router-link to="/extranet/profile" class="box-header--button">
                <img :src="assets.pencil1" alt="">
              </router-link>
            </div>
            <div class="box-body">
              <img :src="pres.picture" :alt="pres.name.value">
              <div class="box-body--content">
                <div class="name">
                  <div>{{ pres.name.label }}:</div>
                  <div>{{ pres.name.value }}</div>
                </div>
                <div class="description">
                  <div>{{ pres.description.label }}:</div>
                  <p>{{ pres.description.value }}</p>
                </div>
                <div class="contacts">
                  <span>{{ pres.phone }}</span>
                  <div v-if="pres.portable" class="dot"></div>
                  <span v-if="pres.portable">{{ pres.portable }}</span>
                  <div v-if="pres.email" class="dot"></div>
                  <span v-if="pres.email">{{ pres.email }}</span>
                  <div v-if="pres.website" class="dot"></div>
                  <span v-if="pres.website">{{ pres.website }}</span>
                </div>
                <div class="address">
                  {{ pres.address }}
                </div>
              </div>
            </div>
          </section>
          <section class="box1 documents">
            <div class="box-header">
              <div class="box-header--object">
                Document reçu
              </div>
              <router-link to="/extranet/fichiers" class="box-header--button">
                Voir tout >
              </router-link>
            </div>
            <div class="list">
              <FileBox
                v-for="({ title, date, name, icon }, index) in files.items"
                :key="'folder-item-'+index"
                :icon = "icon"
                :title = "title"
                :date = "date"
                :goodId = "name"
              />
            </div>
          </section>
        </div>
        <aside class="box1">
          <div class="box-header">
            <div class="box-header--object">
              Derniers utilisateur inscris
            </div>
            <div>(8)</div>
          </div>
          <div class="aside-items">
            <div class="box1">
              <div class="aside-item--header">
                <div class="aside-item--header-profile">
                  <img :src="pres.picture" alt="" />
                  <p>Stephan Plaza</p>
                </div>
                <img :src="assets.tripleDots" alt="">
              </div>
              <div class="aside-item--content">
                <span class="date">08-10-2022</span>
                <div class="dot2"></div>
                <span class="option option__rm">Gestion locative</span>
                <div class="dot2"></div>
                <span class="role">Locataire</span>
              </div>
            </div>
            <div class="box1">
              <div class="aside-item--header">
                <div class="aside-item--header-profile">
                  <img :src="pres.picture" alt="" />
                  <p>Stephan Plaza</p>
                </div>
                <img :src="assets.tripleDots" alt="">
              </div>
              <div class="aside-item--content">
                <span class="date">08-10-2022</span>
                <div class="dot2"></div>
                <span class="option option__rm">Gestion locative</span>
                <div class="dot2"></div>
                <span class="role">Locataire</span>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
  </ExtranetView>
</template>

<style src="./ExtranetHome.css"></style>