<script>
  import CountController from '@/components/CountController/CountController.vue';
import TableOne from '@/containers/TableOne/TableOne.vue';

  import { roomsSectionButtons } from '../datas';

  export default {
    components: {
    CountController,
    TableOne
},
    data(){
      return{
        counters: roomsSectionButtons
      }
    }
  }
</script>

<template>
  <div class="rooms-section">
    <section>
      <div class="flexed rooms-buttons">
        <div
          v-for="(counter, index) in counters"
          :key="'counter-'+index"
          class="row-centered rooms-button"
        >
          <p class="title">{{ counter.label }}</p>
          <CountController
            @emitedValue="(value) => counter.value = value"
          />
        </div>
      </div>
    </section>

    <section>
      <div>
        <TableOne title="Liste des pièces" />
      </div>
    </section>
  </div>
</template>

<style>
  .rooms-section .rooms-buttons{
    gap: 30px;
  }
</style>