<script lang="js">
import { mapActions, mapWritableState } from 'pinia';

import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import TextField1 from '@/components/TextField1/TextField1.vue'

import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'

import { findIndex } from '@/utils/helpers';

import { formSteps } from './datas';
import { path as basePath } from "../datas"

import useCoTenantStore from '../store';
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';
import { initialList } from '@/utils/helpers';
const baseUrl = basePath[basePath.length - 1].url

export default {
  components: {
    RentalManagement,
    EditionForm,
    TextField1,
   
    ButtonOne,
    AnimatedLoader,
    ErrorMessage
  },
  data() {
    return {
      // path,
      steps: formSteps,
      currentStep: 0,
      changed: false,
      initialList,
      deleteClicked: false,
      cotenantFound: false
    }
  },
  computed: {
    // gives access to store co_tenant inside the component and allows setting it
    ...mapWritableState(useCoTenantStore, ['co_tenant', 'ibans', 'updatedCoTenantDatas', 'deletionProcess', 'loading', 'status', 'error', 'getCoTenantProcess','updateCoTenantProcess']),
    urlParam() {
      return this.$route.params.tenantId
    },
    cotenantId() {
      return parseInt(this.urlParam);
    },
    path() {
      return [
        ...basePath,
        {
          label: (this.co_tenant.first_name + " " +this.co_tenant.lastname).split(" ").slice(0, 2).join("-").toUpperCase() + "...",
          url: baseUrl + "/" + this.cotenantId
        }
      ]
    }
  },
  methods: {
    findIndex,
    reload() {
      location.reload()
    },
    ...mapActions(useCoTenantStore, ['getCoTenant', 'updateCoTenant', 'deleteCoTenant']),
    handleSubmit() {
      this.updateCoTenant()
    },
    handleUpdateValue(name, newValue) {
      this.changed = true;
      this.co_tenant[name] = newValue;
      ! this.updatedCoTenantDatas.includes(name) && this.updatedCoTenantDatas.push(name);
    },
    handleDeleteCoTenant() {
      if (this.deleteClicked) {
        this.deleteClicked = false;
        this.deleteCoTenant(this.tenantId);
      } else {
        this.deleteClicked = true;
      }
    }
  },
  mounted(){
    this.path[this.path.length-1].url += this.urlParam
    this.getCoTenant(this.cotenantId)
  },
}
</script>

<template>
  <RentalManagement :path="path">
    <div v-if="getCoTenantProcess.status === 'pending'">
      <AnimatedLoader dark />
    </div>
    <div v-if="getCoTenantProcess.status === 'success'" class="container">
      <div class="owner-header">
        <h1 class='container-title'>Locataire: {{ co_tenant.first_name }} {{ co_tenant.lastname }}</h1>
        <ButtonOne 
          @click="handleDeleteCoTenant()" 
          :text="deleteClicked ? 'Cliquez à nouveau pour supprimer' : 'Supprimer'" 
          :dangerous="deleteClicked"
          :loading="deletionProcess.loading"
          :status="deletionProcess.status"
          isAsync
        />
      </div>
    </div>
    <div class="container">
      <h1 class="container-title"> {{ co_tenant.first_name }} {{ co_tenant.lastname }} </h1>
      <div class="profile container-body">
        <EditionForm :save="changed" :steps="steps" @step="(step) => currentStep = step" edit >
          <template #form>
            <form @submit.prevent="() => updateCoTenant(cotenantId)" class="flex flex-col w-full">
              <section class="form-part" v-if="currentStep == findIndex('general')" >
              <TextField1 label="Civilités" placeholder="Mr., Mme, ..." :value="co_tenant.civility"  @update:value="newValue => co_tenant.civility = newValue" />

              <TextField1 label="Email" placeholder="Votre email" :value="co_tenant.email"  @update:value="newValue => co_tenant.email = newValue" />
            
              <div class="row">
                <TextField1 small label="Prénom" placeholder="Votre prénom" :value="co_tenant.first_name"  @update:value="newValue => co_tenant.first_name = newValue" />

                <TextField1 small label="Nom" placeholder="Votre nom" :value="co_tenant.lastname"  @update:value="newValue => co_tenant.lastname = newValue" />
              </div>

              <div class="row">
                <TextField1 label="Date de naissance" type="date" :value="co_tenant.date_of_birth"  @update:value="newValue => co_tenant.date_of_birth = newValue" small />
                
                <TextField1 label="Lieu de naissance" placeholder="Lieu de naissance" :value="co_tenant.place_of_birth"  @update:value="newValue => co_tenant.place_of_birth = newValue" small />
              </div>

              <div class="row">
                <TextField1 label="Adresse" placeholder="Votre adresse" :value="co_tenant.address"  @update:value="newValue => co_tenant.address = newValue" small />
              
                <TextField1 label="Complément d'adresse" placeholder="Complément d'adresse" :value="co_tenant.complete_address"  @update:value="newValue => co_tenant.complete_address = newValue" small />
              </div>

              <div class="row">
                <TextField1 label="Ville" placeholder="Votre ville" :value="co_tenant.city"  @update:value="newValue => co_tenant.city = newValue" small />
            
                <TextField1 label="Pays" placeholder="Votre pays" :value="co_tenant.country"  @update:value="newValue => co_tenant.country = newValue" small />
              </div>

              <div class="row">
                <TextField1 label="Code postal" placeholder="Votre code postal" :value="co_tenant.zip_code"  @update:value="newValue => co_tenant.zip_code = newValue" small />
          
                <TextField1 label="Fax" placeholder="Votre fax" :value="co_tenant.fax"  @update:value="newValue => co_tenant.fax = newValue" small />
              </div>

              <div class="row">
                <TextField1 label="Téléhone" placeholder="Votre téléphone" :value="co_tenant.phone"  @update:value="newValue => co_tenant.phone = newValue" small />

                <TextField1 label="Portable" placeholder="Votre portable" :value="co_tenant.cell_phone"  @update:value="newValue => co_tenant.cell_phone = newValue" small />
              </div>
            
            </section>

            <section class="form-part" v-if="currentStep == findIndex('iban')" >
                <div class="row">
                  <TextField1 label="IBAN" placeholder="Votre IBAN" :value="co_tenant.iban"  @update:value="newValue => co_tenant.iban = newValue" small />
              
                  <TextField1 label="BIC" placeholder="Votre bic" :value="co_tenant.bic"  @update:value="newValue => co_tenant.bic = newValue" small />
                </div>

                <div class="row">
                  <TextField1 label="Nom créancier" placeholder="Votre Banque" :value="co_tenant.creditor_name"  @update:value="newValue => co_tenant.creditor_name = newValue" small />
            
                  <TextField1 label="N° ICS" placeholder="ICS" :value="co_tenant.ics_number"  @update:value="newValue => co_tenant.ics_number = newValue" small />
                </div>
            </section>



              <section class="form-part" v-if="currentStep == findIndex('accounting')" >
                <div class="row">
                  <TextField1 label="Type de comptabilité" placeholder="Votre type de compatbilité" :value="co_tenant.accounting_type"  @update:value="newValue => co_tenant.accounting_type = newValue" small/>
        
                  <TextField1 label="Compte comptable trop perçu" placeholder="Iban ou Numéro simple" :value="co_tenant.accounting_account_overpayment"  @update:value="newValue => co_tenant.accounting_account_overpayment = newValue" small/>

                </div>
                
                  <div class="row">
                    <TextField1 label="Compte comptable analytique" placeholder="Compte comptable analytique" :value="co_tenant.cost_accounting_account"  @update:value="newValue => co_tenant.cost_accounting_account = newValue" small />

                    <TextField1 label="Code Auxiliaire" placeholder="Code" :value="co_tenant.auxiliary_code"  @update:value="newValue => co_tenant.auxiliary_code = newValue" small />
                </div>

                <div class="header">
                  <h3>RELANCE IMPAYÉE</h3>
                </div>

                <div class="flexed">
                  <input type="checkbox" name="reminder" v-model="co_tenant.unpaid_reminder" id="reminder" >
                  <label for="reminder">Faire des relances impayés pour ce locataire</label>
                </div>

                <div class="flex flex-col gap-4">
                    <ErrorMessage v-if="updateCoTenantProcess.status === 'failure'" :message="updateCoTenantProcess.error" />
                    <ButtonOne isAsync :loading="updateCoTenantProcess.loading" :status="updateCoTenantProcess.status" text="Modifier" />
              </div>
              </section>
            </form>
            <div class="flex gap-[20px]">
              <div v-if="deletionProcess.status === 'failure'">
                <ErrorMessage :message="deletionProcess.error" />
              </div>
  
              <div v-if="status === 'failure'">
                <ErrorMessage :message="error" />
              </div>
            </div>
             
          </template>
        </EditionForm>
      </div>
    </div>
    <div v-if="getCoTenantProcess.status === 'failure'" class="container"> 
      <div class="co_tenant-header">
        <h1 class='container-title'>Erreur de chargement</h1>
        <ButtonOne 
          @click="reload()" 
          text="Cliquez pour recharger" 
        />
      </div>
    </div>
  </RentalManagement>
</template>