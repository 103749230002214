import { path as basePath } from '../SyndicsHome/datas'

// path

export const path = [
  ...basePath,
  {
    label: "copropriétés",
    url: basePath[basePath.length-1].url+"/coproprietes"
  }
]

// page title value
export const pageTitle = "Copropriétés"
