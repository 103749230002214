<script lang="js">
  import ExtranetView from '../ExtranetView.vue';
  import tripleDots from '@/assets/icons/triple-dots.svg'
  import messageGray from '@/assets/icons/message_gray1.svg'
  import sendIcon from "@/assets/icons/send-message1.svg"

  import { conversations, path } from './datas';

  export default {
    components: {
      ExtranetView,
    },
    data(){
      return{
        unread: 12,
        totalConv: conversations.length,
        conversations: conversations,
        path,
        assets: {
          tripleDots,
          messageGray,
          sendIcon
        },
        message: "",
        active: conversations[0]
      }
    },
    methods: {
      updateConversations(){
        this.conversations = this.conversations.filter(({id}) => id !== this.active.id);
          this.conversations.push(this.active)
          localStorage.setItem('xxl-immo-conversations', JSON.stringify(this.conversations))
          this.$refs["messagesContainer"].scrollTop = this.$refs["messagesContainer"].clientHeight+100000000;
      },
      sendMessage(){
        if(this.message.length > 0){
          const newMessage = {
            id: this.active?.messages?.length,
            author: 1,
            content: this.message
          }
  
          this.active.messages.push(newMessage)
  
          this.message = "";

          this.updateConversations()
        }
      },
      recieveMessage(){
        if(this.message.length > 0){
          const newMessage = {
            id: this.active.messages.length,
            author: 2,
            content: this.message
          }
  
          this.active.messages.push(newMessage)
  
          this.message = ""
          
          this.updateConversations()
        }
      },
    },
    watch: {
      active(newValue){
        localStorage.setItem('xxl-immo-activeConv', JSON.stringify(newValue));
      },
    },
    mounted(){
      this.active = JSON.parse(localStorage.getItem('xxl-immo-activeConv')) || conversations[0];
      this.conversations = JSON.parse(localStorage.getItem('xxl-immo-conversations')) || conversations;
      // this.$refs["messagesContainer"].scrollTop = this.$refs["messagesContainer"].clientHeight+100000000;
      this.$refs["discussions-list"].scrollBottom = -this.$refs["discussions-list"].clientHeight+100000000 ;
    }
  }
</script>

<template>
  <ExtranetView :path="path">
    <div class="container">
      <div class="container-body">
        <div class="discussions">
          <div class="discussions-list">
            <div class="row-centered discussions-list--top">
              <h3>Conversations</h3>
              <span>{{ unread }}</span>
            </div>
            <div class="box1 discussions-list--box">
              <div class="row-centered discussions-list--header">
                <span>Utilisateurs</span>
                <span>({{ totalConv }})</span>
              </div>
              <div class="discussions--items" ref="discussions-list">
                <div
                  v-for="(conv, index) in conversations"
                  :key="conv?.user?.name+index"
                  class="discussion box1"
                  :class="{'discussion__active': active.id === conv.id}"
                  @click="active = conv"
                >
                  <div class="row-centered discussion--header">
                    <div class="row-centered">
                      <img class="pp-xs" :src="conv?.user?.picture" alt="">
                      <span>{{ conv?.user?.name }}</span>
                    </div>
                    <img :src="assets.tripleDots" alt="">
                  </div>
                  <div class="row-centered discussion--infos">
                    <span>08-10-2022</span>
                    <div class="dot"></div>
                    <div  class="discussion--last-message">
                      <span>
                        {{ conv?.messages[conv?.messages?.length-1].content }}
                      </span>
                      ...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box1 discussion-container">
            <div class="row-centered discussion--top">
              <div class="row-centered">
                <img :src="active.user.picture" alt="" class="pp-sm">
                <div>
                  <div class="row-centered user-infos">
                    <span class="discussion--user-name">{{ active.user.name }}</span>
                    <div class="dot2"></div>
                    <span class="discussion--module">{{ active.user.module }}</span>
                  </div>
                  <div class="row-centered discussion-infos">
                    <span>05/05/2022</span>
                    <div v-if="active.user.role" class="dot2"></div>
                    <span v-if="active.user.role">{{ active.user.role }}</span>
                    <span class="dot2"></span>
                    <div class="row-centered">
                      <img :src="assets.messageGray" alt="">
                      {{ active.messages.length }} messages</div>
                  </div>
                </div>
              </div>
              <img :src="assets.tripleDots" alt="" />
            </div>
            <div class="discussion--messages" ref="messagesContainer">
              <div 
                v-for="(message, index) in active.messages" 
                :key="message.content"
                class="row-centered discussion-message"
                :class="{ 'discussion-message__right': message.author === 1}"
              >
                <div class="row-centered message--user">
                  <img
                    v-if="(index === 0 || active.messages[index-1].author != message.author) && message.author === 2"
                    :src="active.user.picture"
                    class="pp-sm"
                  />
                </div>
                <div class="message--content">
                  {{ message.content }}
                </div>
              </div>
            </div>
            <div class="discussion--send-message">
              <input 
                type="text"
                placeholder="Entrez votre message"
                :name="`message-${active.id}`"
                v-model="message"
              >
              <button @click.prevent="recieveMessage">Receive</button>
              <button type="submit" @click.prevent="sendMessage">
                <img :src="assets.sendIcon" alt="">
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ExtranetView>
</template>

<style src="./FirstPage.css"></style>