<script>
import AuthHeader from '@/containers/Auth/AuthHeader/AuthHeader.vue'
import useAuthStore from '../store'
import mailIcon from '@/assets/icons/mail-box.svg'
import { mapActions, mapWritableState } from 'pinia';
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

export default {
  components: {
    AnimatedLoader,
    AuthHeader,
    ErrorMessage,
    ButtonOne
  },
  data() {
    return {
      assets: {
        mailIcon,
      }
    }
  },
  computed: {
    token() {
      return this.$route.query.token;
    },
    ...mapWritableState(useAuthStore , ['validateAccProcess']),
  },
  methods: {
    ...mapActions(useAuthStore, ['validateAccount']),
  },
  mounted(){
    this.validateAccount(this.token, this.$confetti)
  }
}
</script>

<template>
  <div class="page auth-page">
    <div class="auth-box validate-acc">
      <AuthHeader title="Activez votre compte !" />
      <div v-if="validateAccProcess.loading === true" class="validation-pending">
        <div class="validation-pending-animation">
          <h4>Patientez ...</h4>
          <AnimatedLoader dark />
        </div>
      </div>
      <ErrorMessage v-else-if="validateAccProcess.status === 'failure'" :message="validateAccProcess.error" />
      <div v-else-if="validateAccProcess.status === 'success'" class="validation-succeed">
        <h4>Compte activé avec SUCCÈS !</h4>
        <ButtonOne @click="$router.push('/login')" text="Connectez vous !" />
      </div>
    </div>
  </div>
</template>

<style src="../Auth.css"></style>
<style src="../SignUp.css"></style>
<style src="./style.css"></style>