<script lang="js">
import { mapActions, mapWritableState } from 'pinia';

import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import TextField1 from '@/components/TextField1/TextField1.vue';
import TableOne from '@/containers/TableOne/TableOne.vue';
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'

import { formSteps } from './datas';
import { path as basePath } from "../datas"

import useCoOwnerStore from '../store';
import { initialList } from '@/utils/helpers';


import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';
const baseUrl = basePath[basePath.length - 1].url
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';

export default {
  components: {
    RentalManagement,
    EditionForm,
    TextField1,
    TableOne,
    ButtonOne,
    AnimatedLoader,
    ErrorMessage
},
  data() {
    return {
      // path,
      steps: formSteps,
      currentStep: 0,
      changed: false,
      initialList,
      deleteClicked: false,
      coOwnerFound: false
    }
  },
  computed: {
    // gives access to store owner inside the component and allows setting it
    ...mapWritableState(useCoOwnerStore, ['coOwner', 'coOwnerIbans', 'coOwnerProperties', 'updatedCoOwnerDatas', 'deletionProcess', 'loading', 'status', 'error', 'getCoOwnerProcess', 'updateCoOwnerProcess', 'updateCoOwnerIbanProcess']),
    urlParam() {
      return this.$route.params.coOwnerId
    },
    coOwnerId() {
      return parseInt(this.urlParam);
    },
    path() {
      return [
        ...basePath,
        {
          label: (this.coOwner.first_name + " " +this.coOwner.lastname).split(" ").slice(0, 2).join("-").toUpperCase() + "...",
          url: baseUrl + "/" + this.coOwnerId
        }
      ]
    }
  },
  methods: {
    findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    },
    reload() {
      location.reload()
    },
    ...mapActions(useCoOwnerStore, ['getCoOwner', 'updateCoOwner', 'deleteCoOwner', 'updateCoOwnerIban']),
    handleUpdateValue(name, newValue) {
      this.changed = true;
      this.coOwner[name] = newValue;
      ! this.updatedCoOwnerDatas.includes(name) && this.updatedCoOwnerDatas.push(name);
    },
    handleDeleteCoOwner() {
      if (this.deleteClicked) {
        this.deleteClicked = false;
        this.deleteCoOwner(this.coOwnerId);
      } else {
        this.deleteClicked = true;
      }
    }
  },
  async mounted(){
    this.path[this.path.length-1].url += this.urlParam
    this.getCoOwner(this.coOwnerId)
  },
}
</script>

<template>
  <RentalManagement :path="path">
    <div v-if="getCoOwnerProcess.status === 'pending'">
      <AnimatedLoader dark />
    </div>
    <div v-if="getCoOwnerProcess.status === 'success'" class="container">
      <div class="owner-header">
        <h1 class='container-title'>CoPropriétaire: {{ coOwner.first_name }} {{ coOwner.lastname }}</h1>
        <ButtonOne 
          @click="handleDeleteCoOwner()" 
          :text="deleteClicked ? 'Cliquez à nouveau pour supprimer' : 'Supprimer'" 
          :dangerous="deleteClicked"
          :loading="deletionProcess.loading"
          :status="deletionProcess.status"
          isAsync
        />
      </div>
      <div class="profile container-body">
        <EditionForm :changed="changed"  :steps="steps" @step="(step) => currentStep = step" edit :loading="loading" :status="status" >
          <template #form>
            <form class="flex flex-col w-full" @submit.prevent="() => updateCoOwner(coOwnerId)">
              <section class="form-part" v-if="currentStep == findIndex('general')" >
              <TextField1 fullWidth label="Civilités" placeholder="Mr., Mme, ..." :value="coOwner.civility"  @update:value="newValue => handleUpdateValue('civility', newValue)" />

              <TextField1 fullWidth label="Email" placeholder="email" :value="coOwner.email"  @update:value="newValue => handleUpdateValue('email', newValue)" />
            
              <div class="row w-full">
                <TextField1 fullWidth label="Prénom" placeholder="prénom" :value="coOwner.first_name"  @update:value="newValue =>  handleUpdateValue('first_name', newValue)" />
 
                <TextField1 fullWidth label="Nom" placeholder="nom" :value="coOwner.lastname"  @update:value="newValue =>  handleUpdateValue('lastname', newValue)" />
              </div>

              <div class="row w-full">
                <TextField1 label="Date de naissance" type="date" :value="coOwner.date_of_birth"  @update:value="newValue => handleUpdateValue('date_of_birth', newValue)" fullWidth />
                
                <TextField1 label="Lieu de naissance" placeholder="Lieu de naissance" :value="coOwner.place_of_birth"  @update:value="newValue => handleUpdateValue('place_of_birth', newValue)" fullWidth />
              </div>

              <div class="row w-full">
                <TextField1 label="Adresse" placeholder="adresse" :value="coOwner.address"  @update:value="newValue => handleUpdateValue('address', newValue)" fullWidth />
              
                <TextField1 label="Complément d'adresse" placeholder="Complément d'adresse" :value="coOwner.complete_address"  @update:value="newValue => handleUpdateValue('complete_address', newValue)" fullWidth />
              </div>

              <div class="row w-full">
                <TextField1 label="Ville" placeholder="ville" :value="coOwner.city"  @update:value="newValue => handleUpdateValue('city', newValue)" fullWidth />
            
                <TextField1 label="Pays" placeholder="pays" :value="coOwner.country"  @update:value="newValue =>  handleUpdateValue('country', newValue)" fullWidth />
              </div>

              <div class="row w-full">
                <TextField1 label="Code postal" placeholder="code postal" :value="coOwner.zip_code"  @update:value="newValue => handleUpdateValue('zip_code', newValue)" fullWidth />
          
                <TextField1 label="Fax" placeholder="fax" :value="coOwner.fax"  @update:value="newValue =>  handleUpdateValue('fax', newValue)" fullWidth />
              </div>

              <div class="row w-full">
                <TextField1 label="Téléphone" placeholder="téléphone" :value="coOwner.phone"  @update:value="newValue => handleUpdateValue('phone', newValue)" fullWidth />

                <TextField1 label="Portable" placeholder="portable" :value="coOwner.cell_phone"  @update:value="newValue => handleUpdateValue('cell_phone', newValue)" fullWidth />
              </div>
              <div class="flex flex-col gap-4">
                    <ErrorMessage v-if="updateCoOwnerProcess.status === 'failure'" :message="updateCoOwnerProcess.error" />
                    <ButtonOne isAsync :loading="updateCoOwnerProcess.loading" :status="updateCoOwnerProcess.status" text="Modifier" />
                </div>
            </section>
          </form>
          <form class="flex flex-col w-full" @submit.prevent="updateCoOwnerIban(coOwnerIbans.id, coOwnerId)">
            <section class="form-part" v-if="currentStep == findIndex('iban')" >
                <div class="row w-full">
                  <TextField1 fullWidth label="IBAN" placeholder="IBAN" :value="coOwnerIbans.iban"  @update:value="newValue => coOwnerIbans.iban = newValue"  />
              
                  <TextField1 fullWidth label="BIC" placeholder="bic" :value="coOwnerIbans.bic"  @update:value="newValue => coOwnerIbans.bic = newValue"  />
                </div>

                <div class="row w-full">
                  <TextField1 fullWidth label="Nom créancier" placeholder="Banque" :value="coOwnerIbans.creditor_name"  @update:value="newValue => coOwnerIbans.creditor_name = newValue"  />
            
                  <TextField1 fullWidth label="N° ICS" placeholder="ICS" :value="coOwnerIbans.ics_number"  @update:value="newValue => coOwnerIbans.ics_number = newValue"  />
                </div>
                <div class="flex flex-col gap-4">
                    <ErrorMessage v-if="updateCoOwnerIbanProcess.status === 'failure'" :message="updateCoOwnerIbanProcess.error" />
                    <ButtonOne isAsync :loading="updateCoOwnerIbanProcess.loading" :status="updateCoOwnerIbanProcess.status" text="Modifier" />
                </div>
            </section>
          </form>
           

         

            <section class="form-part" v-if="currentStep == findIndex('properties')" >
              <TableOne
                title="Liste des Biens"
                :cols="['NUMÉRO', 'ADRESSE', 'COMPLÉMENT D\'ADRESSE']"
                :rows="[]"
                :actions="coOwnerProperties.actions"
                :total="coOwnerProperties.total"
                noSearch
                @emitedOffset="(value) => offset = value"
                baseUrl="/gestion-locative/coproprietaires/proprietes"
              />
            </section>

            <section class="form-part" v-if="currentStep == findIndex('accounting')" >
              <TextField1 label="Type de comptabilité" placeholder="type de compatbilité" :value="coOwner.accounting_type"  @update:value="newValue => handleUpdateValue('accounting_type', newValue)" />
      
              <TextField1 label="Compte comptable trop perçu" placeholder="Iban ou Numéro simple" :value="coOwner.accounting_account_overpayment"  @update:value="newValue => handleUpdateValue('accounting_account_overpayment', newValue)" />
            </section>
          
            <section class="form-part" v-if="currentStep == findIndex('taxation')" >
              <div class="flexed">
                <input type="checkbox" name="taxation" v-model="coOwner.ifi" @change="(e) => handleUpdateValue('ifi', e.target.value)" id="ifi" >
                <label for="ifi">Propriétaire assujetti à l'IFI (Impôt sur la Fortune Immobilière)</label>
              </div>
              <div class="flexed">
                <input type="checkbox" name="taxation" v-model="coOwner.vat" @change="(e) => handleUpdateValue('vat', e.target.value)" id="vat" >
                <label for="vat">ropriétaire assujetti à la TVA</label>
              </div>
            </section>

            <div class="flex gap-[20px]">
              <div v-if="deletionProcess.status === 'failure'">
                <ErrorMessage :message="deletionProcess.error" />
              </div>
  
              <div v-if="status === 'failure'">
                <ErrorMessage :message="error" />
              </div>
            </div>
          </template>
        </EditionForm>
      </div>
    </div>
    <div v-if="getCoOwnerProcess.status === 'failure'" class="container"> 
      <div class="owner-header">
        <h1 class='container-title'>Erreur de chargement</h1>
        <ButtonOne 
          @click="reload()" 
          text="Cliquez pour recharger" 
        />
      </div>
    </div>
  </RentalManagement>
</template>
<style src="./CoOwnerView.css"></style>