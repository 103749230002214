<script>
  import WorksView from '../WorksView.vue'
  import WorksWrapper from '@/containers/Works/WorksWrapper/WorksWrapper.vue'

  import { path, pageTitle, contentDatas, worksActions } from './datas'

  export default {
    data(){
      return {
        pageTitle,
        path,
        contentDatas,
        worksActions
      }
    },
    components: {
      WorksView,
      WorksWrapper
    },
  }
</script>

<template>
  <!-- WorksView container will be used in every pages with the same global structure -->
  <WorksView :path="path">
    <div class="container works-home">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <!-- corp de la page -->
      <div class="flexed container-body">
        <WorksWrapper
          :title="contentDatas.title"
          :button="contentDatas.button"
          :table="contentDatas.table"
          :worksActions="worksActions"
        />
      </div>
    </div>
  </WorksView>
</template>

<style src="./FirstPage.css"></style>