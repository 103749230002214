<script>
  import TableOne from '@/containers/TableOne/TableOne.vue';
  import PageContentBox from '../PageContentBox/PageContentBox.vue';
  import PageNavigationBox from '../PageNavigationBox/PageNavigationBox.vue';

  export default {
    components: {
      TableOne,
      PageContentBox,
      PageNavigationBox
    },
    props: {
      title: String,
      button: Object,
      table: Object,
      worksActions: Array
    },
  }
</script>

<template>
  <div class="flex gap-[37px]">
    <PageContentBox :title="title" :buttonText="button.text" :buttonUrl="button.url">
      <TableOne
        :title="table.title"
        :cols="table.cols" 
        :actions="table.actions"
        :rows="table.rows"
      />
    </PageContentBox>
  
    <!-- Works actions -->
    <PageNavigationBox :worksActions="worksActions"  />
  </div>
</template>