import FirstPage from '@/views/Works/Equipments/FirstPage.vue'
import NewEquipment from '@/views/Works/Equipments/New/NewEquipment.vue'
import EquipmentView from '@/views/Works/Equipments/EquipmentView/EquipmentView.vue'
// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/equipements"

const equipmentsRoutes = [
  {
    path: baseUrl,
    name: "equipments",
    component: FirstPage,
  },
  {
    path: baseUrl+"/creer",
    name: "newEquipment",
    component: NewEquipment
  },
  {
    path: baseUrl+"/:equipId",
    name: "equipment",
    component: EquipmentView
  }
];

export default equipmentsRoutes
