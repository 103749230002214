<script>
export default{
  props: {
    label: String,
    url: String,
    color: String,
    vect: String
  }
}
</script>

<template>
  <router-link :to="url" class="item-box3" :class="'item-box3__'+color">
    <img :src="vect" :alt="label" />
    <p>{{ label }}</p>
  </router-link>
</template>

<style src="./ItemBox3.css"></style>