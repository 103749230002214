<script>
import SignUpWrapper from '@/containers/Auth/SignUpWrapper/SignUpWrapper.vue';
import AccTypeBox from '@/containers/Auth/AccTypeBox/AccTypeBox.vue';

import { accountTypes } from './datas'

export default {
  components: {
    SignUpWrapper,
    AccTypeBox
  },
  data() {
    return {
      pageTitle: "Sign Up",
      conditionTitle: "",
      conditionParagraphs: [],
      popUp: false,
      accountTypes
    }
  },
  methods: {
    openPopUp(answer){
      this.popUp = answer.value;
      this.conditionTitle = answer.title;
      this.conditionParagraphs = answer.texts;
    }
  }
}
</script>

<template>
  <SignUpWrapper title="Création de compte: étape 01" text="Êtes-vous un non - proféssionnel, un établissement ou une entreprise ? Veuillez sélectionner pour continuer l’enregistrement">
    <Teleport to='body' v-if="popUp">
      <div class="popup">
        <p class="popup-close btn" @click="popUp=false">close</p>
        <div class="acc-general-cond">

          <h4>Condition générale d'utilisation: <br/>Inscription en tant que {{ conditionTitle }}</h4>
          <div>
            <p v-for="(paragraph, index) in conditionParagraphs" :key="index">
              {{ paragraph }}
            </p>
          </div>
        </div>
      </div>
    </Teleport>
    <div class="account-types">
      <AccTypeBox
        v-for="{ name, text, popUp, type } in accountTypes"
        :key="type"
        :type="type"
        @emited-pop-up="(answer) => openPopUp(answer)" 
        :name="name" 
        :text="text"
        :popUpTexts="popUp.texts" 
      />
    </div>
  </SignUpWrapper>
</template>

<style src="./Auth.css"></style>
<style src="./SignUp.css"></style>