import AccountingAccounts from '@/views/Accounting/AccountingAccounts/AccountingAccounts.vue'

const baseUrl = "/comptabilite/comptes"

const accountingAccountsRoutes = [
  {
    path: baseUrl,
    name: "accountingAccounts",
    component: AccountingAccounts,
  },
];

export default accountingAccountsRoutes
