<script>
import WorksView from '../WorksView.vue'
import PageContentBox from '@/containers/Works/PageContentBox/PageContentBox.vue'
import TableOne from '@/containers/TableOne/TableOne.vue'

import { path, pageTitle, diagnostics } from './datas'

export default {
  data() {
    return {
      path,
      pageTitle,
      diagnostics
    }
  },
  components: {
    WorksView,
    PageContentBox,
    TableOne
  },
}
</script>

<template>
  <WorksView :path="path">
    <div class="container works-diagnostics">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <!-- corp de la page -->
      <div class="container-body">
        <PageContentBox title="Diagnostiques" buttonText="Actualiser" buttonUrl="/travaux/diagnostiques">
          <div>
            <div class="flexed all-diagnostics">
              <div v-for="{ title, logo, amount, expired, comming } in diagnostics" :key="title + amount"
                class="row-centered">
                <img :src="logo" alt="">
                <div>
                  <h3 class="container-title">{{ title }}</h3>
                  <p>{{ amount }} diagnostiques réalisés</p>
                  <p>Expiré(s) : {{ expired }} / Expiration(s) à venir: {{ comming }}</p>
                </div>
              </div>
            </div>

            <div class="">
              <h3 class="container-title">Diagnostiques à renouveler</h3>

              <TableOne title="Liste des diagnotics à renouveler dans les 3 mois à venir" :rows="[]"
                :cols="['Désignation', 'Date de début', 'Date de fin']" :actions="[]" />
            </div>
          </div>
        </PageContentBox>
      </div>
    </div>
  </WorksView>
</template>

<style src="./FirstPage.css"></style>