<script>
  export default {
    props: {
      icon: String,
      title: String,
      text: String
    }
  }
</script>

<template>
  <div class="item-box1 box1">
    <img :src="icon" :alt="title[0]" />
    <div class="item-box1--content">
      <h4>{{ title }}</h4>
      <p class="small">{{ text }}</p>
    </div>
  </div>
</template>

<style src="./ItemBox1.css"></style>