import { path as basePath } from "../RentalManagementHome/datas"

const baseUrl = basePath[basePath.length-1].url+"/biens"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "biens",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Biens"

// fonction permettant de mettre les url au bon format en fonction de l'url de base
function url(url){
  return baseUrl+url
}

export const items = [
  {
    text: "Gestion des biens",
    buttons: [
      {
        label: "Créer un bien",
        url: url("/creer"),
        empty: false
      },
      {
        label: "Ajouter Equipement",
        url: url("/ajouter-equipement"),
        empty: true
      }
      // {
      //   label: "Liste des vacants",
      //   url: url("/list?type=vacants"),
      //   empty: true
      // }
    ]
  },
  {
    text: "Dans le cas ou vous aurez des biens archivées ou inocupé depuis un moment",
    buttons: [
      {
        label: "Liste des archivés",
        url: url("/list?type=archived"),
        empty: false
      },
      {
        label: "en savoir plus",
        url: url("/list?type=archived"),
        empty: true
      },
    ]
  },
  {
    text: "Les immeubles rassemblent plusieurs biens",
    buttons: [
      {
        label: "Gestion des immeubles",
        url: url("/immeubles"),
        empty: false
      },
      {
        label: "Ajouter un immeuble",
        url: url("/immeubles/creer"),
        empty: false
      },
    ]
  }
]

export const tableDatas = {
  title: 'Liste des Biens',
  cols: [
    'N° de Bien', 'Type', 'Adresse', 'Ville', 'Pays', 'Code Postale'
  ],
  rows: [
   
  ],
 
}