<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';

import { mapWritableState } from 'pinia';
import usePropertyStore from '../../store';
import { pieces } from '../datas';

export default {
    data() {
        return {
            pieces
        }

    },
    components: {
        TextField1
    },
    computed: {
        // gives access to store property inside the component and allows setting it
        ...mapWritableState(usePropertyStore, ['property']),
    },
    methods: {
        onValueSelect() {

        },

    }
}
</script>

<template>
  
        <!-- <div class="flexed my-1">
                <input type="checkbox" name="is_coowner" v-model="property.is_coowner" id="is_coowner"
                    class="mt-1">
                <label for="is_coowner">Bien situé en copropriété ? si oui continuez.</label>
        </div> -->
        <div class="row w-full">
            <TextField1 fullWidth label="Réf lot copropriété" placeholder="Réf lot copropriéte" :value="property.ref_coowner"
             @update:value="newValue => property.ref_copro = newValue" />
             <TextField1 fullWidth  label="Concierge" placeholder="Concierge" :value="property.concierge"
             @update:value="newValue => property.concierge = newValue" />
        </div>
       
        
        
        
        <div class="row w-full">
            <TextField1 fullWidth label="Copropriété" placeholder="Copropriéte" :value="property.copropriete"
             @update:value="newValue => property.copropriete = newValue" :optional="true" />

             <TextField1 fullWidth 
             label="Lot" placeholder="Lot" :value="property.lot"
             @update:value="newValue => property.lot = newValue" />

        </div>
       

   
    

</template>