import { path as basePath } from "../RentalManagementHome/datas"

const  baseUrl = basePath[basePath.length-1].url+"/coproprietaires"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "copropriétaires",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "CoPropriétaires"


function url(url){
  return baseUrl+url
}

export const itemsCoOwnerList = [
  {
    text: "Gestion des copropriétaires",
    buttons: [
      {
        label: "Créer un copropriétaire",
        url: url("/creer"),
        empty: true
      },
      {
        label: "Solde de tout compte",
        url: url("/soldes-comptes"),
        empty: false
      }
    ]
  },
 
  {
    text: "Envoyez un mail simultané à plusieurs ou tous vos copropriétaires",
    buttons: [
      {
        label: "Envoyer un mail groupé",
        url: url("/envoie-mail"),
        empty: true
      },
    ]
  }
]

export const tableDatas = {

  coproprietaire:{
    title: 'Liste des CoPropriétaires',
    cols: [
      'N° CoPropriétaire', 'Nom', 'Prénom', 'Email', 'Téléphone'
    ],
    rows: [
      
    ],
  },


  actions: [
    {
        type: 'display',
        label: "Afficher"
    },
    {
      type: 'delete',
      label: "Supprimer"
    },
    {
      type: 'update',
      label: "Modifier"
    }
  ]
}