import FirstPage from '@/views/Registers/Archives/FirstPage.vue'

// url de base des pages relatives à la corbeille
const baseUrl = "/registres/archives"

const registersArchives = [
  {
    path: baseUrl,
    name: "registersArchives",
    component: FirstPage,
  }
];

export default registersArchives
