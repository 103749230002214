import FirstPage from '@/views/RentalManagement/Goods/FirstPage.vue'
import NewProperty from '@/views/RentalManagement/Goods/New/NewProperty.vue'
import PropertyView from '@/views/RentalManagement/Goods/PropertyView/PropertyView.vue'
import EquipmentView from '@/views/RentalManagement/Goods/EquipmentView/EquipmentView.vue'
// url de base des pages relatives aux biens
const baseUrl = "/gestion-locative/biens"

const goodsRoutes = [
  {
    path: baseUrl,
    name: "goods",
    component: FirstPage,
  },
  {
    path: baseUrl+"/creer",
    name: "newProperty",
    component: NewProperty
  },
  {
    path: baseUrl+"/:propertyId",
    name: "property",
    component: PropertyView
  },
  {
    path: baseUrl+"/ajouter-equipement",
    name: "ajoutEquipement",
    component: EquipmentView
  }
  
];

export default goodsRoutes
