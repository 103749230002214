import { getLocalUser, refreshToken, setLocalUser } from "@/utils/helpers";
import { BASE_URL } from "@/urls";
import axios from "axios";

const access_token = getLocalUser("access_token");

const client = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Accept': 'application/json',
    "Authorization": `Bearer ${access_token}`
  }
})


client.interceptors.response.use(
  function(interceptorResponse){
    refreshToken()
    return interceptorResponse
  },
  function(interceptorError){
    if(interceptorError.response.status === 401){
      alert("Votre Session a expiré, vous allez être redirigé vers la page de connexion !");
      setLocalUser();
      location.replace("/login");
    }
    return interceptorError
  }
)

export default client