import FirstPage from '@/views/Syndics/Coproperties/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/syndics/coproprietes"

const copropertiesRoutes = [
  {
    path: baseUrl,
    name: "coproperties",
    component: FirstPage,
  }
];

export default copropertiesRoutes
