<script lang="js">
  import ExtranetView from '../ExtranetView.vue';

  import { users, path, pageTitle } from './datas'

  import addUser from '@/assets/icons/add-user.svg'
  import profilesSet from '@/assets/vectors/profiles-set.svg'
  import tripleDots from '@/assets/icons/triple-dots.svg'

  export default {
    components: {
      ExtranetView
    },
    data() {
      return{
        assets: {
          addUser,
          profilesSet,
          tripleDots
        },
        users,
        path,
        pageTitle
      }
    }
  }
</script>

<template>
  <ExtranetView :path="path">
    <div class="container extranet-users">
      <div class="container-header">
        <h3>{{ pageTitle }}</h3>
        <div class="container-header--add-user">
          <div>
            <img :src="assets.addUser" alt="" />
            <p>Ajouter un nouvel utilisateur</p>
          </div>
          <img :src="assets.profilesSet" alt="">
        </div>
      </div>
      <div class="container-body list">
        <div 
          v-for="list in users"
          :key="'option-'+list.option"
          class="box1 list--item option-list"
          :class="'option-list__'+list.color"
        >
          <div class="option-list--header">
            <div>
              <div class="dot3"></div>
              <span class="option-list--name">{{ list.option }}</span>
              <span class="option-list--total">{{ list.total }}</span>
            </div>
            <img :src="list.icon" alt="">
          </div>
          <div class="users-list">
            <div
              v-for="{ name, picture, date, role } in list.usersList"
              :key="'user-'+name"
              class="box1 user"
            >
              <div class="user-header">
                <div>
                  <img class="user--picture" :src="picture" alt="">
                  <p class="user--name">{{ name }}</p>
                </div>
                <img :src="assets.tripleDots" alt="">
              </div>
              <div class="user-infos">
                <span class="user--date">{{ date }}</span>
                <div v-if="list.option" class="dot2"></div>
                <span v-if="list.option" class="user--option">{{ list.option }}</span>
                <div v-if="role" class="dot2"></div>
                <span v-if="role" class="user--role">{{ role }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ExtranetView>
</template>

<style src="./FirstPage.css"></style>