<script>
  export default{
    data(){
      return{
        value: 0
      }
    }, 
    emits: ['emitedValue'],
    watch: {
      value(newValue){
        this.$emit('emitedValue', newValue)
      }
    }
  }
</script>

<template>
  <div class="count-controller">
    <button class="decrease-count" @click="value -= 1">-</button>
    <p class="value-count">
      {{ `${value < 0 ? '-' : ''}${Math.abs(value) <= 9 ? '0' : ''}${Math.abs(value)}` }}
    </p>
    <button class="increase-count" @click="value += 1">+</button>
  </div>
</template>

<style src="./CountController.css"></style>