<script>
  import PathViewer from '@/containers/PathViewer/PathViewer.vue';
  import SideBar1 from '@/containers/SideBar1/SideBar1.vue';

  import { registers } from '@/utils/data/aside'

  export default {
    components: {
      PathViewer,
      SideBar1
    },
    props: {
      path: Array
    },
    data() {
      return {
        title: registers.title,
        asideLinks: registers.asideLinks,
      }
    }
  }
</script>

<template>
  <div class="registers page">
    <PathViewer :path="path" />
    <div class="body">
      <SideBar1
        :title="title"
        :links="asideLinks"
        module="registres"
      />
      <slot></slot>
    </div>
  </div>
</template>

