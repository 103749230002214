import FirstPage from '@/views/GED/Folders/FirstPage.vue'

// url de base des pages relatives aux dossiers
const baseUrl = "/ged/dossiers"

const foldersRoutes = [
  {
    path: baseUrl,
    name: "gedFolders",
    component: FirstPage,
  }
];

export default foldersRoutes
