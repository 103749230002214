<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';
import { mapWritableState } from 'pinia';
import useTenantStore from '../../store';
import {civils, pieces} from '../datas';
export default {
  data(){
    return{
      civils,
      pieces
    }
  },
  components: {
    TextField1
  },
  computed: {
    // gives access to store owner inside the component and allows setting it
    ...mapWritableState(useTenantStore, ['tenant']),
  },
  methods:{
    handleIntValue(name, newValue) {
    
    this.tenant[name] = parseInt(newValue);
    
  },
  }
}
</script>

<template>
  <div class="row w-full">
    <div class="flex flex-col min-w-[25%]">
    <div class="flex flex-row mb-2"><label>Civilité <span>: </span></label>
    <p class="required-tag text-red ms-1">*</p></div>
    <select v-model="tenant.civility" class="form-select rounded-md">
      <option disabled value="">Selectionnez</option>
      <option v-for="(item, i) in civils" :key="i" >
      {{ item.value }}</option>
    </select>
   
   </div>
  </div>

  <div class="row w-full">
    <TextField1 fullWidth  label="Prénom" placeholder="prénom" :value="tenant.first_name"  @update:value="newValue => tenant.first_name = newValue" />
    <TextField1 fullWidth  label="Nom" placeholder="nom" :value="tenant.lastname"  @update:value="newValue => tenant.lastname = newValue" />
  </div>

  <TextField1 label="Email" placeholder="email" :value="tenant.email"  @update:value="newValue => tenant.email = newValue" fullWidth />

  <div class="row w-full">
    <TextField1  label="Date de naissance" type="date" :value="tenant.date_of_birth"  @update:value="newValue => tenant.date_of_birth = newValue" fullWidth  />
  
    <TextField1 label="Lieu de naissance" placeholder="Lieu de naissance" :value="tenant.place_of_birth"  @update:value="newValue => tenant.place_of_birth = newValue" fullWidth  />
  </div>

  <div class="row w-full ">
    <TextField1 fullWidth label="Pays" placeholder="Pays" :value="tenant.country"  @update:value="newValue => tenant.country= newValue"/>
    <TextField1  fullWidth label="Ville" placeholder="Ville" :value="tenant.city"  @update:value="newValue => tenant.city= newValue"/>
  </div>
  <div class="row w-full">
    <TextField1 fullWidth  label="Profession" placeholder="Profession" :value="tenant.profession"  @update:value="newValue => tenant.profession = newValue" />

    <TextField1 fullWidth  label="Code postal" placeholder="Votre code postal" :value="tenant.zip_code"  @update:value="newValue => tenant.zip_code = newValue"  />
  </div>
    <div class="row w-full ">
      <div class="flex  flex-col min-w-[40%]">
      <div class="flex flex-row mb-2"><label>Nombre d'enfants<span>: </span></label>
      <p class="required-tag text-red ms-1">*</p></div>
      <select v-model="tenant.child" @change="handleIntValue('child', tenant.child)" class="form-select rounded-md">
        <option disabled value="">Selectionnez</option>
        <option v-for="(item, i) in pieces" :key="i" >
        {{ item.value }}</option>
      </select>
    </div>
  
    <div class="flex  flex-col min-w-[40%]">
      <div class="flex flex-row mb-2"><label>Nombre d'animaux de compagnie<span>: </span></label>
      <p class="required-tag text-red ms-1">*</p></div>
      <select v-model="tenant.pets" @change="handleIntValue('pets', tenant.pets)" class="form-select rounded-md">
        <option v-for="(item, i) in pieces" :key="i" >
        {{ item.value }}</option>
      </select>
    </div>
  </div>
    
  <div class="row w-full">
   
    <TextField1 fullWidth  label="Fax" placeholder="fax" :value="tenant.fax"  @update:value="newValue => tenant.fax = newValue" />
    <TextField1 label="Téléhone" placeholder="téléphone" :value="tenant.phone"  @update:value="newValue => tenant.phone = newValue" fullWidth />
  </div>

</template>