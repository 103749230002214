import { getLocalUser, initProcess, initProcessReverse, noEmptyFields, setLocalUser } from "@/utils/helpers";
import { defineStore } from "pinia";
import { initialCompanyInfos, initialUser } from "./datas";
import client from "./api/client";
// import axios from "axios";

const localUser = getLocalUser()
const initialInfos = {
  access_token: "",
  refresh_token: "",
  ...initialUser,
  id_professionnel: "",
  ...initialCompanyInfos
}
const operation = {
  loading: false,
  status: ""
}

const useAuthStore = defineStore('auth', {
  state: () => ({
    user: localUser ? localUser : initialInfos,
    openedSection: 'main-infos',
    loading: false,
    status: "",
    errorMessage: "Une erreur pourrait se produire !",
    loginStatus: operation,
    validateAccProcess: initProcessReverse,
    resendMailProcess: initProcess,
    resetPasswordProcess: initProcess,
  }),
  getters: {
    idInfos: (state) => ({
      nom: state.user.nom,
      prenom: state.user.prenom,
      email: state.user.email,
      password: state.user.password,
      password_confirm: state.user.password_confirm
    }),
    proInfos: (state) => ({
      nom_entreprise: state.user.nom_entreprise,
      email_pro: state.user.email_pro,
      id_professionnel: state.user.id_professionnel,
      siret_siren: state.user.siret_siren,
      tva: state.user.tva
    }),
    compInfos: (state) => ({
      nom_entreprise: state.user.nom_entreprise,
      email_pro: state.user.email_pro,
      type_entreprise: state.user.type_entreprise,
      siret_siren: state.user.siret_siren,
      tva: state.user.tva,
    }),
    suppInfos: (state) => ({
      pays: state.user.pays,
      ville: state.user.ville,
      telephone: parseInt(state.user.telephone),
      adresse: state.user.adresse,
      code_postal: state.user.code_postal
    }),
  },
  actions: {
    async login(toast){
      if(!this.loginStatus.loading){
        toast.loading("Veuillez patienter")
        this.loginStatus = { ...this.loginStatus, loading: true, status: "pending" }
        const { email, password } = this.user
        if (email !== "" && password !== "") {
          try {
            const resp = await client.login({email, password})
            if(resp) {
              console.log({ resp });
              if (resp.status === 200) {
                console.log({ resp })
                setLocalUser(resp.data)
                this.loginStatus = { loading: false, status: "succeed" }
                toast.success("Content de te revoir", "Connexion Réussie");
                location.replace("/");
              } else {
                this.loginStatus = { loading: false, status: "failure" }
                this.errorMessage = "Erreur ! Veuillez vérifier votre connexion internet ou réessayer plus tard !"
              }
            }
          } catch(error) {
            console.log({ error });
            this.loginStatus = { loading: false, status: "failure" }
            this.errorMessage = error?.response?.data?.detail;
            // alert(error?.response?.data.detail)
          }
          // localStorage.setItem('xxl-profil-immo-user', JSON.stringify(user))
          // location.replace('/')
        } else {
          this.errorMessage = "Tous les champs sont obligatoires !"
          this.loginStatus = { loading: false, status: "failure" }
        }
      }
    },
    goToNextStep(){
      console.log("command recieved !")
      // this functions allows the user to go to the next step of the form
      if (this.openedSection === "main-infos") {
        if (noEmptyFields(this.idInfos)){
          if(this.user.password === this.user.password_confirm){
            // do this if all main infos are valid
            this.status = ""
            this.openedSection = 
              (this.user.user_type === "particulier" && "supp-infos") ||
              (this.user.user_type === "pro" && "pros-infos") ||
              (this.user.user_type === "entreprise" && "comp-infos") 
          } else {
            this.errorMessage = "Le mot de passe et la confirmation de mot de passe doivent être identiques",
            this.status = "failure"
            this.loading = false
          }
        } else {
          this.errorMessage = "Vous semblez avoir omis certains champs obligatoires !",
          this.status = "failure"
          this.loading = false
        }
        // do this if opened section is either the company infos or the pro infos section
      } else {
        if(
          // it's ok if, in the case where the opened section is pros infos section, there is no empty fields between the pro fields
          (this.openedSection === "pros-infos" && noEmptyFields(this.proInfos))
          // it's ok if, in the case where the opened section is company infos section, there is no empty fields between the company fields
          || (this.openedSection === "comp-infos" && noEmptyFields(this.compInfos))
        ) {
          this.openedSection = "supp-infos"
          this.status = ""
        } else {
          this.errorMessage = "Vous semblez avoir omis certains champs obligatoires !",
          this.status = "failure"
          this.loading = false
        }
      }
    },
    backToPrevStep(){
      console.log("code reached !")
      // this function allows the userto back to previous section
      if((this.openedSection !== "supp-infos") || (this.openedSection === "supp-infos" && this.user.user_type === "particulier")){
        this.openedSection= "main-infos"
      } else {
        this.openedSection = 
          (this.user.user_type === "entreprise" && "comp-infos") ||
          (this.user.user_type === "pro" && "pros-infos")
      }
    },
    async register(){
      // this object will return either the pro or the company infos, according to the form type
      if(!this.loading){
        let complementInfos = {}

        this.user.user_type === "pro" 
          ? complementInfos = this.proInfos
          : this.user.user_type === "entreprise"
            ? complementInfos = this.compInfos
            : complementInfos = {}

        const userDatas = {
          user_type: this.user.user_type,
          ...this.idInfos,
          ...this.suppInfos,
          ...complementInfos,
          complement_adresse: this.user.complement_adresse
        }

        if(!noEmptyFields(this.suppInfos, ['code_postal'])){
          this.errorMessage = "Il vous manquent certaines informations importantes"
          this.loading = false
          this.status = "failure"
        } else {
          this.loading = true;
          console.log(this.loading)
          try {
            console.log({ userDatas })
            const resp = await client.register(userDatas)
            if (resp){
              console.log({ resp });
              this.loading = false;
              this.status = "success"
              location.replace("/email-envoye/?action=signup&email="+userDatas.email);
            } else {
              this.errorMessage = "Erreur ! Veuillez vérifier votre connexion internet ou réessayer plus tard !"
              this.loading = false
              this.status = "failure"
            }
          } catch (error) {
            this.errorMessage = error?.response?.data?.detail;
            this.status = "failure"
            this.loading = false
            console.log({ error });
          }
        }
      } else {
        alert("processus déjà enclanché !\nVeuillez patienter svp !")
      }
    },
    async validateAccount(token, confetti){
      try{
        const response = await client.validateAccount(token)
        if(response){
          console.log({response})
          this.validateAccProcess = {
            loading: false,
            status: "success",
            error: ""
          }
          confetti.start({
            particles: [
              {type: 'rect', dropRate: 20}
            ],
            particlesPerFrame: 4
          })
          setTimeout(() => confetti.stop(), 5000)
        } else {
          this.validateAccProcess = {
            loading: false,
            status: "failure",
            error: "Une erreur s'est produite"
          }
        }
      } catch(error) {
        console.log({error})
        this.validateAccProcess = {
          loading: false,
          status: "failure",
          error: error?.response?.data?.detail
        }
      }
    },
    async resendCode(email){
      if(this.resendMailProcess.loading){
        alert("Processus en cours, merci de patienter !")
      } else {
        this.resendMailProcess = {
          ...this.resendMailProcess,
          status: "pending",
          loading: true
        }
        try{
          const resp = await client.resendCode(email);
          if(resp){
            console.log({resendCodeResp: resp});
            this.resendMailProcess = {
              ...this.resendMailProcess,
              status: "success",
              loading: false
            }
          } else {
            this.resendMailProcess = {
              ...this.resendMailProcess,
              status: "failure",
              loading: false,
              error: "Une erreur s'est produite !"
            }
          }
        } catch (resendCodeError) {
          console.log({resendCodeError})
          this.resendMailProcess = {
            ...this.resendMailProcess,
            status: "failure",
            loading: false,
            error: resendCodeError?.response?.data?.detail
          }
        }
      }
    },
    async sendResetPasswordRequest(router){
      if(this.resetPasswordProcess.loading){
        alert("Requête déjà en cours d'envoie, merci de patienter !")
      } else {
        this.resetPasswordProcess = {
          ...this.resetPasswordProcess,
          loading: true,
          status: "pending",
        }
        try{
          const resp = await client.sendRPRequest(this.user.email);
          if(resp){
            console.log({resp});
            this.resetPasswordProcess = {
              ...this.resetPasswordProcess,
              loading: false
            }
            router.push(`/mot-de-passe-oublie/nouveau/?email=${this.user.email}&step=0`)
          } else {
            this.resetPasswordProcess = {
              ...this.resetPasswordProcess,
              status: "failure",
              loading: false,
              error: "Une erreur s'est produite !"
            }
          }
        } catch(error){
          console.log({error})
          this.resetPasswordProcess = {
            ...this.resetPasswordProcess,
            status: "failure",
            loading: false,
            error: error?.response?.data?.detail
          }
        }
      }
    },
    async checkResetPasswordCode(router, code, email){
      if(this.resetPasswordProcess.loading){
        // alert("Requête déjà en cours d'envoie, merci de patienter !")
      } else {
        this.resetPasswordProcess = {
          ...this.resetPasswordProcess,
          loading: true,
          status: "pending",
        }
        try{
          const resp = await client.checkResetPasswordCode(code, email);
          if(resp){
            console.log({resp});
            this.resetPasswordProcess = {
              ...this.resetPasswordProcess,
              loading: false
            }
            router.push(`/mot-de-passe-oublie/nouveau/?email=${email}&step=1`)
          } else {
            this.resetPasswordProcess = {
              ...this.resetPasswordProcess,
              status: "failure",
              loading: false,
              error: "Une erreur s'est produite !"
            }
          }
        } catch(error){
          console.log({error})
          this.resetPasswordProcess = {
            ...this.resetPasswordProcess,
            status: "failure",
            loading: false,
            error: error?.response?.data?.detail
          }
        }
      }
    },
    async resetPassword(email, code){
      if(this.resetPasswordProcess.loading){
        // alert("Requête déjà en cours d'envoie, merci de patienter !")
      } else if(this.user.password !== this.user.password_confirm) {
        this.resetPasswordProcess = {
          error: "Le mot de passe et la confirmation de mot de passe doivent être identiques !",
          loading: true,
          status: "failure",
        }
      } else {
        this.resetPasswordProcess = {
          ...this.resetPasswordProcess,
          loading: true,
          status: "pending",
        }
        try{
          const resp = await client.resetPassword(this.user.password, this.user.password_confirm, email, code);
          if(resp){
            console.log({resp});
            this.resetPasswordProcess = {
              ...this.resetPasswordProcess,
              loading: false
            }
            location.replace("/login")
          } else {
            this.resetPasswordProcess = {
              ...this.resetPasswordProcess,
              status: "failure",
              loading: false,
              error: "Une erreur s'est produite !"
            }
          }
        } catch(error){
          console.log({error})
          this.resetPasswordProcess = {
            ...this.resetPasswordProcess,
            status: "failure",
            loading: false,
            error: error?.response?.data?.detail
          }
        }
      }
    },
  }
})


export default useAuthStore;