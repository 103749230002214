<!-- Deuxième Header recontré dans le module GED -->
<script>
  import TextField1 from '@/components/TextField1/TextField1.vue';
  import research1 from '@/assets/icons/research1.svg'
  
  export default {
    components: {
      TextField1
    },
    props: {
      title: String,
      text: String, 
      icon: String,
      button: Object
    },
    data(){
      return {
        searchKey: "",
        assets: {
          research: research1
        }
      }
    },
    methods: {
      handleChange(e){
        this.searchKey = e.target.value
      }
    }
  }
</script>

<template>
  <div class="row-centered ged-header2">
    <div class="row-centered ged-header2--intro">
      <img :src="icon" :alt="icon" />
      <div>
        <p class="title">{{ title || "titre" }}</p>
        <p class="text">{{ text || "texte" }}</p>
      </div>
    </div>
    <div class="row-centered ged-header2--options">
      <form class="row-centered ged-header2--search">
        <TextField1
          placeholder="Search"
          :value="searchKey"
          :onChange="handleChange"
          type="text"
          name="search"
        />
        <button class="full-centered" type="submit"><img :src="assets.research" alt="search"></button>
      </form>
      <router-link v-if="button" class="ged-header2--button" :to="button.url">{{ button.label }}</router-link>
    </div>
  </div>
</template>

<style src="./HeaderTwo.css"></style>