<script>
  import PathViewer from '@/containers/PathViewer/PathViewer.vue';
  import SideBar1 from '@/containers/SideBar1/SideBar1.vue';

  import { ged } from '@/utils/data/aside'

  export default {
    components: {
      PathViewer,
      SideBar1
    },
    props:{
      path: Array
    },
    data() {
      return {
        title: ged.title,
        asideLinks: ged.asideLinks,
        asideButtons: ged.asideButtons,
        pub: ged.pub
      }
    }
  }
</script>

<template>
  <div class="ged page">
    <PathViewer :path="path" />
    <div class="body">
      <SideBar1
        :title="title"
        :links="asideLinks"
        :buttons="asideButtons"
        :pub="pub"
        module="ged"
      />
      <slot></slot>
    </div>
  </div>
</template>

