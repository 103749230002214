<!-- Sous header recyclable utilisé dans les pages relatives à l'authentification  -->
<script>
import logo from '@/assets/logo_4.svg';

export default {
  props: {
    title: String,
    text: String,
    teaseTrial: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      logo
    }
  }
}
</script>

<template>
  <div class="auth-header">
    <div class="row-centered auth-flag">
      <img :src="logo" alt="">
      <p>Le logiciel de gestion immobilière par exellence. Gérer des biens n’a jamais été aussi facile. Découvrez XXL LOCKIMMO</p>
    </div>

    <h3 v-if="title">{{ title }}</h3>

    <div>
      <p class="auth-header--text" v-if="text">{{ text }}</p>
      <p class="auth-header--text" v-if="teaseTrial">Et profitez d'un essai gratuit pendant 30 jours sans engagement. 🎁</p>
    </div>
  </div>
</template>

<style src="./AuthHeader.css"></style>