import FirstPage from '@/views/RentalManagement/Tenants/FirstPage.vue'
import NewTenant from '@/views/RentalManagement/Tenants/New/NewTenant.vue'
import TenantView from '@/views/RentalManagement/Tenants/TenantView/TenantView.vue'
// url de base des pages relatives aux locataires
const baseUrl = "/gestion-locative/locataires"

const tenantsRoutes = [
  {
    path: baseUrl,
    name: "tenants",
    component: FirstPage,
  },
  
  {
    path: baseUrl+"/creer",
    name: "newTenant",
    component: NewTenant
  },

  {
    path: baseUrl+"/:rentId",
    name:"tenant-details",
    component: TenantView
  }


];

export default tenantsRoutes
