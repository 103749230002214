import FirstPage from '@/views/Works/Claims/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/sinistres"

const claimsRoutes = [
  {
    path: baseUrl,
    name: "claims",
    component: FirstPage,
  }
];

export default claimsRoutes
