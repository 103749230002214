<script>

import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

export default {
    props:{
        description:String,
    },
    components: {
        ButtonOne
    },
    data() {
        return {
            previewImage: null
            
        };
    },
    methods: {
        selectImage() {
            this.$refs.fileInput.click()
        },
        pickFile() {
            let input = this.$refs.fileInput
            let file = input.files
            if (file && file[0]) {
                let reader = new FileReader
                reader.onload = e => {
                    this.previewImage = e.target.result
                }
                console.log(this.previewImage);
                reader.readAsDataURL(file[0])
                this.$emit('input', file[0])
            }
        }
    }
}
</script>
<template>
    <div>
        <div v-if="previewImage === null"><img src="http://placehold.it/200x200" class="py-5 pr-8"></div>
        <div class="imagePreviewWrapper py-5" :style="{ 'background-image': `url(${previewImage})` }" @click="selectImage"
            v-else>
        </div>
        <p class="py-2">{{ description }}</p>
        <div class="flex flex-row justify-between">
            <div class="relative">
                <ButtonOne text="Modifier"/>
                <input class="absolute w-full h-full top-0 left-0 opacity-0" ref="fileInput" type="file" @input="pickFile">

            </div>
            
            <ButtonOne text="Enregistrer"/>
        </div>
       
            
       

    </div>
  
</template>
<style>
.imagePreviewWrapper {
    width: 200px;
    height: 200px;
    display: block;
    cursor: pointer;
    margin: 0 auto 30px;
    background-size: cover;
    background-position: center center;
   
}




</style>