<script lang="js">
import WorksView from '../../WorksView.vue'

import { mapActions, mapWritableState } from 'pinia'

import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import { path, pageTitle, formSteps} from './datas'
import TextField1 from '@/components/TextField1/TextField1.vue';

import useEquipementStore from '../store'
import usePropertyStore from '../../../RentalManagement/Goods/store'


export default {
    components: {
        WorksView,
       
        ButtonOne,
        EditionForm,
        TextField1
    },
    data() {
        return {
            path,
            pageTitle,
            steps:formSteps,
            currentStep:0,

        }
    },
    computed: {
        ...mapWritableState(useEquipementStore, ['equip', 'loading', 'status', 'error']),
        ...mapWritableState(usePropertyStore, ['goods']),

    },
    methods: {
        ...mapActions(useEquipementStore, ['addEquipment']),
        ...mapActions(usePropertyStore, ['getProperties']),
        findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    },
    reload() {
      location.reload()
    },
    },
    mounted() {
        this.getProperties(0);
        console.log(this.goods);
    },
    watch: {
        offset(value) {
            this.getProperties(value);
        }
    }
} 
</script>
<template>
    <WorksView :path="path">
        <div class="container">
            <h1 class="container-title"> {{ pageTitle }}</h1>
            <div class="container-body">
                <EditionForm :save="addEquipment" :steps="steps" @step="(step)" :loading="loading" :status="status">
                    <template #form>
                        <section class="form-part" v-if="currentStep == findIndex('general')">
                        <div class="flex flex-row items-center w-full">
                            <div class="flex flex-col">
                                <div class="flex flex-row mb-2"><label>Bien <span>: </span></label>
                                    <p class="required-tag text-red ms-1">*</p>
                                </div>
                                <select v-model="equip.id_bien_info" class="form-select rounded-md">
                                    <option disabled value="">Selectionnez</option>
                                    <option v-for="(item, i) in goods.list" :key="i" :value="item[0]">
                                        {{ item[1] }}</option>
                                </select>
                               
                            </div>
                            <router-link class="path-link mt-8 ms-10" :to="{ path: '/gestion-locative/biens/creer' }">
                                    <ButtonOne text="Nouveau" />
                            </router-link>
                        </div>
                        <div class="flex flex-row items-center  w-full">
                            <TextField1 small label="Nom de l'équipement" placeholder="Nom de l'équipement"
                                :value="equip.nom_equipement"
                                @update:value="newValue => equip.nom_equipement = newValue"/>

                        </div>
                        </section>
                        
                    </template>

                </EditionForm>
            </div>
    </div>
</WorksView>
</template>