<!-- Container utilisé dans la page d'accueil de registres de mandats, pour présenter le dashboard principale -->
<script>
  import BoardItem from '@/components/BoardItem/BoardItem.vue';

  export default {
    components: {
    BoardItem,
},
    props: {
      board: Object
    }
  }
</script>

<template>
  <div class="col-board">
    <p class="board--title" v-if="board.title">{{ board.title }}</p>
    <div class="board--separator" :class="'board--separator__'+board.color" />
    <div class="board--items">
      <BoardItem 
        v-for="({ title, subTitle, text, date, endDate, numero, total }, index) in board.items"
        :key="'board-item-'+index"
        :title="title"
        :subTitle="subTitle"
        :text="text"
        :date="date"
        :endDate="endDate"
        :numero="numero"
        :total="total"
      />
    </div>
  </div>
</template>

<style src="./ColBoard.css"></style>