<script>
import TextField1 from '@/components/TextField1/TextField1.vue'
import AuthHeader from '@/containers/Auth/AuthHeader/AuthHeader.vue'

import mailIcon from '@/assets/icons/mail-box.svg'
import phoneIcon from '@/assets/icons/phone-box.svg'

import { mapActions, mapWritableState } from 'pinia'
import useAuthStore from '../store'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

export default {
  components: {
    TextField1,
    AuthHeader,
    ButtonOne,
    ErrorMessage
  },
  data() {
    return {
      pageTitle: "Login",
      assets: {
        mailIcon,
        phoneIcon,
      }
    }
  },
  computed: {
    ...mapWritableState(useAuthStore, ['user', 'resetPasswordProcess'])
  },
  methods: {
    ...mapActions(useAuthStore, ['sendResetPasswordRequest'])
  }
}
</script>

<template>
  <div class="page auth-page">
    <div class="auth-box reset-pw">
      <AuthHeader title="Mot de passe oublié" />

      <form @submit.prevent="sendResetPasswordRequest($router)">
        <section>
          <div class="rp-section--header">
            <img :src="assets.mailIcon" alt="">
            <div>
              <h4>Entrez votre email</h4>
              <p>Identifiez vous afin de modifier votre mot de passe</p>
            </div>
          </div>
          <TextField1 small type="email" :onChange="(e) => user.email = e.target.value" :value="user.email" name="email" placeholder="Votre adresse email" />
        </section>
        <section>
          <div class="rp-section--header">
            <img :src="assets.phoneIcon" alt="">
            <div>
              <h4>Contactez le service client</h4>
              <p>06 65 65 45</p>
            </div>
          </div>
        </section>
        <ErrorMessage v-if="resetPasswordProcess.status === 'failure'" :message="resetPasswordProcess.error" />
        <ButtonOne isAsync text="Confirmez" :loading="resetPasswordProcess.loading" :status="resetPasswordProcess.status" />
      </form>

      <router-link class="rp-no--account" to="/signup"><p>Inscrivez vous !</p></router-link>
    </div>
  </div>
</template>

<style src="../Auth.css"></style>
<style src="../SignUp.css"></style>
<style src="./ResetPW.css"></style>