import { path as basePath } from "../datas";

// path

export const path = [
  ...basePath,
  {
    label: "Nouveau",
    url: basePath[basePath.length - 1].url + "/creer",
  },
];

// page title value
export const pageTitle = "Ajouter un bien";

export const items = [
    { label: "loyer", value: "loyer" },
    { label: "propriété", value: "propriete" }
  ];

export const pieces = [
  {label:"0", value:"0"},
  {label:"1", value:"1"},
  {label:"2", value:"2"},
  {label:"3", value:"3"},
  {label:"4", value:"4"},
  {label:"5", value:"5"},
  {label:"6", value:"6"},
  {label:"7", value:"7"},
  {label:"8", value:"8"},
  {label:"9", value:"9"},
  {label:"10", value:"10"}
];

export const loyers = [
  {label:"journalier", value:"journalier"},
  {label:"hebdomadaire", value:"hebdomadaire"},
  {label:"mensuel", value:"mensuel"},
 
];

// form steps
export const formSteps = [
  {
    name: "general",
    label: "Coordonnées",
  },
  {
    name: "description",
    label: "Description",
  },

  {
    name: "diagnostic",
    label: "Diagnostics",
  },
  {
    name: "photos",
    label: "Photos",
  },
  {
    name: "fiscales",
    label: "Infos Fiscales",
  },
  {
    name: "copropriété",
    label: "Copropriété",
  },
  
  // {
  //   name: "compteurs",
  //   label: "Compteurs",
  // },
 
  // {
  //   name: "rentabilité",
  //   label: "Rentabilité",
  // },
  
  // {
  //   name: "clé",
  //   label: "Clé",
  // },
  // {
  //   name: "entretien",
  //   label: "Entretien",
  // },
  // {
  //   name: "dossier technique",
  //   label: "Dossier Technique",
  // },
  // {
  //   name: "autres",
  //   label: "Autres Informations",
  // },
];

export const generalInfos = {
  id_owner: 0,
  type_de_bien:"",
  adresse: "",
  complement_add: "",
  ville: "",
  pays: "",
  code_postale:"",
  nb_escalier: "0",
  nb_porte: "0",
  nb_etage: "0",
  type_location:0,
  daily_amount:0,
  weekly_amount:0,
  monthly_amount:0,
  rent_type: ""

};

export const locationTypeInfos = {
  id:0,
  nom: "",
  description:"",

};

export const descriptionInfos = {
  designation:"",
  description:"",
  superficie:"",
  superficie_boutin:"",
  surface_terrasse:"",
  surface_annexe:"",
  surface_carrez:"",
  ref_cadastrale:"",
  nb_sallon:"0",
  nb_chambre:"0",
  nb_salle_a_manger:"0",
  nb_douche:"0",
  nb_cuisine:"0",
  autre_piece:""

};

export const diagnosticInfos = {
  annee_construction: "",
  date_dpe: "",
  energie: "",
  climat: "",
  diagnostic_dpe: "",
  diagnostic_plomb: "",
  diagnostic_termites: "",
  diagnostic_electricite: "",
  diagnostic_gaz: "",
  diagnostic_erp:""
};

export const fiscaleInfos = {
  // type_de_location:"",
  // categorie_location:"",
  dispositif_fiscale:"",
  regime_fiscale:"",
  zone_pinel:"",
  sur_hab_pinel:"",
  sur_annexe_pinel:"",
  centre_impots:"",
  tresorerie:"",
  num_invariant:"",
  taxe_ordure:false,
  taxe_bureaux:false,
  contri_revenue_locatif:false,
  taxe_logement_vacant:false,
  zone_tondue:false
  
};

export const counterInfos = {

  numero_pdl:"",
  numero_pce:"",
  compteur_eau:"",
  location_compteur:""

};

export const coOwnerInfos = {
 // is_coowner: true,
  ref_copro:"",
  concierge:"",
  lot:"",
  copropriete: 0

};

export const otherInfos ={
  note:"",
  ref_contrat:"",
  assureur:"",
  date_echeance:"",
  detecteur_fumee:"",
  detecteur_monoxyde:""

};

export const internationalInfos = {
  export_identifier: "",
  language: "",
  resident_number: "",
  nationality: "",
};


export const companyInfos = {
  siret: "",
  intravo_vat: "",
};

export const equipmentInfos = {
  id_bien_info:0,
  id_equipement: 0,
  valeur: ""
};