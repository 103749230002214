import FirstPage from '@/views/Works/Meters/FirstPage.vue'
import NewMeterView from '@/views/Works/Meters/NewMeter/NewMeterView.vue'
import MeterView from '@/views/Works/Meters/MeterView/MeterView.vue'
// url de base des pages relatives aux encaissements
const baseUrl = "/travaux/compteurs"

const metersRoutes = [
  {
    path: baseUrl,
    name: "meters",
    component: FirstPage,
  },
  {
    path: baseUrl+"/creer",
    name: "newMeter",
    component: NewMeterView
  },
  {
    path: baseUrl+"/:meterId",
    name: "meter",
    component: MeterView
  }
];

export default metersRoutes
