<script>
  import RegistersView from '../RegistersView.vue'
  import RegHeader from '@/containers/Registers/Header/RegHeader.vue'
  import TableOne from '@/containers/TableOne/TableOne.vue'
  import { header, path, pageTitle } from './datas'

  export default {
    data(){
      return {
        links: header.links,
        title: header.title,
        table: {
          cols: ['Nom du mandat', 'Adresse du mandat', "Situation de l'immeuble", 'Date de création', "Date d'effet", "Observation"],
          rows: [
            ["premier mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["second mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["troisième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["quatrième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["cinquième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["sixième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["septième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["huitième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["neuvième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["dixième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
            ["onzième mandat", "Descente Avenue Germaine", "situation ok", "12/05/2022", "12/05/2023", "RAS"],
          ],
          actions: [
            {
              type: "update",
              label: "modifier"
            },
            {
              type: "delete",
              label: "supprimer"
            },
          ]
        },
        path,
        pageTitle
      }
    },
    components: {
      RegistersView,
      RegHeader,
      TableOne
    },
  }
</script>

<template>
  <RegistersView :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <RegHeader :links="links" :title="title" />
      <TableOne 
        :cols="table.cols"
        :rows="table.rows"
        :actions="table.actions"
        title="Tous les projets de mandat"
      />
    </div>
  </RegistersView>
</template>

<style src="./FirstPage.css"></style>