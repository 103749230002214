<!-- Premier Header recontré dans le module GED -->
<script>
  import ItemBox3 from '@/components/ItemBox3/ItemBox3.vue'

  export default {
    components: {
      ItemBox3
    },
    props: {
      vector: String,
      resumes: Array,
      shortLinks: Array
    }
  }
</script>

<template>
  <div class="ged-header">
    <div class="ged-header--resumes">
      <img :src="vector" alt="data">
      <div class="box1 resumes-container">
        <div 
          class="resumes-item"
          v-for="({ text, number }, index) in resumes"
          :key="'resume-'+index"
        >
          <p class="paragraph resumes-item--text">{{ text }}</p>
          <p class="resumes-item--number">{{ number }}</p>
        </div>
      </div>
    </div>
    <hr class="ged-header--separator">
    <div class="ged-header--links">
      <ItemBox3
        v-for="({ label, url, vect, color }, index) in shortLinks"
        :key="'short-link-'+index"
        :label="label"
        :url="url"
        :vect="vect"
        :color="color"
      />
    </div>
  </div>
</template>

<style src="./HeaderOne.css"></style>