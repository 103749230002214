import { defineStore } from "pinia";
import api from "../api";

import {
  generalInfos,
  ibanInfos,
  accountingInfos,
  taxationInfos
} from "./New/datas";
import { noEmptyFields, initialList, initProcess } from "@/utils/helpers";

const useOwnerStore = defineStore('owner', {
  state: () => ({
    loading: false,
    status: "",
    error: "",
    owner: {
      ...generalInfos,
      ...accountingInfos,
      ...taxationInfos,
      ...ibanInfos
    },
  
    getOwnersProcess: initProcess,
    getOwnerProcess: initProcess,
    getOwnerIbanProcess: initProcess,
    updateOwnerProcess: initProcess,
    updateOwnerIbanProcess: initProcess,
    deletionProcess: initProcess,
    updatedOwnerDatas: [],
    updatedOwnerIbanDatas:[],
    owners: initialList,

   
    ibans: initialList,
   
    properties: initialList,
    
  }),
  actions: {
    async createOwner() {
      // handle owner creation
      if (!this.loading) {
        if (
          // checking if no field is empty
          noEmptyFields(this.owner, ["complete_address", "fax", "phone"])
        ) {
          this.loading = true;
          this.status = "pending"
          // execute request
          try {
            console.log({newOwner: this.owner})
            const resp = await api.addOwner(this.owner)
            console.log({resp})
            if (resp.status === 200) {
              this.loading = false;
              this.status = "success"
              location.replace("/gestion-locative/proprietaires");
            } else {
              this.loading = false;
              this.status = "failure"
              this.error = resp?.response?.data?.detail
            }
          } catch (error) {
              this.loading = false;
              this.status = "failure"
              this.error = error?.data?.detail
              console.log({error})
          }
        } else {
          this.loading = false;
          this.status = "failure"
          this.error = "Vous semblez avoir omis certains des champs obligatoires !"
        }
      } else {
        //
      }
    },
  
    async getOwner(ownerId) {
      // taking user info from local storage
      try {
        this.getOwnerProcess = {loading: true, status: "pending"}
        const resp = await api.getOwner(ownerId)
        if (resp) {
          console.log({ resp })
          this.owner = resp.data.owner_info[0]
          this.ibans = resp.data.iban_info[0];
          // let rows = [];
          // let cols = ["iban", "bic", "creditor_name", "ics_number"];
          // resp.data.iban_info.map((item) => {
          //   let row = [];
          //   cols.map((col) => {
          //     row.push(item[col]);
          //   });
          //   rows.push(row);
          // });
          // this.ibans = {
          //   ...this.ibans,
          //   list: rows,
          //   total: resp.data.iban_info.length,
          // };
          this.properties = resp.data.properties
          this.getOwnerProcess = {loading: false, status: 'success'}
        } else {
          // alert("Une erreur s'est produite !")
          this.getOwnerProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        }
      } catch (getOwnerError) {
        console.log({ getOwnerError });
        this.getOwnerProcess = {loading: false, status: "failure", error: getOwnerError?.data?.detail}
      }
    },
 
    async updateOwner(id) {
      this.updateOwnerProcess = {loading: true, status: "pending", error:""}
      // merging user data into owner infos in a single datas objects
      console.log('updating owner: '+id+' ...')
      let updatedDatas = {};
      // console.log({updatedOwnerDatas: this.updatedOwnerDatas})
      this.updatedOwnerDatas.map((name) => (
        updatedDatas = { ...updatedDatas, [name]: this.owner[name]}
      ))
      updatedDatas = { ...updatedDatas, id }

      // console.log({updatedDatas})
      // sending request
      try {
        const resp = await api.updateOwner(updatedDatas)
        if (resp.status === 200) {
          console.log({ updatedOwner: resp })
          this.updateOwnerProcess = {loading: false, status: "success", error:"Opération réussie"}
          //location.reload()
        } else {
          console.log({resp})
          // alert("Une erreur s'est produite !\nVeuillez Réessayer plus tard !")
          this.updateOwnerProcess = {loading: false, status: "failure", error:resp?.response?.data?.detail || "Veuillez vérifier vos informations !"}
     
        }
      } catch (error) {
        console.log({ error })
        this.updateOwnerProcess = {loading: false, status: "failure", error:error.data.detail}
      }
    },
  
    async deleteOwner(id){
      // delete owner
      this.deletionProcess = {loading: true, status: "pending"}
      try {
        const deletedOwner = await api.deleteOwner({id, owner_or_co_owner: "owner"})
        if (deletedOwner?.status === 200) {
          console.log({ deletedOwner })
          this.deletionProcess = {loading: false, status: "success"}
          location.replace("/gestion-locative/proprietaires");
        } else {
          this.deletionProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
          console.log({ deletedOwner2: deletedOwner})
        }
      } catch (deletionError) {
        this.deletionProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        console.log({ deletionError });
      }
    },
    async getOwners(offset) {
      // sending request
      try {
        this.getOwnersProcess = {loading: true, status: "pending", error: ""}
        const resp = await api.getOwners(10, offset)
        if (resp) {
          let rows = []
          let cols = ['id', 'lastname', 'first_name', 'email', 'cell_phone']
          resp.data.list.map((item) => {
            let row = []
            cols.map((col) => {
              row.push(item[col])
            })
            rows.push(row)
          })
          this.owners = { ...this.owners, list: rows, total: resp.data.total }
          console.log({ owners: this.owners })
          this.getOwnersProcess = {loading: false, status: "success"}
        } else {
          this.getOwnersProcess = {loading: false, status: "failure", error: "Une erreur s'est produite, Veuillez réessayer plus tard !"}
        }
      } catch (error) {
        console.log({ error })
        this.getOwnersProcess = {loading: false, status: "failure", error: error?.data?.detail}
      }
    },
    async getOwnerIban(ibanId) {
      // taking user info from local storage
      try {
        this.getOwnerIbanProcess = {loading: true, status: "pending"}
        const resp = await api.getOwnerIban(ibanId)
        if (resp) {
          console.log({ resp })
          this.ibans = resp.data.owner_info[0];
          this.getOwnerIbanProcess = {loading: false, status: 'success'}
        } else {
          // alert("Une erreur s'est produite !")
          this.getOwnerIbanProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        }
      } catch (getOwnerIbanError) {
        console.log({ getOwnerIbanError });
        this.getOwnerIbanProcess = {loading: false, status: "failure", error: getOwnerIbanError?.data?.detail}
      }
    },

    async updateOwnerIban(id, owner_id) {
      this.updateOwnerIbanProcess = {loading: true, status: "pending", error:""}
      // merging user data into owner infos in a single datas objects
      console.log('updating ownerIbn: '+id+' ...')
      let updatedDatas = {};
      console.log({updatedOwnerIbanDatas: this.ibans})
      this.updatedOwnerIbanDatas.map((name) => (
        updatedDatas = { ...updatedDatas, [name]: this.owner[name]}
      ))
      updatedDatas = { ...this.ibans, id, owner_id }

      // console.log({updatedDatas})
      // sending request
      try {
        const resp = await api.updateOwnerIban(updatedDatas)
        if (resp.status === 200) {
          console.log({ updatedOwner: resp })
          this.updateOwnerIbanProcess = {loading: false, status: "success", error:"Opération réussie"}
          // location.reload()
        } else {
          console.log({resp})
          // alert("Une erreur s'est produite !\nVeuillez Réessayer plus tard !")
          this.updateOwnerIbanProcess = {loading: false, status: "failure", error: resp?.response?.data?.detail || "Veuillez vérifier vos informations !"}
         
        }
      } catch (error) {
        console.log({ error })
        this.updateOwnerIbanProcess = {loading: false, status: "failure", error:  error.data.detail}

      }
    },
  
  }
})

export default useOwnerStore;