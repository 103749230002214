<script lang="js">
import WorksView from '../../WorksView.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import { mapActions, mapWritableState } from 'pinia'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
import TextField1 from '@/components/TextField1/TextField1.vue';
import { initialList } from '@/utils/helpers'

import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

import { formSteps } from './datas'

import {path as basePath } from "../datas"

import useMeterStore from '../store'

import usePropertyStore from '../../../RentalManagement/Goods/store'

const baseUrl = basePath[basePath.length - 1].url



export default {
  components: {
    ErrorMessage,

    AnimatedLoader,
    ButtonOne,
    WorksView,
    EditionForm,
    TextField1

  },
  data() {
    return {

      steps: formSteps,
      currentStep: 0,
      initialList,
      deleteClicked: false,
      changed: false

    }
  },
  computed: {
    ...mapWritableState(useMeterStore, ['meter', 'updatedMeterDatas', 'updateMeterProcess', 'deletionProcess', 'loading', 'status', 'error', 'getMeterProcess']),
    ...mapWritableState(usePropertyStore, ['goods', 'property']),
    urlParam() {
      return this.$route.params.meterId
    },
    meterId() {
      return parseInt(this.urlParam);
    },
    path() {
      return [
        ...basePath,
        {
          label: (this.meter.nom_compteur) + "...",
          url: baseUrl + "/" + this.meterId
        }
      ]
    }
  },
  methods: {
    ...mapActions(useMeterStore, ['getOneMeter', 'updateMeter', 'deleteMeter']),
    ...mapActions(usePropertyStore, ['getProperties', 'getProperty']),
    findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    },
    reload() {
      location.reload()
    },
    handleUpdateValue(name, newValue) {
      this.changed = true;
      this.meter[name] = newValue;
      !this.updatedMeterDatas.includes(name) && this.updatedMeterDatas.push(name);
    },
    handleDeleteMeter() {
      if (this.deleteClicked) {
        this.deleteClicked = false;
        this.deleteMeter(this.meterId);
      } else {
        this.deleteClicked = true;
      }
    },
    handleUpdateSelect(name, event) {
      this.changed = true;
      this.meter[name] = event.target.value;
      console.log(event.target.value)
      !this.updatedMeterDatas.includes(name) && this.updatedMeterDatas.push(name);
    },
  },
  mounted() {
    this.path[this.path.length - 1].url += this.urlParam
    this.getOneMeter(this.meterId)
    this.getProperties(0);
    console.log(this.goods);

  },
  watch: {
    offset(value) {
      this.getProperties(value);
    }
  }
}</script>


<template>
    <WorksView :path="path">
    <div v-if="getMeterProcess.status === 'pending'">
      <AnimatedLoader dark />
    </div>
    <div v-if="getMeterProcess.status === 'success'" class="container">
      <div class="flex items-center justify-between">
        <h1 class="container-title">Compteur : {{ meter.nom_compteur }}</h1>
        <ButtonOne @click="handleDeleteMeter()" :text="deleteClicked ? 'Cliquez à nouveau pour supprimer' : 'Supprimer'"
          :dangerous="deleteClicked" :loading="deletionProcess.loading" :status="deletionProcess.status" isAsync />
      </div>

      <div class="container-body">
        <EditionForm :save="changed" :steps="steps" @step="(step) => currentStep = step" :loading="loading"
          :status="status">

          <template #form>
            <form @submit.prevent="() =>updateMeter(meterId)">
              <section class="form-part" v-if="currentStep == findIndex('general')">
                <div class="flex flex-row items-center w-full">
                  <div class="flex flex-col">
                    <div class="flex flex-row mb-2"><label>Bien <span>: </span></label>
                      <p class="required-tag text-red ms-1">*</p>
                    </div>
                    <select v-model="meter.id_bien_info" @change="handleUpdateSelect('id_bien_info', $event)"
                      class="form-select rounded-md">
                      <option disabled value="">Selectionnez</option>
                      <option v-for="(item, i) in goods.list" :key="i" :value="item[0]">
                        {{ item[1] }}</option>
                    </select>

                  </div>
                  <router-link class="path-link mt-8 ms-10" :to="{ path: '/gestion-locative/biens/creer' }">
                    <ButtonOne text="Nouveau" />
                  </router-link>
                </div>

                <div class="flex flex-row items-center  w-full">
                            <TextField1 small label="Intitulé Compteur" placeholder="Intitulé Compteur"
                                :value="meter.nom_compteur"
                                @update:value="newValue => handleUpdateValue('nom_compteur', newValue)"/>
                                <TextField1 class="ms-4" small label="Localisation Compteur" placeholder="Localisation Compteur"
                                :value="meter.localisation"
                                @update:value="newValue => handleUpdateValue('localisation', newValue)"/>

                        </div>
                        <div class="flex flex-row items-center  w-full">
                            <TextField1 small label="Numéro PDL" placeholder="Numéro PDL"
                                :value="meter.numero_pdl"
                                @update:value="newValue => handleUpdateValue('numero_pdl', newValue)"/>
                                <TextField1 class="ms-4" small label="Numéro PCE" placeholder="Numéro PCE"
                                :value="meter.numero_pce"
                                @update:value="newValue => handleUpdateValue('numero_pce', newValue)"/>

                        </div>
              
              </section>
              <div class="flex gap-[20px]">
                <div v-if="deletionProcess.status === 'failure'">
                  <ErrorMessage :message="deletionProcess.error" />
                </div>

                <div v-if="status === 'failure'">
                  <ErrorMessage :message="error" />
                </div>

              </div>
              <div class="flex justify-center items-center mt-8">
                <ButtonOne isAsync :loading="updateMeterProcess.loading" :status="updateMeterProcess.status"
                  text="Modifier" />
              </div>
            </form>



          </template>

        </EditionForm>
      </div>

    </div>

    <div v-if="getMeterProcess.status === 'failure'" class="container">
      <div class="owner-header">
        <h1 class='container-title'>Erreur de chargement</h1>
        <ButtonOne @click="reload()" text="Cliquez pour recharger" />
      </div>
    </div>
</WorksView>
</template>