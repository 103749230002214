import { defineStore } from "pinia";
import api from "../api";
import { noEmptyFields, initialList, initProcess } from "@/utils/helpers";
import {
  generalInfos,
  descriptionInfos,
  diagnosticInfos,
  fiscaleInfos,
  counterInfos,
  coOwnerInfos,
  otherInfos,
  locationTypeInfos
} from "./New/datas";

const usePropertyStore = defineStore("property", {
  state: () => ({
    loading: false,
    status: "",
    error: "",
    property: {
      ...generalInfos,
      ...descriptionInfos,
      ...diagnosticInfos,
      ...fiscaleInfos,
      ...coOwnerInfos,
    },
    propertyType : {
      ...locationTypeInfos
    },
    furniture : {
      id_bien_info:0,
      id_equipement:0,
      valeur: ""
    },

    counterInfos,
    coOwnerInfos,
    otherInfos,
    getGoodsProcess: initProcess,
    getGoodProcess: initProcess,
    updateGoodProcess: initProcess,
    updatedGoodDatas:[],
    equips:initialList,
    goods: initialList,
    furnitures : initialList,
    types: initialList,
    deletionProcess: initProcess,
  }),
  getters: {
    // property: (state) => ({
    //   ...state.generalInfos,
    //   ...state.descriptionInfos,
    //   ...state.diagnosticInfos,
    //   ...state.fiscaleInfos,
    //   ...state.coOwnerInfos,
    // }),
  },
  actions: {
    //add property
    async addProperty() {
      if (!this.loading) {
        if (
          // checking if no field is empty
          noEmptyFields(this.property, [
            "complement_add",
            "description",
            "superficie_boutin",
            "surface_terrasse",
            "surface_annexe",
            "surface_carrez",
            "autre_piece",
            "nb_sallon",
            "nb_chambre",
            "nb_salle_a_manger",
            "nb_douche",
            "nb_cuisine",
            "energie",
            "climat",
            "date_dpe",
            "diagnostic_dpe",
            "diagnostic_plomb",
            "diagnostic_termites",
            "diagnostic_electricite",
            "diagnostic_gaz",
            "diagnostic_erp",
            "zone_pinel",
            "sur_hab_pinel",
            "sur_annexe_pinel",
            "num_invariant",
            "nb_escalier",
            "nb_etage",
            "tresorerie",
            "copropriete",
          ])
        ) {
          this.loading = true;
          this.status = "pending";
          console.log({ newProperty: this.property });
          // execute request
          try {
            
            const resp = await api.addGood(this.property);
            console.log({ resp });
            if (resp.status === 200) {
              this.loading = false;
              this.status = "success";
              location.replace("/gestion-locative/biens");
            } else {
              this.loading = false;
              this.status = "failure";
              this.error = resp?.response?.data?.detail;
            }
          } catch (error) {
            console.log({ error });
            this.loading = false;
            this.status = "failure";
            this.error = error?.data?.detail;
           
          }
        } else {
          this.loading = false;
          this.status = "failure";
          this.error =
            "Vous semblez avoir omis certains des champs obligatoires !";
        }
      } else {
        //
      }
    },

    // get property by id
    async getProperty(propertyId) {
      try {
        this.getGoodProcess = {loading: true, status: "pending"};
        const resp = await api.getGood(propertyId)
       
        if (resp) {
          this.property = {...resp.data }
          console.log({ property: this.property })
          this.getGoodProcess = {loading: false, status: "success"};
        } else {
          this.getGoodProcess = {loading: false, status: "failure", error:"Une erreur s'est produite !"};
        }
      } catch (error) {
          console.log({ error });
          this.getGoodProcess = {loading: false, status: "failure", error: error};
      }
    },
    // update a property by id
    async updateProperty(id) {
    this.updateGoodProcess = {loading:true, status:"pending"}
    console.log('updating : '+id+' ...')
    let updatedDatas = {};
    // console.log({updatedOwnerDatas: this.updatedOwnerDatas})
    this.updatedGoodDatas.map((name) => (
      updatedDatas = {[name]: this.property[name]}
      
    ))

    updatedDatas = { ...this.property, "id_bien":id}
    
      try {
        console.log({updatedDatas})
        const resp = await api.updateGood(updatedDatas)
        console.log({resp})
        if (resp.status === 200) {
          console.log({result : this.resp})
          this.updateGoodProcess = {loading: false, status:"succcess", error:"Opération réussie"}
        } else {
          this.updateGoodProcess = {loading: false, status:"failure", error:"Une erreur s'est produite"}
        }
      } catch (error) {
        console.log({ error })
        this.updateGoodProcess = {loading: false, status:"failure", error:error?.data.detail}
      }
    },
    handleChange(property, newValue) {
      this.property = { ...this.property, [property]: newValue };
    },
    // fetch all properties
    async getProperties(offset) {
      try {
        this.getGoodsProcess = {
          loading: true,
          status: "pending",
          error: "",
        };
        const resp = await api.getGoods(10, offset);
       
        if (resp) {
          console.log({ resp: resp });
          if (resp.status === 200) {
           
            let rows = [];
            let cols = [
              "id",
              "type_de_bien",
              "adresse",
              "ville",
              "pays",
              "code_postale",
            ];
            resp.data.map((item) => {
              let row = [];
              cols.map((col) => {
                row.push(item[col]);
              });
              rows.push(row);
            });
            console.log({ results: rows });
            this.goods = { ...this.goods, list: rows, total: resp.data.length };
            console.log({ goods: this.goods });
            this.getGoodsProcess = { loading: false, status: "success" };
          } else {
            this.getGoodsProcess = {
              loading: false,
              status: "failure",
              error:
                "Une erreur s'est produite, Veuillez réessayer plus tard !",
            };
          }
        }
      } catch (error) {
       // console.log({ error });
        this.getGoodsProcess = {
          loading: false,
          status: "failure",
          error: error?.data?.detail,
        };
      }
    },

    // fetch all propertyTypes
    async getPropertyTypes(offset) {
      try {
       
        const resp = await api.getTypesProperties(10, offset);
        console.log({ resp: resp });
        if (resp) {
         
          if (resp.status === 200) {
           
            let rows = [];
            let cols = [
              "id",
              "nom"
            ];
            resp.data.map((item) => {
              let row = [];
              cols.map((col) => {
                row.push(item[col]);
              });
              rows.push(row);
            });
            console.log({ results: rows });
            this.types = { ...this.types, list: rows, total: resp.data.length };
            console.log({ types: this.types });
            
          } 
        }
      } catch (error) {
        console.log({ error });
       
      }
    },
     // delete a property by id
    async deleteProperty(id){
      this.deletionProcess = {loading: true, status: "pending"}
      try {
        const resp = await api.deleteGood({id_bien: id});
        if(resp?.status == 200){
          console.log({resp});
          this.deletionProcess = {loading: false, status: "success"}
          location.replace("/gestion-locative/biens");

        }else{
          this.deletionProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
          console.log({ deletedProprerty: resp});
        }
      
      } catch (error) {
        this.deletionProcess = {loading: false, status: "failure", error: "Une erreur s'est produite !"}
        console.log({error});
      }
    },

    // get list of equipments by id_type
    async getListFurnitures(id, offset){
      try {
       
        const resp = await api.getListEquipments(id, 10, offset);
        console.log({ resp: resp });
        if (resp) {
         
          if (resp.status === 200) {
           
            let rows = [];
            let cols = [
              "id",
              "id_typ_bien",
              "nom"
            ];
            resp.data.map((item) => {
              let row = [];
              cols.map((col) => {
                row.push(item[col]);
              });
              rows.push(row);
            });
            console.log({ results: rows });
            this.furnitures = { ...this.furnitures, list: rows, total: resp.data.length };
          
            
          } 
        }
      } catch (error) {
        console.log({ error });
       
      }
    },
     // add equipments
     async addEquipment(){
      if(!this.loading){
           if(noEmptyFields(this.furniture, ['valeur'])){
              this.loading = true;
              this.status = "pending";
              console.log({newEquipement: this.furniture});
              try {
                  
                  const resp = await api.addFurniture(this.furniture)
                  console.log({ response : resp})
                  if(resp.status === 200){
                      this.loading = false;
                      this.status = "success";
                      location.replace("/gestion-locative/biens");
                  }else{
                      this.loading = false;
                      this.status = "failure";
                      this.error = resp?.response?.data?.detail;
                  }
                  
              } catch (error) {
                  this.loading = false;
                  this.status = "failure";
                  this.error = error?.data?.detail;
                  console.log({ error });
              }
           }else{
              this.loading = false;
              this.status = "failure";
              this.error =
                "Vous semblez avoir omis certains des champs obligatoires !";
           }
      }
    },

   // fetch all equipments
   async getEquipments(offset){
    try {
       
        const resp = await api.getAllEquipments(10, offset);
        if(resp){
            console.log({resp: resp});
            if(resp.status === 200){
                let rows = [];
                let cols = [
                    "id",
                    "id_equipement",
                    "nom_other",
                    "valeur"
                ];
                resp.data.map((item) => {
                    let row = [];
                    cols.map((col) => {
                      if(col === "id_equipement"){
                        row.push(item[col]["nom"]);
                      }else{
                        row.push(item[col]);
                      }
                      
                    });
                    rows.push(row);
                  });
                  console.log({ results: rows });
                  this.equips = { ...this.equips, list:rows, total:resp.data.length}
            }
        }
    } catch (error) {
        console.log(error);
       
    }
    },

  // fetch equipments by id_property
  async getEquipmentByIdProperty(id_property, offset){
    try {
       
      const resp = await api.getEquipmentById(id_property, 10, offset);
      console.log({ resp: resp });
      if (resp) {
       
        if (resp.status === 200) {
         
          let rows = [];
          let cols = [
            "id",
            "id_typ_bien",
            "nom"
          ];
          resp.data.map((item) => {
            let row = [];
           
            cols.map((col) => {
              row.push(item['id_equipement'][col]);
            });
            rows.push(row);
          });
         
          this.equips = { ...this.equips, list: rows, total: resp.data.length };
          console.log({ results: this.equips.list });
        } 
      }
    } catch (error) {
      console.log({ error });
     
    }
  }
  },
});

export default usePropertyStore;
