import FirstPage from '@/views/Account/SupportView/FirstPage.vue'

// url de base des pages relatives aux baux
const baseUrl = "/account/support"

const supportRoutes = [
  {
    path: baseUrl,
    name: "accountSupport",
    component: FirstPage,
  }
];

export default supportRoutes
