import FirstPage from '@/views/Syndics/Edition/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/syndics/edition"

const editionRoutes = [
  {
    path: baseUrl,
    name: "coproEdition",
    component: FirstPage,
  }
];

export default editionRoutes
