<script>
import PathViewer from '@/containers/PathViewer/PathViewer.vue';
import SideBar1 from '@/containers/SideBar1/SideBar1.vue';

import { syndics } from '@/utils/data/aside'

export default {
  components: {
    PathViewer,
    SideBar1,
  },
  props: {
    path: Array,
  },
  data() {
    return {
      title: syndics.title,
      asideLinks: syndics.asideLinks
    }
  }
}
</script>

<template>
  <div class="rentalManagement page">
    <PathViewer :path="path" />
    <div class="body">
      <SideBar1 :title="title" :links="asideLinks" module="syndics-de-copropriete" />
      <slot> </slot>
    </div>
  </div>
</template>