<script>
import MarketPlace from '../MarketPlace.vue'
import ProductPreview from '@/containers/ProductPreview/ProductPreview.vue'
import TextField1 from '@/components/TextField1/TextField1.vue'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

import { path, pageTitle } from './datas'
import products from '@/utils/data/products'
import filter from '@/assets/icons/filter.svg'
import search from '@/assets/icons/research1.svg'

  export default {
    data(){
      return {
        path,
        pageTitle,
        products,
        searchKey: "",
        icons: {
          filter,
          search
        },
      }
    },
    components: {
      MarketPlace,
      ProductPreview,
      TextField1,
      ButtonOne
    },
    methods: {
      handleSubmit() {

      },
      handleChange(e) {
        const term = e.target.value;
        this.searchKey = term;
      },
    }
  }
</script>

<template>
  <MarketPlace :path="path">
    <div class="container marketplace-home">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="box1 marketplace--header">
        <div class="header-top">
          <p class="header-top--title">
            <img :src="icons.filter" alt="">
            Bailleurs des bien enregistrés
          </p>
          <router-link to="/marketplace/products/add">
            <ButtonOne text="+ Ajouter un bien" />
          </router-link>
        </div>
        <div class="flexed header-content">
          <form class="flexed" @submit.prevent="handleSubmit">
            <TextField1 :onChange="handleChange" :value="searchKey" placeholder="Recherchez un propriétaire" />
            <ButtonOne type="submit" :leftIcon="icons.search" />
          </form>
        </div>
      </div>
      <!-- corp de la page -->
      <div class="container-body">
        <div class="products-list">
          <router-link
            v-for="{ 
              id, images, title, price, charged, type, furnished, area, levels, minParkingPlaces
            } in products" 
            :key="id" 
            :to="`/marketplace/products/${id}`" 
            class="product-item">
            <ProductPreview
              :image="images[0]"
              :title="title"
              subTitle="Private Room"
              :price="price"
              :charges="charged"
              :type="type"
              :furnished="furnished"
              :area="area"
              :levels="levels"
              :minParkingPlaces="minParkingPlaces"
            />
          </router-link>
        </div>
      </div>
    </div>
  </MarketPlace>
</template>

<style src="./FirstPage.css"></style>