<script lang="js">
import TextField1 from '@/components/TextField1/TextField1.vue';
import { mapWritableState } from 'pinia';
import useTenantStore from '../../store';

export default {
  components: {
    TextField1
  },
  computed: {
    // gives access to store tenant inside the component and allows setting it
    ...mapWritableState(useTenantStore, ['tenant']),
  }
}
</script>

<template>
  <div class="row w-full">
    <TextField1 fullWidth label="IBAN" placeholder="IBAN" :value="tenant.iban"
      @update:value="newValue => tenant.iban = newValue" />

    <TextField1 fullWidth label="BIC" placeholder="bic" :value="tenant.bic"
      @update:value="newValue => tenant.bic = newValue" />
  </div>

  <div class="row w-full">
    <TextField1 fullWidth label="Nom créancier" placeholder="Banque" :value="tenant.creditor_name"
      @update:value="newValue => tenant.creditor_name = newValue" />

    <TextField1 fullWidth label="N° ICS" placeholder="ICS" :value="tenant.ics_number"
    @update:value="newValue => tenant.ics_number = newValue" />
</div></template>