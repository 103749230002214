import { path as basePath } from "@/views/Dashboard/data"

// base url for the pages above this page in the global infrastructure
const baseUrl = "/marketplace"

// path to page
export const path = [
  ...basePath,
  {
    label: "marketplace",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Vos Annonces"