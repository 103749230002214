<script>
  import RentalManagement from '../RentalManagement.vue'
 // import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'
  import { items, path, pageTitle, tableDatas } from './datas.js'
import TableOne from '@/containers/TableOne/TableOne.vue'
import { initialList } from '@/utils/helpers'
import { mapActions, mapWritableState } from 'pinia'
import useRentStore from './store'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'

  export default {
    components: {
    RentalManagement,
   // Header2,
    TableOne,
    ErrorMessage,
    AnimatedLoader,
    ButtonOne
},
    data(){
      return {
        items,
        path,
        pageTitle,
        datas: tableDatas,
        offset: 0,
        initialList
      }
    },
    computed:{
      ...mapWritableState(useRentStore, ['rents', 'getRentsProcess'])
    },
    mounted(){
        this.getAllLeaseCall(0);
    },
    methods:{
      ...mapActions(useRentStore, ['getAllLeaseCall'])
    },
    watch:{
      offset(value){
        this.getAllLeaseCall(value);
      }
    }
  }
</script>

<template>
  <RentalManagement :path="path">
    <div class="container">
      <h1 class="container-title">{{ pageTitle }}</h1>
      <!-- <Header2 :items="items" /> -->
      <div class="container-body">
        <AnimatedLoader dark v-if="getRentsProcess.loading" />
        <div v-else-if="getRentsProcess.status === 'failure'" class="flex flex-col items-center gap-4">
          <ErrorMessage :message="getRentsProcess.error" />
          <ButtonOne isAsync @click="getAllLeaseCall(offset)" text="Actualiser" :loading="getRentsProcess.loading" :status="getRentsProcess.status" />
        </div>
        <TableOne
          v-else
          :title="datas.title"
          :cols="datas.cols"
          :rows="rents.list"
          :total="rents.total"
         
          baseUrl="/gestion-locative/loyers"
          @emitedOffset="(value) => offset = value"
        />
      </div>
     
    </div>
  </RentalManagement>
</template>

<style src="./FirstPage.css"></style>