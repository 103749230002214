import RentalManagementHome from '@/views/RentalManagement/RentalManagementHome/RentalManagementHome.vue'
import collectionsRoutes from './collections';
import expensessRoutes from './expenses';
import goodsRoutes from './goods';
import leasesRoutes from './leases';
import ownersRoutes from "./owners";
import rentsRoutes from './rents';
import tenantsRoutes from './tenants';
import coOwnersRoutes from './coowners';
import coTenantsRoutes from './cotenants';
import equipmentRoutes from './equipments';

const baseUrl = "/gestion-locative"

const rentalManagementRoutes = [
  {
    path: baseUrl,
    name: "gestionLocative",
    component: RentalManagementHome,
  },
  ...coOwnersRoutes,
  ...ownersRoutes,
  ...tenantsRoutes,
  ...coTenantsRoutes,
  ...goodsRoutes,
  ...rentsRoutes,
  ...expensessRoutes,
  ...collectionsRoutes,
  ...leasesRoutes,
  ...equipmentRoutes
];

export default rentalManagementRoutes;