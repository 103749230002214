import GEDHome from '@/views/GED/GEDHome/GEDHome.vue'
import basketRoutes from './basket';
import filesRoutes from './files';
import foldersRoutes from './folders';
import repositoriesRoutes from './repositories';

const baseUrl = "/ged"

const gedRoutes = [
  {
    path: baseUrl,
    name: "ged",
    component: GEDHome,
  },
  ...foldersRoutes,
  ...filesRoutes,
  ...repositoriesRoutes,
  ...basketRoutes
];

export default gedRoutes;