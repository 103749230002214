import ValidateAccount from '@/views/Auth/ValidateAccount/ValidateAccount.vue'

const baseUrl = "/valider-votre-mail"

const validateAccountRoutes = [
  {
    path: baseUrl,
    name: "validateAccount",
    component: ValidateAccount,
  },
];

export default validateAccountRoutes;