<script lang="js">
import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import GeneralInfos from './Steps/GeneralInfos.vue'
import DescriptionInfos from './Steps/DescriptionInfos.vue'
import DiagnosticInfos from './Steps/DiagnosticInfos.vue'
import FiscaleInfos from './Steps/FiscaleInfos.vue'
// import CounterInfos from './Steps/CounterInfos.vue'
import CoOwnerInfos from './Steps/CoOwnerInfos.vue'
// import OtherInfos from './Steps/OtherInfos.vue'
import { mapActions, mapWritableState } from 'pinia'
import usePropertyStore from '../store'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue';
import {
  path,
  pageTitle, 
  formSteps
} from './datas';


export default {
  components: {
    RentalManagement,
    EditionForm,
    GeneralInfos,
    DescriptionInfos,
    DiagnosticInfos,
    FiscaleInfos,
    // CounterInfos,
    CoOwnerInfos,
    // OtherInfos,
    ErrorMessage
  },

  data() {
    return {
      path,
      pageTitle,
      steps: formSteps,
      currentStep: 0,
      name: "durin"
    }
  },
  computed: {
    ...mapWritableState(usePropertyStore, ['property', 'loading', 'status', 'error'])
  },

  methods: {
    ...mapActions(usePropertyStore, ['addProperty',]),
    findIndex(name) {
      return this.steps.indexOf(this.steps.find(
        (step) => step.name === name))
    }
  },
 
 
}
</script>

<template>
  <RentalManagement :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="profile container-body">
        <EditionForm :save="addProperty" :steps="steps" @step="(step) => currentStep = step"  :loading="loading" :status="status">
          <template #form>
            <section class="form-part " v-if="currentStep == findIndex('general')" >
              <GeneralInfos />    
            </section>
            <section class="form-part " v-if="currentStep == findIndex('description')" >
              <DescriptionInfos />    
            </section>

            <section class="form-part" v-if="currentStep == findIndex('diagnostic')" >
              <DiagnosticInfos />
            </section>
            <section class="form-part" v-if="currentStep == findIndex('fiscales')" >
              <FiscaleInfos />
            </section>
            <section class="form-part" v-if="currentStep == findIndex('copropriété')" >
              <CoOwnerInfos />
            </section>
            <!-- <section class="form-part" v-if="currentStep == findIndex('compteurs')" >
              <CounterInfos />
            </section>
          
            <section class="form-part" v-if="currentStep == findIndex('autres')" >
              <OtherInfos />
            </section> -->
            <div v-if="status === 'failure'" class="flex gap-[20px]">
              <div>
                <ErrorMessage :message="error" />
              </div>
            </div>
          </template>
        </EditionForm>
      </div>
    </div>
  </RentalManagement>
</template>