<script>
  import TextField1 from '@/components/TextField1/TextField1.vue';
  import SelectField1 from '@/components/SelectField1/SelectField1.vue';

  export default {
    components: {
      TextField1,
      SelectField1
    }
  }
</script>

<template>
  <div>
    <section>
      <div>
        <SelectField1 label="Civilité" />
        <TextField1 label="Prénom" />
        <TextField1 label="Nom" />
        <TextField1 label="Adresse" />
        <TextField1 label="Complément d'adresse" />
      </div>
      <div>
        <TextField1 label="Téléhone" />
        <TextField1 label="Portable" />
        <TextField1 label="Email 1" />
        <TextField1 label="Email 2" />
      </div>
      <div>
        <TextField1 label="Code postal" small />
        <TextField1 label="Ville" />
      </div>
    </section>
  </div>
</template>