<script>
import { setButtonText } from '@/utils/helpers'
import AnimatedLoader from '../Loader/AnimatedLoader.vue';

export default {
  props: {
    loading: Boolean,
    status: String,
    originalText: String
  },
  components: {
    AnimatedLoader,
  },
  methods:{
    setButtonText
  }
}
</script>

<template>
  <div v-if="!loading">
    {{ setButtonText(loading, status, originalText) }}
  </div>
  <AnimatedLoader v-else />
</template>