import SyndicsHome from '@/views/Syndics/SyndicsHome/SyndicsHome.vue'
import AGRoutes from './AG';
import archivesRoutes from './archives';
import coOwnersRoutes from './coOwners';
import copropertiesRoutes from './coproperties';
import editionRoutes from './edition';
import technicRoutes from './technic';

const baseUrl = "/syndics"

const syndicsRoutes = [
  {
    path: baseUrl,
    name: "coproSyndics",
    component: SyndicsHome,
  },
  ...copropertiesRoutes,
  ...coOwnersRoutes,
  ...AGRoutes,
  ...technicRoutes,
  ...editionRoutes,
  ...archivesRoutes
];

export default syndicsRoutes;