import { path as basePath } from "@/views/Dashboard/data"

// basic url for rental management pages
const baseUrl = "/comptabilite"

// le chemin d'accès
export const path = [
  ...basePath,
  {
    label: "comptabilité",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Dashboard"

// function allowing to add automatically basic url to any url
function url(url){
  return baseUrl+url
}

// transactions infos
export const transactions = [
  {
    date: "08 / 08 / 2022",
    hour: "10:46",
    transId: "045613",
    link: url(`/transactions/045613`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 07 / 2022",
    hour: "12:39",
    transId: "045614",
    link: url(`/transactions/045614`)
  },
  {
    date: "05 / 07 / 2022",
    hour: "16:46",
    transId: "045616",
    link: url(`/transactions/045616`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  },
  {
    date: "07 / 08 / 2022",
    hour: "12:46",
    transId: "045612",
    link: url(`/transactions/045612`)
  }
]

// usefull infos datas
export const usefullInfos = [
  {
    text: "Baux arrivant à expiration",
    value: 4,
    link: url("/baux")
  },
  {
    text: "Régularisations des charges",
    value: 8,
    link: url("/depenses")
  },
  {
    text: "Attestion d’assurances à obtenir",
    value: 2,
    link: url("/loyers")
  },
  {
    text: "Baux pouvant être dénnoncer",
    value: 6,
    link: url("/baux")
  },
  {
    text: "Révision de loyer en anomalie",
    value: 5,
    link: url("/loyers")
  }
]