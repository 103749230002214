<script>
import Header from '@/containers/RentalManagement/Header/RMHeader.vue'
import TransactionBox from '@/components/TransactionBox/TransactionBox.vue'
import UsefullInfo from "@/components/UsefullInfo/UsefullInfo.vue"

import reviewIcon from '@/assets/icons/monthly-review.svg'
import locationIcon from '@/assets/icons/location2.svg'
import menuIcon from '@/assets/icons/menu-button.svg'
import downArrowIcon from '@/assets/icons/downArrow_black.svg'
import rentsGraph from '@/assets/vectors/rents-report.svg'

export default {
  name: "DashboardPage",
  props: {
    pageTitle: String,
    headerItems: Object,
    monthlyReview: Number,
    agenda: Object,
    lastTransactions: Array,
    rentReport: Object,
    usefullInfos: Array
  },
  data() {
    return {
      reportPeriods: [
        "06 mois", "12 mois", "1 an", "2 ans"
      ],
      choosenReportPeriod: "12 mois",
      assets: {
        review: reviewIcon,
        location: locationIcon,
        menu: menuIcon,
        downArrow: downArrowIcon,
        rents: rentsGraph
      },
    }
  },
  components: {
    Header,
    Transaction: TransactionBox,
    UsefullInfo
  }
}
</script>

<template>
  <div class="dashboardPage">
    <Header
      v-if="headerItems"
      :items="headerItems.items" 
    />
    <div class="container-body">
      <div class="sections flex gap-[20px]">
        <section class="flex flex-col gap-[20px] flex-1">
          <!-- monthly review -->
          <div class="box1 flex items-center justify-between min-w-fit" v-if="monthlyReview">
            <div class="flex gap-[8px] items-center">
              <img :src="assets.review" alt="">
              <div>
                <p class="text-[#222] text-[21px] font-[500] leading-normal tracking-[-1.05px]">Bilan mensuel</p>
                <p class="review-text">Recettes</p>
              </div>
            </div>
            <div class="text-black_v1 text-[28px] font-[500] tracking-[-1.4px]">
              € {{ monthlyReview }}
            </div>
          </div>
          <!-- agenda -->
          <div class="box1 agenda" v-if="agenda">
            <div class="row-centered agenda-header">
              <div class="agenda-header--text">
                <p class="agenda-title">Agenda</p>
                <p class="agenda-text">
                  Découvrez et faites votre liste de tâches
                </p>
              </div>
              <div class="row-centered agenda-buttons">
                <div class="location"><img :src="assets.location" alt=""></div>
                <div class="details">détail</div>
              </div>
            </div>
            <!-- <ejs-calendar class="agenda-calendar" :min="calSettings.minDate" :max="calSettings.maxDate" :isMultiSelection="true"></ejs-calendar> -->
          </div>
          <!-- last transactions -->
          <div class="box1 flex flex-col gap-[19px]" v-if="lastTransactions">
            <div class="flex flex-col gap-[1px]">
              <div class="flex items-center justify-between">
                <p class="text-black_v1 text-[24px] font-[500] tracking-[-1.2px]">Dernières transactions</p>
                <img :src="assets.menu" alt="">
              </div>
              <p class="text-black_v1 text-[12px] font-[400] tracking-[-0.6px]">
                Encaissements et dépenses
              </p>
            </div>
            <div class="flex flex-col max-h-[520px] overflow-auto gap-[10px] p-[8px] border-solid border-2px border-t-[#91949933] border-b-[#91949933] hover:shadow-mdInset">
              <Transaction
                v-for="({date, hour, transId, link}, index) in lastTransactions"
                :key="index+transId"
                :date="date"
                :hour="hour"
                :transId="transId"
                :link="link"
              />
            </div>
          </div>
        </section>
        <section class="flex flex-col gap-[20px] flex-[1.7]">
          <!-- Rent Report -->
          <div class="box1 flex flex-col gap-[15px]" v-if="rentReport">
            <div class="flex items-center justify-between">
              <p class="box-title">Rapport de loyers</p>
              <div class="row-centered gap-[43.6px]">
                <div class="row-centered gap-[25px]">
                  <div class="row-centered entry">
                    <div class="legend-color w-[12px] h-[12px] rounded-[3px] bg-primary"></div>
                    <span class="box-text text-[#919499]">Entrée</span>
                  </div>
                  <div class="row-centered total">
                    <div class="legend-color w-[12px] h-[12px] rounded-[3px] bg-primary_trans"></div>
                    <span class="box-text text-[#919499]">Total loyers</span>
                  </div>
                </div>
                <div class="relative font-[500] text-[12px] leading-[18px] rents-periods">
                  <button class="btn flex justify-center py-[7px] px-[13px] gap-[10px] h-[32px] w-[100px] border-solid border-[1px] border-[#919499] rounded-[12px] items-center">
                    {{ choosenReportPeriod }}
                    <img :src="assets.downArrow" alt="">
                  </button>
                  <div class="scale-0 absolute top-[100%] bg-white py-[5px] shadow-mdDark rounded-[5px] w-full periods-options">
                    <div
                      v-for="period in reportPeriods.filter((p) => p !== choosenReportPeriod)"
                      :key="period"
                      @click="choosenReportPeriod = period "
                      class="btn period-option"
                    >
                      {{ period }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="max-w-full overflow-auto">
              <img :src="assets.rents" alt="">
            </div>
          </div>
          <!-- usefull Infos -->
          <div class="box1 flex flex-col gap-[19px]" v-if="usefullInfos">
            <div class="flex flex-col gap-[1px] box1-header border-solid border-b-[1px] border-b-gray_5 pb-[13px]">
              <div class="flex justify-between">
                <p class="box-title">Informations utiles</p>
                <img :src="assets.menu" alt="">
              </div>
              <p class="box-text">Quelques informatinos pour vous aider à mieux gérer</p>
            </div>
            <div class="w-full flex flex-col">
              <UsefullInfo
                v-for="{ text, value, link } in usefullInfos"
                :key="text+link"
                :text="text"
                :value="value"
                :link="link"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<style>
.dashboardPage .rents-periods:hover .periods-options {
  transform: scale(1);
}

.dashboardPage .rents-periods:hover button img{
  transform: rotate(180deg);
}

.dashboardPage .rents-periods .period-option{
  padding: 10px;
  text-align: center;
  width: 100%;
  border-bottom: 1px solid rgba(128, 128, 128, 0.137);
}
.dashboardPage .rents-periods .period-option:last-child{
  border-bottom: none;
}
</style>