import MarketPlaceHome from '@/views/MarketPlace/MarketPlaceHome/FirstPage.vue'
import marketePlaceArchivesRoutes from './archives';
import marketplaceProductRoute from './product';
import marketePlaceOwnersRoutes from './owners';
import commercialAttachesRoutes from './commercialAttaches';

const marketPlaceRoutes = [
  {
    path: "/marketplace",
    name: "marketPlace",
    component: MarketPlaceHome,
  },
  ...marketePlaceOwnersRoutes,
  ...marketePlaceArchivesRoutes,
  ...marketplaceProductRoute,
  ...commercialAttachesRoutes
];

export default marketPlaceRoutes;