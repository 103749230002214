<script>
  import RegistersView from '../RegistersView.vue'
  import ColBoard from '@/containers/Registers/ColBoard/ColBoard.vue'
  import { columns, path, pageTitle } from './datas'

  export default {
    data(){
      return {
        columns,
        path,
        pageTitle
      }
    },
    components: {
      RegistersView,
      ColBoard
    },
  }
</script>

<template>
  <RegistersView :path="path">
    <div class="container">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <div class="container-body">
        <div
          class="container--column"
          v-for="(column, index) in columns"
          :key="'col-'+index"
        >
          <ColBoard
            v-for="(board, index) in column.boards"
            :key="'board-'+index"
            :board="board"
          />
        </div>
      </div>
    </div>
  </RegistersView>
</template>

<style src="./RegistersHome.css"></style>