import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// redirect to login page if unauthenticated

router.beforeEach(async (to) => {
  // redirect to login page if not logged and trying to access a restricted page
  const publicPages = [
    '/login', '/signup', '/signup/form', '/mot-de-passe-oublie', '/mot-de-passe-oublie/nouveau/', '/login/tiers', '/valider-votre-mail/', '/email-envoye/'
  ];  
  const authRequired = !publicPages.includes(to.path);
  const user = JSON.parse(localStorage.getItem('xxl-profil-immo-user'));

  if (authRequired && !user) {
    console.log(user);
    return '/signup'
  }
  if (!authRequired && user) {
    return "/"
  }
})

export default router;