import client from "@/views/client";

const baseUrl = "/auth/user"

export const api = {
  getUserInfos: () => client.get(`${baseUrl}/current_user_info`),
  getUserPhoto: () =>  client.get(`/auth/logged_user/get_avatar`),
  updateUserInfos: (data) => client.patch(`${baseUrl}/update_info`, data),
  updateWorkUserInfos: (data) => client.patch(`${baseUrl}/update_last_info/`, data),
  updateUserPassword: (data) => client.patch(`${baseUrl}/update_password`, {data}),
  updateUserPhoto:(data) => client.post(`/auth/logged_user/user/init_avatar`, {data}),
  registerOwner:(data)=> client.post(`/gl/owner/add`, {data})
}

export default api;
