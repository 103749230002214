import { defineStore } from "pinia";
import api from "../api";

import {
  generalInfos,
  ibanInfos,
  accountingInfos,
  taxationInfos,
} from "./NewCoOwner/datas";
import { noEmptyFields, initialList, initProcess } from "@/utils/helpers";

const useCoOwnerStore = defineStore("owner", {
  state: () => ({
    loading: false,
    status: "",
    error: "",
   
    coOwner: {
      ...generalInfos,
      ...accountingInfos,
      ...taxationInfos,
      ...ibanInfos,
    },
   
    getCoOwnerProcess: initProcess,
    getCoOwnersProcess: initProcess,
    deletionProcess: initProcess,
    updateCoOwnerProcess: initProcess,
    updateCoOwnerIbanProcess: initProcess,
    updatedCoOwnerDatas: [],
    updatedCoOwnerIbanDatas:[],
   
    coOwners: initialList,
   
    coOwnerIbans: initialList,
    properties: initialList,
   
  }),
  actions: {
   
    async createCoOwner() {
      // handle owner creation
      if (!this.loading) {
        console.log(this.coOwner);
        if (
          // checking if no field is empty

          noEmptyFields(this.coOwner, ["complete_address", "fax", "phone"])
        ) {
          this.loading = true;
          this.status = "pending";
          // execute request
          try {
            const resp = await api.addCoOwner(this.coOwner);
            console.log({ resp });
            if (resp.status === 200) {
              this.loading = false;
              this.status = "success";
              location.replace("/gestion-locative/coproprietaires");
            } else {
              this.loading = false;
              this.status = "failure";
              this.error = resp?.response?.data?.detail;
            }
          } catch (error) {
            this.loading = false;
            this.status = "failure";
            this.error = error?.data?.detail;
            console.log({ error });
          }
        } else {
          this.loading = false;
          this.status = "failure";
          this.error =
            "Vous semblez avoir omis certains des champs obligatoires !";
        }
      } else {
        //
      }
    },

    async getCoOwner(coOwnerId) {
      // taking user info from local storage
      try {
        this.getCoOwnerProcess = { loading: true, status: "pending" };
        const resp = await api.getCoOwner(coOwnerId);
        if (resp) {
          //   console.log({ resp })
          this.coOwner = resp.data.owner_info[0];
          this.coOwnerIbans = resp.data.iban_info[0];
        //   let rows = [];
        //   let cols = ["iban", "bic", "creditor_name", "ics_number"];
        //   resp.data.iban_info.map((item) => {
        //     let row = [];
        //     cols.map((col) => {
        //       row.push(item[col]);
        //     });
        //     rows.push(row);
        //   });
        //   this.coOwnerIbans = {
        //     ...this.coOwnerIbans,
        //     list: rows,
        //     total: resp.data.iban_info.length,
        //   };
          
          this.coOwnerProperties = resp.data.properties;
          this.getCoOwnerProcess = { loading: false, status: "success" };
        } else {
          // alert("Une erreur s'est produite !")
          this.getCoOwnerProcess = {
            loading: false,
            status: "failure",
            error: "Une erreur s'est produite !",
          };
        }
      } catch (getCoOwnerError) {
        console.log({ getCoOwnerError });
        this.getCoOwnerProcess = {
          loading: false,
          status: "failure",
          error: getCoOwnerError?.data?.detail,
        };
      }
    },
   
    async updateCoOwner(id) {
        this.updateCoOwnerProcess = {loading: true, status: "pending", error:""}
      // merging user data into owner infos in a single datas objects
      console.log("updating coOwner: " + id + " ...");
      let updatedDatas = {};
      // console.log({updatedCoOwnerDatas: this.updatedCoOwnerDatas})
      this.updatedCoOwnerDatas.map(
        (name) =>
          (updatedDatas = { ...updatedDatas, [name]: this.coOwner[name] })
      );
      updatedDatas = { ...updatedDatas, id };

      // console.log({updatedDatas})
      // sending request
      try {
        const resp = await api.updateCoOwner(updatedDatas);
        if (resp.status === 200) {
          console.log({ updatedCoOwner: resp });
          this.updateCoOwnerProcess = {loading: false, status: "success", error:"Opération réussie"}
          // location.reload()
        } else {
          console.log({ resp });
          // alert("Une erreur s'est produite !\nVeuillez Réessayer plus tard !")
          this.updateCoOwnerProcess = {loading: false, status: "failure", error:resp?.response?.data?.detail ||
          "Veuillez vérifier vos informations !"}
          
        }
      } catch (error) {
        console.log({ error });
        this.updateOwnerProcess = {loading: false, status: "failure", error:error.data.detail}
        
      }
    },
    async deleteCoOwner(id) {
      // delete owner
      this.deletionProcess = { loading: true, status: "pending" };
      try {
        const deletedOwner = await api.deleteCoOwner({
          id,
          owner_or_co_owner: "co_owner",
        });
        if (deletedOwner?.status === 200) {
          console.log({ deletedOwner });
          this.deletionProcess = { loading: false, status: "success" };
          location.replace("/gestion-locative/coproprietaires");
          
        } else {
          this.deletionProcess = {
            loading: false,
            status: "failure",
            error: "Une erreur s'est produite !",
          };
          console.log({ deletedOwner2: deletedOwner });
        }
      } catch (deletionError) {
        this.deletionProcess = {
          loading: false,
          status: "failure",
          error: "Une erreur s'est produite !",
        };
        console.log({ deletionError });
      }
    },
  
    async getCoOwnersList(offset) {
      // sending request
      try {
        this.getCoOwnersProcess = {
          loading: true,
          status: "pending",
          error: "",
        };
        const resp = await api.getCoOwnersList(10, offset);
        if (resp) {
          let rows = [];
          let cols = ["id", "lastname", "first_name", "email", "cell_phone"];
          resp.data.list.map((item) => {
            let row = [];
            cols.map((col) => {
              row.push(item[col]);
            });
            rows.push(row);
          });
          this.coOwners = {
            ...this.coOwners,
            list: rows,
            total: resp.data.total,
          };
          console.log({ coOwners: this.coOwners });
          this.getCoOwnersProcess = { loading: false, status: "success" };
        } else {
          this.getCoOwnersProcess = {
            loading: false,
            status: "failure",
            error: "Une erreur s'est produite, Veuillez réessayer plus tard !",
          };
        }
      } catch (error) {
        console.log({ error });
        this.getCoOwnersProcess = {
          loading: false,
          status: "failure",
          error: error?.data?.detail,
        };
      }
    },
    async updateCoOwnerIban(id, owner_id) {
        this.updateCoOwnerIbanProcess = {loading: true, status: "pending", error:""}
        // merging user data into owner infos in a single datas objects
        console.log('updating coownerIbn: '+id+' ...')
        let updatedDatas = {};
        console.log({updatedCoOwnerIbanDatas: this.coOwneribans})
        this.updatedCoOwnerIbanDatas.map((name) => (
          updatedDatas = { ...updatedDatas, [name]: this.coOwner[name]}
        ))
        updatedDatas = { ...this.coOwneribans, id, owner_id }
  
        // console.log({updatedDatas})
        // sending request
        try {
          const resp = await api.updateCoOwnerIban(updatedDatas)
          if (resp.status === 200) {
            console.log({ updatedOwner: resp })
            this.updateCoOwnerIbanProcess = {loading: false, status: "success", error:"Opération réussie"}
            // location.reload()
          } else {
            console.log({resp})
            // alert("Une erreur s'est produite !\nVeuillez Réessayer plus tard !")
            this.updateCoOwnerIbanProcess = {loading: false, status: "failure", error: resp?.response?.data?.detail || "Veuillez vérifier vos informations !"}
           
          }
        } catch (error) {
          console.log({ error })
          this.updateCoOwnerIbanProcess = {loading: false, status: "failure", error:  error.data.detail}
  
        }
      },
  },
});

export default useCoOwnerStore;
