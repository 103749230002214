<script>
import { mapActions, mapWritableState } from 'pinia'
import RentalManagement from '../../RentalManagement.vue'
import EditionForm from '@/containers/EditionForm/EditionForm.vue'
import TextField1 from '@/components/TextField1/TextField1.vue'
import { path, pageTitle, formSteps} from './datas'
import useLeaseStore from '../../Leases/store'
import useTenantStore from '../../Tenants/store'


//import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
export default{
    components: {
        RentalManagement,
        EditionForm,
        TextField1,
    
        // ErrorMessage
    },
    data(){
        return{
            path,
            pageTitle, 
            steps: formSteps,
            currentStep: 0, 
           
        }
    },
    computed: {
        ...mapWritableState(useLeaseStore, ['leases','makeCall', 'lease','loading', 'status', 'error']),
        ...mapWritableState(useTenantStore, ['tenantOwners'])
    },
    methods: {
        ...mapActions(useLeaseStore, ['getLeaseList']),
        ...mapActions(useTenantStore, ['getTenants']),

        handleUpdateValue(name, newValue) {
     
        this.lease[name] = newValue;
        },

        handleSelect(event){
        
        this.lease[name] = event.target.value;
        console.log(event.target.value)
        },
    },
    mounted(){
        this.getLeaseList(0);
        this.getTenants();

    },
    watch : {
        offset(value){
            this.getLeaseList(value);
            this.getTenants();

        }
    }
}
</script>
<template>
    <h1>Hello World</h1>
    <RentalManagement :path="path">
        <div class="container">
            <h1  class="container-title"> {{ pageTitle }} </h1>
            <div class="container-body">
                <EditionForm :save="save" :steps="steps" @step="(step => currentStep = step)" :loading="loading" :status="status">
                    <template #form>
                        <div class="flex w-full items-start">
                            <div class="flex flex-col min-w-[48%]">
                                <div class="flex flex-row mb-2"><label>N° Contrat <span>: </span></label>
                                <p class="required-tag text-red ms-1">*</p></div>
                                <select @change="handleSelect($event)" class="form-select rounded-md">
                                <option disabled value="">Selectionnez</option>
                                <option v-for="(item, i) in leases.list" :key="i" :value="item">
                                {{ item[1]}}</option>
                                </select>
                                
                            </div>
                        </div>
                       
                        <div class="flex w-full justify-start">
                            <!-- <div class="flex flex-col min-w-[45%] me-9">
                                <div class="flex flex-row mb-2"><label>Locataire <span>: </span></label>
                                <p class="required-tag text-red ms-1">*</p></div>
                                <select v-model="rent.tenant_id" class="form-select rounded-md">
                                <option disabled value="">Selectionnez</option>
                                <option v-for="(item, i) in tenantOwners.list" :key="i" :value="item[0]">
                                {{ item[1]}}</option>
                                </select>
                                
                            </div> -->
                    
                                <TextField1 fullWidth class="me-3" label="Locataire" :value="rent.tenant_id"  @update:value="newValue => rent.tenant_id = newValue"/>
                            
                          
                                <TextField1 fullWidth label="Date" type="date" :value="rent.date_rent"  @update:value="newValue => rent.date_rent = newValue"/>
                           

                            
                        </div>
                       
                    </template>

                </EditionForm>
            </div>
        </div>
    </RentalManagement>
</template>