<script>

import ButtonContent from '../ButtonContent/ButtonContent.vue';
export default {
  props: {
    text: String,
    leftIcon: String,
    rightIcon: String,
    fullRounded: Boolean,
    type: String,
    unActive: Boolean,
    unClickable: Boolean,
    loading: Boolean,
    status: String,
    dangerous: Boolean,
    isAsync: {
      type: Boolean,
      default: false
    }
  },
  components: { ButtonContent }
}
</script>

<template>
  <button :type="type" class="btn button-one" :class="{ 'button-one__full-rounded': fullRounded, 'button-one__unactive': unActive, 'button-one__unclickable': unClickable, 'button-one__dangerous': dangerous }">
    <img v-if="leftIcon" :src="leftIcon" alt="left-icon">
    <ButtonContent v-if="isAsync" :loading="loading" :status="status" :originalText="text" />
    <div v-else>{{text}}</div>
    <img v-if="rightIcon" :src="rightIcon" alt="right-icon">
  </button>
</template>

<style src="./ButtonOne.css"></style>