import { path as basePath } from "../MarketPlaceHome/datas"

// base url for the pages above this page in the global infrastructure
const baseUrl = basePath[basePath.length-1].url+"/attaches-commerciaux"

// path to page
export const path = [
  ...basePath,
  {
    label: "attaché commerciaux",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Vos attachés commerciaux"
