<script>
  import TextField1 from '@/components/TextField1/TextField1.vue';
  import SelectField1 from '@/components/SelectField1/SelectField1.vue';

  export default {
    components: {
      TextField1,
      SelectField1
    }
  }
</script>

<template>
  <div class="description-step">
    <section>
      <div>
        <TextField1 label="Titre" />
        <TextField1 label="Descriptif" textarea />
      </div>
      <div>
        <SelectField1 label="Type de bien" />
        <SelectField1 label="Type précis" />
      </div>
    </section>
    <section>
      <TextField1 label="N° de bien" />
      <div>
        <TextField1 label="Adresse" textarea />
        <TextField1 label="Complemenet d'adresse" />
      </div>
      <div>
        <TextField1 label="Code postale" small />
        <TextField1 label="Ville" />
      </div>
      <div>
        <TextField1 label="Ville anonce" />
        <SelectField1 label="Ville secteur" />
      </div>
      <TextField1 label="Date de disponibilité" type="date" small />
    </section>
  </div>
</template>

<style>
.product--edit-form--part .description-step section:last-child{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.product--edit-form--part .description-step section > div{
  display: flex;
  justify-content: flex-start;
}
</style>