import rentalManagementRoutes from './RentalManagement'
import accoutingRoutes from './Accounting'
import componentsRoutes from './ComponentsView'
import dashboardRoutes from './Dashboard'
import extranetRoutes from './Extranet'
import financesRoutes from './Finances'
import gedRoutes from './GED'
import registerRoutes from './Registers'
import marketPlaceRoutes from './MarketPlace'
import worksRoutes from './works'
import errorsRoutes from './Errors'
import authRoutes from './Auth'
import syndicsRoutes from './Syndics'
import accountRoutes from './Account'


const routes = [
  ...rentalManagementRoutes,
  ...accoutingRoutes,
  ...componentsRoutes,
  ...dashboardRoutes,
  ...extranetRoutes,
  ...financesRoutes,
  ...gedRoutes,
  ...registerRoutes,
  ...marketPlaceRoutes,
  ...worksRoutes,
  ...errorsRoutes,
  ...authRoutes,
  ...syndicsRoutes,
  ...accountRoutes
];

export default routes