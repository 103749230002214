<script>
  import TextField1 from '@/components/TextField1/TextField1.vue';
  import SelectField1 from '@/components/SelectField1/SelectField1.vue';

  export default {
    components: {
      TextField1,
      SelectField1
    }
  }
</script>

<template>
  <div>
    <section>
      <div>
        <SelectField1 label="Avancement" />
        <SelectField1 label="Négociateur lié" />
      </div>
      <div>
        <SelectField1 label="Type de mandat" />
        <TextField1 label="N° de mandat" />
        <TextField1 label="Date du mandat" type="date" small />
        <SelectField1 label="Objectif" type="date" />
      </div>
      <div>
        <h2>Infos contacts pour vos anonces</h2>
        <TextField1 label="Nom à afficher" />
        <TextField1 label="Téléphone à afficher" type="phone" />
        <TextField1 label="Email à afficher" />
      </div>
    </section>
    <section>
      <h2>Détail de vente</h2>
      <div>
        <TextField1 label="NEt vendeur" type="number" />
        <SelectField1 label="Honoraires payés par"  />
      </div>
      <div>
        <TextField1 label="Taux d'honoraires" />
        <TextField1 label="% honos à la charge du vendeur" placeholder="...%" />
      </div>
      <TextField1 label="honoraires" />
      <TextField1 label="Prix de vente" type="number" />
    </section>
  </div>
</template>