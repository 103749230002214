import { path as basePath } from "../RegistersHome/datas"

// base url for the pages above this page in the global infrastructure
const baseUrl = basePath[basePath.length-1].url+"/archives"

// path to page
export const path = [
  ...basePath,
  {
    label: "archives",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Registres Archivés"
