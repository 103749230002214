import emailSentRoutes from './emailSent';
import logInRoutes from './logIn';
import resetPasswordRoutes from './resetPassword';
import signUpRoutes from './signUp';
import validateAccountRoutes from './validateAccount';
  
const authRoutes = [
  ...logInRoutes,
  ...signUpRoutes,
  ...resetPasswordRoutes,
  ...validateAccountRoutes,
  ...emailSentRoutes
];

export default authRoutes;