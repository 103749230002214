import FirstPage from '../../views/RentalManagement/CoTenants/FirstPage.vue'
import NewCoTenant from '../../views/RentalManagement/CoTenants/New/NewCoTenant.vue'
import CoTenantView from '../../views/RentalManagement/CoTenants/CoTenantView/CoTenantView.vue'

// url de base des pages relatives aux locataires
const baseUrl = "/gestion-locative/co-locataires"


const coTenantsRoutes = [
    {
      path: baseUrl,
      name: "coTenants",
      component: FirstPage,
    },
    {
      path: baseUrl+"/creer",
      name: "newCoTenant",
      component: NewCoTenant
    },
    {
      path: baseUrl+"/:cotenantId",
      name: "tenant",
      component: CoTenantView
    }
  ];
  
  export default coTenantsRoutes