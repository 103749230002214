<script>
  import SideBar from '@/containers/Home/SideBar/SideBar.vue';
  import PathViewer from '../../containers/PathViewer/PathViewer.vue';
  import HeaderOne from '@/containers/Home/HeaderOne/HeaderOne.vue';
  import modules from '@/utils/data/modules'
  import { path } from './data';

  import unlockAnime from '@/assets/animes/unlock.gif'

  export default{
    components: {
      PathViewer,
      SideBar,
      HeaderOne
    },
    data(){
      return{
        modules,
        active: modules[0],
        unlockAnime,
        path
      }
    },
    watch: {
      active(newValue){
        localStorage.setItem("home-active-module", JSON.stringify(newValue))
      }
    },
    mounted(){
      this.active = JSON.parse(localStorage.getItem('home-active-module')) || modules[0]
    },
    computed:{
      menu() {
        const activeModule = modules.find(({label}) => label.toLowerCase() == this.active.label.toLocaleLowerCase());
        return activeModule?.menu;
      }
    }
  }
</script>

<template>
  <div class="page home">
    <PathViewer :path="path"/>
    <div class="body">
      <SideBar
        @active="(result) => active = result"
        :on="active"
      />
      <div class="container">
        <h1 class="container-title">Sous-sections du module {{ active.label }}</h1>
        <HeaderOne :module="active" />
        <div class="container-body">
          <div v-if="active.unlocked" class="module-links">
            <router-link
              :to="item.url"
              class="box1 module--link" 
              v-for="item in menu" :key="item"
            >
              <img :src="item.icon" alt="">
              <h3>{{ item.label }}</h3>
            </router-link>
          </div>
          <div class="unlock-cta" v-else>
            <img :src="unlockAnime" alt="" />
            <router-link class="unlock-cta--link" :to="`/upgrade/${active.label.split(' ').join('-').toLowerCase()}`">Débloquer le module</router-link> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./DashboardView.css"></style>