<script>
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue';

  export default {
    props: {
        links: Array,
        title: String
    },
    components: { ButtonOne }
}
</script>

<template>
  <div class="reg-header">
    <!-- <h1 class="reg-header--title title">{{ title }}</h1> -->
    <div 
      v-for="({ label, url }, index) in links"
      :key="'link-'+index"
      class="reg-header-link"
    >
      <!-- <div class="reg-header--separator"></div> -->
      <router-link :to="url">
        <ButtonOne :text="label" />
      </router-link>
    </div>
  </div>
</template>

<style src="./RegHeader.css"></style>