<script>
import logo from "@/assets/logo_3.svg";
import research from "@/assets/icons/research1.svg";
import notif from "@/assets/icons/notifs1.svg";
import person from "@/assets/icons/person1.svg";
import modules from "@/assets/icons/modules.svg";

import modulesLinks from "@/utils/data/modules";

const navLinks = [{ name: "Messagerie", url: "/extranet/conversations" }];

export default {
  data() {
    return {
      assets: {
        logo,
        research,
        notif,
        person,
        modules,
      },
      navLinks,
      modulesLinks,
      toggleMenuOn: false,
      user: localStorage.getItem("xxl-profil-immo-user"),
    };
  },
  methods: {
    toggleMenu() {
      this.toggleMenuOn = !this.toggleMenuOn;
      this.$nextTick(() => {
        this.$refs["toggle-menu"].focus();
      });
    },
    handleBlur() {
      setTimeout(() => {
        this.toggleMenuOn = false;
      }, 200);
    },
    handleLogout() {
      this.user = null;
      localStorage.clear("xxl-profil-immo-user");
      location.replace("/");
    },
  },
  watch: {
    $route() {
      this.toggleMenuOn = false;
    },
    user() {
      this.$router.push("/");
    },
  },
};
</script>

<template>
  <header>
    <div class="header--navigation">
      <router-link to="/"><img :src="assets.logo" alt="logo" /></router-link>
      <nav v-if="user">
        <div class="toggle-modules">
          <img
            :src="assets.modules"
            alt="modules"
            class="toggle-button"
            @click="toggleMenu"
          />
          <div
            class="toggle-list"
            v-if="toggleMenuOn"
            tabindex="-1"
            ref="toggle-menu"
            @blur.prevent="handleBlur"
          >
            <router-link
              v-for="({ label, url }, index) in modulesLinks.filter(
                ({ unlocked }) => unlocked
              )"
              :key="'module-link--' + label + index"
              class="toggle-link"
              :to="url"
              @click="$refs['toggle-menu'].focus()"
            >
              {{ label }}
            </router-link>
          </div>
        </div>
        <router-link
          v-for="(link, index) in navLinks"
          :to="link.url"
          :key="`link-${index}`"
          class="header-link"
        >
          {{ link.name }}
        </router-link>
      </nav>
    </div>
    <div class="header--buttons">
      <form class="header--buttons--research">
        <button type="submit"><img :src="assets.research" alt="" /></button>
        <input type="text" placeholder="Recherchez ici" />
      </form>
      <div v-if="user" class="header--buttons">
        <span class="header-button">
          <img :src="assets.notif" alt="notifications" />
        </span>
        <router-link class="header-button" to="/account">
          <img :src="assets.person" alt="account" />
        </router-link>
        <button @click="handleLogout" class="btn">Déconnexion</button>
      </div>
      <div class="flexed" v-else>
        <button><router-link to="/login">Connexion</router-link></button>
        <button><router-link to="/signup">Enregistrement</router-link></button>
      </div>
    </div>
  </header>
</template>

<style src="./HeaderVue.css"></style>
