import { defineStore } from "pinia";
import { generalInfos} from "./New/datas"
import {  initialList, initProcess } from "@/utils/helpers";
import api from "../api";

const useRentStore = defineStore('appel-loyer', {
    state : () => ({
        loading : false,
        status : "",
        error : "",
        rent : {
            ...generalInfos,
        },
        getRentsProcess : initProcess,
        getRentProcess : initProcess,
        updateRentProcess : initProcess,
        updatedRentDatas : [],
        rents : initialList,
        deletionProcess: initProcess
    }),
    actions:{
        // get all lease call
        async getAllLeaseCall(offset){
            this.getRentsProcess = {loading:true, status: "pending", error:""}

            try {
                const resp = await api.getAllCall(10, offset)
                console.log({resp : resp})
                if(resp.status === 200){
                    let rows = []
                    let cols = ['id_lease','date_to', 'charge', 'id_property', 'id_tenant', 'echeance_date']
                    resp.data.listcall.map((item) =>{
                        let row = []
                        cols.map((col) =>{
                            if(col === 'id_property'){
                             
                                row.push(item[col]['type_de_bien'])
                            }else if(col === 'id_tenant'){

                                row.push(item[col]['lastname'])
                            }else{
                                row.push(item[col])
                            }
                           
                        })
                        rows.push(row)
                    })
                    this.rents = { ...this.rents, list: rows, total: resp.data.nbItem}
                    console.log({ rents: this.rents })
                    this.getRentsProcess = {loading: false, status: "success"}
                }else{
                    this.getRentsProcess = {loading: false, status:"failure", error: "Une erreur s'est produite, veuillez réessayer plus tard !"}
                }
            } catch (error) {
                console.log({error})
                this.getRentsProcess = {loading:false, status: "failure", error: error?.data?.detail}
            }
        }
    }
})

export default useRentStore;