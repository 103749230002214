import { path as basePath } from '../SyndicsHome/datas'

// path

export const path = [
  ...basePath,
  {
    label: "copropriétaires",
    url: basePath[basePath.length-1].url+"/coproprietaires"
  }
]

// page title value
export const pageTitle = "Copropriétaires"
