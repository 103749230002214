import {path as basePath} from "../datas";


// path
export const path = [
    ...basePath,
    {
      label: "Nouveau",
      url: basePath[basePath.length - 1].url + "/creer",
    },
  ];

  // page title value
export const pageTitle = "Ajoutez un compteur";

export const meterInfos ={
    id_bien_info:0,
    numero_pdl: "",
  numero_pce: "",
  nom_compteur: "",
  localisation: ""
}; 

export const formSteps = [
    {
      name: "general",
      label: "Infos",
    },]