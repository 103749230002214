import client from "@/views/client";

const baseUrl = "/gl/owner"

export const ownerApi = {
  addOwner: (data) => client.post(`${baseUrl}/add`, data),
  getOwner: (ownerId) => client.get(`${baseUrl}/owner_info/${ownerId}`),
  getOwnerIban: (ownerIbanId) => client.get(`${baseUrl}/iban_info/${ownerIbanId}`),
  updateOwner: (data) => client.patch(`${baseUrl}/update_owner`, data),
  updateOwnerIban: (data) => client.patch(`${baseUrl}/update_iban`, data),
  
  // get owners list with pagnination handeling
  getOwners: (limit, offset) => client.get(`${baseUrl}/list_owners/${limit}/${offset}`),
  deleteOwner: (data) => client.delete(`${baseUrl}/delete_owner`, {data})
}

export default ownerApi;
