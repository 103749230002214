export const toastifySettings = {
   // Only allow one notification on the screen at a time
   singular: false ,

   duration : 7000,

   // With backdrop or not
   withBackdrop: false,
 
   // Backdrop color
   backdrop: "rgba(0, 0, 0, 0.2)",
 
   // "top-left", "top-center", "top-right", "center-left", 
   // "center-center", "center-right", "bottom-left", "bottom-center", "bottom-right"
   position: "top-right",
 
   // The duration in milliseconds the error notification should be visible for
   errorDuration: 7000,
 
   // The duration in milliseconds the success notification should be visible for.
   successDuration: 7000,
 
   // The duration in milliseconds the warning and info notification should be visible for.
   warningInfoDuration: 1500,
 
   // Whether the notifications disappears after the set time.
   canTimeout: true,
 
   // Whether the notifications can be paused by hovering over them.
   canPause: true,
 
   // Hide the progress bar
   hideProgressbar: false,
 
   // Show default title
   defaultTitle: true,
 
   // or "light"
   theme: "dark",
 
   // Whether new notifications should display on top of the stack or not.
   orderLatest: true,
 
   // If string supplied this will apply the usual transition classes (eg.: .name-enter-active);
   // If object supplied it expect a name and optionally a moveClass (this class has to use !important for its rules) attribute.
   // The name will be applied as above. The move class applied when the notifications adjust their position.
   // String / Object
   transition: null,
 
   // If set to false, no icon will be shown on the notification.
   iconEnabled: true,
 
   // Drag to dismiss or not
   draggable: true,
 
   // How far the notification should be dragged to dismiss
   dragThreshold: 0.75,
 
   // Base icon class
   baseIconClass: null,
 
   // Max number of toasts allowed to be visible at a time
   maxToasts: 6,
 
   // If turned on, only toasts with unique mode/type will be show. Others are queued.
   oneType: false,
}