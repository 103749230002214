<script>
  export default {
    name: "AnimatedLoader",
    props: {
      dark: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<template>
  <div class="spinner" :class="{ 'spinner-dark': dark }">
  </div>
</template>

<style>
  .spinner{
    width: 20px;
    height: 20px;
    border: 4px solid #fafafa;
    border-top-color: transparent;
    border-radius: 50%;
    animation: ldio-vf23pybtrmd 1s linear infinite;
    margin: auto;
  }
  
  .spinner-dark{
    border: 4px solid var(--color-primary);
    border-top-color: transparent;
  }
  @keyframes ldio-vf23pybtrmd {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>