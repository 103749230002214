import ProfilInfos from '@/views/Account/ProfilInfos/FirstPage.vue'
import financesRoutes from "./finances";
import invoicesQuotationsRoutes from './invoicesQuotations';
import supportRoutes from './support';
import changePasswordRoutes from './changePassword';

const baseUrl = "/account"

const accountRoutes = [
  {
    path: baseUrl,
    name: "account",
    component: ProfilInfos,
  },

  ...financesRoutes,
  ...invoicesQuotationsRoutes,
  ...supportRoutes,
  ...changePasswordRoutes,
];

export default accountRoutes;