import { path as basePath } from "../RegistersHome/datas"

// base url for pages directly above this page in global project infrastructure
const baseUrl = basePath[basePath.length-1].url+"/liste"

// path to the page
export const path = [
  ...basePath,
  {
    label: "registres de mandats",
    url: baseUrl
  }
]

// page title value
export const pageTitle = "Registres de mandat"

// datas relative to header links
export const header = {
  title: 'Registre de mandat',
  links: [
    {
      label: "+ Ajouter un nouveau mandat", 
      url: baseUrl+"/projets?tranform=true",
    }
  ]
}