import FirstPage from '@/views/Syndics/Archives/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/syndics/archives"

const archivesRoutes = [
  {
    path: baseUrl,
    name: "coproArchives",
    component: FirstPage,
  }
];

export default archivesRoutes
