import FirstPage from '@/views/Extranet/Users/FirstPage.vue'

// url de base des pages relatives aux utilisateurs
const baseUrl = "/extranet/utilisateurs"

const usersRoutes = [
  {
    path: baseUrl,
    name: "extranetUsers",
    component: FirstPage,
  }
];

export default usersRoutes
