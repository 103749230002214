<script>
  import WorksView from '../WorksView.vue'
  import { path, pageTitle } from './datas'

  export default {
    data(){
      return {
        path,
        pageTitle,
      }
    },
    components: {
      WorksView
    },
  }
</script>

<template>
  <WorksView :path="path">
    <div class="container works-home">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <!-- corp de la page -->
      <div class="container-body">
        container body
      </div>
    </div>
  </WorksView>
</template>

<style src="./FirstPage.css"></style>