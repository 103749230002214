import client from "@/views/client";

const baseUrl = "/co_owner"

export const coOwnerApi = {
  addCoOwner: (data) => client.post(`${baseUrl}/add`, data),
  getCoOwner: (co_ownerId) => client.get(`${baseUrl}/co_owner_info/${co_ownerId}`),
  getCoOwnerIban: (co_ownerIbanId) => client.get(`${baseUrl}/co_owner_iban_info/${co_ownerIbanId}`),
  updateCoOwner: (data) => client.patch(`${baseUrl}/update`, data),
  updateCoOwnerIban: (data) => client.patch(`${baseUrl}/update_iban`, data),
  // get owners list with pagnination handeling
  getCoOwnersList: (limit, offset) => client.get(`${baseUrl}/list/${limit}/${offset}`),
  deleteCoOwner: (data) => client.delete(`/gl/owner/delete_owner`, {data})
 
}

export default coOwnerApi
