import FirstPage from '@/views/Syndics/Technic/FirstPage.vue'

// url de base des pages relatives aux encaissements
const baseUrl = "/syndics/technique"

const technicRoutes = [
  {
    path: baseUrl,
    name: "coproTechnic",
    component: FirstPage,
  }
];

export default technicRoutes
