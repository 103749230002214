<script>  
  import infos from '@/assets/icons/infos1.svg'

  export default {
    props: {
      text: String
    },
    data(){
      return{
        assets: {
          infosIcon: infos
        }
      }
    }
  }
</script>

<template>
  <div class="infos-text">
    <img :src="assets.infosIcon" alt="">
    <p>{{ text }}</p>
  </div>
</template>

<style src="./InfosText.css"></style>