<script>
import WorksView from '../WorksView.vue'
import Header2 from '@/containers/RentalManagement/Header2/RMHeader2.vue'

//import WorksWrapper from '@/containers/Works/WorksWrapper/WorksWrapper.vue'
import { mapActions, mapWritableState } from 'pinia'
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage.vue'
import AnimatedLoader from '@/components/Loader/AnimatedLoader.vue'
import { initialList } from '@/utils/helpers'
import ButtonOne from '@/components/ButtonOne/ButtonOne.vue'
import {items, path, pageTitle, worksActions } from './datas'
import useEquipementStore from './store'
import TableOne from '@/containers/TableOne/TableOne.vue'

  export default {
    data(){
      return {
        path,
        pageTitle,
        worksActions,
        initialList,
        items
      }
    },
    components: {
      WorksView,
     // WorksWrapper,
      ErrorMessage,
      AnimatedLoader,
      ButtonOne,
      TableOne,
      Header2
    },
    computed: {
      ...mapWritableState(useEquipementStore, ['equips', 'getEquipmentsProcess'])
    },
   methods:{
    ...mapActions(useEquipementStore, ['getEquipements'])
   },
   mounted(){
    this.getEquipements(0);
   },
   watch:{
    offset(value){
      this.getEquipements(value);
    }
   }
  }
</script>

<template>
  <WorksView :path="path">
    <div class="container works-home">
      <h1 class="container-title"> {{ pageTitle }} </h1>
      <Header2 :items="items" />
      <!-- body page -->
      <div class="container-body">
        <AnimatedLoader dark v-if="getEquipmentsProcess.loading" />
                <div v-else-if="getEquipmentsProcess.status === 'failure'" class="flex flex-col items-center gap-4">
                    <ErrorMessage :message="getEquipmentsProcess.error" />
                    <ButtonOne isAsync @click="getEquipements(offset)" text="Actualiser"
                        :loading="getEquipmentsProcess.loading" :status="getEquipmentsProcess.status" />
                </div>
        <!-- <WorksWrapper
        v-else  title="Équipements"
          :button="{ text: 'Ajouter un équipement', url: '/travaux/equipements/creer' }"
          :table="{ cols: ['ID', 'Désignation'], rows: equips.list, actions: initialList.actions }"
          :worksActions="worksActions"/> -->
          <TableOne v-else :title="Équipements" :cols="['ID', 'Désignation']" :rows="equips.list"
                    :total="equips.total" :actions="initialList.actions" baseUrl="/travaux/equipements"
                    noSearch @emitedOffset="(value) => offset = value" />
      </div>
    </div>
  </WorksView>
</template>

<style src="./FirstPage.css"></style>