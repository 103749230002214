import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { registerLicense } from '@syncfusion/ej2-base'
import VueConfetti from 'vue-confetti'
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css';


import App from './App.vue'
import router from './router'
import { toastifySettings } from './settings'

// license registering for syncfusion libraries
registerLicense("Mgo+DSMBaFt+QHFqVkNrWE5Gf0BAXWFKblZ8QGZTfVpgFChNYlxTR3ZcQV1iTntbdExlXHhZ;Mgo+DSMBPh8sVXJ1S0d+X1RPckBAVHxLflF1VWZTelt6dlFWACFaRnZdQV1gSn5ScUBrW3ZYd3ZS;ORg4AjUWIQA/Gnt2VFhhQlJBfVtdX2pWfFN0RnNcdVt1fldCcDwsT3RfQF5jSn1Td0JmUX1XdnxTQQ==;MTU2NDI5MkAzMjMxMmUzMTJlMzMzNUNhbzhrRjg1K25GMjRpUkgwcVFueFZlbW13S0ZPdEQ4YjdkRGxCaUR2SU09;MTU2NDI5M0AzMjMxMmUzMTJlMzMzNVNHRSs5b01LNko5M3RzaGV0MGhnOEU5bm5BSFpyT2VTWm5iczN0RU1LL1E9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RGQmFAYVF2R2BJelR1cV9EZEwxOX1dQl9gSX1QdkRkXHdceXNQQWc=;MTU2NDI5NUAzMjMxMmUzMTJlMzMzNWNBbjJIemJQaUFVdHA1ZEZhRFpzb2JRaFpmVkNzbGQ0YnIyMlhaMmFjOUk9;MTU2NDI5NkAzMjMxMmUzMTJlMzMzNWRaRi82QUlXQXZteHFtN25YbUlZUmxwYXZlcjNyS0xPWVZKNWU3MS9YOHc9;Mgo+DSMBMAY9C3t2VFhhQlJBfVtdX2pWfFN0RnNcdVt1fldCcDwsT3RfQF5jSn1Td0JmUX1XeHZUQQ==;MTU2NDI5OEAzMjMxMmUzMTJlMzMzNVpJZ1VSS2NNVG1sNmhxVzhVallkS2YvRGtBdHBnTkNGVG1KSGt6LzdlQWM9;MTU2NDI5OUAzMjMxMmUzMTJlMzMzNUQydnlSNFJKb212a0FQWmduR2ZKWE5Pb2dLQjVDSTMzbkJnQmZUK2RRdlk9;MTU2NDMwMEAzMjMxMmUzMTJlMzMzNWNBbjJIemJQaUFVdHA1ZEZhRFpzb2JRaFpmVkNzbGQ0YnIyMlhaMmFjOUk9")

createApp(App)
  .use(VueConfetti)
  .use(Vue3Toastify, toastifySettings)
  .use(createPinia())
  .use(router)
  .mount('#app')